import React, { useEffect, useState } from 'react';
import { Translate } from 'react-redux-i18n';
import { Switch, NavLink, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import CanPerform from '../CanPerform';
import Card from '../Card';
import ShipmentItemList from './ShipmentItemList';
import {
  getShipmentItems,
  toggleShipmentItem,
  scheduleShipments,
  deleteShipment,
  toggleAllShipmentsSelected,
  delayShipment,
  undoDelayShipment,
  updateShipmentDelay,
  setSelectDelayDateModalVisibility,
  updateDelayDateModalVisibility
} from '../../actions';
import { APPLICATION_BASE_PATH, DATE_FORMAT } from '../../constants';
import { Fragment } from 'react';
import Modal from '../Modal';
import DatePickerWithFallback from '../DatePickerWithFallback';

const MonitorShipmentsView = ({
  reports,
  authToken,
  getShipmentItems,
  toggleShipmentItem,
  scheduleShipments,
  deleteShipment,
  toggleAllShipmentsSelected,
  authority,
  locale,
  setSelectDelayDateModalVisibility,
  updateDelayDateModalVisibility,
  ...rest
}) => {
  const [selectedShipment, setSelectedShipment] = useState();
  const [selectedDelayDate, setSelectedDelayDate] = useState(
    moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(7, 'd').toDate()
  );
  const [selectedShipmentToUpdate, setSelectedShipmentToUpdate] = useState();
  const [selectedDelayDateToUpdate, setSelectedDelayDateToUpdate] = useState();

  useEffect(() => {
    getShipmentItems(authToken);
  }, [authToken, getShipmentItems]);

  const scheduleShipmentItems = () => {
    scheduleShipments(authToken, reports.selectedShipmentItems);
  };

  const removeShipment = (e, shipmentId) => {
    e.stopPropagation();
    deleteShipment(authToken, shipmentId);
  };

  const delayShipment = () => {
    rest.delayShipment(authToken, selectedShipment, moment(selectedDelayDate).format(DATE_FORMAT));
    setSelectedDelayDate(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(7, 'd').toDate());
  };

  const undoDelayShipment = (shipment) => {
    rest.undoDelayShipment(authToken, shipment, moment().add(-1, 'd').format(DATE_FORMAT));
  };

  const updateDelayDate = () => {
    rest.updateShipmentDelay(
      authToken,
      selectedShipmentToUpdate,
      moment(selectedDelayDateToUpdate).format(DATE_FORMAT)
    );
    setSelectedDelayDateToUpdate(null);
  };

  return (
    <Fragment>
      <CanPerform action="managePatients">
        <Card
          titleI18nKey="reports_view.shipments.header"
          additionalClass="large"
          corner={
            <div className="card-tab-links">
              <NavLink exact to={`/${APPLICATION_BASE_PATH}/services/monitor-shipments`} activeClassName="is-active">
                <Translate value="reports_view.shipments.active_shipments" />
              </NavLink>
              <NavLink
                exact
                to={`/${APPLICATION_BASE_PATH}/services/monitor-shipments/delayed`}
                activeClassName="is-active"
              >
                <Translate value="reports_view.shipments.delayed_shipments" />
              </NavLink>
            </div>
          }
        >
          <Switch>
            <Route
              exact
              path={`/${APPLICATION_BASE_PATH}/services/monitor-shipments`}
              requiredAuthority={authority.managePatients}
              render={(props) => (
                <ShipmentItemList
                  {...props}
                  isLoadingShipments={reports.loadingShipmentItems}
                  error={reports.shipmentItemsError}
                  selectedShipmentItems={reports.selectedShipmentItems}
                  shipmentItems={reports.shipmentItems}
                  toggleAllShipmentsSelected={toggleAllShipmentsSelected}
                  currentlyDeletingShipments={reports.currentlyDeletingShipments}
                  toggleShipmentItem={toggleShipmentItem}
                  removeShipment={removeShipment}
                  schedulingShipments={reports.schedulingShipments}
                  scheduleShipmentItems={scheduleShipmentItems}
                  onSelectDelayDate={(shipment) => {
                    setSelectDelayDateModalVisibility(true);
                    setSelectedShipment(shipment);
                  }}
                />
              )}
            />
            <Route
              exact
              path={`/${APPLICATION_BASE_PATH}/services/monitor-shipments/delayed`}
              requiredAuthority={authority.managePatients}
              render={(props) => (
                <ShipmentItemList
                  {...props}
                  undoDelayShipment={undoDelayShipment}
                  isLoadingShipments={reports.loadingShipmentItems}
                  error={reports.shipmentItemsError}
                  selectedShipmentItems={reports.selectedDelayedShipmentItems}
                  shipmentItems={reports.delayedShipmentItems}
                  currentlyDeletingShipments={reports.currentlyDeletingShipments}
                  removeShipment={removeShipment}
                  schedulingShipments={reports.schedulingShipments}
                  updateDelayDate={(shipment) => {
                    updateDelayDateModalVisibility(true);
                    setSelectedShipmentToUpdate(shipment);
                  }}
                />
              )}
            />
          </Switch>
        </Card>
      </CanPerform>
      <Modal
        visible={reports.selectDelayDateModalVisible}
        actionCompletable={true}
        size="auto"
        headerI18nKey="reports_view.shipments.delay_header"
        actionI18nKey="reports_view.shipments.delay"
        onClose={() => setSelectDelayDateModalVisibility(false)}
        onActionCompleted={delayShipment}
        actionCompleting={reports.delayingShipment}
      >
        <DatePickerWithFallback
          inline
          locale={locale}
          selectedDate={selectedDelayDate}
          maxDate={null}
          minDate={moment().toDate()}
          placeholderI18nKey="global.choose_date"
          onChange={(date) => setSelectedDelayDate(date)}
          onChangeNative={(e) =>
            e.target.value ? setSelectedDelayDate(moment(e.target.value).toDate()) : setSelectedDelayDate(undefined)
          }
        />
      </Modal>
      <Modal
        visible={reports.updateDelayDateModalVisible}
        actionCompletable={!!selectedDelayDateToUpdate}
        size="auto"
        headerI18nKey="reports_view.shipments.update_delay_header"
        actionI18nKey="reports_view.shipments.update_delay"
        onClose={() => updateDelayDateModalVisibility(false)}
        onActionCompleted={updateDelayDate}
        actionCompleting={reports.updatingDelay}
      >
        <DatePickerWithFallback
          inline
          locale={locale}
          selectedDate={selectedDelayDateToUpdate}
          maxDate={null}
          minDate={moment().add(1, 'd').toDate()}
          placeholderI18nKey="global.choose_date"
          onChange={(date) => setSelectedDelayDateToUpdate(date)}
          onChangeNative={(e) =>
            e.target.value
              ? setSelectedDelayDateToUpdate(moment(e.target.value).toDate())
              : setSelectedDelayDateToUpdate(undefined)
          }
        />
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    authority: state.sharedData.authorityTypes,
    reports: state.reports,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  getShipmentItems,
  toggleShipmentItem,
  scheduleShipments,
  deleteShipment,
  toggleAllShipmentsSelected,
  delayShipment,
  undoDelayShipment,
  updateShipmentDelay,
  setSelectDelayDateModalVisibility,
  updateDelayDateModalVisibility
};

export default connect(mapStateToProps, mapActionsToProps)(MonitorShipmentsView);
