import React from "react";
import LoadingIcon from "../LoadingIcon";
import ErrorMessage from "../ErrorMessage";
//import { FixMe } from "../../types/FixMe";
import "./ContentLoader.scss";

type Props = {
  children: React.ReactNode;
  isLoading: boolean;
  error: FixMe;
  errorTitleI18n: string;
};

const ContentLoader: React.FC<Props> = ({
  children,
  isLoading,
  error,
  errorTitleI18n,
}) => {
  return (
    <div className="pos-rel">
      {isLoading ? (
        <div className="content-loader">
          <LoadingIcon type="icon" />
        </div>
      ) : error ? (
        <ErrorMessage error={error} errorI18nKey={errorTitleI18n} />
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
};

export default ContentLoader;
