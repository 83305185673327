import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import thunk from 'redux-thunk';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import { translationsObject } from './i18n';
import tokenRefreshMiddleware from './middleware/tokenRefreshMiddleware';
import trackingMiddleware from './middleware/trackingMiddleware';

const browserLanguage = navigator.language.substr(0, 2);
const defaultLanguage = Object.getOwnPropertyNames(translationsObject).includes(browserLanguage)
  ? browserLanguage
  : 'en';

moment.locale(defaultLanguage);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    return { type: action.type };
  }
});

export default function configureStore() {
  const finalCreateStore = compose(
    applyMiddleware(thunk, tokenRefreshMiddleware, trackingMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    sentryReduxEnhancer
  )(createStore);

  const store = finalCreateStore(rootReducer);
  if (module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(require('./reducers')));
  }

  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translationsObject));
  if (!localStorage.getItem('locale')) {
    localStorage.setItem('locale', defaultLanguage);
  }
  store.dispatch(setLocale(localStorage.getItem('locale')));
  return store;
}
