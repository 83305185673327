import { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import moment from 'moment';
import saveAs from 'file-saver';
import api from '../../../api/apiClient';
import ContentLoader from '../../ContentLoader';
import DatePickerWithFallback from '../../DatePickerWithFallback';
import styles from './AccountingReportView.module.scss';
import LoaderButton from '../../LoaderButton';
import { showNotification } from '../../../actions';
import { AccountingContext } from '../AccountingProvider';
import LoadingIcon from '../../LoadingIcon';

type AccountState = {
  number: string;
  name: string;
  description: string;
  debit: number;
  credit: number;
  sum: number;
  itemCount: number;
  ingoingBalance: number;
  outgoingBalance: number;
};

type AccountSummary = {
  debit: number;
  credit: number;
  sum: number;
  accounts: AccountState[];
  ingoingBalance: number;
  outgoingBalance: number;
};

const AccountingReportView = ({ authToken, locale, showNotification }) => {
  const { fromDate, toDate, setFromDate, setToDate } = useContext(AccountingContext);
  const [accountSummary, setAccountSummary] = useState<AccountSummary>();
  const [error, setError] = useState();
  const [isFetchingTransactions, setIsFetchingTransactions] = useState(false);
  const [isFetchingInitial, setIsFetchingInitial] = useState(false);
  const [isDownloadingTransactions, setIsDownloadingTransactions] = useState<string | undefined>();

  useEffect(() => {
    setIsFetchingInitial(accountSummary?.credit === undefined);
    setIsFetchingTransactions(true);
    api
      .getAccountSummary(authToken, moment(fromDate).format('YYYY-MM-DD'), moment(toDate).format('YYYY-MM-DD'))
      .then((res) => {
        setAccountSummary(res);
      })
      .catch((err) => setError(err))
      .finally(() => {
        setIsFetchingTransactions(false);
        setIsFetchingInitial(false);
      });
  }, [fromDate, toDate]);

  const downloadTransactions = async (format: 'pdf' | 'csv') => {
    setIsDownloadingTransactions(format);
    const acceptFormat = format === 'csv' ? 'text/csv' : 'application/pdf';

    try {
      const response = await api.getAccountTransactions(
        authToken,
        acceptFormat,
        moment(fromDate).format('YYYY-MM-DD'),
        moment(toDate).format('YYYY-MM-DD')
      );
      const blob = await response.blob();
      saveAs(
        blob,
        `Verifikat_${moment(fromDate).format('YYYY-MM-DD')}_${moment(toDate).format('YYYY-MM-DD')}.${format}`
      );
    } catch (error) {
      showNotification(I18n.t('notification.download_transactions.error'), 'error');
    } finally {
      setIsDownloadingTransactions(undefined);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <h2>
            <Translate value="accounting.summary.header" />
          </h2>
          {isFetchingTransactions && !isFetchingInitial ? <LoadingIcon type="spinner-secondary" size="small" /> : null}
        </header>
        <div className="space-between">
          <div className="flex">
            <div className="mr-20">
              <h4>
                <Translate value="global.from_date" />
              </h4>
              <DatePickerWithFallback
                locale={locale}
                selectedDate={fromDate}
                maxDate={undefined}
                minDate={null}
                selectsStart
                startDate={fromDate}
                endDate={toDate}
                placeholderI18nKey="global.choose_date"
                onChange={setFromDate}
                onChangeNative={(e) =>
                  e.target.value ? setFromDate(moment(e.target.value).toDate()) : setFromDate(fromDate)
                }
              />
            </div>
            <div>
              <h4>
                <Translate value="global.to_date" />
              </h4>
              <DatePickerWithFallback
                locale={locale}
                selectedDate={toDate}
                maxDate={undefined}
                minDate={null}
                selectsEnd
                startDate={fromDate}
                endDate={toDate}
                placeholderI18nKey="global.choose_date"
                onChange={setToDate}
                onChangeNative={(e) =>
                  e.target.value ? setToDate(moment(e.target.value).toDate()) : setToDate(toDate)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <ContentLoader isLoading={isFetchingInitial} error={error} errorTitleI18n="accounting.summary.error">
        <div className={`table-container mt-30 ${isFetchingTransactions ? 'opacity-6' : ''}`}>
          <table className="table w-100">
            <thead className={styles.tableHeader}>
              <tr>
                <th>
                  <Translate value="accounting.summary.table_header.account" />
                </th>
                <th>
                  <Translate value="accounting.summary.table_header.name" />
                </th>
                <th>
                  <Translate value="accounting.summary.table_header.debit" />
                </th>
                <th>
                  <Translate value="accounting.summary.table_header.credit" />
                </th>
                <th>
                  <Translate value="accounting.summary.table_header.sum" />
                </th>
                <th>
                  <Translate value="accounting.summary.table_header.ingoing_balance" />
                </th>
                <th>
                  <Translate value="accounting.summary.table_header.outgoing_balance" />
                </th>
              </tr>
            </thead>
            <tbody>
              {accountSummary?.accounts.map((account) => (
                <tr key={account.number}>
                  <td>{account.number}</td>
                  <td>{account.name}</td>
                  <td>{account.debit?.toLocaleString() ?? '-'}</td>
                  <td>{account.credit?.toLocaleString() ?? '-'}</td>
                  <td>{account.sum?.toLocaleString() ?? '-'}</td>
                  <td>{account.ingoingBalance?.toLocaleString() ?? '-'}</td>
                  <td>{account.outgoingBalance?.toLocaleString() ?? '-'}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>
                  <Translate value="accounting.summary.table_header.sum" />
                </th>
                <th></th>
                <th>{accountSummary?.debit.toLocaleString() ?? '-'}</th>
                <th>{accountSummary?.credit.toLocaleString() ?? '-'}</th>
                <th>{accountSummary?.sum.toLocaleString() ?? '-'}</th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </ContentLoader>
      <div className="mt-30 text-right">
        <h3>
          <Translate value="accounting.summary.download" />
        </h3>
        <div className={styles.downloadButtons}>
          <LoaderButton
            buttonType="secondary"
            isLoading={isDownloadingTransactions === 'pdf'}
            onClick={() => downloadTransactions('pdf')}
          >
            PDF <span className="icon pdf" />
          </LoaderButton>
          <LoaderButton
            buttonType="secondary"
            isLoading={isDownloadingTransactions === 'csv'}
            onClick={() => downloadTransactions('csv')}
          >
            CSV <span className="icon csv" />
          </LoaderButton>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(AccountingReportView);
