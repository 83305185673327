import { useState } from 'react';

function getQueryStringValue(key: string): string | null {
  return new URLSearchParams(window.location.search).get(key);
}

export default function useQueryParam(
  key: string,
  defaultValue: string,
  ignoreValues: string[] = []
): [string, (value: string) => void] {
  const [query, setQuery] = useState(getQueryStringValue(key) || defaultValue);

  const updateUrl = (newValue: string) => {
    setQuery(newValue);

    const query = new URLSearchParams(window.location.search);

    if (newValue.trim() !== '' && !ignoreValues.includes(newValue)) {
      query.set(key, newValue);
    } else {
      query.delete(key);
    }

    const { protocol, pathname, host } = window.location;
    // @ts-ignore
    const newUrl = `${protocol}//${host}${pathname}${query.size ? '?' : ''}${query.toString()}`;
    window.history.replaceState({}, '', newUrl);
  };

  return [query, updateUrl];
}
