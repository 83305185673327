import { createContext, useCallback, useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import api from '../../api/apiClient';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import * as Sentry from '@sentry/browser';
import { CURRENT_ENV, DATE_FORMAT, JIRA_RESOLVED_STATUSES } from '../../constants';
import metadata from '../../metadata.json';
import { deduplicateObjects } from '../../utils';

const env = process.env.REACT_APP_API_ENV || CURRENT_ENV;
const basePath = metadata.environments[env].apiUrl.replace(/\/+$/, '');

type ChatContextValue = {
  authToken: string;
  isOpen: boolean;
  isLoadingInitial: boolean;
  messages: ChatMessage[];
  authors: Author[];
  error?: unknown;
  fullSizeImageModalVisible: boolean;
  fullSizeImageObjectUrl: string;
  fullSizeImageError?: unknown;
  open(): void;
  close(): void;
  getMessageAuthor(authorId: string): Author;
  sendMessage(messageText: string): Promise<Response>;
  closeImageModal(): void;
  loadFullSizeImage(image?: ChatAttachment): void;
};

const ChatContext = createContext<ChatContextValue>({
  authToken: '',
  isOpen: false,
  isLoadingInitial: false,
  messages: [],
  authors: [],
  fullSizeImageModalVisible: false,
  fullSizeImageObjectUrl: '',
  open: () => null,
  close: () => null,
  getMessageAuthor: () => ({}),
  sendMessage: () => new Promise((r) => r(new Response())),
  closeImageModal: () => null,
  loadFullSizeImage: () => null
});

const ChatProvider = ({ authToken, patient, localAuthor, jiraIssues, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingInitial, setIsLoadingInitial] = useState(false);
  const [error, setError] = useState<unknown>();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [authors, setAuthors] = useState<Author[]>([]);
  const [fullSizeImageModalVisible, setFullSizeImageModalVisible] = useState(false);
  const [fullSizeImageObjectUrl, setFullSizeImageObjectUrl] = useState('');
  const [fullSizeImageError, setFullSizeImageError] = useState<unknown>();

  const getChatMessages = useCallback(async () => {
    if (!patient.guid) {
      return;
    }

    setError(undefined);
    try {
      const fromTimestamp = messages.length
        ? moment(messages[messages.length - 1].sentTs, DATE_FORMAT)
            // Subtract an hour to handle clock differences between client and server
            // in case the last message was locally added. Duplicates are removed below anyway.
            .add(-1, 'hour')
            .format(DATE_FORMAT)
        : undefined;
      const res = await api.getSupportChatMessages(authToken, patient.guid, fromTimestamp);

      if (!res.ok) {
        throw new Error('Could not get chat messages');
      }

      const json = await res.json();
      const newMessages = json.messages as unknown as ChatMessage[];
      const newAuthors = json.authors as unknown as Author[];

      let existingMessages = [...messages];

      // If we're only getting new messages, mark the previous ones as read
      if (fromTimestamp) {
        existingMessages = existingMessages.map((m) => ({ ...m, wasReadByPatient: true }));
      }

      setMessages(deduplicateObjects(fromTimestamp ? [...existingMessages, ...newMessages] : newMessages, 'messageId'));
      setAuthors(deduplicateObjects([...authors, ...newAuthors], 'userId'));
    } catch (e) {
      setError(e);
    }
  }, [patient, messages]);

  useEffect(() => {
    if (!patient.guid) {
      return;
    }

    setIsLoadingInitial(true);
    getChatMessages().then(() => setIsLoadingInitial(false));
  }, [patient]);

  useEffect(() => {
    setIsOpen(
      jiraIssues.some((issue) => issue.type === 'SupportMessage' && !JIRA_RESOLVED_STATUSES.includes(issue.status))
    );
  }, [jiraIssues]);

  useEffect(() => {
    if (!patient.guid) {
      return;
    }

    const abortController = new AbortController();

    const listenForData = async () => {
      await fetchEventSource(`${basePath}/admin/chat/${patient.guid}/sse`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        },
        signal: abortController.signal,
        // @ts-ignore
        onopen(res) {
          if (res.status >= 400 && res.status !== 429) {
            Sentry.captureException(new Error(`SSE response code: ${res.status}`));
            abortController.abort();
          }
        },
        onmessage(event) {
          if (event.event === 'AdminNewChatMessage') {
            getChatMessages();
          }
        },
        onclose() {
          console.log('Connection closed by the server');
        },
        onerror(err) {
          Sentry.captureException(err);
        }
      });
    };

    listenForData();

    return () => abortController.abort();
  }, [authToken, patient, getChatMessages]);

  const open = useCallback(() => setIsOpen(true), []);

  const close = useCallback(() => setIsOpen(false), []);

  const getMessageAuthor = (authorId: string) => {
    const foundAuthor = authors.find((author) => author.userId === authorId);
    const fallbackAuthor: Author = { name: 'System', userId: '0' };

    if (!foundAuthor) {
      console.error(`Message author not found: ${authorId}`);
      return fallbackAuthor;
    }

    return foundAuthor;
  };

  const sendMessage = async (messageText: string): Promise<Response> => {
    const message: ChatMessageRequestBody = {
      messageId: crypto.randomUUID(),
      text: messageText,
      type: 'MESSAGE'
    };

    return new Promise((resolve, reject) => {
      api
        .sendSupportChatMessage(authToken, patient.guid, message)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Could not send message');
          }

          // This endpoint returns no data. Construct a local message object and add it.
          const newMessage: ChatMessage = {
            ...message,
            id: Math.floor(Math.random() * 10000),
            author: localAuthor.guid,
            sentTs: new Date().toISOString(),
            updatedTs: new Date().toISOString(),
            chatChannel: 'SUPPORT',
            wasReadByPatient: false,
            isNew: true
          };

          setMessages([...messages, newMessage]);

          if (!authors.map((a) => a.userId).includes(localAuthor.guid)) {
            setAuthors([
              ...authors,
              { userId: localAuthor.guid, name: `${localAuthor.givenName} ${localAuthor.familyName}`, role: 'SUPPORT' }
            ]);
          }
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const closeImageModal = useCallback(() => {
    setFullSizeImageModalVisible(false);
    setFullSizeImageObjectUrl('');
  }, []);

  const loadFullSizeImage = async (image?: ChatAttachment) => {
    if (!image) {
      return;
    }

    setFullSizeImageModalVisible(true);
    setFullSizeImageError(undefined);
    try {
      const response = await fetch(`${basePath}${image.filePath}?maxWidth=${1000}`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Could not get image');
      }

      const imageData = await response.blob();
      setFullSizeImageObjectUrl(URL.createObjectURL(imageData));
    } catch (e) {
      setFullSizeImageError(e);
    }
  };

  const value = {
    authToken,
    isOpen,
    isLoadingInitial,
    messages,
    authors,
    error,
    fullSizeImageModalVisible,
    fullSizeImageObjectUrl,
    fullSizeImageError,
    open,
    close,
    getMessageAuthor,
    sendMessage,
    closeImageModal,
    loadFullSizeImage
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    localAuthor: state.auth.token.user,
    patient: state.members.currentMember,
    jiraIssues: state.jira.jiraIssues
  };
};

export default connect(mapStateToProps)(ChatProvider);

export function useChat() {
  return useContext(ChatContext);
}
