import moment from 'moment';
import useSWR from 'swr';
import Tooltip from '@material-ui/core/Tooltip';
import { I18n, Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import { CAREGIVER_ROLE } from '../../../constants';
import { useAbsence } from '../AbsenceProvider';
import { decamelize } from '../../../utils';
import Card from '../../Card';
import Select from '../../Select';
import SkeletonComponent from '../../SkeletonComponent';
import styles from './AbsenceList.module.scss';

const periodFilterOptions: SelectOption[] = [];
const date = moment();
const twoYearsFromNow = moment().add(2, 'years');

while (date.isBefore(twoYearsFromNow)) {
  periodFilterOptions.push({
    label: date.format('MMMM YYYY'),
    value: date.format('YYYY-MM-01')
  });
  date.add(1, 'month');
}

export default function AbsenceList() {
  const {
    doctors,
    nurses,
    selectedRoleFilter,
    setSelectedRoleFilter,
    selectedPeriodFilter,
    setSelectedPeriodFilter,
    selectedCaregiverFilter,
    setSelectedCaregiverFilter
  } = useAbsence();
  const searchParams = new URLSearchParams();

  if (selectedRoleFilter?.value) {
    searchParams.append('role', selectedRoleFilter.value.toLowerCase());
  } else {
    searchParams.delete('role');
  }

  if (selectedPeriodFilter?.value) {
    searchParams.append('from', selectedPeriodFilter.value);
    searchParams.append('to', moment(selectedPeriodFilter.value).add(1, 'month').format('YYYY-MM-DD'));
  } else {
    searchParams.delete('from');
    searchParams.delete('to');
  }

  if (selectedCaregiverFilter?.value) {
    searchParams.append('caregiver', selectedCaregiverFilter.value);
  } else {
    searchParams.delete('caregiver');
  }

  const { data: absences = [], isLoading } = useSWR<CaregiverAbsence[]>(
    // @ts-ignore
    `/admin/modules/caregiver-absence${searchParams.size ? `?${searchParams.toString()}` : ''}`
  );

  const caregiverRoles = [
    {
      label: I18n.t('global.doctor'),
      value: CAREGIVER_ROLE.DOCTOR
    },
    {
      label: I18n.t('global.nurse'),
      value: CAREGIVER_ROLE.NURSE
    }
  ];

  const caregiverOptions = [...doctors, ...nurses].map((c) => ({
    label: `${c.givenName} ${c.familyName}`,
    value: c.guid
  }));

  return (
    <Card
      titleI18nKey="manage_absence.list_heading"
      additionalClass="x-large"
      actionButtons={
        <Link to="manage-absence/new">
          <Translate value="manage_absence.create_heading" />
        </Link>
      }
    >
      <div className="flex space-x-2 mb-20">
        <Select
          title={I18n.t('global.role')}
          placeholder={I18n.t('global.choose_role')}
          isSearchable={false}
          value={selectedRoleFilter}
          onChange={setSelectedRoleFilter}
          classNamePrefix="single-select"
          options={caregiverRoles}
          isClearable
        />
        <Select
          title={I18n.t('global.period')}
          placeholder={I18n.t('global.choose_period')}
          isSearchable={false}
          value={selectedPeriodFilter}
          onChange={setSelectedPeriodFilter}
          classNamePrefix="single-select"
          options={periodFilterOptions}
          isClearable
        />
        <Select
          title={I18n.t('global.caregiver')}
          placeholder={I18n.t('global.choose_caregiver')}
          isSearchable={false}
          value={selectedCaregiverFilter}
          onChange={setSelectedCaregiverFilter}
          classNamePrefix="single-select"
          options={caregiverOptions}
          isClearable
        />
      </div>

      <div style={{ '--column-count': 4 } as React.CSSProperties}>
        <div className="row heading">
          <div>
            <Translate value="global.caregiver" />
          </div>
          <div>
            <Translate value="global.role" />
          </div>
          <div>
            <Translate value="manage_absence.absence_reason_heading" />
          </div>
          <div>
            <Translate value="manage_absence.period_heading" />
          </div>
        </div>

        {isLoading ? (
          <SkeletonComponent count={5} width="100%" height="42px" />
        ) : absences.length ? (
          absences.map((absence) => (
            <Link to={`manage-absence/${absence.id}/edit`} key={absence.id} className="link-row">
              <div className="row">
                <span>
                  {absence.caregiver?.givenName} {absence.caregiver?.familyName}
                </span>
                <span>
                  <Translate value={`global.${absence.caregiver?.caregiverRole}`} />
                </span>
                <span>
                  {absence.reason ? (
                    <Translate value={`manage_absence.reason.${decamelize(absence.reason)}`} />
                  ) : (
                    <em>
                      <Translate value="manage_absence.reason.missing" />
                    </em>
                  )}{' '}
                  ({absence.coveringCaregivers?.reduce((acc, curr) => acc + Number(curr.percentage), 0)} %)
                </span>
                <span className={styles.period}>
                  {moment(absence.from).format('D MMM')} – {moment(absence.to).format('D MMM')}
                  <IndicatorIcon absenceStart={absence.from} />
                </span>
              </div>
            </Link>
          ))
        ) : (
          <div>
            {selectedPeriodFilter?.value ? (
              <Translate value="manage_absence.no_absence_planned_during_period" />
            ) : (
              <Translate value="manage_absence.caregiver_no_absence" />
            )}
          </div>
        )}
      </div>
    </Card>
  );
}

export const IndicatorIcon = ({ absenceStart, absolute = true }) => {
  const isOngoing = moment().isSameOrAfter(moment(absenceStart), 'day');

  if (!isOngoing) {
    return null;
  }

  return (
    <Tooltip
      title={
        <Translate
          value={`manage_absence.${isOngoing ? 'absence_ongoing' : 'absence_about_to_start'}`}
          className="fs-12"
        />
      }
      arrow
      placement="top"
    >
      <span className={`${styles.indicator} ${isOngoing ? styles.ongoing : ''} ${absolute ? 'pos-abs' : ''}`}></span>
    </Tooltip>
  );
};
