import moment from 'moment';
import { CHAT_THUMBNAIL_WIDTH, DATE_FORMAT } from '../../../../constants';
import styles from './ChatMessage.module.scss';
import RenderNewLines from '../../../RenderNewLines';
import { ReactComponent as MessageReadIcon } from '../../../../assets/icons/delivered-checkmark.svg';
import errorThumbnailSrc from '../../../../assets/thumbnail-error.svg';
import { useEffect, useState } from 'react';
import { basePath } from '../../../../api/apiClient';
import useIsOnscreen from '../../../../hooks/useIsOnscreen';
import { Translate } from 'react-redux-i18n';
import { useChat } from '../../../../contexts/ChatProvider';

type Props = {
  message: ChatMessage;
  author: Author;
};

export default function ChatMessage({ message, author }: Props) {
  const { loadFullSizeImage } = useChat();
  const authorStyle = styles[`author-${author.role?.toLowerCase()}`];

  return (
    <div
      className={`${styles.wrapper} ${authorStyle || ''} ${
        styles[author.role === 'PATIENT' ? 'incoming' : 'outgoing']
      }`}
    >
      <div className={`${styles.bubble} ${message.isNew ? styles.animate : ''}`}>
        {message.attachment?.contentType === 'IMAGE' ? (
          <ChatMessageThumbnail
            imageSrc={message.attachment.filePath}
            onClick={() => loadFullSizeImage(message.attachment)}
          />
        ) : null}
        <RenderNewLines text={message.text} />
      </div>
      <div className={styles.author}>
        {author.role === 'SUPPORT' && message.wasReadByPatient ? <MessageReadIcon className="mr-5" /> : null}
        {author.name}
      </div>
      <div>
        <time>{moment(message.sentTs, DATE_FORMAT).format('HH:mm - YYYY-MM-DD')}</time>
      </div>
    </div>
  );
}

const ChatMessageThumbnail = ({ imageSrc, onClick }) => {
  const [isOnscreen, elementRef] = useIsOnscreen();
  const [objectUrl, setObjectUrl] = useState<string>();
  const [error, setError] = useState();
  const { authToken } = useChat();

  useEffect(() => {
    if (objectUrl || !isOnscreen) {
      return;
    }

    fetch(`${basePath}${imageSrc}?maxWidth=${CHAT_THUMBNAIL_WIDTH}`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Could not get thumbnail');
        }

        return response.blob();
      })
      .then((image) => {
        setObjectUrl(URL.createObjectURL(image));
      })
      .catch(setError);
  }, [isOnscreen, objectUrl]);

  return (
    <div ref={elementRef} className="pointer" onClick={onClick}>
      {error ? <img src={errorThumbnailSrc} /> : objectUrl ? <img src={objectUrl} /> : null}
      {error ? (
        <div className="error-message text-center">
          <Translate value="member_details.chat.thumbnail_error" />
        </div>
      ) : null}
    </div>
  );
};
