import { connect } from 'react-redux';
import moment from 'moment';
import { Translate, I18n } from 'react-redux-i18n';
import Button from '../../Button';
import api from '../../../api/apiClient';
import { showNotification, setCurrentMemberProperty } from '../../../actions';
import { DATE_FORMAT } from '../../../constants';

const PatientDataErasure = ({ authToken, patient, showNotification, setCurrentMemberProperty }) => {
  const handleClick = () => {
    if (window.confirm(I18n.t('member_details.deregister_confirmation'))) {
      deregister();
    }
  };

  const deregister = async () => {
    if (!patient.guid) {
      return;
    }

    try {
      const res = await api.deregisterPatient(authToken, patient.guid);

      const json = await res.json();

      if (res.ok) {
        showNotification(json.message, 'success');
        setCurrentMemberProperty('deregisterRequested', moment().format(DATE_FORMAT));
      } else {
        showNotification(json.message || I18n.t('error_boundary.header'), 'error');
      }
    } catch {
      showNotification(I18n.t('error_boundary.header'), 'error');
    }
  };

  return (
    <div className="card-container">
      <Button buttonType="secondary" onClick={handleClick} fullWidth disabled={!!patient.deregisterRequested}>
        <Translate value="member_details.deregister" />
      </Button>
      {patient.deregisterRequested ? (
        <Translate
          value="member_details.deregistered"
          date={moment(patient.deregisterRequested).format('YYYY-MM-DD')}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    patient: state.members.currentMember
  };
};

const mapActionsToProps = {
  showNotification,
  setCurrentMemberProperty
};

export default connect(mapStateToProps, mapActionsToProps)(PatientDataErasure);
