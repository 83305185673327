import * as types from '../constants/actionTypes';

const initialState = {
  cannedMessages: [],
  templateTags: []
};

const cannedMessagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CANNED_RESPONSES_REQUEST:
      return { ...state, loadingCannedMessages: true };
    case types.GET_CANNED_RESPONSES_SUCCESS:
      return {
        ...state,
        loadingCannedMessages: false,
        cannedMessages: action.payload,
        cannedMessagesError: undefined
      };
    case types.GET_CANNED_RESPONSES_ERROR:
      return {
        ...state,
        loadingCannedMessages: false,
        cannedMessagesError: action.payload
      };
    case types.GET_TEMPLATE_TAGS_REQUEST:
      return { ...state, loadingTemplateTags: true };
    case types.GET_TEMPLATE_TAGS_SUCCESS:
      return {
        ...state,
        loadingTemplateTags: false,
        templateTags: action.payload.map((tag) => {
          return {
            ...tag,
            key: `%{${tag.key}}`
          };
        }),
        templateTagsError: undefined
      };
    case types.GET_TEMPLATE_TAGS_ERROR:
      return {
        ...state,
        loadingTemplateTags: false,
        templateTagsError: action.payload
      };
    default:
      return state;
  }
};

export default cannedMessagesReducer;
