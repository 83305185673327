import React, { useReducer } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import Card from '../Card';
import CanPerform from '../CanPerform';
import LoaderButton from '../LoaderButton';
import { sendBulkReferrals } from '../../actions';
import './BulkReferralsView.scss';

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateMessage':
      return { ...state, message: action.message };
    case 'setFileContents':
      return { ...state, fileContents: action.fileContents, fileError: undefined };
    case 'setFileError':
      return { ...state, fileError: action.error };
    default:
      throw new Error();
  }
};

const BulkReferralsView = ({ authToken, reports, sendBulkReferrals }) => {
  const [state, dispatch] = useReducer(reducer, {
    message:
      '[Automatiskt meddelande] Nu finns det möjlighet att ta prover hos våra labb. Vi ser gärna att du tar dig till något av våra provtagningsställen för att ta blod- och urinprov. När vi har resultaten kan vi göra en bättre bedömning för vilka insatser som vi kan göra för dig framöver. I de bifogade instruktionerna finns all information som du behöver för att ta proverna.',
    fileContents: null
  });

  const EOL = '\r\n';

  const postMemberData = () => {
    if (!state.fileContents.every((line) => line.length === 12)) {
      return dispatch({ type: 'setFileError', error: 'reports_view.bulk_referrals.file_format_error' });
    }

    if (window.confirm(I18n.t('reports_view.bulk_referrals.confirm'))) {
      sendBulkReferrals(authToken, state.fileContents, state.message);
    }
  };

  const onFileInputChange = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (e) => {
        dispatch({ type: 'setFileContents', fileContents: e.target.result.split(EOL) });
      };
    }
  };

  return (
    <CanPerform action="sendCommunications">
      <div className="bulk-referrals">
        <Card
          titleI18nKey="reports_view.bulk_referrals.header"
          descriptionI18nKey="reports_view.bulk_referrals.description"
        >
          <div className="mb-20">
            <input type="file" id="bulk-csv" accept=".csv" onChange={onFileInputChange} />
            <label htmlFor="bulk-csv">
              <Translate value="reports_view.bulk_referrals.select_file" />
            </label>
          </div>

          <div className="mb-20">
            <h4>
              <Translate value="reports_view.bulk_referrals.message_header" />
            </h4>
            <textarea
              placeholder={I18n.t('reports_view.bulk_referrals.message_placeholder')}
              value={state.message}
              onChange={(e) => dispatch({ type: 'updateMessage', message: e.target.value })}
            ></textarea>
          </div>

          <div>
            <LoaderButton
              buttonType="primary"
              disabled={state.message.length === 0 || !state.fileContents}
              onClick={postMemberData}
              isLoading={reports.sendingBulkReferrals}
            >
              <Translate
                value="reports_view.bulk_referrals.send_referrals"
                numReferrals={state.fileContents ? state.fileContents.length : ''}
              />
            </LoaderButton>
          </div>

          {state.fileError ? (
            <div className="error-message-block mt-20">
              <Translate value={state.fileError} />
            </div>
          ) : null}
        </Card>
      </div>
    </CanPerform>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    reports: state.reports
  };
};

const mapActionsToProps = {
  sendBulkReferrals
};

export default connect(mapStateToProps, mapActionsToProps)(BulkReferralsView);
