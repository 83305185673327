import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';
import { usePartner, Partner } from '../PartnerProvider';
import SkeletonComponent from '../../SkeletonComponent';
import Card from '../../Card';
import styles from './PartnerList.module.scss';
import ErrorMessage from '../../ErrorMessage';

export default function PartnerList() {
  const { setPartners: setGlobalPartners, getPartners } = usePartner();
  const [partners, setPartners] = useState<Partner[]>([]);
  const [loadingPartners, setLoadingPartners] = useState(false);
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    setLoadingPartners(true);
    getPartners()
      .then((res) => {
        setPartners(res);
        setGlobalPartners(res);
      })
      .catch(setError)
      .finally(() => setLoadingPartners(false));
  }, []);

  return (
    <>
      <Card
        titleI18nKey="manage_partners.header"
        errorI18nKey="manage_partners.error"
        description={I18n.t('manage_partners.partner_list_sub_header')}
        additionalClass="x-large"
        actionButtons={
          <NavLink to="partners/new">
            <div className="vertical-align">
              <span className="icon add mr-5"></span>
              <span>
                <Translate value="manage_partners.add_new_partner.header" />
              </span>
            </div>
          </NavLink>
        }
      >
        {error ? (
          <ErrorMessage error={error} errorI18nKey="manage_partners.partner_list_error" />
        ) : loadingPartners ? (
          <>
            {new Array(3).fill(0).map((_, i) => (
              <SkeletonComponent key={i} width="100%" height="38px" />
            ))}
          </>
        ) : (
          partners.map((partner) => (
            <div className={styles.partner} key={partner.id}>
              <NavLink to={`partners/${partner.id}`}>
                <div className="flex space-between">
                  <div className={styles.partnerName}>{partner.name}</div>
                  <div className={styles.facilityCount}>
                    {partner.facilityCount === 1 ? (
                      <Translate value="manage_partners.partner_overview.single_facility" />
                    ) : (
                      <Translate
                        value="manage_partners.partner_overview.num_facilities"
                        facilityCount={partner.facilityCount || I18n.t('global.none')}
                      />
                    )}
                  </div>
                </div>
              </NavLink>
            </div>
          ))
        )}
      </Card>
    </>
  );
}
