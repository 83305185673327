// @ts-nocheck
import { Switch, NavLink, Redirect } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { APPLICATION_BASE_PATH } from '../../constants';
import PrivateRoute from '../PrivateRoute';
import ManageAbsenceView from '../ManageAbsenceView';
import CaregiverAllocationView from '../CaregiverAllocationView';
import CaregiverMetricsOverviewView from '../CaregiverMetricsOverviewView';

const PlanningView = ({ authorities, authority }) => {
  return (
    <div className="services">
      <div className="columns">
        <div className="column is-3 no-padding sub-menu-wrapper">
          <div className="sub-menu">
            <NavLink
              disabled={!authorities.includes(authority.manageAbsence)}
              to={`/${APPLICATION_BASE_PATH}/planning/manage-absence`}
              activeClassName="is-active"
            >
              <Translate value="planning_view.page.absence_management" />
            </NavLink>
            <NavLink
              disabled={!authorities.includes(authority.medicalManagement)}
              to={`/${APPLICATION_BASE_PATH}/planning/allocation`}
              activeClassName="is-active"
            >
              <Translate value="planning_view.page.allocation" />
            </NavLink>
            <NavLink disabled to={`/${APPLICATION_BASE_PATH}/planning/metrics`} activeClassName="is-active">
              <Translate value="planning_view.page.metrics" />
            </NavLink>
          </div>
        </div>
        <div className="column is-9 no-padding">
          <Switch>
            <PrivateRoute exact path={`/${APPLICATION_BASE_PATH}/planning`} component={NoSelection} />
            <PrivateRoute
              exact
              path={`/${APPLICATION_BASE_PATH}/planning/allocation`}
              component={CaregiverAllocationView}
              requiredAuthority={authority.medicalManagement}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/planning/manage-absence`}
              component={ManageAbsenceView}
              requiredAuthority={authority.manageAbsence}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/planning/metrics`}
              component={CaregiverMetricsOverviewView}
              requiredAuthority={authority.medicalManagement}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authorities: state.auth.authorities,
    authority: state.sharedData.authorityTypes
  };
};

export default connect(mapStateToProps)(PlanningView);

const NoSelection = () => {
  return <Redirect to={`/${APPLICATION_BASE_PATH}/planning/manage-absence`} />;
};
