import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { APPLICATION_BASE_PATH } from '../../../constants';
import { isCaregiver } from '../../../utils';
import Button from '../../Button';
import styles from './CaregiverAllocationView.module.scss';
import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import SkeletonComponent from '../../SkeletonComponent';
import { Translate } from 'react-redux-i18n';
import { basePath } from '../../../api/apiClient';
import { showNotification } from '../../../actions';

function CaregiverAllocationView({ authToken, user, showNotification, authorities, authority }) {
  const isUserCaregiver = isCaregiver(user);
  const [editMode, setEditMode] = useState(false);
  const [newCapacity, setNewCapacity] = useState('');
  const { data: allocationWrapper, isLoading } = useSWR<CaregiverAllocationWrapper>(
    isUserCaregiver ? `/admin/modules/caregiver-allocation?caregiverId=${user.guid}` : null
  );

  const allocation = allocationWrapper?.allocatedCaregivers[0];

  useEffect(() => {
    if (!newCapacity) {
      setNewCapacity(allocationWrapper?.allocatedCaregivers[0]?.capacity?.toString() ?? '');
    }
  }, [allocationWrapper]);

  if (user?.guid && !isUserCaregiver) {
    return <Redirect to={`/${APPLICATION_BASE_PATH}/user/${user.guid}`} />;
  }

  if (isLoading) {
    return <SkeletonComponent height="45px" width="100%" count={3} />;
  }

  const allocationPercentage =
    allocation?.assignedPatients && allocation?.capacity
      ? Number((allocation?.assignedPatients / allocation?.capacity) * 100)
      : 0;

  const saveNewCapacity = async () => {
    if (!user.guid) {
      return;
    }

    const res = await fetch(`${basePath}/admin/modules/caregiver-allocation/${user.guid}`, {
      headers: {
        authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PATCH',
      body: JSON.stringify({ capacity: Number(newCapacity) })
    });

    if (!res.ok) {
      showNotification('Kunde inte spara ny kapacitet.', 'error');
      return;
    }

    mutate(`/admin/modules/caregiver-allocation?caregiverId=${user.guid}`);
    setEditMode(false);
  };

  return (
    <>
      <div className="card-header">
        <Translate value="system_user_details.tab.allocation" />
      </div>

      <div className={styles.row}>
        <div>
          <strong>
            <Translate value="manage_allocation.headings.allocation" />
          </strong>
        </div>
        <div>
          <span>
            <strong className={allocationPercentage < 100 ? 'positive' : 'negative'}>
              {allocationPercentage.toFixed()} %
            </strong>{' '}
            (
            <Translate
              value="manage_allocation.num_patients"
              numPatients={allocation?.assignedPatients.toLocaleString()}
            />
            )
          </span>
        </div>
      </div>

      <div className={styles.row}>
        <div>
          <strong>
            <Translate value="manage_allocation.treatment_capacity" />
          </strong>
        </div>
        <div>
          <div className="flex space-between">
            <span>
              {editMode ? (
                <input
                  type="text"
                  value={newCapacity}
                  onChange={(e) => setNewCapacity(e.target.value.replace(/\D/g, ''))}
                />
              ) : (
                allocation?.capacity.toLocaleString()
              )}{' '}
              <Translate value="manage_allocation.patients" />
            </span>
            {authorities.includes(authority.medicalManagement) ? (
              <Button buttonType="text" onClick={editMode ? saveNewCapacity : () => setEditMode(true)}>
                <Translate value={`global.buttons.${editMode ? 'save' : 'edit'}`} />
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <strong>
            <Translate value="manage_allocation.share_of_inflow" />
          </strong>
        </div>
        <div>
          <span>{allocation?.assignmentPercentage} %</span>
        </div>
      </div>

      {authorities.includes(authority.medicalManagement) ? (
        <div className="text-right mt-20">
          <Button as="a" buttonType="secondary" to={`/planning/allocation`}>
            <Translate value="manage_allocation.go_to_list" />
          </Button>
        </div>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    user: state.systemUsers.currentSystemUser,
    authorities: state.auth.authorities,
    authority: state.sharedData.authorityTypes
  };
};

const mapActionsToProps = {
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(CaregiverAllocationView);
