import { Component, Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Switch, Prompt, NavLink } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';
import ContentLoader from '../ContentLoader';
import ProfileCard from '../ProfileCard';
import { APPLICATION_BASE_PATH } from '../../constants';
import ErrorFallback from '../ErrorFallback';
import {
  getSystemUser,
  clearCurrentSystemUser,
  updateSystemUser,
  getSystemUserProfileImage,
  showNotification
} from '../../actions';
import './SystemUserDetailsView.scss';
import SystemUserPermissions from './SystemUserPermissions';
import PermanentTransferView from './PermanentTransferView';
import PlannedAbsenceView from './PlannedAbsenceView';
import CaregiverMetricsView from './CaregiverMetricsView';
import CaregiverAllocationView from './CaregiverAllocationView';
import { isCaregiver } from '../../utils';

class SystemUserDetailsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newUserflashMessageShown: false
    };
  }

  componentDidMount() {
    this.props.getSystemUser(this.props.authToken, this.props.match.params.memberId);
  }

  componentDidUpdate(prevProps) {
    const { currentSystemUser } = this.props.systemUsers;

    if (this.props.location?.state?.newUser && !this.state.newUserflashMessageShown) {
      this.props.showNotification(
        I18n.t('notification.create_user.success', {
          type: I18n.t(this.props.location.state.userTypeI18nKey).toLowerCase()
        }),
        'success'
      );
      this.setState({
        newUserflashMessageShown: true
      });
    }

    if (currentSystemUser && currentSystemUser.guid !== prevProps.systemUsers.currentSystemUser.guid) {
      if (currentSystemUser.profileImage && currentSystemUser.profileImage.id) {
        this.props.getSystemUserProfileImage(
          this.props.authToken,
          this.props.match.params.memberId,
          currentSystemUser.profileImage.id
        );
      }
    }
  }

  render() {
    const { currentSystemUser, fetchingSystemUser, systemUserError } = this.props.systemUsers;
    const userIsCaregiver = isCaregiver(currentSystemUser);

    return (
      <Fragment>
        <Prompt
          message={(location) => {
            if (
              (this.props.systemUsers.hasHiddenRegionChanges ||
                this.props.systemUsers.hasHiddenSourcesChanges ||
                this.props.systemUsers.hasAuthorityChanges) &&
              location.pathname.indexOf(this.props.match.params.memberId) === -1
            ) {
              return I18n.t('member_details.unsaved_changes_prompt');
            }

            return true;
          }}
        />
        <div className="details-container system-user-details">
          {!systemUserError ? (
            <Fragment>
              <div className="columns is-marginless">
                <div className="column is-4">
                  <ProfileCard
                    member={currentSystemUser}
                    error={systemUserError}
                    isLoading={fetchingSystemUser}
                    userType="user"
                    getProfileImage={this.props.getSystemUserProfileImage}
                  />
                </div>
                <div className="column">
                  <div className="tabs">
                    <NavLink exact to={`${this.props.match.url}`}>
                      <Translate value="system_user_details.tab.permissions" />
                    </NavLink>
                    <NavLink
                      exact
                      to={`${this.props.match.url}/transfer`}
                      className={!userIsCaregiver ? 'tab-disabled' : undefined}
                    >
                      <Translate value="system_user_details.tab.patient_handover" />
                    </NavLink>
                    <NavLink
                      exact
                      to={`${this.props.match.url}/planned-absence`}
                      className={!userIsCaregiver ? 'tab-disabled' : undefined}
                    >
                      <Translate value="system_user_details.tab.planned_absence" />
                    </NavLink>
                    <NavLink exact to={`${this.props.match.url}/metrics`} className="tab-disabled">
                      <Translate value="system_user_details.tab.metrics" />
                    </NavLink>
                    <NavLink
                      exact
                      to={`${this.props.match.url}/allocation`}
                      className={!userIsCaregiver ? 'tab-disabled' : undefined}
                    >
                      <Translate value="system_user_details.tab.allocation" />
                    </NavLink>
                  </div>
                  <div className="card-container">
                    <ContentLoader
                      isLoading={fetchingSystemUser}
                      error={systemUserError}
                      errorTitleI18n="member_details.error_fetching"
                    >
                      <Switch>
                        <PrivateRoute
                          exact
                          path={`/${APPLICATION_BASE_PATH}/user/:memberId`}
                          component={SystemUserPermissions}
                        />
                        <PrivateRoute
                          path={`/${APPLICATION_BASE_PATH}/user/:memberId/transfer`}
                          component={PermanentTransferView}
                        />
                        <PrivateRoute
                          exact
                          path={`/${APPLICATION_BASE_PATH}/user/:memberId/planned-absence`}
                          component={PlannedAbsenceView}
                        />
                        <PrivateRoute
                          exact
                          path={`/${APPLICATION_BASE_PATH}/user/:memberId/metrics`}
                          component={CaregiverMetricsView}
                        />
                        <PrivateRoute
                          exact
                          path={`/${APPLICATION_BASE_PATH}/user/:memberId/allocation`}
                          component={CaregiverAllocationView}
                        />
                      </Switch>
                    </ContentLoader>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <ErrorFallback>
              <Translate value="member_details.error_fetching" />
            </ErrorFallback>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    systemUsers: state.systemUsers,
    sharedData: state.sharedData
  };
};

const mapActionsToProps = {
  getSystemUser,
  clearCurrentSystemUser,
  updateSystemUser,
  getSystemUserProfileImage,
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(SystemUserDetailsView);
