import { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { SWRConfig } from 'swr';
import * as Sentry from '@sentry/react';
import IndexView from '../../components/IndexView';
import LogoutView from '../../components/LogoutView';
import PrivateRoute from '../../components/PrivateRoute';
import { APPLICATION_BASE_PATH } from '../../constants';
import NavigationBar from '../../components/NavigationBar';
import ThemeProvider from '../../components/ThemeProvider';
import MemberDetails from '../../components/MemberDetails';
import SystemUserSearchView from '../../components/SystemUserSearchView';
import ServicesView from '../../components/ServicesView';
import Notification from '../../components/Notification';
import CreateUserView from '../../components/CreateUserView';
import PlanningView from '../../components/PlanningView';
import SystemUserDetails from '../../components/SystemUserDetails';
import { basePath } from '../../api/apiClient';
import {
  getRegions,
  getAuthorities,
  hideNotification,
  getCaregivers,
  getRemunerationScheme,
  getNurses,
  getProfileImage
} from '../../actions';

const App = (props) => {
  const { authority } = props;

  useEffect(() => {
    props.getRegions(props.authToken);
    props.getCaregivers(props.authToken);
    props.getNurses(props.authToken);
    props.getAuthorities();
    props.getRemunerationScheme(props.authToken);

    if (props.user.profileImage) {
      props.getProfileImage(props.authToken, props.user.guid, props.user.profileImage.id);
    }
  }, []);

  const fetcher = async (path) => {
    const url = `${basePath}${path}`;
    const res = await fetch(url, {
      headers: {
        authorization: `Bearer ${props.authToken}`
      }
    });

    if (!res.ok) {
      const error = new Error('An error occurred while fetching the data.');
      let cause = { url };
      let errorResponse = {};

      try {
        errorResponse = await res.json();
      } catch {
        errorResponse = { body: 'No response data available' };
      }

      cause = { ...cause, ...errorResponse };
      error.cause = cause;
      // @ts-ignore
      error.status = res.status;
      throw error;
    }

    return res.json();
  };

  if (!authority) return null;

  return (
    <SWRConfig
      value={{
        fetcher,
        onErrorRetry: (err) => {
          if (err.response?.status === 404) return;
        }
      }}
    >
      <ThemeProvider>
        <NavigationBar />
        <div className="page-content">
          <Switch>
            <PrivateRoute exact path={`/${APPLICATION_BASE_PATH}`} component={IndexView} />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/member/:memberId`}
              component={MemberDetails}
              requiredAuthority={authority.viewPatients}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/users`}
              component={SystemUserSearchView}
              requiredAuthority={authority.manageUsers}
            />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/user/:memberId`}
              component={SystemUserDetails}
              requiredAuthority={authority.manageUsers}
            />
            <PrivateRoute path={`/${APPLICATION_BASE_PATH}/services`} component={ServicesView} />
            <PrivateRoute
              path={`/${APPLICATION_BASE_PATH}/create-user`}
              component={CreateUserView}
              requiredAuthority={authority.manageUsers}
            />
            <PrivateRoute path={`/${APPLICATION_BASE_PATH}/planning`} component={PlanningView} />
            <PrivateRoute path={`/${APPLICATION_BASE_PATH}/logout`} component={LogoutView} />
          </Switch>
        </div>
        <Notification {...props.notification} onClose={props.hideNotification} />
      </ThemeProvider>
    </SWRConfig>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    user: state.auth.token.user,
    notification: state.notifications,
    authority: state.sharedData.authorityTypes
  };
};

const mapActionsToProps = {
  getRegions,
  getAuthorities,
  hideNotification,
  getCaregivers,
  getNurses,
  getRemunerationScheme,
  getProfileImage
};

export default connect(mapStateToProps, mapActionsToProps)(Sentry.withProfiler(App));
