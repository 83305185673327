import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { APPLICATION_BASE_PATH } from '../../../constants';
import Card from '../../Card';
import { Partner, PartnerServiceState, usePartner } from '../PartnerProvider';
import Select from '../../Select';
import { useState } from 'react';
import { readFileAsync, toggleItem } from '../../../utils';
import DropdownIndicator from '../../DropdownIndicator';
import LoaderButton from '../../LoaderButton';
import Button from '../../Button';
import { Translate, I18n } from 'react-redux-i18n';
import { PartnerFacilityParams } from '..';
import Modal from '../../Modal';
import ErrorMessage from '../../ErrorMessage';
import WebScreeningForm from './WebScreeningForm';
import styles from './CreatePartner.module.scss';

const partnerNameTemplateTag = '{{partnerName}}';
const smsTemplate = `Välkommen! Din vårdgivare på ${partnerNameTemplateTag} har hänvisat dig till oss på Blodtrycksdoktorn. Ladda ner appen här för att komma igång: https://blodtrycksdoktorn.se/ladda-ner-1\n\nOm du har några frågor eller funderingar kan du läsa mer på https://blodtrycksdoktorn.se/\nHär hittar du även vår integritetspolicy och kontaktuppgifter till vår patientsupport.\n\nVänliga hälsningar,\nBlodtrycksdoktorn`;
const smsReminderTemplate =
  'Hej! Du behöver ladda ner appen för att komma vidare. Det är både enkelt och smidigt och Blodtrycksdoktorn hjälper dig sedan med nästa steg.\n\nHos oss gör du det mesta hemifrån, utan några väntetider.\n\nLadda ner appen här: https://blodtrycksdoktorn.se/ladda-ner-2\n\nHjärtliga hälsningar,\nVi på Blodtrycksdoktorn';

export default function CreatePartner({ editMode }) {
  const history = useHistory();
  const { partnerId } = useParams<PartnerFacilityParams>();
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const {
    facilityServices,
    createPartner,
    getPartner,
    updatePartner,
    removePartner,
    addRecommendationInfoToPartner,
    addWebScreeningConfigurationToPartner,
    toast
  } = usePartner();
  const [partner, setPartner] = useState<Partner>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  const [fetchingPartner, setFetchingPartner] = useState(false);
  const [removingPartner, setRemovingPartner] = useState(false);
  const [smsContent, setSmsContent] = useState('');
  const [smsReminderContent, setSmsReminderContent] = useState('');
  const [partnerName, setPartnerName] = useState('');
  const [active, setActive] = useState(false);
  const [removalConfirmation, setRemovalConfirmation] = useState<string>();
  const [confirmRemovalModalVisible, setConfirmRemovalModalVisible] = useState(false);
  const [startImageSrc, setStartImageSrc] = useState<string>();
  const [footerImageSrc, setFooterImageSrc] = useState<string>();
  const [handlesCertificatePatients, setHandlesCertificatePatients] = useState(false);
  const [imageUrls, setImageUrls] = useState<Record<string, string | undefined>>({});

  const servicesOptions = facilityServices.map((service) => {
    return { value: service, label: I18n.t(`manage_partners.service.${service}`) };
  });

  const setPartnerData = (partner: Partner) => {
    setPartner(partner);
    setPartnerName(partner.name ?? '');
    setSmsContent(partner.recommendationInfo?.smsContent ?? '');
    setSmsReminderContent(partner.recommendationInfo?.smsReminderContent ?? '');
    setSelectedServices(
      partner.services?.filter((s) => s.selected && facilityServices.includes(s.service)).map((s) => s.service) ?? []
    );
    setActive(!!partner.active);
    setHandlesCertificatePatients(!!partner.recommendationInfo?.recommendationReasons?.includes('certificate'));
    setStartImageSrc(partner.kioskConfiguration?.startScreenImage);
    setFooterImageSrc(partner.kioskConfiguration?.footerImage);
    setImageUrls({
      ...imageUrls,
      logoImage: partner.webScreeningConfiguration?.logoImage,
      startPageImage: partner.webScreeningConfiguration?.startPage?.imageUrl,
      lowRiskWebScreeningImageSv: partner.webScreeningConfiguration?.lowRisk?.imageUrl?.sv,
      lowRiskWebScreeningImageEn: partner.webScreeningConfiguration?.lowRisk?.imageUrl?.en
    });
  };

  useEffect(() => {
    if (editMode) {
      setFetchingPartner(true);
      getPartner(partnerId)
        .then((res) => {
          setPartnerData(res);
        })
        .catch(setError)
        .finally(() => setFetchingPartner(false));
    }
  }, []);

  useEffect(() => {
    if (selectedServices.includes('recommendation')) {
      if (!smsContent) {
        setSmsContent(smsTemplate);
      }
      if (!smsReminderContent) {
        setSmsReminderContent(smsReminderTemplate);
      }
    }
  }, [selectedServices]);

  const getWebScreeningConfiguration = (e) => {
    const formData = new FormData(e.target);

    const webScreeningConfiguration = {
      urlPath: formData.get('urlPath')?.toString()?.replaceAll('/', ''),
      logoImage: imageUrls?.logoImage,
      mainCta: {
        url: formData.get('webScreeningMainCtaUrl')?.toString(),
        text: {
          sv: formData.get('webScreeningMainCtaTextSv')?.toString(),
          en: formData.get('webScreeningMainCtaTextEn')?.toString()
        }
      },
      secondaryCta: {
        url: {
          sv: formData.get('webScreeningSecondaryCtaUrlSv')?.toString(),
          en: formData.get('webScreeningSecondaryCtaUrlEn')?.toString()
        },
        text: {
          sv: formData.get('webScreeningSecondaryCtaTextSv')?.toString(),
          en: formData.get('webScreeningSecondaryCtaTextEn')?.toString()
        }
      },
      startPage: {
        imageUrl: imageUrls?.startPageImage,
        heading: {
          sv: formData.get('webScreeningStartPageHeadingSv')?.toString(),
          en: formData.get('webScreeningStartPageHeadingEn')?.toString()
        },
        text: {
          sv: formData.get('webScreeningStartPageTextSv')?.toString(),
          en: formData.get('webScreeningStartPageTextEn')?.toString()
        }
      },
      lowRisk: {
        imageUrl: {
          sv: imageUrls?.lowRiskWebScreeningImageSv,
          en: imageUrls?.lowRiskWebScreeningImageEn
        },
        heading: {
          sv: formData.get('webScreeningLowRiskHeadingSv')?.toString(),
          en: formData.get('webScreeningLowRiskHeadingEn')?.toString()
        },
        text: {
          sv: formData.get('webScreeningLowRiskTextSv')?.toString(),
          en: formData.get('webScreeningLowRiskTextEn')?.toString()
        }
      },
      highRisk: {
        heading: {
          sv: formData.get('webScreeningHighRiskHeadingSv')?.toString(),
          en: formData.get('webScreeningHighRiskHeadingEn')?.toString()
        },
        text: {
          sv: formData.get('webScreeningHighRiskTextSv')?.toString(),
          en: formData.get('webScreeningHighRiskTextEn')?.toString()
        }
      }
    };

    return webScreeningConfiguration;
  };

  const getPartnerObject = (e) => {
    const services = facilityServices.map(
      (s) => ({ service: s, selected: selectedServices.includes(s), disabled: false } as PartnerServiceState)
    );

    services.push({ service: 'admin', selected: true, disabled: false });

    const partnerObj = {
      name: partnerName,
      services,
      active
    } as Partner;

    if (selectedServices.includes('kiosk')) {
      partnerObj.kioskConfiguration = {
        startScreenImage: startImageSrc,
        footerImage: footerImageSrc
      };
    }

    if (selectedServices.includes('webScreening')) {
      const configuration = getWebScreeningConfiguration(e);

      if (
        partner?.webScreeningConfiguration?.urlPath &&
        configuration.urlPath !== partner.webScreeningConfiguration.urlPath
      ) {
        if (
          window.confirm(
            I18n.t('manage_partners.add_new_partner.web_screening.url_change_confirmation', {
              path: partner.webScreeningConfiguration.urlPath
            })
          )
        ) {
          // @ts-ignore
          partnerObj.webScreeningConfiguration = configuration;
        }
      } else {
        // @ts-ignore
        partnerObj.webScreeningConfiguration = configuration;
      }
    }

    return partnerObj;
  };

  const createNew = async (e) => {
    e.preventDefault();

    const newPartner = getPartnerObject(e);

    try {
      setIsLoading(true);
      const partner = await createPartner(newPartner);
      if (smsContent) {
        await addRecommendationInfoToPartner(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          partner.id!,
          smsContent.replace(partnerNameTemplateTag, partnerName),
          handlesCertificatePatients ? ['certificate'] : [],
          smsReminderContent
        );
      }
      if (selectedServices.includes('webScreening')) {
        const configuration = getWebScreeningConfiguration(e);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await addWebScreeningConfigurationToPartner(partner.id!, configuration);
      }
      history.push(`/${APPLICATION_BASE_PATH}/services/partners/${partner.id}`);
    } catch (err) {
      console.log(err);
      toast(I18n.t('notification.create_partner.error'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (e) => {
    e.preventDefault();

    const updatedPartner = getPartnerObject(e);

    try {
      setIsLoading(true);
      await updatePartner(partnerId, updatedPartner);
      if (selectedServices.includes('recommendation')) {
        await addRecommendationInfoToPartner(
          partnerId,
          smsContent.replace(partnerNameTemplateTag, partnerName),
          handlesCertificatePatients ? ['certificate'] : [],
          smsReminderContent
        );
      }

      history.push(`/${APPLICATION_BASE_PATH}/services/partners/${partnerId}`);
    } catch (err) {
      console.log(err);
      toast(I18n.t('notification.update_partner.error'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const selectedServicesChange = (_, event) => {
    switch (event.action) {
      case 'clear':
        setSelectedServices([]);
        break;
      case 'remove-value':
        setSelectedServices(toggleItem(selectedServices, event.removedValue.value));
        break;
      case 'select-option':
        setSelectedServices(toggleItem(selectedServices, event.option.value));
        break;
      default:
        return;
    }
  };

  const remove = async () => {
    setRemovingPartner(true);
    try {
      await removePartner(partnerId);
      toast(I18n.t('notification.remove_partner.success'), 'success');
      history.replace(`/${APPLICATION_BASE_PATH}/services/partners`);
    } catch (err) {
      console.log(err);
      toast(I18n.t('notification.remove_partner.error'), 'error');
    } finally {
      setRemovingPartner(false);
    }
  };

  const handleImageSelection = async (element, name) => {
    if (element?.files && element.files[0]) {
      const dataUrl = await readFileAsync(element.files[0]);
      setImageUrls({ ...imageUrls, [name]: dataUrl });
    }
  };

  const variants = {
    open: {
      height: '100%'
    },
    closed: {
      height: 0
    }
  };

  return (
    <>
      <Card
        titleI18nKey={`manage_partners.add_new_partner.header${editMode ? '_edit' : ''}`}
        errorI18nKey="manage_partners.error"
        additionalClass="x-large"
        isLoading={fetchingPartner}
      >
        {error ? (
          <ErrorMessage error={error} errorI18nKey="manage_partners.add_new_partner.fetch_error" />
        ) : (
          <form onSubmit={editMode ? update : createNew} className={`partner-form ${styles.wrapper}`}>
            <label>
              <h4>
                <Translate value="manage_partners.add_new_partner.partner_name" />
              </h4>
              <input
                type="text"
                name="name"
                value={partnerName}
                onChange={(e) => setPartnerName(e.target.value)}
                autoComplete="off"
                disabled={fetchingPartner}
                required
              />
            </label>
            <div className="mt-20 mb-20">
              <h4>
                <Translate value="manage_partners.partner_services.select_services" />
              </h4>
              <Select
                isMulti
                disabled={fetchingPartner}
                closeMenuOnSelect
                placeholder={I18n.t('manage_partners.partner_services.available_services')}
                noOptionsMessage={() => <Translate value="manage_partners.partner_services.all_selected" />}
                isSearchable={false}
                onChange={selectedServicesChange}
                classNamePrefix="multi-select"
                components={{ DropdownIndicator }}
                options={servicesOptions}
                value={selectedServices.map((s) => ({
                  value: s,
                  label: I18n.t(`manage_partners.service.${s}`)
                }))}
              />
            </div>
            {selectedServices.includes('recommendation') ? (
              <h3>
                <Translate value="manage_partners.service.recommendation" />
              </h3>
            ) : null}
            <motion.div
              className={`overflow-hidden mb-${selectedServices.includes('recommendation') ? '2' : ''}0`}
              animate={selectedServices.includes('recommendation') ? 'open' : 'closed'}
              initial="closed"
              variants={variants}
            >
              <div className="mb-10">
                <h4>
                  <Translate value="manage_partners.add_new_partner.sms_content_1" />
                </h4>
                <textarea
                  style={{ height: '200px' }}
                  value={partnerName && smsContent ? smsContent.replace('{{partnerName}}', partnerName) : smsContent}
                  onChange={(e) =>
                    setSmsContent(partnerName ? e.target.value.replace(partnerName, '{{partnerName}}') : e.target.value)
                  }
                />
              </div>
              <div>
                <h4>
                  <Translate value="manage_partners.add_new_partner.sms_content_2" />
                </h4>
                <textarea
                  style={{ height: '200px' }}
                  value={
                    partnerName && smsReminderContent
                      ? smsReminderContent.replace('{{partnerName}}', partnerName)
                      : smsReminderContent
                  }
                  onChange={(e) =>
                    setSmsReminderContent(
                      partnerName ? e.target.value.replace(partnerName, '{{partnerName}}') : e.target.value
                    )
                  }
                />
              </div>
              <div className="mt-20">
                <h4>Övrigt</h4>
                <input
                  type="checkbox"
                  checked={handlesCertificatePatients}
                  id="certificate-patients-checkbox"
                  onChange={() => setHandlesCertificatePatients(!handlesCertificatePatients)}
                  disabled={fetchingPartner}
                />
                <label htmlFor="certificate-patients-checkbox" className="ml-5 fw-500">
                  <Translate value="manage_partners.add_new_partner.handles_certificate_patients" />
                </label>
              </div>
            </motion.div>
            {selectedServices.includes('kiosk') ? (
              <h3>
                <Translate value="manage_partners.service.kiosk" />
              </h3>
            ) : null}
            <motion.div
              className={`overflow-hidden mb-${selectedServices.includes('kiosk') ? '2' : ''}0`}
              animate={selectedServices.includes('kiosk') ? 'open' : 'closed'}
              initial="closed"
              variants={variants}
            >
              <h4>
                <Translate value="manage_partners.add_new_partner.kiosk.images_header" />
              </h4>
              <div className={styles.imageSelectorWrapper}>
                <ImageSelector
                  heading={I18n.t('manage_partners.add_new_partner.kiosk.start_image')}
                  name="start"
                  imageSrc={imageUrls?.startImage}
                  required={!editMode && selectedServices.includes('kiosk')}
                  onChange={(e) => handleImageSelection(e.target, 'startImage')}
                  accept=".png,.svg"
                />
                <ImageSelector
                  heading={I18n.t('manage_partners.add_new_partner.kiosk.footer_image')}
                  name="footer"
                  imageSrc={imageUrls?.footerImage}
                  required={!editMode && selectedServices.includes('kiosk')}
                  onChange={(e) => handleImageSelection(e.target, 'footerImage')}
                  accept=".png,.svg"
                />
              </div>
            </motion.div>
            {selectedServices.includes('webScreening') ? (
              <>
                <h3>Blodtryckstest</h3>
                <motion.div
                  className={`overflow-hidden mb-${selectedServices.includes('webScreening') ? '2' : ''}0`}
                  animate={selectedServices.includes('webScreening') ? 'open' : 'closed'}
                  initial="closed"
                  variants={variants}
                >
                  <WebScreeningForm
                    partner={partner}
                    required={!editMode && selectedServices.includes('webScreening')}
                    imageUrls={imageUrls}
                    onImageSelection={(imageName) => (e) => handleImageSelection(e.target, imageName)}
                  />
                </motion.div>
              </>
            ) : null}
            <div>
              <h4>
                <Translate value="manage_partners.facilities.status" />
              </h4>
              <input
                type="checkbox"
                checked={active}
                id="facility-active-checkbox"
                onChange={() => setActive(!active)}
                disabled={fetchingPartner}
              />
              <label htmlFor="facility-active-checkbox" className="ml-5 fw-500">
                <Translate value="manage_partners.status.active" />
              </label>
            </div>
            <div className="card-buttons">
              {editMode ? (
                <Button onClick={() => setConfirmRemovalModalVisible(true)} buttonType="destructive">
                  <Translate value="manage_partners.add_new_partner.button.remove" />
                </Button>
              ) : null}
              <LoaderButton type="submit" isLoading={isLoading}>
                {editMode ? (
                  <Translate value="manage_partners.add_new_partner.button.update" />
                ) : (
                  <Translate value="manage_partners.add_new_partner.button.add" />
                )}
              </LoaderButton>
            </div>
          </form>
        )}
      </Card>
      <Modal
        headerI18nKey="manage_partners.add_new_partner.confirm_delete_header"
        actionI18nKey="manage_partners.add_new_partner.button.remove"
        visible={confirmRemovalModalVisible}
        onClose={() => setConfirmRemovalModalVisible(false)}
        onActionCompleted={remove}
        actionCompletable={removalConfirmation === partner?.name}
        actionCompleting={removingPartner}
        size="x-small"
      >
        <div className="mb-20">
          <Translate value="manage_partners.add_new_partner.confirm_delete" />
        </div>
        <input type="text" placeholder={partner?.name} onChange={(e) => setRemovalConfirmation(e.target.value)} />
      </Modal>
    </>
  );
}

type ImageSelectorProps = {
  name: string;
  heading: string;
  imageSrc?: string;
  required?: boolean;
  accept?: string;
  onChange: FixMe;
};

export const ImageSelector = ({
  name,
  heading,
  imageSrc,
  onChange,
  required,
  accept = '.png,.svg,.jpg,.jpeg'
}: ImageSelectorProps) => {
  return (
    <div>
      <h5>{heading}</h5>
      {imageSrc ? (
        <div className={styles.imageWrapper}>
          <img src={imageSrc} alt="" />
        </div>
      ) : null}
      <div>
        <input
          type="file"
          required={required}
          name={name}
          id={`partner-image-${name}`}
          accept={accept}
          onChange={onChange}
        />
        <label htmlFor={`partner-image-${name}`}>
          <Translate value={`manage_partners.add_new_partner.select_image`} />
        </label>
      </div>
    </div>
  );
};
