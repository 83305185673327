import React from 'react';
import LoaderButton from '../../LoaderButton';
import { Translate } from 'react-redux-i18n';
import { DEACTIVATION_FUNNEL_STATE } from '../../../constants';
import Button from '../../Button';
import Modal from '../../Modal';
import { connect } from 'react-redux';
import { toggleDeactivateMemberModal, reactivatePatient, deactivatePatient } from '../../../actions';

const DeactivationFunnel = ({
  authToken,
  members,
  deactivatePatient,
  reactivatePatient,
  toggleDeactivateMemberModal,
  memberId
}) => {
  const { currentMember, memberState } = members;

  const handleDeactivatePatient = (sendReturnSlip) => {
    deactivatePatient(authToken, memberId, sendReturnSlip, `${currentMember.givenName} ${currentMember.familyName}`);
  };

  const handleReactivatePatient = () => {
    reactivatePatient(authToken, memberId, `${currentMember.givenName} ${currentMember.familyName}`);
  };

  const isInDeactivation =
    memberState.DeactivationFunnel &&
    !(
      memberState.DeactivationFunnel === DEACTIVATION_FUNNEL_STATE.DONE ||
      memberState.DeactivationFunnel === DEACTIVATION_FUNNEL_STATE.ABORTED
    );

  const isBlocked = currentMember.serviceStatus === 'blocked';

  return (
    <>
      {!isInDeactivation && !isBlocked ? (
        <div className="columns is-mobile">
          <div className="column">
            <Button onClick={toggleDeactivateMemberModal} fullWidth={true} buttonType="secondary">
              <Translate value="member_details.block_member" />
            </Button>
          </div>
        </div>
      ) : null}
      {isInDeactivation || isBlocked ? (
        <div className="columns is-mobile">
          <div className="column">
            <div className="text-center mb-10">
              <div className="label error">
                <Translate value="member_details.blocked_label" />
              </div>
            </div>
            <LoaderButton
              isLoading={members.reactivatingPatient}
              buttonType="primary"
              onClick={handleReactivatePatient}
              fullWidth={true}
            >
              <Translate value="member_details.unblock_member" />
            </LoaderButton>
            {currentMember.doNotSendReturnSlip ? (
              <small>
                <Translate value="member_details.blocked_without_return_slip" />
              </small>
            ) : (
              <small>
                <Translate value="member_details.blocked_with_return_slip" />
              </small>
            )}
          </div>
        </div>
      ) : null}
      <Modal
        headerI18nKey="member_details.block_member"
        actionI18nKey="global.buttons.cancel"
        visible={members.deactivateMemberModalVisible}
        onClose={toggleDeactivateMemberModal}
        onActionCompleted={toggleDeactivateMemberModal}
        actionCompletable={true}
      >
        <div className="deactivate-member-wrapper">
          <div className="mb-30">
            <LoaderButton
              isLoading={members.deactivatingMemberWithReturnSlip}
              buttonType="destructive"
              onClick={() => handleDeactivatePatient(true)}
            >
              <Translate value="member_details.block_with_return_slip" />
            </LoaderButton>
            <p>
              <Translate value="member_details.block_with_return_slip_details" />
            </p>
          </div>
          <div>
            <LoaderButton
              isLoading={members.deactivatingMemberWithoutReturnSlip}
              buttonType="destructive"
              onClick={() => handleDeactivatePatient(false)}
            >
              <Translate value="member_details.block_without_return_slip" />
            </LoaderButton>
            <p>
              <Translate value="member_details.block_without_return_slip_details" />
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    members: state.members
  };
};

const mapActionsToProps = {
  toggleDeactivateMemberModal,
  reactivatePatient,
  deactivatePatient
};

export default connect(mapStateToProps, mapActionsToProps)(DeactivationFunnel);
