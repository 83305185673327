import { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import CustomDateInput from '../CustomDateInput';
import sv from 'date-fns/locale/sv';
import en from 'date-fns/locale/en-GB';
registerLocale('sv', sv);
registerLocale('en', en);

const DatePickerWithFallback = ({
  selectedDate,
  onChange,
  onChangeNative,
  placeholderI18nKey,
  locale,
  isValid = true,
  ...rest
}) => {
  return (
    <Fragment>
      <div className={`native-date-picker ${!isValid ? 'invalid-input' : ''}`}>
        <input
          className={selectedDate ? 'has-value' : ''}
          onChange={onChangeNative}
          type="date"
          defaultValue={selectedDate}
          placeholder={I18n.t(placeholderI18nKey)}
        />
      </div>
      <div className={`non-native-date-picker ${!isValid ? 'invalid-input' : ''}`}>
        <DatePicker
          customInput={<CustomDateInput placeholderI18nKey={placeholderI18nKey} disabled={rest.disabled} />}
          selected={selectedDate}
          onChange={onChange}
          locale={locale}
          dateFormat="yyyy-MM-dd"
          {...rest}
        />
      </div>
    </Fragment>
  );
};

export default DatePickerWithFallback;
