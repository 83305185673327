import React, { useReducer } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import Select from '../Select';
import Card from '../Card';
import CanPerform from '../CanPerform';
import DropdownIndicator from '../DropdownIndicator';
import { toggleItem } from '../../utils';
import LoaderButton from '../LoaderButton';
import { sendBulkMessage } from '../../actions';
import './BulkMessageView.scss';

const reducer = (state, action) => {
  switch (action.type) {
    case 'clear':
      return { ...state, selectedCaregivers: [] };
    case 'remove-value':
      return { ...state, selectedCaregivers: toggleItem(state.selectedCaregivers, action.event.removedValue.value) };
    case 'select-option':
      return { ...state, selectedCaregivers: toggleItem(state.selectedCaregivers, action.event.option.value) };
    case 'updateMessage':
      return { ...state, message: action.message };
    default:
      throw new Error();
  }
};

const BulkMessageView = ({ caregivers, authToken, reports, sendBulkMessage }) => {
  const [state, dispatch] = useReducer(reducer, { message: '', selectedCaregivers: [] });

  const onCaregiverChange = (value, event) => {
    dispatch({ type: event.action, event });
  };

  const sendMessageToMembers = () => {
    if (window.confirm(I18n.t('reports_view.bulk_message.confirm'))) {
      sendBulkMessage(authToken, state.selectedCaregivers, state.message);
    }
  };

  return (
    <CanPerform action="sendCommunications">
      <div className="bulk-message">
        <Card
          titleI18nKey="reports_view.bulk_message.header"
          descriptionI18nKey="reports_view.bulk_message.description"
        >
          <div className="mb-20">
            <h4>
              <Translate value="reports_view.bulk_message.caregiver_header" />
            </h4>
            <Select
              isMulti
              name="caregivers"
              placeholder={I18n.t('reports_view.bulk_message.select_caregivers')}
              noOptionsMessage={() => <Translate value="reports_view.bulk_message.no_more_caregivers" />}
              closeMenuOnSelect={true}
              isSearchable={false}
              onChange={onCaregiverChange}
              classNamePrefix="multi-select"
              components={{ DropdownIndicator }}
              options={caregivers.map((caregiver) => {
                return { value: caregiver.guid, label: `${caregiver.givenName} ${caregiver.familyName}` };
              })}
            />
          </div>

          <div className="mb-20">
            <h4>
              <Translate value="reports_view.bulk_message.message_header" />
            </h4>
            <textarea
              placeholder={I18n.t('reports_view.bulk_message.message_placeholder')}
              value={state.message}
              onChange={(e) => dispatch({ type: 'updateMessage', message: e.target.value })}
            ></textarea>
          </div>

          <div>
            <LoaderButton
              buttonType="primary"
              disabled={!state.message.length || !state.selectedCaregivers.length}
              onClick={sendMessageToMembers}
              isLoading={reports.sendingBulkMessage}
            >
              <Translate value="reports_view.bulk_message.send_message" />
            </LoaderButton>
          </div>
        </Card>
      </div>
    </CanPerform>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    caregivers: state.sharedData.caregivers,
    reports: state.reports
  };
};

const mapActionsToProps = {
  sendBulkMessage
};

export default connect(mapStateToProps, mapActionsToProps)(BulkMessageView);
