import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import authReducer from './authReducer';
import themeReducer from './themeReducer';
import membersReducer from './membersReducer';
import systemUsersReducer from './systemUsersReducer';
import sharedDataReducer from './sharedDataReducer';
import reportsReducer from './reportsReducer';
import notificationReducer from './notificationReducer';
import jiraReducer from './jiraReducer';
import cannedMessagesReducer from './cannedMessagesReducer';
import userReducer from './userReducer';

const reducers = combineReducers({
  auth: authReducer,
  theme: themeReducer,
  members: membersReducer,
  systemUsers: systemUsersReducer,
  sharedData: sharedDataReducer,
  reports: reportsReducer,
  notifications: notificationReducer,
  jira: jiraReducer,
  cannedMessages: cannedMessagesReducer,
  user: userReducer,
  i18n: i18nReducer // Needs to come last in this list
});

export default reducers;
