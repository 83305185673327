import { useState, useEffect, useRef } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { useHistory, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { APPLICATION_BASE_PATH } from '../../../constants';
import Card from '../../Card';
import Select from '../../Select';
import { usePartner, PartnerServiceState, Facility } from '../PartnerProvider';
import { PartnerFacilityParams } from '..';
import LoaderButton from '../../LoaderButton';
import DropdownIndicator from '../../DropdownIndicator';
import { cleanUpFilename, toggleItem } from '../../../utils';

export default function CreatePartnerFacility({ editMode }) {
  const history = useHistory();
  const {
    createPartnerFacility,
    getPartnerFacility,
    updatePartnerFacility,
    addVisitationInfoToFacility,
    updateVisitationInfoForFacility,
    removePartnerFacility,
    toast,
    partners,
    facilityServices
  } = usePartner();
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const { partnerId, facilityId } = useParams<PartnerFacilityParams>();
  const [facility, setFacility] = useState<Facility>();
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingFacility, setFetchingFacility] = useState(false);
  const [instructionsFilename, setInstructionsFilename] = useState<string>();
  const [instructionsFile, setInstructionsFile] = useState<File | undefined>();
  const [missingFileError, setMissingFileError] = useState<string>();
  const [removingFacility, setRemovingFacility] = useState(false);
  const servicesRequiringVisitationInfo = ['auscultation', 'examination'];
  const requiresVisitationInfo = selectedServices.some((s) => servicesRequiringVisitationInfo.includes(s));
  const newFacilityId = useRef<string>();
  const [streetAddressValid, setStreetAddressValid] = useState(true);

  const servicesOptions = (
    partners
      .find((p) => p.id === partnerId)
      ?.services?.filter((s) => s.selected && facilityServices.includes(s.service))
      .map((s) => s.service) ?? facilityServices
  ).map((service) => {
    return { value: service, label: I18n.t(`manage_partners.service.${service}`) };
  });

  useEffect(() => {
    if (editMode) {
      setFetchingFacility(true);
      getPartnerFacility(partnerId, facilityId)
        .then((res) => {
          if (res.facilities?.length) {
            const facility = res.facilities[0];
            setFacility(facility);
            setSelectedServices(
              facility?.services
                ?.filter((s) => s.selected && facilityServices.includes(s.service))
                .map((s) => s.service) ?? []
            );
            setActive(!!facility?.active);
            setInstructionsFilename(
              facility?.visitationInfo?.instructions?.filename
                ? cleanUpFilename(facility?.visitationInfo?.instructions?.filename)
                : undefined
            );
          }
        })
        .finally(() => setFetchingFacility(false));
    }
  }, []);

  const getFacilityFromFormData = (e) => {
    const formData = new FormData(e.target);

    const services = facilityServices.map(
      (s) => ({ service: s, selected: selectedServices.includes(s), disabled: false } as PartnerServiceState)
    );

    services.push({ service: 'admin', selected: true, disabled: false });

    const facility = {
      name: formData.get('name')?.toString(),
      streetaddress: formData.get('streetaddress')?.toString(),
      city: formData.get('city')?.toString(),
      postalCode: formData.get('postalCode')?.toString().replace(' ', ''),
      phoneNumber: formData.get('phoneNumber')?.toString(),
      email: formData.get('email')?.toString(),
      partnerRegion: formData.get('partnerRegion')?.toString(),
      partnerCostCenter: formData.get('partnerCostCenter')?.toString(),
      services,
      active
    };

    return facility;
  };

  const getVisitationInfoFromFormData = (e) => {
    const formData = new FormData(e.target);
    const cancellationPolicy = formData.get('cancellationPolicy')?.toString();

    const visitationInfo = {
      distance: formData.get('distance')?.toString(),
      cancellationPolicy: cancellationPolicy || undefined,
      filename: instructionsFile ? instructionsFilename : undefined,
      instructionsFile
    };

    return visitationInfo;
  };

  const updateFacility = async (e) => {
    e.preventDefault();

    if (!streetAddressValid) {
      return;
    }

    const updatedFacility = getFacilityFromFormData(e);
    const visitationInfo = getVisitationInfoFromFormData(e);

    try {
      setIsLoading(true);
      const facility = await updatePartnerFacility(partnerId, facilityId, updatedFacility);
      history.push(`/${APPLICATION_BASE_PATH}/services/partners/${partnerId}/facility/${facilityId}`);
      if (
        requiresVisitationInfo &&
        (visitationInfo.cancellationPolicy !== facility.visitationInfo?.cancellationPolicy ||
          visitationInfo.distance !== facility.visitationInfo?.distance?.toString() ||
          visitationInfo.filename !== facility.visitationInfo?.instructions?.filename)
      ) {
        await updateVisitationInfoForFacility(
          partnerId,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          facility.id!,
          visitationInfo.distance,
          visitationInfo.filename,
          visitationInfo.instructionsFile,
          visitationInfo.cancellationPolicy
        );
      }
    } catch (err) {
      console.log(err);
      toast(I18n.t('notification.update_facility.error'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const createNewFacility = async (e) => {
    e.preventDefault();

    const newFacility = getFacilityFromFormData(e);

    try {
      setIsLoading(true);
      if (!newFacilityId.current) {
        const facility = await createPartnerFacility(partnerId, newFacility);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        newFacilityId.current = facility.id!;
      }
      if (requiresVisitationInfo) {
        const visitationInfo = getVisitationInfoFromFormData(e);

        if (!newFacilityId.current) {
          throw new Error('Facility ID missing');
        }

        if (!visitationInfo.instructionsFile) {
          setMissingFileError(I18n.t('manage_partners.facilities.empty_file'));
        }

        if (!visitationInfo.distance || !visitationInfo.filename || !visitationInfo.instructionsFile) {
          throw new Error('VisitationInfo field(s) missing');
        }

        await addVisitationInfoToFacility(
          partnerId,
          newFacilityId.current,
          visitationInfo.distance,
          visitationInfo.filename,
          visitationInfo.instructionsFile,
          visitationInfo.cancellationPolicy
        );
      }
      history.push(`/${APPLICATION_BASE_PATH}/services/partners/${partnerId}/facility/${newFacilityId.current}`);
    } catch (err) {
      console.log(err);
      toast(I18n.t('notification.create_facility.error'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const selectedServicesChange = (_, event) => {
    switch (event.action) {
      case 'clear':
        setSelectedServices([]);
        break;
      case 'remove-value':
        setSelectedServices(toggleItem(selectedServices, event.removedValue.value));
        break;
      case 'select-option':
        setSelectedServices(toggleItem(selectedServices, event.option.value));
        break;
      default:
        return;
    }
  };

  const onFileInputChange = (e) => {
    const file = e.target.files && e.target.files[0];
    setInstructionsFilename(file.name);
    setInstructionsFile(file);
    setMissingFileError(undefined);
  };

  const remove = async () => {
    if (window.confirm(I18n.t('manage_partners.facilities.confirm_delete'))) {
      setRemovingFacility(true);
      try {
        await removePartnerFacility(partnerId, facilityId);
        history.replace(`/${APPLICATION_BASE_PATH}/services/partners/${partnerId}`);
      } catch (err) {
        console.log(err);
        toast(I18n.t('notification.remove_facility.error'), 'error');
      } finally {
        setRemovingFacility(false);
      }
    }
  };

  const variants = {
    open: {
      height: '100%'
    },
    closed: {
      height: 0
    }
  };

  return (
    <Card
      titleI18nKey={
        editMode
          ? 'manage_partners.facilities.edit_header'
          : partners.length
          ? I18n.t(`manage_partners.facilities.create_new_header`, {
              partner: partners.find((p) => p.id === partnerId)?.name
            })
          : ''
      }
      loadingTitle={fetchingFacility}
      errorI18nKey="manage_partners.error"
      additionalClass="x-large"
      isLoading={fetchingFacility}
    >
      <form onSubmit={editMode ? updateFacility : createNewFacility} className="partner-form">
        <div>
          <h4>
            <Translate value="manage_partners.partner_services.select_services" />
          </h4>
          <Select
            isMulti
            disabled={fetchingFacility}
            closeMenuOnSelect
            placeholder={I18n.t('manage_partners.partner_services.available_services')}
            noOptionsMessage={() => <Translate value="manage_partners.partner_services.all_selected" />}
            isSearchable={false}
            onChange={selectedServicesChange}
            classNamePrefix="multi-select"
            components={{ DropdownIndicator }}
            options={servicesOptions}
            value={selectedServices.map((s) => ({
              value: s,
              label: I18n.t(`manage_partners.service.${s}`)
            }))}
          />
        </div>
        <label>
          <h4>
            <Translate value="manage_partners.facilities.name" />
          </h4>
          <input
            type="text"
            name="name"
            autoComplete="off"
            defaultValue={facility?.name}
            required
            disabled={fetchingFacility}
          />
        </label>
        <label>
          <h4>
            <Translate value="member_details.street_address" />
            {!streetAddressValid ? (
              <span className="error-message ml-10">Kommatecken tillåts inte i gatuadressen</span>
            ) : null}
          </h4>
          <input
            type="text"
            name="streetaddress"
            autoComplete="off"
            defaultValue={facility?.streetaddress}
            required
            disabled={fetchingFacility}
            onBlur={(e) => setStreetAddressValid(!e.target.value.includes(','))}
          />
        </label>
        <div className="flex">
          <label className="mr-10">
            <h4>
              <Translate value="member_details.city" />
            </h4>
            <input
              type="text"
              name="city"
              autoComplete="off"
              defaultValue={facility?.city}
              required
              disabled={fetchingFacility}
              pattern="[^,]+"
            />
          </label>
          <label>
            <h4>
              <Translate value="member_details.postal_code" />
            </h4>
            <input
              type="text"
              name="postalCode"
              autoComplete="off"
              defaultValue={facility?.postalCode}
              required
              disabled={fetchingFacility}
              pattern="[0-9\s]{5,6}"
            />
          </label>
        </div>
        <label>
          <h4>
            <Translate value="manage_partners.facilities.region" />
          </h4>
          <input
            type="text"
            name="partnerRegion"
            autoComplete="off"
            defaultValue={facility?.partnerRegion}
            disabled={fetchingFacility}
          />
        </label>
        <label>
          <h4>
            <Translate value="manage_partners.facilities.cost_center" />
          </h4>
          <input
            type="text"
            name="partnerCostCenter"
            autoComplete="off"
            defaultValue={facility?.partnerCostCenter}
            disabled={fetchingFacility}
          />
        </label>
        <label>
          <h4>
            <Translate value="manage_partners.facilities.phone" />
          </h4>
          <input
            type="text"
            name="phoneNumber"
            autoComplete="off"
            defaultValue={facility?.phoneNumber}
            required
            disabled={fetchingFacility}
          />
        </label>
        <label>
          <h4>
            <Translate value="manage_partners.facilities.email" />
          </h4>
          <input
            type="text"
            name="email"
            autoComplete="off"
            defaultValue={facility?.email}
            required
            disabled={fetchingFacility}
          />
        </label>
        <motion.div
          className="overflow-hidden"
          animate={requiresVisitationInfo ? 'open' : 'closed'}
          initial="closed"
          variants={variants}
        >
          <div className="mt-20">
            <h4>
              <Translate value="manage_partners.facilities.instructions_file" />
            </h4>
            <input
              type="file"
              name="instructions"
              id="facility-instructions-file-input"
              accept=".pdf"
              onChange={onFileInputChange}
              disabled={fetchingFacility}
            />
            <label htmlFor="facility-instructions-file-input">
              <Translate value="manage_partners.facilities.select_file" />
            </label>
            <span className="ml-5">{instructionsFilename}</span>
            {missingFileError ? <span className="error-message">{missingFileError}</span> : null}
          </div>
          <label>
            <h4>
              <Translate value="manage_partners.facilities.acceptable_distance" />
            </h4>
            <input
              type="text"
              name="distance"
              autoComplete="off"
              defaultValue={facility?.visitationInfo?.distance}
              required={requiresVisitationInfo}
              disabled={fetchingFacility}
            />
          </label>
          <label>
            <h4>
              <Translate value="manage_partners.facilities.cancellation_policy" />
            </h4>
            <textarea
              name="cancellationPolicy"
              defaultValue={facility?.visitationInfo?.cancellationPolicy}
              disabled={fetchingFacility}
            />
          </label>
        </motion.div>
        <div className="mt-20">
          <h4>
            <Translate value="manage_partners.facilities.status" />
          </h4>
          <input
            type="checkbox"
            checked={active}
            id="facility-active-checkbox"
            onChange={() => setActive(!active)}
            disabled={fetchingFacility}
          />
          <label htmlFor="facility-active-checkbox" className="ml-5 fw-500">
            <Translate value="manage_partners.status.active" />
          </label>
        </div>
        <div className="card-buttons">
          {editMode ? (
            <LoaderButton isLoading={removingFacility} onClick={remove} buttonType="destructive">
              <Translate value="manage_partners.facilities.button.remove" />
            </LoaderButton>
          ) : null}
          <LoaderButton type="submit" isLoading={isLoading}>
            {editMode ? (
              <Translate value="manage_partners.facilities.button.update" />
            ) : (
              <Translate value="manage_partners.facilities.button.add" />
            )}
          </LoaderButton>
        </div>
      </form>
    </Card>
  );
}
