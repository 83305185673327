import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';

class CustomDateInput extends Component {
  render() {
    const { value, onClick, placeholderI18nKey, disabled } = this.props;
    return (
      <span className={`fake-input ${disabled ? 'opacity-7' : ''}`} onClick={onClick}>
        {!value ? (
          <span className="fake-input__placeholder">
            <Translate value={placeholderI18nKey} />
          </span>
        ) : (
          <span>{value}</span>
        )}
      </span>
    );
  }
}

export default CustomDateInput;
