import CaregiverHandover from '../../CaregiverHandover';

const PermanentTransferView = () => {
  return (
    <div>
      <CaregiverHandover />
    </div>
  );
};

export default PermanentTransferView;
