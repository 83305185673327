import React from 'react';
import Select from '../Select';
import { Translate } from 'react-redux-i18n';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import DropdownIndicator from '../DropdownIndicator';
import Button from '../Button';
import api from '../../api/apiClient';
import { HTTP_STATUS_CODES } from '../../constants';
import CanPerform from '../CanPerform';
import Card from '../Card';
import {
  setInvoiceReportMonth,
  setInvoiceReportYear,
  getInvoiceReportRequest,
  getInvoiceReportSuccess,
  getInvoiceReportError
} from '../../actions';

const InvoiceReport = ({
  authToken,
  reports,
  setInvoiceReportMonth,
  setInvoiceReportYear,
  getInvoiceReportRequest,
  getInvoiceReportSuccess,
  getInvoiceReportError
}) => {
  const downloadInvoiceReport = () => {
    getInvoiceReportRequest();

    api
      .getInvoiceReport(authToken, reports.invoiceReportDate)
      .then((response) => {
        getInvoiceReportSuccess(response);
        const blob = new Blob([response], {
          type: 'text/plain'
        });
        saveAs(blob, `fakturering_${reports.invoiceReportDate.substring(0, 7)}.txt`);
      })
      .catch((error) => {
        console.log(error);
        getInvoiceReportError(error);
      });
  };

  return (
    <CanPerform action="readInvoiceReport">
      <Card titleI18nKey="reports_view.invoice_report.header">
        <div className="columns">
          <div className="column">
            <h4>
              <Translate value="reports_view.invoice_report.month_header" />
            </h4>
            <Select
              isSearchable={false}
              defaultValue={reports.months[reports.defaultMonth]}
              onChange={setInvoiceReportMonth}
              classNamePrefix="single-select"
              components={{ DropdownIndicator }}
              options={reports.months}
            />
          </div>
          <div className="column">
            <h4>
              <Translate value="reports_view.invoice_report.year_header" />
            </h4>
            <Select
              isSearchable={false}
              defaultValue={reports.years[reports.defaultYear]}
              onChange={setInvoiceReportYear}
              classNamePrefix="single-select"
              components={{ DropdownIndicator }}
              options={reports.years}
            />
          </div>
          <div className="column text-right mt-25">
            <Button onClick={downloadInvoiceReport}>
              <Translate value="global.buttons.generate" />
            </Button>
          </div>
        </div>
        {reports.invoiceReportError ? (
          reports.invoiceReportError.status && reports.invoiceReportError.status === HTTP_STATUS_CODES.NOT_FOUND ? (
            <div>
              <Translate value="reports_view.invoice_report.no_data" />
            </div>
          ) : (
            <div className="error-message">
              <Translate value="reports_view.invoice_report.error" />
            </div>
          )
        ) : null}
      </Card>
    </CanPerform>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    reports: state.reports,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  setInvoiceReportMonth,
  setInvoiceReportYear,
  getInvoiceReportRequest,
  getInvoiceReportSuccess,
  getInvoiceReportError
};

export default connect(mapStateToProps, mapActionsToProps)(InvoiceReport);
