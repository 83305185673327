import React from 'react';
import { Translate } from 'react-redux-i18n';
import './PaginationControl.scss';
import { MEMBER_SEARCH_PAGE_WINDOW_SIZE } from '../../../constants';

let startIndex = 0;
let endIndex = MEMBER_SEARCH_PAGE_WINDOW_SIZE;
const pageSize = MEMBER_SEARCH_PAGE_WINDOW_SIZE;

const PaginationControl = ({
  visible,
  initialPage,
  gotoPage,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  pageIndex,
  numPages
}) => {
  if (!visible) return null;

  if (initialPage) {
    gotoPage(initialPage);
  }

  const pageList = Array(numPages)
    .fill()
    .map((x, i) => i);

  if (pageIndex >= endIndex) {
    startIndex += pageSize;
    endIndex += pageSize;
  }

  if (pageIndex < startIndex) {
    startIndex -= pageSize;
    endIndex -= pageSize;
  }

  return (
    <div className="pagination-control">
      <div className={`previous-page ${!canPreviousPage ? 'disabled' : ''}`} onClick={previousPage}>
        <Translate value="global.previous" />
      </div>
      {pageIndex >= pageSize ? <div>...</div> : null}
      {pageList.slice(startIndex, endIndex).map((i) => (
        <PaginationButton key={i} id={i} pageIndex={pageIndex} gotoPage={gotoPage} />
      ))}
      {pageList.slice(startIndex, endIndex).length >= pageSize &&
      pageList.slice(startIndex, endIndex).length !== pageList.length ? (
        <div>...</div>
      ) : null}
      <div className={`next-page ${!canNextPage ? 'disabled' : ''}`} onClick={nextPage}>
        <Translate value="global.next" />
      </div>
    </div>
  );
};

const PaginationButton = ({ id, pageIndex, gotoPage }) => {
  return (
    <div
      key={id}
      className={`${id === pageIndex ? 'current-page' : ''}`}
      onClick={() => {
        gotoPage(id);
      }}
    >
      {id + 1}
    </div>
  );
};

export default PaginationControl;
