import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { APPLICATION_BASE_PATH } from '../../../constants';
import { isCaregiver } from '../../../utils';

function CaregiverMetricsView({ user }) {
  if (user?.guid && !isCaregiver(user)) {
    return <Redirect to={`/${APPLICATION_BASE_PATH}/user/${user.guid}`} />;
  }
  return <div>Mätpunkter etc. här 📈</div>;
}

const mapStateToProps = (state) => {
  return {
    user: state.systemUsers.currentSystemUser
  };
};

export default connect(mapStateToProps)(CaregiverMetricsView);
