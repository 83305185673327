import React, { FC } from "react";
import "./ToggleSwitch.scss";

type Props = {
  scope?: string;
  size?: string;
  type?: string;
  onChange: () => void;
  checked: boolean;
};

const ToggleSwitch: FC<Props> = ({ scope = "toggle", ...props }) => {
  const id = `switch-${scope || "toggle"}`;
  return (
    <span className={`toggle-switch ${props.size || ""} ${props.type || ""}`}>
      <input
        onChange={props.onChange}
        checked={props.checked}
        type="checkbox"
        id={id}
      />
      <label htmlFor={id}></label>
    </span>
  );
};

export default ToggleSwitch;
