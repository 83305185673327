import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import { APPLICATION_BASE_PATH } from '../../constants';
import EditMemberDetailsView from '../MemberDetailsView/EditMemberDetailsView';
import MemberDetailsView from '../MemberDetailsView';
import { clearCurrentMember } from '../../actions';
import { useEffect } from 'react';

const MemberDetails = ({ clearCurrentMember }) => {
  useEffect(() => {
    return () => clearCurrentMember();
  }, []);

  return (
    <Switch>
      <PrivateRoute
        path={`/${APPLICATION_BASE_PATH}/member/:memberId/edit`}
        component={EditMemberDetailsView}
        requiredAuthority="managePatients"
      />
      <PrivateRoute
        path={`/${APPLICATION_BASE_PATH}/member/:memberId`}
        component={MemberDetailsView}
        requiredAuthority="viewPatients"
      />
    </Switch>
  );
};

const mapActionsToProps = {
  clearCurrentMember
};

export default connect(null, mapActionsToProps)(MemberDetails);
