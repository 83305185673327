import { useState, createContext, useRef } from 'react';
import moment from 'moment';

type AccountingContextValue = {
  fromDate: Date;
  toDate: Date;
  setFromDate(date: Date): void;
  setToDate(date: Date): void;
  searchParams: URLSearchParams;
};

export const AccountingContext = createContext<AccountingContextValue>({
  fromDate: new Date(),
  toDate: new Date(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFromDate: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setToDate: () => {},
  searchParams: new URLSearchParams()
});

const AccountingProvider = ({ children }) => {
  const searchParams = useRef<URLSearchParams>(new URLSearchParams(new URL(document.location.href).searchParams));
  const [fromDate, setFrom] = useState(
    searchParams.current.has('from')
      ? moment(searchParams.current.get('from'), 'YYYY-MM-DD').toDate()
      : moment().add(-1, 'month').startOf('month').toDate()
  );
  const [toDate, setTo] = useState(
    searchParams.current.has('to')
      ? moment(searchParams.current.get('to'), 'YYYY-MM-DD').toDate()
      : moment().add(-1, 'month').endOf('month').toDate()
  );

  const setFromDate = (date: Date) => {
    setFrom(date);
    searchParams.current.set('from', moment(date).format('YYYY-MM-DD'));
    searchParams.current.set('to', moment(toDate).format('YYYY-MM-DD'));
    window.history.replaceState(
      history.state,
      '',
      `?from=${searchParams.current.get('from')}&to=${searchParams.current.get('to')}`
    );
  };

  const setToDate = (date: Date) => {
    setTo(date);
    searchParams.current.set('from', moment(fromDate).format('YYYY-MM-DD'));
    searchParams.current.set('to', moment(date).format('YYYY-MM-DD'));
    window.history.replaceState(
      history.state,
      '',
      `?from=${searchParams.current.get('from')}&to=${searchParams.current.get('to')}`
    );
  };

  const value = {
    fromDate,
    toDate,
    setToDate,
    setFromDate,
    searchParams: searchParams.current
  };

  return <AccountingContext.Provider value={value}>{children}</AccountingContext.Provider>;
};

export default AccountingProvider;
