import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Translate } from 'react-redux-i18n';
import useClickOutside from '../../hooks/useClickOutside';
import './DropdownMenu.scss';

const DropdownMenu = ({ actions, userAuthorities }) => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const toggle = () => setDropdownActive(!dropdownActive);
  const ref = useClickOutside(() => setDropdownActive(false));

  const menuVariants = {
    open: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -10 }
  };

  return (
    <div ref={ref} className={`dropdown dropdown-menu-actions ${dropdownActive ? 'is-active' : ''}`}>
      <div onClick={toggle}>
        <span className="icon more pointer"></span>
      </div>
      <motion.div
        className="dropdown-menu mt-5 ml-10"
        initial="hidden"
        animate={dropdownActive ? 'open' : 'hidden'}
        variants={menuVariants}
        transition={{ duration: 0.1 }}
      >
        <div className="dropdown-content">
          {actions.map((action, i) => {
            return action.type === 'link' ? (
              <div key={i} className="dropdown-item">
                <a
                  href={action.url}
                  disabled={!userAuthorities.includes(action.authority) || action.disabled}
                  className="no-padding block external"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Translate value={action.i18nKey} />
                </a>
              </div>
            ) : (
              <div
                key={i}
                className={`dropdown-item ${
                  !userAuthorities.includes(action.authority) || action.disabled ? 'disabled' : ''
                }`}
                onClick={() => {
                  action.onClick();
                  setDropdownActive(false);
                }}
              >
                <Translate value={action.i18nKey} />
              </div>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
};

export default DropdownMenu;
