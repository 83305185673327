import { Mixpanel, eventType } from '../utils/mixpanel';
import * as types from '../constants/actionTypes';

const trackedActions = {
  [types.SHOW_JIRA_MODAL]: {
    eventType: eventType.createJiraIssue,
    trackingType: 'time_event'
  },
  [types.SEND_MESSAGE_SUCCESS]: {
    eventType: eventType.sendMessage,
    trackingType: 'track'
  },
  [types.SHOW_MESSAGE_MODAL]: {
    eventType: eventType.sendMessage,
    trackingType: 'time_event'
  },
  [types.UPDATE_PAYMENT_EXEMPTION_SUCCESS]: {
    eventType: eventType.updatePaymentExemption,
    trackingType: 'track'
  },
  [types.POST_PAYMENT_EXEMPTION_SUCCESS]: {
    eventType: eventType.addPaymentExemption,
    trackingType: 'track'
  },
  [types.DELETE_PAYMENT_EXEMPTION_SUCCESS]: {
    eventType: eventType.removePaymentExemption,
    trackingType: 'track'
  },
  [types.TOGGLE_ADVANCED_SEARCH]: {
    eventType: eventType.toggleAdvancedSearch,
    trackingType: 'track'
  }
};

const trackingMiddleware = () => (next) => (action) => {
  if (trackedActions[action.type]) {
    const trackedAction = trackedActions[action.type];
    Mixpanel[trackedAction.trackingType](trackedAction.eventType);
  }

  if (action.type === types.LOGIN_SUCCESS) {
    Mixpanel.set('$name', `${action.payload.user.givenName} ${action.payload.user.familyName}`);
    Mixpanel.identify(action.payload.user.email);
    Mixpanel.set('$email', action.payload.user.email);
    Mixpanel.identify();
  }

  if (action.type === types.LOGOUT) {
    Mixpanel.reset();
  }

  if (action.type === types.LOGIN_ERROR) {
    Mixpanel.track(eventType.loginFailure, {
      errorCode: action.payload.response && action.payload.response.body && action.payload.response.body.code
    });
  }

  if (action.type === types.POST_MEMBER_JIRA_ISSUE_SUCCESS) {
    Mixpanel.track(eventType.createJiraIssue, { issueType: action.payload.type });
  }

  if (action.type === types.SET_ADDITIONAL_SEARCH_CRITERIA) {
    Mixpanel.track(eventType.setSearchCriteria, {
      searchProperty: action.payload.property
    });
  }

  return next(action);
};

export default trackingMiddleware;
