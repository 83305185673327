import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Card from '../../Card';
import { Facility, usePartner, PartnerUsersResponse, FacilitiesResponse, Partner } from '../PartnerProvider';
import { I18n, Translate } from 'react-redux-i18n';
import SkeletonComponent from '../../SkeletonComponent';
import { PartnerFacilityParams } from '..';
import styles from './PartnerDetails.module.scss';
import PartnerUserList from '../PartnerUserList';
import ErrorMessage from '../../ErrorMessage';

export default function PartnerDetails() {
  const { partnerId } = useParams<PartnerFacilityParams>();
  const { getPartnerFacilities, getUsers, getPartner, facilityServices, updatePartners } = usePartner();
  const [users, setUsers] = useState<PartnerUsersResponse>();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [usersError, setUsersError] = useState<unknown>();
  const [facilities, setFacilities] = useState<FacilitiesResponse>();
  const [loadingFacilities, setLoadingFacilities] = useState(false);
  const [facilitiesError, setFacilitiesError] = useState<unknown>();
  const [partner, setPartner] = useState<Partner>();

  useEffect(() => {
    getPartner(partnerId).then((res) => {
      setPartner(res);
      updatePartners(res);
    });
    setLoadingUsers(true);
    getUsers(partnerId)
      .then(setUsers)
      .catch(setUsersError)
      .finally(() => setLoadingUsers(false));

    setLoadingFacilities(true);
    getPartnerFacilities(partnerId)
      .then(setFacilities)
      .catch(setFacilitiesError)
      .finally(() => setLoadingFacilities(false));
  }, [partnerId]);

  return (
    <>
      <Card
        title={facilities?.partnerName ?? partner?.name}
        loadingTitle={loadingFacilities}
        errorI18nKey="manage_partners.error"
        additionalClass="x-large"
        actionButtons={
          <NavLink to={`${partnerId}/edit`}>
            <div className="vertical-align">
              <span className="icon pen mr-5"></span>
              <span>
                <Translate value="manage_partners.partner_overview.edit_partner" />
              </span>
            </div>
          </NavLink>
        }
      >
        <div className="partner-row mt-40">
          {loadingFacilities ? (
            <SkeletonComponent width="100%" height="38px" />
          ) : (
            <div className="space-between pb-10">
              <div className="fw-bold">
                <Translate value="manage_partners.partner_overview.partner_services" />
              </div>
              <div>
                {partner?.services
                  ?.filter((s) => s.selected && facilityServices.includes(s.service))
                  .map((s) => I18n.t(`manage_partners.service.${s.service}`))
                  .join(', ')}
              </div>
            </div>
          )}
        </div>
      </Card>
      <Card additionalClass="x-large">
        {loadingFacilities ? (
          <SkeletonComponent width="100%" height="38px" />
        ) : (
          <div className="space-between">
            <span className="fw-bold">
              <Translate value="manage_partners.facilities.status" />:{' '}
              <span className={`${partner?.active ? 'status-active' : 'status-inactive'}`}>
                {partner?.active ? I18n.t('manage_partners.status.active') : I18n.t('manage_partners.status.inactive')}
              </span>
            </span>
          </div>
        )}
      </Card>
      <Card
        titleI18nKey="manage_partners.facilities.header"
        errorI18nKey="manage_partners.error"
        additionalClass="x-large"
        description={
          facilities?.facilities
            ? facilities?.facilities?.length
              ? facilities?.facilities?.length === 1
                ? I18n.t('manage_partners.partner_overview.single_facility')
                : I18n.t('manage_partners.partner_overview.num_facilities', {
                    facilityCount: facilities?.facilities?.length
                  })
              : I18n.t('manage_partners.partner_overview.no_facilities')
            : ''
        }
        actionButtons={
          <NavLink to={`${partnerId}/new`}>
            <div className="vertical-align">
              <span className="icon facility mr-5"></span>
              <span>
                <Translate value="manage_partners.partner_overview.add_facility" />
              </span>
            </div>
          </NavLink>
        }
      >
        <FacilityList
          facilities={facilities?.facilities}
          isLoading={loadingFacilities}
          partnerId={partnerId}
          error={facilitiesError}
        />
      </Card>
      <PartnerUserList partnerId={partnerId} users={users?.users} isLoading={loadingUsers} error={usersError} />
    </>
  );
}

type FacilityListProps = {
  facilities?: Facility[];
  partnerId: string;
  isLoading: boolean;
  error?: unknown;
};

const FacilityList = ({ facilities = [], partnerId, isLoading, error }: FacilityListProps) => {
  if (error) {
    return <ErrorMessage error={error} errorI18nKey="manage_partners.facilities.error" />;
  }

  if (isLoading) {
    return (
      <>
        {new Array(3).fill(0).map((_, i) => (
          <SkeletonComponent key={i} width="100%" height="38px" />
        ))}
      </>
    );
  }

  return (
    <>
      {facilities.map((facility) => (
        <div className={styles.row} key={facility.id}>
          <NavLink to={`${partnerId}/facility/${facility.id}`}>
            <div className="space-between">
              <div>
                <div className={styles.name}>{facility.name}</div>
                <div className={styles.userCount}>
                  <Translate
                    value="manage_partners.facilities.num_users"
                    userCount={facility.userCount || I18n.t('global.none')}
                  />
                </div>
              </div>
              <div className={`${styles.status} ${facility.active ? styles.active : ''}`}>
                {facility.active ? I18n.t('manage_partners.status.active') : I18n.t('manage_partners.status.inactive')}
              </div>
            </div>
          </NavLink>
        </div>
      ))}
    </>
  );
};
