import React, { Component, Fragment } from 'react';
import spinnerPrimary from '../../assets/spinner-small-primary.png';
import spinnerSecondary from '../../assets/spinner-small-secondary.png';
import Button from '../Button';
import './LoaderButton.scss';

class LoaderButton extends Component {
  render() {
    const { isLoading, children, ...rest } = this.props;

    return (
      <Button {...rest}>
        {isLoading ? (
          rest.buttonType !== 'text' ? (
            <div className="loader-button-container">
              <span className="opacity-0">{children}</span>
              <img
                className={`loader-button-image ${rest.size || ''}`}
                src={rest.buttonType !== 'secondary' ? spinnerPrimary : spinnerSecondary}
                alt="loading..."
              />
            </div>
          ) : (
            <Fragment>
              <img className="loader-button-image__text" src={spinnerSecondary} alt="loading..." />
              <span>{children}</span>
            </Fragment>
          )
        ) : (
          children
        )}
      </Button>
    );
  }
}

export default LoaderButton;
