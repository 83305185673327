import './ErrorFallback.scss';

const ErrorFallback = ({ children }) => {
  return (
    <div className="error-fallback-wrapper">
      <div className="error-content">{children}</div>
    </div>
  );
};

export default ErrorFallback;
