import { useState } from 'react';
import { connect } from 'react-redux';
import { useChat } from '../../../contexts/ChatProvider';
import Button from '../../Button';
import styles from './ChatMessageInput.module.scss';
import { showNotification } from '../../../actions';

type Props = {
  disabled?: boolean;
  showNotification(message: string, type: string): void;
};

const ChatMessageInput = ({ disabled = false, showNotification }: Props) => {
  const { sendMessage } = useChat();
  const [newMessage, setNewMessage] = useState('');

  const send = async () => {
    if (!newMessage) {
      return;
    }

    try {
      await sendMessage(newMessage);
      setNewMessage('');
    } catch (e) {
      showNotification('Kunde inte skicka meddelandet.', 'error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    send();
  };

  const handleKeyDown = async (e) => {
    if (e.ctrlKey && e.code === 'Enter') {
      send();
    }
  };

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      <div>
        <textarea value={newMessage} onChange={(e) => setNewMessage(e.target.value)} onKeyDown={handleKeyDown} />
      </div>
      <div className="space-between top-align">
        <div className="fs-14">
          Skicka med <kbd>Ctrl</kbd>+<kbd>Enter</kbd>.
        </div>
        <div>
          <Button type="submit" disabled={disabled}>
            Skicka
          </Button>
        </div>
      </div>
    </form>
  );
};

const mapActionsToProps = {
  showNotification
};

export default connect(null, mapActionsToProps)(ChatMessageInput);
