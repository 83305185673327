import React, { useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Redirect } from 'react-router-dom';
import LoaderButton from '../LoaderButton';
import createUserReducer, { initialState, authorityGroups } from '../../reducers/createUserReducer';
import api from '../../api/apiClient';
import { showNotification } from '../../actions';
import Select from '../Select';

const CreateUserView = ({ authToken, showNotification }) => {
  const [state, dispatch] = useReducer(createUserReducer, initialState);

  useEffect(() => {
    if (state.error) {
      showNotification(I18n.t('notification.create_user.error'), 'error');
    }
  }, [state.error]);

  const onChangeHandler = (property: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (property === 'title') {
      dispatch({ type: 'setUserTitle', title: e.target.value });
    } else {
      dispatch({ type: 'setUserProperty', propertyName: property, propertyValue: e.target.value });
    }
  };

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch({ type: 'setSubmitted' });
    try {
      const newUser = await api.createUser(authToken, state.user, state.extras);
      dispatch({ type: 'setCreated', newUserGuid: newUser.guid });
    } catch (error) {
      dispatch({ type: 'setError', error });
    }
  };

  if (state.newUserGuid) {
    return (
      <Redirect
        to={{
          pathname: `user/${state.newUserGuid}`,
          state: { newUser: true, userTypeI18nKey: state.authorityGroup.labelI18nKey }
        }}
      />
    );
  }

  return (
    <div className="page-card">
      <h1>
        <Translate value="create_user_view.header" />
      </h1>
      <form onSubmit={onSubmit}>
        <div className="columns">
          <div className="column">
            <h4>
              <Translate value="create_user_view.authority_group" />
            </h4>
            <Select
              isSearchable={false}
              value={state.authorityGroup}
              onChange={(e) => {
                dispatch({ type: 'setAuthorityGroup', authorityGroup: e });
              }}
              classNamePrefix="single-select"
              options={authorityGroups}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h4>
              <Translate value="global.personal_number" />*
            </h4>
            <input
              type="text"
              value={state.user.personalNumber}
              required
              minLength={12}
              maxLength={13}
              onChange={onChangeHandler('personalNumber')}
            />
          </div>
          <div className="column">
            <h4>
              <Translate value="member_details.given_name" />*
            </h4>
            <input
              type="text"
              value={state.user.givenName}
              required
              maxLength={50}
              onChange={onChangeHandler('givenName')}
            />
          </div>
          <div className="column">
            <h4>
              <Translate value="member_details.family_name" />*
            </h4>
            <input
              type="text"
              value={state.user.familyName}
              required
              maxLength={50}
              onChange={onChangeHandler('familyName')}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h4>
              <Translate value="system_user_details.title" />
            </h4>
            <input type="text" value={state.extras.title} onChange={onChangeHandler('title')} />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h4>
              <Translate value="member_details.email" />*
            </h4>
            <input
              value={state.user.email}
              type="email"
              required
              pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
              maxLength={254}
              onChange={onChangeHandler('email')}
            />
          </div>
          <div className="column">
            <h4>
              <Translate value="member_details.phone_number" />
            </h4>
            <input
              value={state.user.phoneNumber}
              type="tel"
              pattern="[0-9-\+\s]+"
              maxLength={20}
              onChange={onChangeHandler('phoneNumber')}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h4>
              <Translate value="member_details.street_address" />
            </h4>
            <input
              type="text"
              value={state.user.streetaddress}
              maxLength={50}
              onChange={onChangeHandler('streetaddress')}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h4>
              <Translate value="member_details.postal_code" />
            </h4>
            <input
              type="text"
              value={state.user.postalCode}
              pattern="[0-9\s]+"
              maxLength={6}
              onChange={onChangeHandler('postalCode')}
            />
          </div>
          <div className="column">
            <h4>
              <Translate value="member_details.city" />
            </h4>
            <input type="text" value={state.user.city} maxLength={30} onChange={onChangeHandler('city')} />
          </div>
        </div>
        <div className="columns update-actions">
          <div className="column">
            <LoaderButton buttonType="primary" type="submit" isLoading={state.submitted}>
              <Translate value="create_user_view.create_user" />
            </LoaderButton>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt
  };
};

const mapActionsToProps = {
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(CreateUserView);
