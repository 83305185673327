import * as types from '../constants/actionTypes';
import { loadState, saveState } from '../services/localStorage';

const initialState = {
  currentTheme: 'default'
};

let nightModeSetting = loadState('nightMode', true);

if (nightModeSetting) {
  document.documentElement.setAttribute('data-theme-variant', 'dark');
} else if (nightModeSetting === false) {
  document.documentElement.setAttribute('data-theme-variant', 'light');
} else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
  document.documentElement.setAttribute('data-theme-variant', 'dark');
  nightModeSetting = true;
}

initialState.nightMode = nightModeSetting;

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SWITCH_THEME:
      return { ...state, currentTheme: action.payload };
    case types.TOGGLE_NIGHT_MODE:
      document.documentElement.setAttribute('data-theme-variant', !state.nightMode ? 'dark' : 'light');
      saveState(!state.nightMode, 'nightMode');

      return { ...state, nightMode: !state.nightMode };
    default:
      return state;
  }
};

export default themeReducer;
