import React, { Fragment, useEffect } from 'react';
import { useTable, useTableState, usePagination, useSortBy } from 'react-table';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import PaginationControl from '../PaginationControl';
import SkeletonComponent from '../../SkeletonComponent';
import { APPLICATION_BASE_PATH } from '../../../constants';
import './MemberTable.scss';

const MemberTable = ({
  columns,
  data,
  fetchData,
  limit,
  pagination,
  jumpToFirstPage,
  initialPage,
  initalSort,
  urlFragment,
  isLoading
}) => {
  const tableState = useTableState({ pageIndex: 0, pageSize: limit });
  const [{ pageIndex, pageSize, sortBy }] = tableState;

  const {
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      state: tableState,
      manualPagination: true,
      manualSorting: true,
      disableSortRemove: true,
      disablePageResetOnDataChange: true,
      pageCount: pagination ? Math.ceil(pagination.totalNumberOfItems / limit) : 0
    },
    useSortBy,
    usePagination
  );

  // Listen for changes in pagination and sort order and fetch new data
  useEffect(
    () => {
      fetchData({ pageIndex, pageSize, sortBy });
    },
    // Adding fetchData to the dependency array causes an infinite loop. Not sure why.
    // eslint-disable-next-line
    [pageSize, pageIndex, sortBy]
  );

  useEffect(() => {
    if (pageIndex > 0 && jumpToFirstPage) {
      gotoPage(0);
    }
  }, [gotoPage, pageIndex, jumpToFirstPage]);

  const container = {
    hidden: {
      opacity: 1
    },
    visible: {
      opacity: 1
    }
  };

  const item = {
    hidden: {
      x: -50,
      opacity: 0
    },
    visible: {
      x: 0,
      opacity: 1
    }
  };

  return (
    <Fragment>
      <div className={`${urlFragment}-table`}>
        {headerGroups.map((headerGroup, i) => (
          <div
            key={`headerGroup-${i}`}
            className={`r-table-headers r-table__${columns.filter((column) => column.show).length + 1}-cols`}
          >
            {headerGroup.headers.map((column, j) => (
              <div
                key={`header-${j}`}
                {...column.getHeaderProps(column.getSortByToggleProps({ title: '' }))}
                className="r-table-header"
              >
                <span
                  className={
                    (initalSort && initalSort.split(':')[0] === column.id) || column.isSorted ? 'column__sorted' : ''
                  }
                >
                  {column.render('Header')}
                </span>
                <span
                  className={
                    (initalSort && initalSort.split(':')[1] === 'desc') || column.isSortedDesc
                      ? 'column__sorted-desc'
                      : 'column__sorted-asc'
                  }
                ></span>
              </div>
            ))}
          </div>
        ))}
      </div>
      {!data.length && isLoading ? (
        <Fragment>
          {new Array(15).fill(0).map((_, i) => (
            <SkeletonComponent key={i} width="100%" height="55px" />
          ))}
        </Fragment>
      ) : (
        <motion.div
          className={`${urlFragment}-table r-table r-table__${columns.filter((column) => column.show).length + 1}-cols`}
          variants={container}
          animate="visible"
        >
          {page.map((row) => {
            return (
              <Link key={row.original.guid} to={`/${APPLICATION_BASE_PATH}/${urlFragment}/${row.original.guid}`}>
                <motion.div
                  key={row.original.guid}
                  className="r-table-row"
                  variants={item}
                  positionTransition={{ ease: 'easeInOut' }}
                  initial="hidden"
                >
                  {prepareRow(row) ||
                    row.cells.map((cell, j) => {
                      return (
                        <div
                          key={j}
                          className={`r-table-cell ${
                            j === row.cells.length - 1 ? 'r-table-cell__foot' : j === 0 ? 'r-table-cell__head' : ''
                          }`}
                        >
                          <span>{cell.render('Cell')}</span>
                        </div>
                      );
                    })}
                </motion.div>
              </Link>
            );
          })}
        </motion.div>
      )}

      <PaginationControl
        visible={pagination && pagination.totalNumberOfItems > limit}
        initialPage={initialPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        numPages={pageOptions.length}
      />
    </Fragment>
  );
};

export default MemberTable;
