import React, { Component, Fragment } from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Prompt, Redirect } from 'react-router-dom';
import { updateSystemUserRequest, updateSystemUserSuccess, updateSystemUserError } from '../../../actions';
import { APPLICATION_BASE_PATH } from '../../../constants';
import EditableText from '../../EditableText';
import LoaderButton from '../../LoaderButton';
import api from '../../../api/apiClient';

class EditSystemUserDetailsView extends Component {
  constructor(props) {
    super(props);

    this.saveChanges = this.saveChanges.bind(this);
    this.updateProperty = this.updateProperty.bind(this);
    this.updateExtraProperty = this.updateExtraProperty.bind(this);

    this.state = {
      systemUserUpdates: {},
      systemUserExtrasUpdates: {},
      renderRedirect: false,
      hasChanges: false
    };
  }

  preventNavigation(e) {
    e.preventDefault();
    e.returnValue = '';
  }

  saveChanges(e) {
    const { updateSystemUserRequest, updateSystemUserSuccess, updateSystemUserError } = this.props;
    e.preventDefault();

    updateSystemUserRequest();

    api
      .updateSystemUser(this.props.authToken, this.props.match.params.memberId, {
        user: { ...this.state.systemUserUpdates },
        systemUserExtras: { ...this.state.systemUserExtrasUpdates }
      })
      .then((response) => {
        updateSystemUserSuccess(response);

        this.setState({
          hasChanges: false,
          renderRedirect: true
        });

        window.removeEventListener('beforeunload', this.preventNavigation);
      })
      .catch((error) => {
        console.log(error);
        updateSystemUserError(error);
      });
  }

  updateProperty(event, property) {
    let updatedValue = event.target.value;

    this.setState({
      systemUserUpdates: {
        ...this.state.systemUserUpdates,
        [property]: updatedValue
      },
      hasChanges: true
    });

    window.addEventListener('beforeunload', this.preventNavigation);
  }

  updateExtraProperty(event, property) {
    let updatedValue = event.target.value;

    this.setState({
      systemUserExtrasUpdates: {
        ...this.state.systemUserExtrasUpdates,
        [property]: updatedValue
      },
      hasChanges: true
    });

    window.addEventListener('beforeunload', this.preventNavigation);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.preventNavigation);
  }

  render() {
    const { currentSystemUser, systemUserUpdateError, updatingSystemUser } = this.props.systemUsers;

    if (!currentSystemUser.personalNumber || this.state.renderRedirect) {
      return <Redirect to={`/${APPLICATION_BASE_PATH}/user/${this.props.match.params.memberId}`} />;
    }

    return (
      <Fragment>
        <Prompt
          message={(location) => {
            if (this.state.hasChanges && location.pathname.indexOf('edit') === -1) {
              return I18n.t('member_details.unsaved_changes_prompt');
            }

            return true;
          }}
        />
        <div className="edit-profile-container">
          <div className="card-container">
            <h2>
              <Translate value="member_details.edit_profile" />
            </h2>
            <form onSubmit={this.saveChanges}>
              <div className="columns">
                <div className="column">
                  <h4>
                    <Translate value="member_details.given_name" />
                  </h4>
                  <EditableText
                    text={currentSystemUser.givenName}
                    required
                    maxLength="50"
                    isEditing={true}
                    onChange={(e) => this.updateProperty(e, 'givenName')}
                  />
                </div>
                <div className="column">
                  <h4>
                    <Translate value="member_details.family_name" />
                  </h4>
                  <EditableText
                    text={currentSystemUser.familyName}
                    required
                    maxLength="50"
                    isEditing={true}
                    onChange={(e) => this.updateProperty(e, 'familyName')}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <h4>
                    <Translate value="system_user_details.title" />
                  </h4>
                  <EditableText
                    text={currentSystemUser.title}
                    isEditing={true}
                    onChange={(e) => this.updateExtraProperty(e, 'title')}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <h4>
                    <Translate value="member_details.street_address" />
                  </h4>
                  <EditableText
                    text={currentSystemUser.streetaddress}
                    maxLength="50"
                    isEditing={true}
                    onChange={(e) => this.updateProperty(e, 'streetaddress')}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <h4>
                    <Translate value="member_details.postal_code" />
                  </h4>
                  <EditableText
                    text={currentSystemUser.postalCode}
                    pattern="[0-9\s]+"
                    maxLength="6"
                    isEditing={true}
                    onChange={(e) => this.updateProperty(e, 'postalCode')}
                  />
                </div>
                <div className="column">
                  <h4>
                    <Translate value="member_details.city" />
                  </h4>
                  <EditableText
                    text={currentSystemUser.city}
                    maxLength="30"
                    isEditing={true}
                    onChange={(e) => this.updateProperty(e, 'city')}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <h4>
                    <Translate value="member_details.email" />
                  </h4>
                  <EditableText
                    text={currentSystemUser.email}
                    type="email"
                    pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                    maxLength="254"
                    isEditing={true}
                    onChange={(e) => this.updateProperty(e, 'email')}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <h4>
                    <Translate value="member_details.phone_number" />
                  </h4>
                  <EditableText
                    text={currentSystemUser.phoneNumber}
                    type="tel"
                    pattern="[0-9-\+\s]+"
                    maxLength="20"
                    isEditing={true}
                    onChange={(e) => this.updateProperty(e, 'phoneNumber')}
                  />
                </div>
              </div>
              <div className="columns update-actions">
                <div className="column is-8">
                  {systemUserUpdateError ? (
                    <span className="error-message-block">
                      <Translate value="member_details.update_profile_error" />
                    </span>
                  ) : null}
                </div>
                <div className="column is-4">
                  <LoaderButton buttonType="primary" type="submit" isLoading={updatingSystemUser}>
                    <Translate value="member_details.save_details" />
                  </LoaderButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    systemUsers: state.systemUsers
  };
};

const mapActionsToProps = {
  updateSystemUserRequest,
  updateSystemUserSuccess,
  updateSystemUserError
};

export default connect(mapStateToProps, mapActionsToProps)(EditSystemUserDetailsView);
