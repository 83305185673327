import api from '../api/apiClient';
import { isTokenNearingExpiry } from '../utils';
import { refreshTokenRequest, refreshTokenSuccess } from '../actions';

const excludedActions = [
  'LOGIN',
  'LOGOUT',
  'TOGGLE_ADVANCED_SEARCH',
  'TOGGLE_NIGHT_MODE',
  '@@' // 3rd party libraries
];

const tokenRefreshMiddleware = (store) => (next) => (action) => {
  const token = store.getState().auth.token;
  const isRefreshing = store.getState().auth.refreshingToken;
  const isExcluded = excludedActions.some((actionType) => action.type.indexOf(actionType) > -1);

  if (token && isTokenNearingExpiry(token) && !isRefreshing && !isExcluded) {
    next(refreshTokenRequest());

    api
      .refreshToken(token.jwt)
      .then((response) => {
        store.dispatch(refreshTokenSuccess(response));
        return next(action);
      })
      .catch((error) => {
        // Todo: Handle token refresh error
        console.log(error);
        return next(action);
      });
  } else {
    return next(action);
  }
};

export default tokenRefreshMiddleware;
