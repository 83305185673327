import { connect } from 'react-redux';
import api from '../../../api/apiClient';
import moment from 'moment';
import ContentLoader from '../../ContentLoader';
import { motion, AnimatePresence } from 'framer-motion';
import { useDataFetcher } from '../../../hooks/useDataFetcher';
import { useState } from 'react';
import LoaderButton from '../../LoaderButton';
import styles from './BatchView.module.scss';
import DatePickerWithFallback from '../../DatePickerWithFallback';
import { showNotification } from '../../../actions';
import { I18n, Translate } from 'react-redux-i18n';

type Pagination = {
  offset: number;
  limit: number;
  totalNumberOfItems: number;
};

type Batch = {
  batchNumber: string;
  price: number;
  depreciationSpan?: number;
  numberOfItems: number;
  pricePerUnit?: number;
  incomingDate: string;
  new?: boolean;
};

type PaginatedBatches = {
  pagination: Pagination;
  prices: Batch[];
};

const BatchView = ({ authToken, locale, showNotification }) => {
  const getBatches = () => {
    return api.getBatches(authToken);
  };

  const {
    data: batches,
    setData: setBatches,
    isLoading,
    error
  } = useDataFetcher<PaginatedBatches>(getBatches, { pagination: {}, prices: [] });
  const [isSavingBatch, setIsSavingBatch] = useState(false);
  const [batchFormVisible, setBatchFormVisible] = useState(false);
  const [numberOfItems, setNumberOfItems] = useState('');
  const [price, setPrice] = useState('');
  const [incomingDate, setIncomingDate] = useState<Date>();

  const createBatch = async (e) => {
    e.preventDefault();
    setIsSavingBatch(true);
    const formData = new FormData(e.target);

    const batchNumber = formData.get('batchNumber')?.toString();
    const numberOfItems = formData.get('numberOfItems')?.toString();
    const price = formData.get('price')?.toString();

    if (!batchNumber || !numberOfItems || !price || !incomingDate) {
      return;
    }

    const newBatch = {
      batchNumber,
      numberOfItems: Number(numberOfItems),
      price: Number(price),
      incomingDate: moment(incomingDate).format('YYYY-MM-DD')
    };

    try {
      const batch = await api.createBatch(authToken, newBatch);
      setBatches({ ...batches, prices: [...batches.prices, { ...batch, new: true }] });
      e.target.reset();
      setIncomingDate(undefined);
      setPrice('');
      setNumberOfItems('');
      setBatchFormVisible(false);
    } catch (error) {
      showNotification(I18n.t('notification.create_delivery_batch.error'), 'error');
    } finally {
      setIsSavingBatch(false);
    }
  };

  const variants = {
    open: {
      height: '100%'
    },
    closed: {
      height: 0
    }
  };

  return (
    <div className={styles.wrapper}>
      <h2>
        <Translate value="accounting.batches.header" />
      </h2>
      <ContentLoader isLoading={isLoading} error={error} errorTitleI18n="accounting.batches.error">
        <div className={`table-container ${styles.batchTable}`}>
          <table className="table w-100">
            <thead>
              <tr>
                <th>
                  <Translate value="accounting.batches.table_header.batch_number" />
                </th>
                <th>
                  <Translate value="accounting.batches.table_header.value" />
                </th>
                <th>
                  <Translate value="accounting.batches.table_header.depreciation" />
                </th>
                <th>
                  <Translate value="accounting.batches.table_header.num_units" />
                </th>
                <th>
                  <Translate value="accounting.batches.table_header.unit_value" />
                </th>
                <th>
                  <Translate value="accounting.batches.table_header.date" />
                </th>
              </tr>
            </thead>
            <tbody>
              <AnimatePresence initial={false}>
                {batches.prices.map((batch) => (
                  <motion.tr
                    key={`${batch.batchNumber}-${batch.numberOfItems}`}
                    initial={{ y: 50 }}
                    animate={{ y: 0 }}
                    transition={{ ease: 'easeOut', duration: 0.3 }}
                    className={batch.new ? styles.newRow : ''}
                  >
                    <td>{batch.batchNumber}</td>
                    <td>{batch.price?.toLocaleString() ?? '-'}</td>
                    <td>{batch.depreciationSpan?.toLocaleString() ?? '-'}</td>
                    <td>{batch.numberOfItems?.toLocaleString() ?? '-'}</td>
                    <td>{batch.pricePerUnit?.toLocaleString()}</td>
                    <td>{batch.incomingDate ? moment(batch.incomingDate).format('YYYY-MM-DD') : '-'}</td>
                  </motion.tr>
                ))}
              </AnimatePresence>
            </tbody>
          </table>
        </div>
      </ContentLoader>
      <div className="text-right">
        <span
          className={`option-expand mt-15 ${batchFormVisible ? 'expanded' : ''}`}
          onClick={() => setBatchFormVisible(!batchFormVisible)}
        >
          <Translate value="accounting.batches.new" />
        </span>
      </div>
      <motion.div
        className="overflow-hidden"
        animate={batchFormVisible ? 'open' : 'closed'}
        initial="closed"
        variants={variants}
      >
        <form onSubmit={createBatch} className={styles.form}>
          <div className="mb-10">
            <label htmlFor="batch-number">
              <h4>
                <Translate value="accounting.batches.table_header.batch_number" />
              </h4>
            </label>
            <input type="text" autoComplete="off" id="batch-number" name="batchNumber" required />
          </div>
          <div className="mb-10">
            <h4>
              <Translate value="accounting.batches.delivery_date" />
            </h4>
            <DatePickerWithFallback
              locale={locale}
              selectedDate={incomingDate}
              minDate={undefined}
              maxDate={moment().add(10, 'y').toDate()}
              placeholderI18nKey="global.choose_date"
              popperPlacement="bottom-start"
              onChange={(date) => {
                setIncomingDate(date);
              }}
              onChangeNative={(e) =>
                e.target.value ? setIncomingDate(moment(e.target.value).toDate()) : setIncomingDate(incomingDate)
              }
            />
          </div>
          <div className="mb-10">
            <label htmlFor="num-units">
              <h4>
                <Translate value="accounting.batches.delivery_items" />
              </h4>
            </label>
            <input
              type="text"
              autoComplete="off"
              id="num-units"
              name="numberOfItems"
              value={numberOfItems}
              onChange={(e) => setNumberOfItems(e.target.value)}
              required
            />
          </div>
          <div className="mb-10">
            <label htmlFor="total-batch-value">
              <div className="space-between">
                <h4 className="mt-0">
                  <Translate value="accounting.batches.delivery_value" />
                </h4>
                {numberOfItems && price ? (
                  <h4 className="mt-0">
                    <Translate
                      value="accounting.batches.unit_value"
                      unitValue={(parseInt(price) / parseInt(numberOfItems)).toLocaleString()}
                    />
                  </h4>
                ) : null}
              </div>
            </label>
            <input
              type="text"
              autoComplete="off"
              id="total-batch-value"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </div>
          <div className="text-right">
            <LoaderButton type="submit" isLoading={isSavingBatch}>
              <Translate value="accounting.batches.create" />
            </LoaderButton>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(BatchView);
