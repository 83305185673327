import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Translate } from 'react-redux-i18n';
import { motion } from 'framer-motion';
import Select from '../../Select';
import chunk from 'lodash.chunk';
import { DATE_FORMAT } from '../../../constants';
import DropdownIndicator from '../../DropdownIndicator';
import DatePickerWithFallback from '../../DatePickerWithFallback';
import { toggleAdvancedSearch } from '../../../actions';
import './AdvancedSearch.scss';

const AdvancedSearch = ({ additionalCriteriaOptions, additionalCriteria, search, toggleAdvancedSearch, locale }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const numCriteriaColumns = 2;

  if (!additionalCriteriaOptions) {
    return null;
  }

  const onDateChange = (option, isNative) => {
    return (arg) => {
      let date;
      if (!isNative) {
        date = arg;
      } else if (arg.target.value) {
        date = moment(arg.target.value).toDate();
      }

      setSelectedDate(date);
      let queryValue = '';
      if (date) {
        queryValue = `=ge=${moment.utc(date).format(DATE_FORMAT)};createdDate=lt=${moment
          .utc(date)
          .add(1, 'd')
          .format(DATE_FORMAT)}`;
      }

      option.onChange(option.queryProperty)({ value: queryValue });
    };
  };

  const renderOption = (option, i) => {
    const selectedIndex = option.optionsList.map((o) => o.id || o).indexOf(additionalCriteria[option.queryProperty]);
    const mappedSourceList = option.optionsList.map((source, i) => {
      return { value: i > 0 ? source.id || source : '', label: source.name || source };
    });

    return (
      <div className={`column is-${12 / numCriteriaColumns}`} key={i}>
        <h4>
          <Translate value={option.headerI18n} />
        </h4>
        {option.type === 'select' ? (
          <Select
            isSearchable={false}
            defaultValue={selectedIndex > -1 ? mappedSourceList[selectedIndex] : mappedSourceList[0]}
            onChange={option.onChange(option.queryProperty)}
            classNamePrefix="single-select"
            components={{ DropdownIndicator }}
            options={mappedSourceList}
          />
        ) : null}
        {option.type === 'datepicker' ? (
          <DatePickerWithFallback
            locale={locale}
            isClearable
            selectedDate={selectedDate}
            placeholderI18nKey="global.choose_date"
            onChange={onDateChange(option)}
            onChangeNative={onDateChange(option, true)}
          />
        ) : null}
      </div>
    );
  };

  const variants = {
    open: {
      height: '100%'
    },
    closed: {
      height: 0
    }
  };

  return (
    <Fragment>
      <div>
        <span
          className={`option-expand mt-15 ${search.advancedSearchVisible ? 'expanded' : ''}`}
          onClick={toggleAdvancedSearch}
        >
          <Translate value={`member_search.${search.advancedSearchVisible ? 'hide' : 'show'}_advanced_search`} />
        </span>
      </div>
      <motion.div
        className={`advanced-search ${search.advancedSearchVisible ? 'mt-20' : 'overflow-hidden'}`}
        animate={search.advancedSearchVisible ? 'open' : 'closed'}
        initial={search.advancedSearchVisible ? 'open' : 'closed'}
        variants={variants}
      >
        {chunk(additionalCriteriaOptions, numCriteriaColumns).map((c, i) => (
          <div key={i} className="columns">
            {c.map(renderOption)}
          </div>
        ))}
      </motion.div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    search: state.members,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  toggleAdvancedSearch
};

export default connect(mapStateToProps, mapActionsToProps)(AdvancedSearch);
