import React from 'react';
import './EditableText.scss';

const EditableText = ({ type = 'text', text, isEditing, onChange, ...rest }) => {
  return isEditing ? (
    <input className="editable-highlight" type={type} defaultValue={text} onChange={onChange} {...rest} />
  ) : (
    <div>{text || '-'}</div>
  );
};

export default EditableText;
