import React, { Fragment, useState } from 'react';
import moment from 'moment';
import initials from 'initials';
import { Translate, I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import ContentLoader from '../ContentLoader';
import CanPerform from '../CanPerform';
import { getGenderFromPersonalNumber, getAgeFromPersonalNumber, formatPersonalNumber, decamelize } from '../../utils';
import { APPLICATION_BASE_PATH } from '../../constants';
import Button from '../Button';
import DropdownMenu from '../DropdownMenu';
import ProfileImageUploadModal from '../ProfileImageUploadModal';
import Generation from '../Generation';

const ProfileCard = ({
  member,
  error,
  isLoading,
  userType,
  memberActions,
  actionsAuthority,
  onSendMessage,
  userAuthorities,
  getProfileImage
}) => {
  const [fileUploadModalVisible, setFileUploadModalVisible] = useState(false);
  const userInitials = initials(`${member.givenName} ${member.familyName}`);
  const birthday = member && member.personalNumber && moment(member.personalNumber.substr(0, 8));
  const hasProfileImage = !!member.profileImageUrl;

  return (
    <>
      <div className="card-container user-profile">
        <CanPerform action={actionsAuthority}>
          {userType === 'member' ? (
            <div className="profile-card-dropdown">
              <DropdownMenu actions={memberActions} userAuthorities={userAuthorities} />
            </div>
          ) : null}
        </CanPerform>
        <ContentLoader isLoading={isLoading} error={error} errorTitleI18n="member_details.error_fetching">
          <div className="text-center mb-30">
            <div
              className={`pos-rel member-avatar-fallback x-large ${userType} ${
                hasProfileImage ? 'has-profile-image' : ''
              } ${getGenderFromPersonalNumber(member.personalNumber)} ${userInitials.length > 4 ? 'long-initial' : ''}`}
            >
              {!hasProfileImage ? (
                <span className="fallback">{member && member.givenName ? userInitials : '...'}</span>
              ) : (
                <img src={member.profileImageUrl} alt="Avatar" />
              )}
              <CanPerform action={'manageUsers'}>
                {userType === 'user' ? (
                  <span
                    className="icon edit edit-profile-image pointer"
                    onClick={() => setFileUploadModalVisible(true)}
                  ></span>
                ) : null}
              </CanPerform>
            </div>
            <h2>{`${member.givenName} ${member.familyName}`}</h2>
            {userType === 'member' ? (
              <h3>{formatPersonalNumber(member.personalNumber)}</h3>
            ) : (
              <h3 className="system-user-title">{member.title || I18n.t('global.administrator')}</h3>
            )}
            <Generation next>
              <div className="profile-badge next-gen">NextGen</div>
            </Generation>
            {member.source ? (
              <div className={`profile-badge ${decamelize(member.source, '')}`}>{decamelize(member.source, ' ')}</div>
            ) : null}
            {member.pendingDiagnosis &&
            member.pendingDiagnosis !== 'referred' &&
            member.pendingDiagnosis !== 'results' ? (
              <div className="profile-badge undiagnosed">
                <Translate value="global.undiagnosed" />
              </div>
            ) : null}
          </div>
          {userType === 'member' ? (
            <div className="mb-20">
              <Translate value={`global.${getGenderFromPersonalNumber(member.personalNumber)}`} />,{' '}
              {getAgeFromPersonalNumber(member.personalNumber)} <Translate value="global.years" />
              {birthday &&
              birthday.format('M') === moment().format('M') &&
              birthday.format('D') === moment().format('D') ? (
                <span role="img" aria-label="cake">
                  {' '}
                  🍰
                </span>
              ) : null}
            </div>
          ) : null}
          <div className="mb-20">
            {userType === 'member' ? (
              <Fragment>
                <h4>
                  <Translate value="member_details.address_information" />
                </h4>
                {member.streetaddress && member.postalCode && member.city ? (
                  <Fragment>
                    <div>{member.streetaddress}</div>
                    <div>{`${member.postalCode} ${member.city}`}</div>
                  </Fragment>
                ) : (
                  <div className="data-missing">
                    <Translate value="member_details.no_address_information" />
                  </div>
                )}
              </Fragment>
            ) : null}
          </div>
          <div className="mb-30">
            <h4>
              <Translate value="member_details.contact_information" />
            </h4>
            {!member.email && !member.phoneNumber ? (
              <div className="data-missing">
                <Translate value="member_details.no_contact_information" />
              </div>
            ) : null}
            {member.email ? <div className="break-word">{member.email}</div> : null}
            {member.phoneNumber ? <div>{member.phoneNumber}</div> : null}
          </div>
          <CanPerform action={userType === 'member' ? 'managePatients' : 'manageUsers'}>
            <Link
              className="button button-secondary full-width inline-flex"
              to={`/${APPLICATION_BASE_PATH}/${userType}/${member.guid}/edit`}
            >
              <Translate value="member_details.edit_profile" />
            </Link>
          </CanPerform>
          {userType === 'member' ? (
            <CanPerform action="sendCommunications">
              <Button onClick={onSendMessage} fullWidth={true}>
                <Translate value="member_details.send_message" />
              </Button>
            </CanPerform>
          ) : null}
        </ContentLoader>
      </div>
      <ProfileImageUploadModal
        isVisible={fileUploadModalVisible}
        onClose={() => setFileUploadModalVisible(false)}
        memberGuid={member.guid}
        memberName={`${member.givenName} ${member.familyName}`}
        hasProfileImage={hasProfileImage}
        getProfileImage={getProfileImage}
      />
    </>
  );
};

export default ProfileCard;
