export const en = {
  global: {
    title: 'Accumbo Admin',
    loading: 'Loading...',
    dark_mode: 'Dark mode',
    change_language: 'Change language',
    buttons: {
      save: 'Save',
      done: 'Done',
      cancel: 'Cancel',
      ok: 'OK',
      generate: 'Generate',
      block: 'Block user',
      reactivate: 'Reactivate user',
      send_message: 'Skicka meddelande',
      remove: 'Remove',
      edit: 'Edit',
      add: 'Add',
      retry: 'Try again',
      send: 'Send'
    },
    male: 'Man',
    female: 'Woman',
    authorities: 'Authorities',
    personal_number: 'Personal number',
    name: 'Name',
    created: 'Created',
    search: 'Search',
    previous: 'Previous',
    next: 'Next',
    device: {
      ANDROID: 'Android',
      IOS: 'iOS',
      UNKNOWN: 'Unknown'
    },
    years: 'years',
    show_error_info: 'Show detailed information',
    reason: 'Reason',
    logout: 'Sign out',
    login: 'Log in',
    status: 'Status',
    service_status: {
      active: 'Active',
      hidden: 'Inactive',
      blocked: 'Blocked',
      nurse: 'Handled by nurse',
      doctor: 'Handled by doctor',
      deactivating: 'Deactivating'
    },
    choose: 'Select...',
    all: 'All',
    choose_date: 'Choose date',
    choose_caregiver: 'Choose caregiver',
    choose_role: 'Choose role',
    choose_period: 'Choose period',
    period: 'Period',
    username: 'Username',
    password: 'Password',
    disallowed_route: "You don't have access to this page. 😐",
    from_date: 'Start date',
    to_date: 'End date',
    from_date_incl: 'From',
    to_date_incl: 'To',
    administrator: 'Administrator',
    user_title: 'Role',
    no_address: 'Address missing',
    copy: 'Copy',
    yes: 'Yes',
    no: 'No',
    doctor: 'Doctor',
    nurse: 'Nurse',
    role: 'Role',
    caregiver: 'Caregiver',
    patients: 'patients',
    of: 'of',
    both: 'Both',
    back: 'Go back',
    previous_caregiver: {
      temporary: 'Temporary',
      permanent: 'Permanent'
    },
    final_note_reason: {
      checkup: 'Appointment',
      new_member_checkup: 'New patient',
      three_month_checkup: '3-month appointment',
      yearly_checkup: 'Yearly appointment',
      extra_checkup: 'Extra checkup',
      six_week_checkup: '6-week checkup',
      initial_checkup: 'Initial checkup',
      diagnosis_screening: 'Screening',
      examination_follow_up: 'Physical examination follow-up',
      nurse_treatment: 'Nurse treatment, hypertension',
      nurse_new_member: 'Nurse new patient',
      nurse_two_week: 'Nurse two-week checkup',
      nurse_six_month: 'Nurse six-month checkup',
      nurse_yearly: 'Nurse yearly checkup',
      nurse_extra: 'Nurse extra checkup',
      missing: 'Checkup type missing'
    },
    final_note_statistics_patient_group: {
      old_patients: 'Patient before 2022-01-01',
      new_patients: 'Patient after 2022-01-01',
      total: 'Total'
    },
    payment_state: {
      created: 'Payment requested',
      pending: 'Pending',
      exemption: 'Exemption',
      complete_unknown: 'Paid',
      complete_swish: 'Paid with Swish',
      complete_card: 'Paid with card',
      complete_invoice: 'Paid with invoice',
      failed: 'Payment failed',
      error: 'Payment failed',
      cancelled: 'Cancelled'
    },
    payment_provider: {
      nordeaconnect: 'Nordea',
      invoice: 'Invoice',
      billogram: 'Billogram'
    },
    show_all: 'Show all',
    show_fewer: 'Show fewer',
    monitor_size: {
      S: 'Small (18-21 cm)',
      M: 'Medium (22-32 cm)',
      L: 'Large (33-42 cm)',
      XL: 'XLarge (43-56 cm)'
    },
    optional: 'Optional',
    year: 'Year',
    quarter: 'Quarter',
    month: 'Month',
    monitor: {
      ihealth: 'iHealth',
      truly: 'Truly',
      otherManual: 'Manual/own monitor'
    },
    bp_monitor: 'Blood pressure monitor',
    none: 'No',
    undiagnosed: 'Undiagnosed patient',
    other: 'Other',
    open: 'Open'
  },
  icon: {
    service_status: {
      blocked: 'Blocked'
    },
    last_measure_date: 'Last measurement',
    last_lab_result_date: 'Last lab result',
    closed_support_issues: 'Closed support issues: %{value}',
    open_support_issues: 'Open support issues: %{value}',
    last_monitor_sent_date: 'Monitor sent'
  },
  notification: {
    save_generic: {
      success: 'New value saved.',
      error: 'New value could not be saved.'
    },
    service_status_active: {
      success: '%{name} has been unblocked',
      error: '%{name} could not be unblocked'
    },
    service_status_blocked: {
      success: '%{name} has been blocked',
      error: '%{name} could not be blocked'
    },
    service_status_hidden: {
      success: '%{name} has been markes as inactive',
      error: '%{name} could not be marked as inactive'
    },
    save_arm_circumference: {
      success: 'New monitor size saved.',
      error: 'Could not save monitor size.'
    },
    send_bulk_message: {
      success: 'Message sent.',
      error: 'The message could not be sent.'
    },
    update_authorities: {
      error: 'Could not update authorities.'
    },
    update_hidden_regions: {
      error: 'Could not update disallowed regions.'
    },
    update_hidden_sources: {
      error: 'Could not update disallowed sources.'
    },
    new_handover: {
      success: 'Handover saved.',
      error: 'Could not save handover.'
    },
    delete_handover: {
      success: 'Handover canceled.',
      error: 'The handover could not be canceled.'
    },
    update_handover: {
      success: 'End date updated.',
      error: 'Could not update end date.'
    },
    send_bulk_referrals: {
      success: 'Sending of referrals started.',
      error: 'Could not send referrals.'
    },
    save_comment: {
      success: 'Comment saved.',
      error: 'Could not save comment.'
    },
    create_jira_issue: {
      success: 'Issue created',
      error: 'Could not create issue.'
    },
    delete_shipment: {
      error: 'Could not delete the shipment.'
    },
    add_manual_shipment_outgoing: {
      success: 'Monitor shipment created.',
      error: 'Could not create monitor shipment.'
    },
    add_manual_shipment_return: {
      success: 'Return created.',
      error: 'Could not create monitor return.'
    },
    add_manual_shipment_replacement: {
      success: 'Replacement created.',
      error: 'Could not create monitor replacement.'
    },
    delay_shipment: {
      success: 'The shipment has been postponed.',
      error: 'Could not postpone the shipment.'
    },
    undo_delay_shipment: {
      success: 'The postponement was canceled.',
      error: 'The postponement could not be canceled.'
    },
    update_shipment_delay: {
      success: 'The postponement date was updated.',
      error: 'Could not update postponement date.'
    },
    update_communication: {
      error: 'Could not postpone the communication.'
    },
    delete_communication: {
      error: 'Could not remove the communication.'
    },
    update_canned_response_title: {
      success: 'Title updated to "%{title}"',
      error: 'Could not update title.'
    },
    update_canned_response_body: {
      success: 'Text content for "%{title}" updated.',
      error: 'Could not update the text content for "%{title}"'
    },
    create_canned_response: {
      error: 'Could not create the chat template.'
    },
    delete_canned_response: {
      error: 'Could not delete the chat template.'
    },
    update_canned_response: {
      error: 'Could not update the chat template.'
    },
    member_message: {
      success: 'Message sent.',
      error: 'The message could not be sent. Try again or contact technical support.'
    },
    export_journal: {
      error: 'Could not export journal. Try again or contact technical support.'
    },
    priority_saved: {
      success: 'Priority saved for %{name}.',
      error: 'Could not save the priority setting.'
    },
    priority_removed: {
      success: 'Priority removed for %{name}',
      error: 'Could not remove the priority setting.'
    },
    payment_exemption_added: {
      success: 'Payment exemption card saved.',
      error: 'Could not save the payment exemption card.'
    },
    payment_exemption_updated: {
      success: 'Payment exemption card updated.',
      error: 'Could not update the payment exemption card.'
    },
    payment_exemption_removed: {
      success: 'Payment exemption card removed.',
      error: 'Could not remove the payment exemption card.'
    },
    download_pdf: {
      error: 'Could not download PDF. Try again or contact technical support.'
    },
    download_receipt: {
      error: 'Could not download the receipt. Try again or contact technical support.'
    },
    download_invoice: {
      error: 'Could not download the invoice. Try again or contact technical support.'
    },
    upload_profile_picture: {
      success: "%{name}'s profile image was updated.",
      error: 'Could not upload the image.'
    },
    tag_incoming_patients: {
      success_single: 'The personal number was tagged with "%{source}".',
      success_multiple: '%{numPatients} personal numbers tagged with "%{source}".',
      error: 'Something went wrong when tagging. Try again or contact technical support.'
    },
    get_final_note_breakdown: {
      error: 'Could not fetch final note statistics.'
    },
    get_final_notes_per_patient_group: {
      error: 'Could not fetch final note statistics.'
    },
    create_user: {
      success: 'New %{type} created.',
      error: 'Could not create the user. Try again or contact technical support.'
    },
    update_member_state: {
      success: '%{stateName} %{stateAction}',
      error: 'Could not update state for: %{stateName}'
    },
    send_monitor_invoice: {
      success: 'Invoice for monitor sent.',
      error: 'Could not send monitor invoice.',
      error_conflict:
        'Patient has already received an invoice for their monitor. A new invoice has therefore not been created.'
    },
    deactivate_member: {
      success: '%{name} was deactivated.',
      error: 'Could not deactivate %{name}.'
    },
    reactivate_member: {
      success: '%{name} was reactivated.',
      error: 'Could not reactivate %{name}.'
    },
    create_delivery_batch: {
      error: 'Could not save the batch. Try again or contact support.'
    },
    download_transactions: {
      error: 'Could not download transactions. Try again or contact support.'
    },
    add_partner_user: {
      success: 'New user created.',
      error: 'Could not create the user. Try again or contact support.'
    },
    update_partner_user: {
      success: 'The user was updated.',
      error: 'Could not update the user. Try again or contact support.'
    },
    remove_partner_user: {
      success: 'The user was removed.',
      error: 'Could not remove the user. Try again or contact support.'
    },
    instructions_file_download: {
      error: 'Could not download the instructions file.'
    },
    partner_user_export: {
      error: 'Could not download the user export file.'
    },
    create_partner: {
      error: 'Could not create partner. Try again or contact support.'
    },
    update_partner: {
      error: 'Could not update the partner. Try again or contact support.'
    },
    remove_partner: {
      success: 'The partner organization was removed.',
      error: 'Could not remove the partner organization. Try again or contact support.'
    },
    create_facility: {
      error: 'Could not create the facility. Try again or contact support.'
    },
    update_facility: {
      error: 'Could not update the facility. Try again or contact support.'
    },
    remove_facility: {
      error: 'Could not remove the facility. Try again or contact support.'
    },
    send_kiosk_activation_code: {
      success: 'The activation code was sent to %{email}.',
      error: 'Could not send the activation code. Try again or contact support.'
    },
    update_program: {
      error: 'The program could not be updated.'
    },
    url_copied: {
      success: 'The URL was copied to the clipboard.'
    },
    save_absence: {
      success: 'Saved absence.',
      error: 'Could not save the absence. Try again or contact support.'
    },
    remove_absence: {
      success: 'Removed absence.',
      error: 'Could not remove the absence. Try again or contact support.'
    },
    save_allocation: {
      error: 'Could not save the updated allocation.'
    },
    update_caregiver: {
      error: 'Could not save new caregiver.',
      error_conflict: 'Could not save. The selected caregiver has an upcoming absence planned.'
    }
  },
  menu: {
    user_search: 'Patients',
    support: 'Support center',
    system_users: 'System users',
    reports: 'Reports',
    services: 'Services',
    planning: 'Planning'
  },
  login_view: {
    start_login: 'Log in with Mobilt BankID',
    footer_info: ' • Bredbandet 1, 392 30 Kalmar • 0480-797 960 • support@blodtrycksdoktorn.se',
    generic_error: 'Login failed',
    instruction1:
      'Start the BankID app on your phone and press <strong>Skanna QR-kod</strong>. Point the camera at the QR code above.',
    instruction2: 'Enter your security code in the BankID app and press <strong>Identifiera</strong>.',
    cancel_login: 'Cancel login'
  },
  member_search: {
    input_placeholder: 'Search name, personal number, phone, or email address',
    no_results: 'Could not find a user matching the search term.',
    showing_x_of_y_results: 'Showing %{currentPage} of %{totalCount} results',
    error: 'Error loading users.',
    show_advanced_search: 'Show advanced search options',
    hide_advanced_search: 'Hide advanced search options',
    special_searches: 'Special searches',
    all_regions: 'All regions',
    all_sources: 'All sources',
    all_platforms: 'All platforms',
    all_arm_circumferences: 'All monitor sizes',
    created_date: 'Creation date',
    is_next_gen: 'App generation'
  },
  system_user_search: {
    assignment_priority: {
      give_priority_header: 'Give priority to patient assignment',
      current_patients: 'Current number of patients',
      patients_increase: 'Number or patients to add',
      patients_increase_placeholder: 'For example 200',
      priority_overview_header: 'Overview of assignment priority ',
      priority_start_date: 'Start date',
      patients_at_start: 'Started with',
      priority_limit: 'Priority until',
      assigned: 'Assigned',
      delete_button: 'Remove priority',
      give_priority: 'Give priority',
      has_priority: 'Has priority'
    },
    create_user: '+ New user'
  },
  member_details: {
    error_fetching: 'Could not get user details.',
    error_fetching_region_list: 'Could not fetch region list',
    change_details: 'Change details',
    given_name: 'First name',
    family_name: 'Last name',
    phone_number: 'Phone number',
    email: 'Email address',
    age: 'Age',
    street_address: 'Street address',
    postal_code: 'Postal code',
    city: 'City',
    platform: 'Platform',
    created: 'Created',
    region: 'Region',
    source: 'Source',
    update_error: 'Could not save updated user details. Please try again.',
    unsaved_changes_prompt: 'There are unsaved changes. Leave page anyway?',
    responsible_doctor: 'Responsible doctor',
    responsible_nurse: 'Responsible nurse',
    not_active_yet: 'Not yet active',
    error_fetching_pal_list: 'Could not load list of doctors',
    error_fetching_pas_list: 'Could not load list of nurses',
    confirm_member_block: 'Are you sure you want to block this user?',
    block_member_error: 'Could not block the user.',
    reactivate_member_error: 'Could not reactivate the user.',
    treatment: 'Treatment',
    latest_lab_results: 'Latest lab results',
    testing: 'Testing',
    arm_circumference: 'Monitor size',
    select_monitor_size: 'Choose size',
    general: 'General',
    blocked: 'Blocked/deactivated',
    block_member: 'Deactivate patient',
    unblock_member: 'Reactivate patient',
    block_without_return_slip: 'Deactivate without return',
    block_with_return_slip: 'Deactivate with return',
    block_without_return_slip_details: 'The patient does not need to return their monitor.',
    block_with_return_slip_details: 'The patient needs to return their monitor.',
    blocked_without_return_slip: 'Patient deactivated without return.',
    blocked_with_return_slip: 'Patient deactivated with return.',
    blocked_label: 'Patient is deactivated',
    ongoing_deactivation: 'Ongoing deactivation',
    pause_deactivation: 'Pause deactivation',
    paused_deactivation: 'Paused deactivation',
    resume_deactivation: 'Resume deactivation',
    address_information: 'Address information',
    no_address_information: 'No address information',
    contact_information: 'Contact details',
    no_contact_information: 'No contact details',
    edit_profile: 'Edit profile',
    update_profile_error: 'Could not update profile.',
    save_details: 'Save details',
    biometrics: 'Biometrics',
    insurance_header: 'Insurance information',
    claim_id: 'ClaimID',
    create_issue: 'Create new issue',
    no_issues: 'No support issues',
    issue_single: '1 support issue',
    issue_multiple: '%{numIssues} support issues',
    choose_region: 'Choose a region',
    comment: 'Comment',
    last_measurement: 'Last measurement',
    last_login: 'Last login',
    send_message_to: 'Send message to %{name}',
    send_message: 'Send message',
    message_subject_header: 'Subject',
    message_body_header: 'Message',
    message_discard_alert: 'Discard the message?',
    monitor_sent: 'Monitor sent',
    monitor_shipment_status: 'Monitor shipment',
    shipment_status_updated: 'Status updated %{timestamp}',
    mark_inactive: 'Mark as inactive',
    inactive_tooltip: "The patient will not show up in their doctor's errand list until they log in again.",
    chat_communication_header: 'Latest chat read',
    empty_chat: 'No chat messages',
    choose_journal_timespan: 'Choose period...',
    timespan_header: 'Timespan',
    journal_timespan: {
      two_weeks: '2 weeks',
      one_month: '1 month',
      ninety_days: '90 days'
    },
    deregister: 'Deregister patient',
    deregister_confirmation: 'Are you sure you want to deregister the patient?',
    deregistered: 'Marked for removal %{date}',
    updates: {
      responsible_doctor: {
        success: 'Responsible doctor changed to %{newValue}.',
        error: 'Could not change responsible doctor.'
      },
      responsible_nurse: {
        success: 'Responsible nurse changed to %{newValue}.',
        error: 'Could not change responsible nurse.'
      },
      region: {
        success: 'Region changed to %{newValue}.',
        error: 'Could not change region.'
      }
    },
    actions: {
      create_shipment: 'Send monitor',
      create_replacement_shipment: 'Send replacement monitor',
      create_return: 'Create return to Mönsterås',
      create_kalmar_return: 'Create return to Kalmar',
      export_journal: 'Export journal',
      show_spar_info: 'Show information from SPAR',
      open_in_clinic: 'Open in Clinic',
      create_invoice: 'Send monitor invoice'
    },
    scheduled_communication: {
      header: 'Scheduled communication',
      empty: 'No communication scheduled for this patient.',
      name: 'Subject',
      sends_in: 'Sends in',
      delete: 'Remove',
      confirm_delete: 'Are you sure you want to remove the communication?',
      postpone: 'Postpone 1 week',
      edit: 'Edit',
      error: 'Could not fetch scheduled communication.',
      type: {
        email: 'Email',
        sms: 'SMS',
        push: 'Push',
        jira: 'Jira',
        chat: 'Chat'
      }
    },
    funnel_state: {
      header: 'Status',
      inactive: 'Not applicable',
      bottom_funnel: 'Bottom funnel',
      deactivation_funnel: 'Deactivation funnel',
      table_headers: {
        event: 'Event',
        date: 'Date'
      },
      empty: 'No ongoing deactivation',
      ongoing: 'Automatic deactivation started for:',
      error: 'Error while fetching status',
      days_to_invoice: 'Days until automatic invoice is sent:',
      pause: "Pause reminders for 1st doctor's appointment",
      resume: "Resume reminders for 1st doctor's appointment"
    },
    spar: {
      modal_header: 'Information from SPAR',
      error: 'Could not fetch data from SPAR.',
      deregistration_code: {
        A: 'Avliden',
        AV: 'Avliden',
        G: 'Gammalt nummer',
        O: 'Övrig orsak',
        UV: 'Utvandrad',
        GN: 'Gammalt personnummer',
        GS: 'Gammalt samordningsnummer',
        AS: 'Annullering av samordningsnummer',
        OB: 'Försvunnen',
        TA: 'Teknisk avregistrering',
        FI: 'Falsk identitet'
      }
    },
    payment_exemption_header: 'Payment exemption',
    payment_exemption_card_number: 'Card number',
    payment_exemption_card_date: 'Valid until',
    payment_exemption_confirm_removal: "Are you sure you want to remove the patient's payment exemption card?",
    payment_history: {
      header: 'Patient fee history',
      table_headers: {
        visit_type: 'Visit',
        status: 'Status',
        date: 'Date',
        receipt: 'Receipt'
      },
      error: 'Could not fetch patient fee history.',
      no_history: 'The patient has no payment history.',
      download_receipt: 'Download receipt',
      download_invoice: 'Download invoice'
    },
    monitor_list: {
      header: 'Last measured with',
      heading: {
        monitor: 'Monitor',
        sent: 'Sent',
        first_measurement: 'First measurement',
        last_measurement: 'Last measurement'
      }
    },
    program_management: {
      header: 'Programs',
      action_trigger: 'Select action',
      confirm_abort: 'Are you sure you want to abort the program?',
      error: 'Could not fetch program information.',
      empty: 'The patient has no programs',
      edit: 'Change',
      action: {
        resume: 'Resume',
        abort: 'Abort',
        help: 'Help',
        leave: 'Leave',
        redo: 'Redo',
        skip: 'Skip'
      },
      program: {
        AlcoholProgram: 'Alkoholprogram',
        DietProgram: 'Kostprogram',
        DirectOnboardingProgram: 'Onboarding (direkt)',
        DummyAppointmentProgram: 'Starta kontroll (testprogram)',
        ExtraDoctorAppointmentProgram: 'Extrakontroll',
        ExtraNurseAppointmentProgram: 'Extrainsats SSK',
        HLCAProgram: 'HCLA-program',
        HypertensionDiagnosedProgram: 'Nybesök',
        HypertensionFollowUpProgram: 'Uppföljning högt blodtryck',
        HypertensionTreatmentInitializationProgram: 'Blodtryckskontroll',
        HypertensionTreatmentQualification: 'Kvalificering för behandling eller utredning',
        HypertensionUndiagnosedProgram: 'Utredning av högt blodtryck',
        KioskNotQualifiedProgram: 'Kioskresultat för icke kvalificerade',
        KioskOnboardingProgram: 'Onboarding (Kiosk)',
        LabProgram: 'Skicka remiss för provtagning',
        LifestyleProgram: 'Livsstilsutredning',
        LifestyleTreatmentProgram: 'Livsstilsbedömning',
        NewStepsTestProgram: 'NewStepsTestProgram',
        NotificationTestProgram: 'Testa notiser',
        OffboardingProgram: 'Offboarding',
        PartnerOnboardingProgram: 'Onboarding (Partner)',
        ReboardingProgram: 'Reboarding',
        RiskAssessmentProgram: 'Utred risk för hypertonidiagnos',
        SmokingProgram: 'Rökavvänjning',
        SnuffProgram: 'Snusavvänjning',
        StressProgram: 'Stressprogram',
        WeightProgram: 'Viktprogram'
      },
      program_state: {
        ineligible: 'Ineligible',
        eligible: 'Eligible',
        paused: 'Paused',
        started: 'Started',
        planned: 'Planned',
        skipped: 'Skipped',
        redo: 'Redo',
        ongoing: 'Ongoing',
        revalidate: 'Revalidate',
        completed: 'Completed',
        aborted: 'Aborted'
      },
      reason_category: {
        disqualified: 'Disqualified',
        inactive: 'Inactive',
        leave: 'Leave',
        abort: 'Abort',
        help: 'Help',
        resume: 'Resume',
        skip: 'Skip'
      },
      reason: {
        age_under_35: 'Age under 35',
        heart_or_renal_failure: 'Heart or renal failure',
        pregnant: 'Pregnant',
        low_probability: 'Low probability',
        preparations_not_done: 'Preparations not done',
        optional_preparations_not_done: 'Optional preparations not done',
        other_caregiver: 'Other caregiver',
        no_need: 'No need',
        personal: 'Personal',
        other: 'Other',
        not_interested: 'Not interested',
        not_started: 'Not started',
        testing: 'Testing',
        no_selected_action: 'No selected action',
        care_plan_aborted: 'Care plan aborted',
        redo: 'Redo',
        aborted_by_admin: 'Aborted by admin',
        more_time: 'More time',
        skip_optional_steps: 'Skip optional steps',
        resumed_by_admin: 'Resumed by admin',
        no_time: 'No time',
        no_available_time: 'No available time',
        too_long_distance: 'Too long distance',
        cannot_leave_home: 'Cannot leave home',
        not_relevant: 'Not relevant'
      }
    },
    chat: {
      error: 'Could not load the chat history.',
      thumbnail_error: 'Could not load image.',
      image_modal_header: 'Image from %{name}'
    },
    caregiver_history: {
      doctor: {
        header: 'Previous doctors',
        error: 'Could not fetch previous doctors.',
        empty: 'No previous doctors.'
      },
      nurse: {
        header: 'Previous nurses',
        error: 'Could not fetch previous nurses.',
        empty: 'No previous nurses.'
      },
      period: 'From %{from} to %{to}',
      unknown_date: 'unknown date'
    },
    treatment_areas: {
      header: 'Treatment areas',
      cancel_treatment: 'Stop treatment',
      resend_return_monitor_email: 'Resend return email',
      area: {
        hypertension: 'Hypertension',
        obesity: 'Obesity'
      },
      state: {
        investigation: 'Utredning',
        onboarding: 'Onboarding',
        treatment: 'Behandling',
        titration: 'Titrering',
        aborting: 'Under avslut',
        aborted: 'Avslutat'
      },
      modal_header: 'Stop treatment area: %{treatmentArea}',
      reason_header: 'Select a reason',
      choose_reason: 'Select reason',
      prepopulated: 'Quick select',
      patient_returns_monitor: 'Patient returns monitor'
    }
  },
  system_user_details: {
    disallowed_regions: 'Disallowed regions',
    disallowed_sources: 'Disallowed sources',
    no_more_regions: 'No more regions',
    no_more_sources: 'No more sources',
    no_more_authorities: 'No more authorities',
    title: 'Title',
    caregiver_handover: {
      header: 'Permanent patient handover',
      secondary_caregivers_header: 'Recipients',
      add_caregiver: '+ Add another recipent',
      patients_remaining_header: 'Patients left to distribute',
      patients_remaining: '%{numPatients} patients',
      patients_remaining_singular: '1 patient',
      choose_doctor: 'Choose a doctor',
      choose_nurse: 'Choose a nurse',
      save: 'Save handover',
      change_to_date: 'Change end date',
      save_to_date: 'Save end date',
      remove: 'Cancel handover',
      confirm_removal: 'Are you sure you want to cancel the handover?',
      period_header: 'Period',
      caregiver_list_header: 'Substituting doctors',
      type: 'Type of handover',
      permanent: 'Permanent',
      temporary: 'Vacation cover',
      error: {
        region: 'Cannot distribute all patients to the chosen doctor.',
        no_caregiver_selection: 'Please select a doctor.',
        invalid_number: 'Enter a valid number.',
        fetching_doctors: 'Could not fetch doctor list.',
        fetchings: 'Could not fetch active handover.'
      }
    },
    tab: {
      permissions: 'Permissions',
      patient_handover: 'Patient handover',
      planned_absence: 'Planned absence',
      metrics: 'Metrics',
      allocation: 'Allocation'
    }
  },
  create_user_view: {
    header: 'Create new system user',
    authority_group: 'Type of user',
    create_user: 'Create user'
  },
  planning_view: {
    page: {
      absence_management: 'Manage absence',
      allocation: 'Allocation',
      metrics: 'Metrics'
    }
  },
  reports_view: {
    select_page: 'Choose an option in the menu',
    page: {
      invoice_report: 'Invoice data',
      region_skane_referrals: 'Referrals for Region Skåne',
      bulk_message: 'Patient message',
      bulk_referrals: 'Referrals',
      shipments: 'Manage shipments',
      canned_responses: 'Manage chat templates',
      doctor_statistics: 'Caregiver statistics',
      tag_incoming_patients: 'Tag incoming patients',
      accounting: 'Accounting',
      partner_management: 'Manage partners'
    },
    invoice_report: {
      header: 'Invoice data',
      month_header: 'Month',
      year_header: 'Year',
      no_data: 'There is no invoice data for the selected month.',
      error: 'Could not download invoice data.'
    },
    referrals: {
      header: 'Generate referrals for new Region Skåne patients',
      modal_header: 'Configure print settings',
      save_settings: 'Save settings',
      new_referral: 'new referral',
      new_referrals: 'new referrals',
      no_new_referrals: 'No new referrals',
      error: 'Could not download referrals.',
      test_error: 'Could not download test referral.',
      main_button: 'Generate referrals',
      rutin: 'Rutin 1 (mm)',
      special: 'Special 3 (mm)',
      x_offset: 'X offset',
      y_offset: 'Y offset',
      test_referral: 'Generate test referral',
      configure: 'Configure',
      desktop_only_note: 'Referral generation is not available in the mobile version.',
      get_error: 'Could not fetch referrals.',
      no_referrals: 'No referrals to manage.'
    },
    bulk_message: {
      header: 'Patient message',
      description:
        'When you write a message, select one or more doctors, and click "Send message" below, a chat message will be sent to all patients who have the selected doctors as their responsible doctor. Note that the message cannot be changed after it has been sent, and that it may take a moment before it has reached all patients.',
      no_more_caregivers: 'No more doctors',
      select_caregivers: 'Select caregiver(s)',
      caregiver_header: 'Responsible doctor',
      message_header: 'Message to patients',
      send_message: 'Send message',
      message_placeholder: 'Type message here',
      confirm: 'Are you sure you want to send this message?'
    },
    bulk_referrals: {
      header: 'Referrals',
      description:
        'Upload a CSV file with personal numbers for those patients who should get referral information from their doctor in the chat. The file should contain one personal number per row. Based on their chosen region, the system will send each patient the correct referral information.',
      send_referrals: 'Send referrals',
      message_header: 'Chat message to the patient',
      message_placeholder: 'Type message here',
      confirm: 'Are you sure you want to send referrals to these patients?',
      file_format_error: 'Incorrectly formatted data in the selected file.',
      select_file: 'Select CSV file'
    },
    shipments: {
      header: 'Choose shipments',
      no_shipments: 'No shipments to manage.',
      no_delayed_shipments: 'No postponed shipments to manage.',
      error: 'Could not fetch shipments.',
      done_button: 'Send to Logistified',
      created_at: 'Created',
      reason: 'Reason',
      reasons: {
        manual: 'Manual',
        batch: 'Batch',
        service_cancelled: 'Service cancelled',
        manual_kalmar: 'Kalmar manual'
      },
      type: {
        outgoing: 'Outgoing',
        return: 'Return'
      },
      active_shipments: 'Shipments',
      delayed_shipments: 'Postponed shipments',
      delay: 'Postpone',
      delay_header: 'Postpone shipment',
      update_delay: 'Update date',
      update_delay_header: 'Change postponement date',
      current_delay: 'Postponed until %{delayDate}',
      undo_delay: 'Cancel postponement',
      change_delay_date: 'Change date',
      address_missing: 'Address missing'
    },
    canned_responses: {
      header: 'Manage chat templates',
      invalid_tag: 'The text contains an invalid tag. Check the tags and try again.',
      start_editing: 'Edit templates',
      cancel_editing: 'Cancel',
      save: 'Save changes',
      add_node: '+ Response',
      add_sub_category: '+ Subcategory',
      add_main_category: '+ Main category',
      edit_text: 'Edit text',
      tags_header: 'Available tags',
      confirm_cancel: 'There are unsaved changes. Discard changes?',
      expand_all: 'Expand all',
      collapse_all: 'Collapse all',
      paste_notice:
        '<strong>Note:</strong> When pasting text from e.g. Google Docs, make sure to paste without formatting (<kbd>Ctrl</kbd>/<kbd>Cmd</kbd> + <kbd>Shift</kbd> + <kbd>V</kbd>). Formatted text is not supported in the chat.'
    },
    doctor_statistics: {
      header: 'Doctor statistics',
      final_notes_header: 'Final notes',
      final_notes_per_patient_group_header: 'Final notes per patient group',
      choose_year: 'Select a year',
      choose_doctor: 'Select doctor',
      doctor_table_header: 'Doctor',
      nurse_table_header: 'Sjuksköterska',
      patient_totals_header: 'Assigned patients',
      total: 'Total',
      active: 'Active',
      hidden: 'Inactive',
      blocked: 'Blocked',
      statistics_error: 'Could not fetch final note statistics.',
      patient_totals_error: 'Could not fetch patient totals.',
      final_note_type_breakdown_header: 'Invoicing data',
      quarter: 'Quarter (%{monthRange})',
      final_notes_for_month: 'Final notes during %{period}',
      final_notes_for_quarter: 'Final notes during quarter (%{period})',
      show_details: 'Show details',
      download_pdf: 'Download PDF',
      final_note_remuneration: 'Remuneration per final note, inc. VAT',
      total_excl_vat: 'Total excl. VAT',
      total_incl_vat: 'Total incl. VAT',
      monthly_billing: 'Month',
      quarterly_billing: 'Quarter',
      billing_period: 'Billing period',
      caregiver_type: 'Caregiver type'
    },
    tag_incoming_patients: {
      header: 'Tag incoming patients',
      description:
        'Enter the personal number for a patient who should be tagged with the chosen source upon registration. If you enter a phone number we will send an sms with a download link to it.',
      add_more: '+ Add more',
      campaign: 'Campaign'
    }
  },
  jira: {
    select_issue_type: 'Select issue type',
    modal_header: 'Create support issue',
    modal_button: 'Create issue',
    history_header: 'Previous issues for this patient',
    history_error: 'Could not fetch support history.',
    history_empty: 'No previous support issues.',
    history_column: {
      issue: 'Issue',
      created: 'Created',
      status: 'Status'
    },
    description: 'Description'
  },
  profile_image_upload: {
    header: 'Update profile picture',
    header_new: 'Select profile picture',
    action: 'Upload image',
    select_file: 'Select file',
    drag_here: 'or drag an image here'
  },
  authorities: {
    admin: 'Administrator',
    healthscientist: 'Health scientist',
    caregiver: 'Doctor',
    nurse: 'Nurse',
    login_clinic: 'Clinic login',
    login_admin: 'Admin login',
    manage_patients: 'Manage patients',
    manage_users: 'Manage system users',
    manage_content: 'Manage content',
    send_communications: 'Send communications',
    manage_billing: 'Manage billing',
    read_invoice_report: 'Create Privera file',
    view_patients: 'View patients',
    export_journal: 'Export journal data',
    handle_auscultation: 'Assess auscultations',
    accounting: 'Accounting',
    manage_partners: 'Manage partners',
    handle_certificate_patients: 'Handle certificate patients',
    handle_next_gen_patients: 'Handle NextGen patients',
    medical_management: 'Medical management',
    manage_absence: 'Manage absence'
  },
  authority_description: {
    healthscientist: 'All health scientists in the system must have this.',
    caregiver: 'All doctors in the system must have this.',
    nurse: 'All nurses in the system must have this.',
    login_clinic: 'Required to log in to Clinic.',
    login_admin: 'Required to log in to Admin.',
    manage_patients: 'Required to change patient information (email address, phone number, etc.).',
    manage_users:
      "Required to administrate system users, and change their information (e.g. authorities or vacations) as well as to change a patient's PAL.",
    manage_content: 'Required to make changes to the chat templates.',
    send_communications:
      'Required to send mass communications from Admin (bulk chat messages and referrals), as well as to send email to individual patients.',
    manage_billing:
      'Required to see statistics for created final notes and assigned patients. Will be used to create invoice reports for doctors in Admin.',
    read_invoice_report: 'Required to create the Privera file (invoice data for Region Sörmland).',
    view_patients: 'Required to view any patient data at all.',
    export_journal: 'Required to download a patient journal as PDF.',
    handle_auscultation: 'Required to assign as PAL for patients who have done a heart and lung auscultation.',
    accounting: 'Required to access the accounting function on the Services page.',
    manage_partners: 'Required to create and update partners and their facilities and users.',
    handle_certificate_patients:
      'Required to handle patients who were recommended to Blodtrycksdoktorn as certificate patients.',
    handle_next_gen_patients: 'Required to handle patients using the NextGen app.',
    medical_management: 'Permission to view/manage medical staff or processes',
    manage_absence: 'Permission to manage absences for caregivers.'
  },
  error_boundary: {
    header: 'Something went wrong :(',
    summary: 'Information about the error has been passed along to the development team.'
  },
  member_state: {
    default_bottom_funnel: 'Bottom funnel',
    deactivation_funnel: 'Deactivation funnel'
  },
  member_state_actions: {
    bottom_funnel_paused: 'was paused',
    resumed: 'was resumed',
    '-': 'was resumed'
  },
  program_timeline_view: {
    header: 'Timeline',
    get_programs_error: 'Could not fetch programs',
    no_active_program: 'Patient has no ongoing programs',
    program: 'Program',
    step: {
      completed: 'Completed',
      not_started: 'Not started',
      ongoing: 'Ongoing',
      started: 'Started'
    }
  },
  accounting: {
    tabs: {
      summary: 'Accounting reports',
      batches: 'Delivery batches'
    },
    summary: {
      header: 'Account totals',
      error: 'Could not fetch account totals.',
      table_header: {
        account: 'Account',
        name: 'Name',
        debit: 'Debit',
        credit: 'Credit',
        sum: 'Sum',
        ingoing_balance: 'Inc. balance',
        outgoing_balance: 'Outg. balance'
      },
      download: 'Download transactions'
    },
    batches: {
      header: 'Delivery batches',
      error: 'Could not fetch delivery batches.',
      table_header: {
        batch_number: 'Batch number',
        value: 'Value',
        depreciation: 'Depreciation',
        num_units: 'Number of units',
        unit_value: 'Unit value',
        date: 'Date'
      },
      new: 'New batch',
      create: 'Create batch',
      delivery_date: 'Delivery date',
      delivery_items: 'Number of units in delivery',
      delivery_value: 'Delivery value (SEK)',
      unit_value: 'Unit value: %{unitValue} SEK'
    }
  },
  manage_absence: {
    list_heading: 'Registered absences',
    create_heading: 'Register absence',
    edit_heading: 'Edit absence',
    absence_reason_heading: 'Reason',
    period_heading: 'Period',
    reason: {
      vacation: 'Vacation',
      sick_leave: 'Sick leave',
      parental_leave: 'Parental leave',
      leave_of_absence: 'Leave of absence',
      education: 'Education',
      missing: 'Reason missing'
    },
    caregiver_no_absence: 'No absence planned.',
    no_absence_planned_during_period: 'No absence planned during the selected period.',
    confirm_remove_absence: 'Are you sure you want to remove the absence?',
    move_patients_heading: 'Move patients to caregiver',
    add_more: '+ Add more',
    percentage: 'Percentage',
    total_share: 'Total share',
    remove_absence: 'Remove absence',
    responsible_for_n_patients: '%{role} for %{numPatients} patients*',
    num_patients: '≈ %{numPatients} patients',
    count_notice: '* the number may change before the selected start date.',
    absence_about_to_start: "The absence starts in less than two weeks and can't be edited.",
    absence_ongoing: 'Absence ongoing.',
    random_assignment: 'Automatic',
    absence_not_found: 'The absence was not found.',
    generic_error: 'Could not fetch absence.'
  },
  manage_allocation: {
    heading: 'Allocation',
    current_allocation: 'Current allocation',
    headings: {
      caregiver: 'Caregiver',
      allocation: 'Current allocation',
      share: 'Share of incoming patients'
    },
    num_patients: '%{numPatients} patients',
    patients: 'patients',
    treatment_capacity: 'Patient treatment capacity',
    share_of_inflow: 'Share of incoming patients',
    go_to_list: 'Go to allocation list'
  },
  manage_partners: {
    header: 'Manage partners',
    error: 'Could not fetch partners.',
    partner_list_sub_header: 'Organizations',
    partner_list_error: 'Could not fetch list of partner organizations',
    status: {
      active: 'Active',
      inactive: 'Inactive'
    },
    partner_overview: {
      edit_partner: 'Edit partner',
      no_facilities: 'No facilities',
      add_facility: 'Add facility',
      partner_services: 'Partner services',
      num_facilities: '%{facilityCount} facilities',
      single_facility: '1 facility'
    },
    facilities: {
      header: 'Facilities',
      error: 'Could not fetch facilities',
      new_facility: '+ New %{partner} facility',
      create_new_header: 'Add new %{partner} facility',
      edit_header: 'Edit facility',
      edit: 'Edit',
      fetch_facility_error: 'Could not fetch the facility.',
      num_users: '%{userCount} users',
      num_users_global: 'not tied to a specific facility',
      confirm_delete: 'Are you sure you want to remove this facility?',
      empty_file: 'Please select an instructions pdf.',
      name: 'Facility name',
      phone: 'Phone number',
      address: 'Address',
      region: 'Partner region',
      cost_center: 'Partner cost center',
      street_address_missing: 'Street address missing',
      city_missing: 'City missing',
      postal_code_missing: 'Postal code missing',
      email: 'Email',
      instructions_file: 'Instruktions document',
      select_file: 'Select file',
      acceptable_distance: 'Acceptable distance (km)',
      acceptable_distance_no_suffix: 'Acceptable distance',
      cancellation_policy: 'Cancellation policy',
      status: 'Status',
      services: 'Services',
      add_user: 'Add user',
      button: {
        remove: 'Remove facility',
        add: 'Add facility',
        update: 'Update facility'
      },
      activation_codes: {
        title: 'Activation codes',
        description: 'Used to configure kiosk devices.',
        status: {
          used: 'Used',
          revoked: 'Revoked'
        },
        action: {
          generate: 'Generate new code',
          send: 'Send via email',
          revoke: 'Revoke'
        },
        no_codes: 'No activation codes generated.'
      }
    },
    add_new_partner: {
      header: 'Add new partner',
      header_edit: 'Edit partner',
      confirm_delete: 'Type the name of the partner organization to confirm removal.',
      confirm_delete_header: 'Confirm removal',
      partner_name: 'Name of the partner organization',
      sms_content_1: 'Content sms #1',
      sms_content_2: 'Content sms #2 (reminder)',
      handles_certificate_patients: 'Recommends certificate patients',
      fetch_error: 'Could not fetch the partner organization.',
      button: {
        remove: 'Remove partner',
        add: 'Create partner',
        update: 'Update partner'
      },
      kiosk: {
        images_header: 'Kiosk logos',
        start_image: 'Start screen',
        footer_image: 'Footer',
        select_start_image: 'Choose start screen image',
        select_new_start_image: 'Choose new image',
        select_footer_image: 'Choose footer image',
        select_new_footer_image: 'Choose new image'
      },
      select_image: 'Select image',
      web_screening: {
        partner_logo: 'Partner logo',
        start_screen_image: 'Image',
        low_risk_results_image_sv: 'Image (Swedish)',
        low_risk_results_image_en: 'Image (English)',
        heading_sv: 'Heading (Swedish)',
        heading_en: 'Heading (English)',
        text_sv: 'Text (Swedish)',
        text_en: 'Text (English)',
        button_text_sv: 'Button text (Swedish)',
        button_text_en: 'Button text (English)',
        low_risk_cta_url_sv: 'URL (Swedish page)',
        low_risk_cta_url_en: 'URL (English page)',
        start_screen: 'Start screen',
        main_cta: 'CTA #1 (download)',
        secondary_cta: 'CTA #2 (low risk)',
        results_high: 'Results screen (high risk)',
        results_low: 'Results screen (low risk)',
        url_change_confirmation:
          'Are you sure you want to change the URL? All existing links with "/%{path}" will stop working.'
      }
    },
    edit_partner: {
      header: 'Edit %{name}'
    },
    users: {
      header: 'Users',
      export: 'Export users',
      header_global: 'Global partner users',
      error: 'Could not fetch user list.',
      fetch_user_error: 'Could not fetch the user.',
      confirm_delete: 'Are you sure you want to remove this user?',
      given_name: 'First name',
      family_name: 'Last name',
      email: 'Email address',
      services: 'Permissions',
      title: 'Role',
      select_title: 'Select a role',
      recommends_certificate_patients: 'Recommends certificate patients',
      titles: {
        nurse: 'Nurse',
        doctor: 'Doctor',
        administrator: 'Administrator',
        physiotherapist: 'Physiotherapist',
        naprapath: 'Naprapath',
        chiropractor: 'Chiropractor',
        assistantNurse: 'Assistant Nurse',
        other: 'Other'
      },
      button: {
        remove: 'Remove user',
        add: 'Create user',
        update: 'Update user'
      }
    },
    add_user: {
      header: 'Add user',
      header_edit: 'Edit user'
    },
    service: {
      examination: 'Examination analog stethoscope',
      auscultation: 'Examination digital stethoscope',
      recommendation: 'Recommend patients',
      admin: 'Administrator',
      kiosk: 'Kiosk measurement',
      webScreening: 'Web screening'
    },
    partner_services: {
      select_services: 'Select services',
      all_selected: 'All services selected',
      available_services: 'Available services'
    },
    breadcrumb: {
      create_partner: 'Create partner',
      edit_partner: 'Edit partner',
      facility: 'Facility details',
      create_facility: 'Create facility'
    },
    user_property: {
      auscultation_onboarding: {
        unsubmitted: 'Test auscultation not submitted',
        pending: 'Test auscultation pending review',
        rejected: 'Test auscultation rejected',
        accepted: 'Test auscultation approved'
      }
    }
  },
  server_error: {
    db_access_error: 'Database access error',
    internal: 'Internal server error',
    unknown_user: 'Unknown user',
    insufficient_privileges: 'Insufficient privileges',
    authentication_error: 'Authentication error',
    user_canceled_login: 'User canceled login',
    login_already_in_process: 'Login already in progress',
    external_service_timeout: 'External service timeout',
    not_implemented: 'Not implemented',
    invalid_data_posted: 'Invalid data was posted',
    missing_data: 'Missing data',
    id_already_exists: 'ID already exists',
    fallback: 'Unknown error - contact technical support',
    connectivity: 'Could not reach backend server',
    unauthorized: 'Unauthorized'
  }
};
