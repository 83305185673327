import { useReducer, useEffect, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Redirect } from 'react-router-dom';
import Button from '../Button';
import LoaderButton from '../LoaderButton';
import EditableSelect from '../EditableSelect';
import { postHandover } from '../../actions';
import { APPLICATION_BASE_PATH } from '../../constants';
import handoverReducer, { initialState } from '../../reducers/handoverReducer';
import ContentLoader from '../ContentLoader';
import { isCaregiver } from '../../utils';
import './CaregiverHandover.scss';

const CaregiverHandover = ({ authToken, user, sharedData, authority, systemUsers, postHandover }) => {
  const [state, dispatch] = useReducer(handoverReducer, initialState);

  useEffect(() => {
    if (state.numRemaining === state.numTotal) {
      dispatch({ type: 'setNumRemaining', numRemaining: user.numberOfPatients });
    }
  }, [user, state.numRemaining, state.numTotal]);

  if (!user.personalNumber) {
    return null;
  }

  if (user && !isCaregiver(user)) {
    return <Redirect to={`/${APPLICATION_BASE_PATH}/user/${user.guid}`} />;
  }

  let caregiverType = 'doctor';

  if (user.authorities.includes(authority.nurse)) {
    caregiverType = 'nurse';
  }

  const caregivers = sharedData[caregiverType === 'doctor' ? 'caregivers' : 'nurses'];
  const caregiverOptions = caregivers
    .filter((caregiver) => caregiver.guid !== user.guid)
    .map((caregiver) => {
      return { value: caregiver.guid, label: `${caregiver.givenName} ${caregiver.familyName}` };
    });

  const setNumberForSecondary = (id) => {
    return (e) => {
      let value = parseInt(e.target.value, 10);
      if (window.isNaN(value)) {
        value = '';
      }
      dispatch({ type: 'setPropertyForSecondary', id, propertyName: 'numPatients', propertyValue: value });
    };
  };

  const setCaregiverForSecondary = (id) => {
    return (e) => {
      dispatch({ type: 'setPropertyForSecondary', id, propertyName: 'caregiverId', propertyValue: e.value });
    };
  };

  const deduplicateSecondaries = (secondaries) => {
    return secondaries.reduce((acc, curr) => {
      const found = acc.find((elem) => elem.caregiverId === curr.caregiverId);

      if (found) {
        found.numPatients += curr.numPatients;
      } else {
        acc.push(curr);
      }

      return acc;
    }, []);
  };

  const hasEmptyCaregiver = () => {
    return state.selectedSecondaries.some(
      (selection) => !selection.caregiverId || selection.caregiverId === 'no-selection'
    );
  };

  const hasUnassignableSelection = () => {
    if (state.selectedSecondaries.length === 1 && state.numRemaining <= 0) {
      const selectedSecondary = caregivers.filter(
        (caregiver) => caregiver.guid === state.selectedSecondaries[0].caregiverId
      )[0];

      if (!selectedSecondary.hiddenRegions) {
        return false;
      }

      if (user.hiddenRegions && selectedSecondary.hiddenRegions) {
        return !(
          user.hiddenRegions.length === selectedSecondary.hiddenRegions.length &&
          user.hiddenRegions.every((region) => selectedSecondary.hiddenRegions.map((r) => r.id).includes(region.id))
        );
      }
    }

    return false;
  };

  const hasInvalidNumPatients = () => {
    const totalNumPatients = state.selectedSecondaries.reduce((curr, acc) => (curr += acc.numPatients), 0);

    if (totalNumPatients > state.numTotal) {
      return true;
    }

    return state.selectedSecondaries.some(
      (caregiver) =>
        caregiver.numPatients > state.numTotal ||
        caregiver.numPatients < 0 ||
        window.isNaN(parseInt(caregiver.numPatients, 10))
    );
  };

  const registerHandover = () => {
    if (hasEmptyCaregiver()) {
      return dispatch({ type: 'setValidationError', validationError: 'no_caregiver_selection' });
    }

    if (hasUnassignableSelection()) {
      return dispatch({ type: 'setValidationError', validationError: 'region' });
    }

    if (hasInvalidNumPatients()) {
      return dispatch({ type: 'setValidationError', validationError: 'invalid_number' });
    }

    dispatch({ type: 'setValidationError', validationError: null });

    let patientDistribution = JSON.parse(JSON.stringify(deduplicateSecondaries(state.selectedSecondaries)));

    patientDistribution = patientDistribution.filter((s) => s.numPatients > 0);

    patientDistribution.forEach((caregiver) => {
      caregiver.percentage = Math.round((caregiver.numPatients / user.numberOfPatients) * 100);
    });

    patientDistribution.forEach((caregiver) => {
      delete caregiver.numPatients;
      delete caregiver.id;
    });

    // If handing over all patients we need to handle rounding errors to ensure the percentages add up to 100.
    // We simply add the rounding error to the first caregiver.
    if (state.numRemaining === 0) {
      const totalPercentagesDiff = 100 - patientDistribution.reduce((arr, curr) => (arr += curr.percentage), 0);

      if (totalPercentagesDiff > 0) {
        patientDistribution[0].percentage += totalPercentagesDiff;
      } else if (totalPercentagesDiff < 0) {
        patientDistribution[0].percentage -= totalPercentagesDiff;
      }
    }

    postHandover(
      authToken,
      moment().format('YYYY-MM-DD'),
      user.guid,
      patientDistribution,
      () => {
        dispatch({ type: 'reset' });
      },
      state.numRemaining
    );
  };

  return (
    <Fragment>
      <ContentLoader
        isLoading={systemUsers.loadingHandovers}
        error={systemUsers.handoversError}
        errorTitleI18n="system_user_details.caregiver_handover.error.fetching"
      >
        {user.numberOfPatients === 0 ? (
          <div>Vårdgivaren har inga patienter.</div>
        ) : (
          <div className="handover-planner">
            <div className="card-header">
              <Translate value="system_user_details.caregiver_handover.header" />
            </div>

            <h4>
              <Translate value="system_user_details.caregiver_handover.secondary_caregivers_header" />
            </h4>
            {state.selectedSecondaries.map((secondary, i) => {
              return (
                <div className="columns" key={secondary.id}>
                  <div className="column is-8">
                    <EditableSelect
                      isEditable={true}
                      isEditing={true}
                      defaultValue="no-selection"
                      options={caregiverOptions}
                      placeholder={I18n.t(`system_user_details.caregiver_handover.choose_${caregiverType}`)}
                      isLoadingOptions={false}
                      error={null}
                      errorI18nKey="system_user_details.caregiver_handover.error.fetching_doctors"
                      onChange={setCaregiverForSecondary(secondary.id)}
                      name="secondary-pal-select"
                    />
                  </div>
                  <div className={`column ${i > 0 ? 'is-3' : 'is-4'}`}>
                    <input type="text" value={secondary.numPatients} onChange={setNumberForSecondary(secondary.id)} />
                  </div>
                  {i > 0 ? (
                    <div className="column is-1 vertical-align no-padding">
                      <span
                        onClick={() => dispatch({ type: 'removeSecondary', id: secondary.id })}
                        className="icon x-small cross pointer"
                      ></span>
                    </div>
                  ) : null}
                </div>
              );
            })}

            {state.selectedSecondaries.length < caregivers.length && state.numRemaining > 0 ? (
              <Button onClick={() => dispatch({ type: 'addSecondary' })} buttonType="secondary" fullWidth={true}>
                <Translate value="system_user_details.caregiver_handover.add_caregiver" />
              </Button>
            ) : null}

            <h4>
              <Translate value="system_user_details.caregiver_handover.patients_remaining_header" />
            </h4>
            <div className="mb-25">
              <Translate
                value={`system_user_details.caregiver_handover.patients_remaining${
                  state.numRemaining === 1 ? '_singular' : ''
                }`}
                numPatients={state.numRemaining > 0 ? state.numRemaining.toLocaleString() : 0}
              />
              {state.numRemaining > 0
                ? ` (${Math.round(100 * (1 - (state.numTotal - state.numRemaining) / state.numTotal))} %)`
                : null}
            </div>

            <LoaderButton
              onClick={registerHandover}
              isLoading={systemUsers.postingHandover}
              disabled={!state.selectedSecondaries.every((s) => !!s.caregiverId)}
              fullWidth
            >
              <Translate value="system_user_details.caregiver_handover.save" />
            </LoaderButton>

            {state.validationError ? (
              <div className="error-message-block">
                <Translate value={`system_user_details.caregiver_handover.error.${state.validationError}`} />
              </div>
            ) : null}
          </div>
        )}
      </ContentLoader>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    sharedData: state.sharedData,
    systemUsers: state.systemUsers,
    user: state.systemUsers.currentSystemUser,
    authority: state.sharedData.authorityTypes,
    locale: state.i18n.locale
  };
};

const mapActionsToProps = {
  postHandover
};

export default connect(mapStateToProps, mapActionsToProps)(CaregiverHandover);
