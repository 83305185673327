import { v4 as uuid } from 'uuid';
import styles from './RadioButton.module.scss';

type Props = { label: string } & React.ComponentProps<'input'>;

export default function RadioButton({ label, className = '', ...delegated }: Props) {
  const id = delegated.id || uuid();

  return (
    <span className={`${styles.wrapper} ${className}`}>
      <input type="radio" id={id} {...delegated} />
      <label htmlFor={id}>{label}</label>
    </span>
  );
}
