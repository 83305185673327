type NewUser = {
  personalNumber: string;
  givenName: string;
  familyName: string;
  email: string;
  streetaddress?: string;
  postalCode?: string;
  city?: string;
  phoneNumber?: string;
};

type ExtraFields = {
  authorities: string[];
  title: string;
};

type AuthorityGroup = {
  label: string;
  value: string[];
  labelI18nKey: string;
  title: string;
};

type CreateUserReducerState = {
  user: Partial<NewUser>;
  extras: ExtraFields;
  submitted: boolean;
  newUserGuid?: string;
  authorityGroup: AuthorityGroup;
  error?: unknown;
};

type CreateUserAction =
  | { type: 'setUserProperty'; propertyName: string; propertyValue: string }
  | { type: 'setUserTitle'; title: string }
  | { type: 'setAuthorityGroup'; authorityGroup: AuthorityGroup }
  | { type: 'setSubmitted' }
  | { type: 'setCreated'; newUserGuid: string }
  | { type: 'setError'; error: unknown };

export const authorityGroups = [
  {
    label: 'Administratör',
    value: ['loginAdmin', 'managePatients', 'viewPatients'],
    labelI18nKey: 'authorities.admin',
    title: 'Administratör'
  },
  {
    label: 'Läkare',
    value: ['loginClinic', 'caregiver', 'viewPatients'],
    labelI18nKey: 'authorities.caregiver',
    title: 'Leg. läkare'
  },
  {
    label: 'Sjuksköterska',
    value: ['loginClinic', 'nurse', 'viewPatients'],
    labelI18nKey: 'authorities.nurse',
    title: 'Leg. sjuksköterska'
  }
];

export const initialState: CreateUserReducerState = {
  user: {
    personalNumber: '',
    givenName: '',
    familyName: '',
    streetaddress: '',
    postalCode: '',
    city: '',
    email: '',
    phoneNumber: ''
  },
  extras: {
    authorities: authorityGroups[0].value,
    title: authorityGroups[0].title
  },
  authorityGroup: authorityGroups[0],
  submitted: false,
  newUserGuid: undefined,
  error: undefined
};

const createUserReducer = (
  state: CreateUserReducerState = initialState,
  action: CreateUserAction
): CreateUserReducerState => {
  switch (action.type) {
    case 'setUserProperty':
      return {
        ...state,
        user: { ...state.user, [action.propertyName]: action.propertyValue }
      };
    case 'setUserTitle':
      return { ...state, extras: { ...state.extras, title: action.title } };
    case 'setAuthorityGroup':
      return {
        ...state,
        authorityGroup: action.authorityGroup,
        extras: { authorities: action.authorityGroup.value, title: action.authorityGroup.title }
      };
    case 'setSubmitted':
      return { ...state, submitted: true, error: undefined };
    case 'setCreated':
      return { ...state, newUserGuid: action.newUserGuid, error: undefined };
    case 'setError':
      return { ...state, error: action.error, submitted: false };
    default:
      throw new Error(`Unhandled action type: ${action}`);
  }
};

export default createUserReducer;
