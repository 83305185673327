import { useChat } from '../../contexts/ChatProvider';
import { I18n, Translate } from 'react-redux-i18n';
import ChatItem from './ChatItem';
import LoadingIcon from '../LoadingIcon';
import { ReactComponent as Chevron } from '../../assets/icons/chevron_currentColor.svg';
import { ReactComponent as ChatIcon } from '../../assets/icons/chat.svg';
import useScrollToBottomOnUpdate from '../../hooks/useScrollToBottomOnUpdate';
import ErrorMessage from '../ErrorMessage';
import ChatMessageInput from './ChatMessageInput';
import Modal from '../Modal';
import errorFallbackSrc from '../../assets/thumbnail-error.svg';
import styles from './Chat.module.scss';

export default function Chat({ patient }) {
  const {
    isOpen,
    isLoadingInitial,
    open,
    close,
    messages,
    getMessageAuthor,
    error,
    fullSizeImageModalVisible,
    closeImageModal,
    fullSizeImageObjectUrl,
    fullSizeImageError
  } = useChat();
  const [messagesContainerRef, wrapperRef] = useScrollToBottomOnUpdate();

  return (
    <>
      <div className={`${styles.wrapper} ${isOpen ? styles.open : ''}`}>
        <div className={styles.header} onClick={isOpen ? close : open}>
          <div className="vertical-align flex-1">
            <ChatIcon className="mr-10" />
            <div className={styles.patientName}>
              {patient?.givenName ? `${patient.givenName} ${patient.familyName}` : '...'}
            </div>
          </div>
          <Chevron className={!isOpen ? 'rotated' : ''} />
        </div>
        <div className={styles.messagesContainer} ref={messagesContainerRef}>
          {isLoadingInitial ? (
            <div className="h-100 justify-center vertical-align">
              <LoadingIcon type="spinner-secondary" />
            </div>
          ) : error ? (
            <div className="h-100 justify-center vertical-align">
              <ErrorMessage error={error} errorI18nKey="member_details.chat.error" />
            </div>
          ) : !messages.length ? (
            <div className="h-100 justify-center vertical-align">Ingen supportchatthistorik</div>
          ) : (
            <div className={styles.messagesWrapper} ref={wrapperRef}>
              {messages.map((message) => (
                <ChatItem key={message.messageId} message={message} author={getMessageAuthor(message.author)} />
              ))}
            </div>
          )}
        </div>
        <ChatMessageInput disabled={!!error} />
      </div>
      <Modal
        visible={fullSizeImageModalVisible}
        size="large"
        actionCompletable
        headerI18nKey={I18n.t('member_details.chat.image_modal_header', {
          name: `${patient.givenName} ${patient.familyName}`
        })}
        actionI18nKey="global.buttons.ok"
        onActionCompleted={closeImageModal}
        onClose={closeImageModal}
      >
        <div className="h-100 flex justify-center vertical-align">
          {fullSizeImageObjectUrl ? (
            <img src={fullSizeImageObjectUrl} />
          ) : fullSizeImageError ? (
            <div>
              <img src={errorFallbackSrc} style={{ width: '150px' }} />
              <p>
                <Translate value="member_details.chat.thumbnail_error" />
              </p>
            </div>
          ) : (
            <LoadingIcon type="spinner-secondary" size="large" />
          )}
        </div>
      </Modal>
    </>
  );
}
