import React from 'react';
import { connect } from 'react-redux';

const CanPerform = ({ action, authorities, children, fallbackComponent: Fallback }) => {
  if (!action) {
    return children;
  }

  if (Array.isArray(action)) {
    const hasAuthority = authorities.some((a) => action.indexOf(a) >= 0);

    if (!hasAuthority) {
      return Fallback ? <Fallback /> : null;
    }
  } else {
    if (authorities.indexOf(action) === -1) {
      return Fallback ? <Fallback /> : null;
    }
  }

  return children;
};

const mapStateToProps = (state) => {
  return {
    authorities: state.auth.authorities
  };
};

export default connect(mapStateToProps)(CanPerform);
