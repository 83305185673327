import * as types from '../constants/actionTypes';
import api from '../api/apiClient';
import { I18n } from 'react-redux-i18n';
import { v4 as uuidv4 } from 'uuid';
import { decamelize } from '../utils';
import moment from 'moment';
import { ARM_CIRCUMFERENCE_ID, CAREGIVER_ROLE, HTTP_STATUS_CODES } from '../constants';
/**
 * Authentication actions
 */
export const loginRequest = () => {
  return {
    type: types.LOGIN_REQUEST
  };
};

export const loginSuccess = (response) => {
  return {
    type: types.LOGIN_SUCCESS,
    payload: response
  };
};

export const loginError = (response) => {
  return {
    type: types.LOGIN_ERROR,
    payload: response
  };
};

export const logout = () => {
  return {
    type: types.LOGOUT
  };
};

export const refreshTokenRequest = () => {
  return {
    type: types.REFRESH_TOKEN_REQUEST
  };
};

export const refreshTokenSuccess = (response) => {
  return {
    type: types.REFRESH_TOKEN_SUCCESS,
    payload: response
  };
};

export const switchTheme = (theme) => {
  return {
    type: types.SWITCH_THEME,
    payload: theme
  };
};

export const toggleNightMode = () => {
  return {
    type: types.TOGGLE_NIGHT_MODE
  };
};

/**
 * Get members
 */

const getMembersRequest = () => {
  return {
    type: types.GET_MEMBERS_REQUEST
  };
};

const getMembersSuccess = (response, returnToIndexPage) => {
  return {
    type: types.GET_MEMBERS_SUCCESS,
    payload: response,
    returnToIndexPage
  };
};

const getMembersError = (error) => {
  return {
    type: types.GET_MEMBERS_ERROR,
    payload: error
  };
};

export const getMembers = (jwtToken, options) => {
  return (dispatch) => {
    dispatch(getMembersRequest());
    dispatch(setCurrentSearchQuery(options));

    api
      .getMembers(jwtToken, options.query, options.offset, options.limit, options.sortBy, [
        'armCircumference',
        'concatenatedNames',
        'lastMeasureDate',
        'lastLabResultDate',
        'lastMonitorSentDate',
        'openSupportIssues',
        'closedSupportIssues'
      ])
      .then((response) => {
        dispatch(getMembersSuccess(response, options.returnToIndexPage));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMembersError(error));
      });
  };
};

const setCurrentSearchQuery = (options) => {
  return {
    type: types.SET_CURRENT_SEARCH_QUERY,
    payload: options
  };
};

const setCurrentSystemUserSearchQuery = (options) => {
  return {
    type: types.SET_CURRENT_SYSTEM_USER_SEARCH_QUERY,
    payload: options
  };
};

/**
 * Member actions
 */

const getMemberRequest = () => {
  return {
    type: types.GET_MEMBER_REQUEST
  };
};

const getMemberSuccess = (response) => {
  return {
    type: types.GET_MEMBER_SUCCESS,
    payload: response
  };
};

const getMemberError = (error) => {
  return {
    type: types.GET_MEMBER_ERROR,
    payload: error
  };
};

export const getMember = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberRequest());
    const extraFields = [
      'armCircumference',
      'lastLabResultDate',
      'mobileDevice',
      'lastMeasureDate',
      'lastMonitorSentDate',
      'paymentExemption'
    ];

    api
      .getMembers(jwtToken, `guid==${memberGuid}`, 0, 1, undefined, extraFields)
      .then((response) => {
        dispatch(getMemberSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberError(error));
      });
  };
};

export const updateMemberRequest = () => {
  return {
    type: types.UPDATE_MEMBER_REQUEST
  };
};

export const updateMemberSuccess = (response) => {
  return {
    type: types.UPDATE_MEMBER_SUCCESS,
    payload: response
  };
};

export const updateMemberError = (error) => {
  return {
    type: types.UPDATE_MEMBER_ERROR,
    payload: error
  };
};

export const updateMember = (jwtToken, memberGuid, updatedMemberFields) => {
  return (dispatch) => {
    dispatch(updateMemberRequest());

    api
      .updateMember(jwtToken, memberGuid, updatedMemberFields)
      .then((response) => {
        dispatch(updateMemberSuccess(response));
        dispatch(toggleEditMemberDetails());
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateMemberError(error));
      });
  };
};

export const setCurrentMemberProperty = (property, value) => {
  return {
    type: types.SET_CURRENT_MEMBER_PROPERTY,
    property,
    value
  };
};

export const assignCaregiverRequest = () => {
  return {
    type: types.ASSIGN_CAREGIVER_REQUEST
  };
};

export const assignCaregiverSuccess = (response) => {
  return {
    type: types.ASSIGN_CAREGIVER_SUCCESS,
    payload: response
  };
};

export const assignCaregiverError = (error) => {
  return {
    type: types.ASSIGN_CAREGIVER_ERROR,
    payload: error
  };
};

export const assignCaregiver = (jwtToken, memberGuid, caregiver, successCallback) => {
  return (dispatch) => {
    dispatch(assignCaregiverRequest());

    api
      .assignCaregiver(jwtToken, memberGuid, caregiver.guid)
      .then((response) => {
        dispatch(assignCaregiverSuccess(response));
        dispatch(
          showNotification(
            I18n.t(
              `member_details.updates.${decamelize(
                `responsible${caregiver.role === CAREGIVER_ROLE.DOCTOR ? 'Doctor' : 'Nurse'}`,
                '_'
              )}.success`,
              { newValue: `${caregiver.givenName} ${caregiver.familyName}` }
            ),
            'success'
          )
        );
        successCallback && successCallback();
      })
      .catch((error) => {
        console.log(error);
        dispatch(assignCaregiverError(error));
        dispatch(
          showNotification(
            I18n.t(
              `notification.update_caregiver.error${error?.status === HTTP_STATUS_CODES.CONFLICT ? '_conflict' : ''}`
            ),
            'error'
          )
        );
      });
  };
};

const getMemberDevicesRequest = () => {
  return {
    type: types.GET_MEMBER_DEVICES_REQUEST
  };
};

const getMemberDevicesSuccess = (response) => {
  return {
    type: types.GET_MEMBER_DEVICES_SUCCESS,
    payload: response
  };
};

const getMemberDevicesError = (response) => {
  return {
    type: types.GET_MEMBER_DEVICES_ERROR,
    payload: response
  };
};

export const getMemberDevices = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberDevicesRequest());

    return api
      .getMemberDevices(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberDevicesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberDevicesError(error));
      });
  };
};

const getMemberProfileImageRequest = () => {
  return {
    type: types.GET_MEMBER_PROFILE_IMAGE_REQUEST
  };
};

const getMemberProfileImageSuccess = (response) => {
  return {
    type: types.GET_MEMBER_PROFILE_IMAGE_SUCCESS,
    payload: response
  };
};

const getMemberProfileImageError = (response) => {
  return {
    type: types.GET_MEMBER_PROFILE_IMAGE_ERROR,
    payload: response
  };
};

export const getMemberProfileImage = (jwtToken, memberGuid, profileImageId) => {
  return (dispatch) => {
    dispatch(getMemberProfileImageRequest());
    api
      .getFile(jwtToken, memberGuid, profileImageId, 100, 100)
      .then((response) => {
        dispatch(getMemberProfileImageSuccess(response.response.body));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberProfileImageError(error));
      });
  };
};

const getMemberCommentRequest = () => {
  return {
    type: types.GET_MEMBER_COMMENT_REQUEST
  };
};

const getMemberCommentSuccess = (response) => {
  return {
    type: types.GET_MEMBER_COMMENT_SUCCESS,
    payload: response
  };
};

const getMemberCommentError = (response) => {
  return {
    type: types.GET_MEMBER_COMMENT_ERROR,
    payload: response
  };
};

export const getMemberComment = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberCommentRequest());

    return api
      .getMemberComment(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberCommentSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberCommentError(error));
      });
  };
};

const updateMemberCommentRequest = () => {
  return {
    type: types.UPDATE_MEMBER_COMMENT_REQUEST
  };
};

const updateMemberCommentSuccess = (response) => {
  return {
    type: types.UPDATE_MEMBER_COMMENT_SUCCESS,
    payload: response
  };
};

const updateMemberCommentError = (response) => {
  return {
    type: types.UPDATE_MEMBER_COMMENT_ERROR,
    payload: response
  };
};

export const updateMemberComment = (jwtToken, memberGuid, memberComment, showToast) => {
  return (dispatch) => {
    dispatch(updateMemberCommentRequest());

    return api
      .updateMemberComment(jwtToken, memberGuid, memberComment)
      .then(() => {
        dispatch(updateMemberCommentSuccess(memberComment));
        if (showToast) {
          dispatch(showNotification(I18n.t('notification.save_comment.success'), 'success'));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateMemberCommentError(error));
        if (showToast) {
          dispatch(showNotification(I18n.t('notification.save_comment.error'), 'error'));
        }
      });
  };
};

export const updateMemberCommentText = (e) => {
  return {
    type: types.UPDATE_MEMBER_COMMENT_TEXT,
    payload: e.target.value
  };
};

const getScheduledCommunicationRequest = () => {
  return {
    type: types.GET_SCHEDULED_COMMUNICATION_REQUEST
  };
};

const getScheduledCommunicationSuccess = (response) => {
  return {
    type: types.GET_SCHEDULED_COMMUNICATION_SUCCESS,
    payload: response
  };
};

const getScheduledCommunicationError = (response) => {
  return {
    type: types.GET_SCHEDULED_COMMUNICATION_ERROR,
    payload: response
  };
};

export const getScheduledCommunication = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getScheduledCommunicationRequest());

    return api
      .getScheduledCommunication(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getScheduledCommunicationSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getScheduledCommunicationError(error));
      });
  };
};

const updateScheduledCommunicationRequest = () => {
  return {
    type: types.UPDATE_SCHEDULED_COMMUNICATION_REQUEST
  };
};

const updateScheduledCommunicationSuccess = (updatedCommunication, originalDate) => {
  return {
    type: types.UPDATE_SCHEDULED_COMMUNICATION_SUCCESS,
    updatedCommunication,
    originalDate
  };
};

const updateScheduledCommunicationError = (response) => {
  return {
    type: types.UPDATE_SCHEDULED_COMMUNICATION_ERROR,
    payload: response
  };
};

export const updateScheduledCommunication = (
  jwtToken,
  memberGuid,
  communicationId,
  updatedCommunication,
  originalDate
) => {
  return (dispatch) => {
    dispatch(updateScheduledCommunicationRequest());

    return api
      .updateScheduledCommunication(jwtToken, memberGuid, communicationId, updatedCommunication)
      .then(() => {
        dispatch(updateScheduledCommunicationSuccess(updatedCommunication, originalDate));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateScheduledCommunicationError(error));
        dispatch(showNotification(I18n.t('notification.update_communication.error'), 'error'));
      });
  };
};

const deleteScheduledCommunicationRequest = () => {
  return {
    type: types.DELETE_SCHEDULED_COMMUNICATION_REQUEST
  };
};

const deleteScheduledCommunicationSuccess = (deletedId) => {
  return {
    type: types.DELETE_SCHEDULED_COMMUNICATION_SUCCESS,
    deletedId
  };
};

const deleteScheduledCommunicationError = (response) => {
  return {
    type: types.DELETE_SCHEDULED_COMMUNICATION_ERROR,
    payload: response
  };
};

export const deleteScheduledCommunication = (jwtToken, memberGuid, communicationId) => {
  return (dispatch) => {
    dispatch(deleteScheduledCommunicationRequest());

    return api
      .deleteScheduledCommunication(jwtToken, memberGuid, communicationId)
      .then(() => {
        dispatch(deleteScheduledCommunicationSuccess(communicationId));
      })
      .catch((error) => {
        console.log(error);
        dispatch(deleteScheduledCommunicationError(error));
        dispatch(showNotification(I18n.t('notification.delete_communication.error'), 'error'));
      });
  };
};

export const clearCurrentMember = () => {
  return {
    type: types.CLEAR_CURRENT_MEMBER
  };
};

export const toggleEditMemberDetails = () => {
  return {
    type: types.TOGGLE_EDITING_MEMBER_DETAILS
  };
};

export const setAdditionalSearchCriteria = (property) => {
  return (dispatch) => {
    return (e) => {
      dispatch({
        type: types.SET_ADDITIONAL_SEARCH_CRITERIA,
        payload: {
          property,
          value: e.value
        }
      });
    };
  };
};

export const toggleAdvancedSearch = () => {
  return {
    type: types.TOGGLE_ADVANCED_SEARCH
  };
};

const setMemberServiceStatusRequest = () => {
  return {
    type: types.SET_MEMBER_SERVICE_STATUS_REQUEST
  };
};

const setMemberServiceStatusSuccess = (response) => {
  return {
    type: types.SET_MEMBER_SERVICE_STATUS_SUCCESS,
    payload: response
  };
};

const setMemberServiceStatusError = (response) => {
  return {
    type: types.SET_MEMBER_SERVICE_STATUS_ERROR,
    payload: response
  };
};

export const setMemberServiceStatus = (jwtToken, memberGuid, newStatus, memberName) => {
  return (dispatch) => {
    dispatch(setMemberServiceStatusRequest());

    return api
      .updateMember(jwtToken, memberGuid, { serviceStatus: newStatus })
      .then((response) => {
        dispatch(setMemberServiceStatusSuccess(response));
        dispatch(
          showNotification(I18n.t(`notification.service_status_${newStatus}.success`, { name: memberName }), 'success')
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(setMemberServiceStatusError(error));
        dispatch(
          showNotification(I18n.t(`notification.service_status_${newStatus}.error`, { name: memberName }), 'error')
        );
      });
  };
};

export const toggleDeactivateMemberModal = () => {
  return {
    type: types.TOGGLE_DEACTIVATE_MEMBER_MODAL
  };
};

const deactivateMemberRequest = (sendReturnSlip) => {
  return {
    type: types.DEACTIVATE_MEMBER_REQUEST,
    sendReturnSlip
  };
};

const deactivateMemberSuccess = (response, deactivationType) => {
  return {
    type: types.DEACTIVATE_MEMBER_SUCCESS,
    payload: response,
    deactivationType: deactivationType
  };
};

const deactivateMemberError = (response) => {
  return {
    type: types.DEACTIVATE_MEMBER_ERROR,
    payload: response
  };
};

export const deactivatePatient = (jwtToken, patientId, sendReturnSlip, patientName) => {
  const stateName = 'DeactivationFunnel';
  return (dispatch) => {
    dispatch(deactivateMemberRequest(sendReturnSlip));
    let deactivationType = null; // default, return slip is sent

    if (!sendReturnSlip) {
      deactivationType = 'doNotSendReturnSlip';
    }

    return api
      .deactivePatientFromService(jwtToken, patientId, deactivationType)
      .then((response) => {
        dispatch(deactivateMemberSuccess(response, deactivationType));
        dispatch(
          showNotification(
            I18n.t(`notification.deactivate_member.success`, {
              name: patientName
            }),
            'success'
          )
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(deactivateMemberError(error));
        dispatch(
          showNotification(
            I18n.t(`notification.deactivate_member.error`, {
              name: patientName
            }),
            'error'
          )
        );
      })
      .then(() => api.getMemberState(jwtToken, patientId, stateName))
      .then((response) => {
        dispatch(getMemberStateSuccess(stateName, response));
      })
      .catch((error) => {
        console.log(error);
        if (HTTP_STATUS_CODES.NOT_FOUND === error.status) {
          // DeactivationFunnel has been removed
          dispatch(getMemberStateNotFound(stateName));
        } else {
          dispatch(getMemberStateError(error));
        }
      });
  };
};

const reactivatePatientRequest = () => {
  return {
    type: types.REACTIVATE_PATIENT_REQUEST
  };
};

const reactivatePatientSuccess = (response) => {
  return {
    type: types.REACTIVATE_PATIENT_SUCCESS,
    payload: response
  };
};

const reactivatePatientError = (response) => {
  return {
    type: types.REACTIVATE_PATIENT_ERROR,
    payload: response
  };
};

export const reactivatePatient = (jwtToken, patientId, patientName) => {
  const stateName = 'DeactivationFunnel';
  return (dispatch) => {
    dispatch(reactivatePatientRequest());

    return api
      .reactivatePatient(jwtToken, patientId)
      .then((response) => {
        dispatch(reactivatePatientSuccess(response));
        dispatch(
          showNotification(
            I18n.t(`notification.reactivate_member.success`, {
              name: patientName
            }),
            'success'
          )
        );
      })
      .catch((error) => {
        console.log('Error reactivate: ', error);
        dispatch(reactivatePatientError(error));
        dispatch(
          showNotification(
            I18n.t(`notification.reactivate_member.error`, {
              name: patientName
            }),
            'error'
          )
        );
      })
      .then(() => api.getMemberState(jwtToken, patientId, stateName))
      .then((response) => {
        dispatch(getMemberStateSuccess(stateName, response));
      })
      .catch((error) => {
        console.log(error);
        if (HTTP_STATUS_CODES.NOT_FOUND === error.status) {
          // DeactivationFunnel has been removed
          dispatch(getMemberStateNotFound(stateName));
        } else {
          dispatch(getMemberStateError(error));
        }
      });
  };
};

export const getFunnelStatus = (jwtToken, patientId) => {
  return (dispatch) => {
    dispatch(getFunnelStatusRequest());

    return api
      .getFunnelStatusForPatient(jwtToken, patientId)
      .then((response) => {
        dispatch(getFunnelStatusSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getFunnelStatusError(error));
      });
  };
};

const getFunnelStatusRequest = () => {
  return {
    type: types.GET_FUNNEL_STATUS_REQUEST
  };
};

const getFunnelStatusSuccess = (response) => {
  return {
    type: types.GET_FUNNEL_STATUS_SUCCESS,
    payload: response
  };
};

const getFunnelStatusError = (error) => {
  return {
    type: types.GET_FUNNEL_STATUS_ERROR,
    payload: error
  };
};

const updateMemberAnamnesisAnswerRequest = () => {
  return {
    type: types.UPDATE_MEMBER_ANAMNESIS_ANSWER_REQUEST
  };
};

const updateMemberAnamnesisAnswerSuccess = (response, memberProperty, value) => {
  return {
    type: types.UPDATE_MEMBER_ANAMNESIS_ANSWER_SUCCESS,
    payload: {
      response,
      memberProperty,
      value
    }
  };
};

const updateMemberAnamnesisAnswerError = (error) => {
  return {
    type: types.UPDATE_MEMBER_ANAMNESIS_ANSWER_ERROR,
    payload: error
  };
};

export const updateMemberAnamnesisAnswer = (jwtToken, memberGuid, questionId, newAnwer, memberProperty) => {
  return (dispatch) => {
    dispatch(updateMemberAnamnesisAnswerRequest());

    return api
      .updateMemberAnamnesisAnswer(jwtToken, memberGuid, questionId, newAnwer)
      .then((response) => {
        dispatch(updateMemberAnamnesisAnswerSuccess(response, memberProperty, newAnwer));
        dispatch(
          showNotification(
            I18n.t(
              `notification.save_${!memberProperty ? 'generic.success' : `${decamelize(memberProperty)}.success`}`
            ),
            'success'
          )
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateMemberAnamnesisAnswerError(error));
        dispatch(
          showNotification(
            I18n.t(`notification.save_${!memberProperty ? 'generic.error' : `${decamelize(memberProperty)}.error`}`),
            'error'
          )
        );
      });
  };
};

export const showMessageModal = () => {
  return {
    type: types.SHOW_MESSAGE_MODAL
  };
};

export const hideMessageModal = () => {
  return {
    type: types.HIDE_MESSAGE_MODAL
  };
};

export const updateMessageSubject = (e) => {
  return {
    type: types.UPDATE_MESSAGE_SUBJECT,
    payload: e.target.value
  };
};

export const updateMessageBody = (data) => {
  return {
    type: types.UPDATE_MESSAGE_BODY,
    payload: data
  };
};

const sendMemberMessageRequest = () => {
  return {
    type: types.SEND_MESSAGE_REQUEST
  };
};

const sendMemberMessageSuccess = () => {
  return {
    type: types.SEND_MESSAGE_SUCCESS
  };
};

const sendMemberMessageError = (response) => {
  return {
    type: types.SEND_MESSAGE_ERROR,
    payload: response
  };
};

export const sendMemberMessage = (jwtToken, memberGuid, subject, body) => {
  return (dispatch) => {
    dispatch(sendMemberMessageRequest());

    return api
      .sendEmail(jwtToken, memberGuid, subject, body)
      .then(() => {
        dispatch(sendMemberMessageSuccess());
        dispatch(showNotification(I18n.t('notification.member_message.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(sendMemberMessageError(error));
        dispatch(showNotification(I18n.t('notification.member_message.error'), 'error'));
      });
  };
};

const getMemberChatMessagesRequest = () => {
  return {
    type: types.GET_MEMBER_CHAT_MESSAGES_REQUEST
  };
};

const getMemberChatMessagesSuccess = (response, memberGuid) => {
  return {
    type: types.GET_MEMBER_CHAT_MESSAGES_SUCCESS,
    payload: response,
    memberGuid
  };
};

const getMemberChatMessagesError = (response) => {
  return {
    type: types.GET_MEMBER_CHAT_MESSAGES_ERROR,
    payload: response
  };
};

export const getMemberChatMessages = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberChatMessagesRequest());

    return api
      .getMemberChatMessages(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberChatMessagesSuccess(response, memberGuid));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberChatMessagesError(error));
      });
  };
};

export const getMemberJournalRequest = () => {
  return {
    type: types.GET_MEMBER_JOURNAL_REQUEST
  };
};

export const getMemberJournalSuccess = () => {
  return {
    type: types.GET_MEMBER_JOURNAL_SUCCESS
  };
};

export const getMemberJournalError = (response) => {
  return {
    type: types.GET_MEMBER_JOURNAL_ERROR,
    payload: response
  };
};

export const toggleExportJournalModal = () => {
  return {
    type: types.TOGGLE_EXPORT_JOURNAL_MODAL
  };
};

export const toggleSparModal = () => {
  return {
    type: types.TOGGLE_SPAR_MODAL
  };
};

const getMemberSparDataRequest = () => {
  return {
    type: types.GET_MEMBER_SPAR_DATA_REQUEST
  };
};

const getMemberSparDataSuccess = (response) => {
  return {
    type: types.GET_MEMBER_SPAR_DATA_SUCCESS,
    payload: response
  };
};

const getMemberSparDataError = (error) => {
  return {
    type: types.GET_MEMBER_SPAR_DATA_ERROR,
    payload: error
  };
};

export const getMemberSparData = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberSparDataRequest());

    api
      .getMemberSparData(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberSparDataSuccess(JSON.parse(response.stringValue)));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberSparDataError(error));
      });
  };
};

const postMemberPaymentExemptionRequest = () => {
  return {
    type: types.POST_PAYMENT_EXEMPTION_REQUEST
  };
};

const postMemberPaymentExemptionSuccess = (response) => {
  return {
    type: types.POST_PAYMENT_EXEMPTION_SUCCESS,
    payload: response
  };
};

const postMemberPaymentExemptionError = (error) => {
  return {
    type: types.POST_PAYMENT_EXEMPTION_ERROR,
    payload: error
  };
};

export const postMemberPaymentExemption = (jwtToken, memberGuid, paymentExemption) => {
  return (dispatch) => {
    dispatch(postMemberPaymentExemptionRequest());

    api
      .postMemberPaymentExemption(jwtToken, memberGuid, {
        cardNumber: paymentExemption.cardNumber,
        validUntil: moment(paymentExemption.validUntil).format('YYYY-MM-DD')
      })
      .then((response) => {
        dispatch(postMemberPaymentExemptionSuccess(response));
        dispatch(showNotification(I18n.t('notification.payment_exemption_added.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(postMemberPaymentExemptionError(error));
        dispatch(showNotification(I18n.t('notification.payment_exemption_added.error'), 'error'));
      });
  };
};

const updateMemberPaymentExemptionRequest = () => {
  return {
    type: types.UPDATE_PAYMENT_EXEMPTION_REQUEST
  };
};

const updateMemberPaymentExemptionSuccess = (response) => {
  return {
    type: types.UPDATE_PAYMENT_EXEMPTION_SUCCESS,
    payload: response
  };
};

const updateMemberPaymentExemptionError = (error) => {
  return {
    type: types.UPDATE_PAYMENT_EXEMPTION_ERROR,
    payload: error
  };
};

export const updateMemberPaymentExemption = (jwtToken, memberGuid, paymentExemption) => {
  return (dispatch) => {
    dispatch(updateMemberPaymentExemptionRequest());

    api
      .updateMemberPaymentExemption(jwtToken, memberGuid, {
        cardNumber: paymentExemption.cardNumber,
        validUntil: moment(paymentExemption.validUntil).format('YYYY-MM-DD')
      })
      .then((response) => {
        dispatch(updateMemberPaymentExemptionSuccess(response));
        dispatch(showNotification(I18n.t('notification.payment_exemption_updated.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateMemberPaymentExemptionError(error));
        dispatch(showNotification(I18n.t('notification.payment_exemption_updated.error'), 'error'));
      });
  };
};

const deleteMemberPaymentExemptionRequest = () => {
  return {
    type: types.DELETE_PAYMENT_EXEMPTION_REQUEST
  };
};

const deleteMemberPaymentExemptionSuccess = () => {
  return {
    type: types.DELETE_PAYMENT_EXEMPTION_SUCCESS
  };
};

const deleteMemberPaymentExemptionError = (error) => {
  return {
    type: types.DELETE_PAYMENT_EXEMPTION_ERROR,
    payload: error
  };
};

export const deleteMemberPaymentExemption = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(deleteMemberPaymentExemptionRequest());

    api
      .deleteMemberPaymentExemption(jwtToken, memberGuid)
      .then(() => {
        dispatch(deleteMemberPaymentExemptionSuccess());
        dispatch(showNotification(I18n.t('notification.payment_exemption_removed.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(deleteMemberPaymentExemptionError(error));
        dispatch(showNotification(I18n.t('notification.payment_exemption_removed.error'), 'error'));
      });
  };
};

export const updatePaymentExemption = (property, value) => {
  return {
    type: types.UPDATE_PAYMENT_EXEMPTION_PROPERTY,
    payload: value,
    property
  };
};

const getMemberPaymentHistoryRequest = () => {
  return {
    type: types.GET_PAYMENT_HISTORY_REQUEST
  };
};

const getMemberPaymentHistorySuccess = (response) => {
  return {
    type: types.GET_PAYMENT_HISTORY_SUCCESS,
    payload: response
  };
};

const getMemberPaymentHistoryError = (response) => {
  return {
    type: types.GET_PAYMENT_HISTORY_ERROR,
    payload: response
  };
};

export const getMemberPaymentHistory = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberPaymentHistoryRequest());

    return api
      .getMemberPaymentHistory(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberPaymentHistorySuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberPaymentHistoryError(error));
      });
  };
};

const getMemberShipmentStatusRequest = () => {
  return {
    type: types.GET_SHIPMENT_STATUS_REQUEST
  };
};

const getMemberShipmentStatusSuccess = (response) => {
  return {
    type: types.GET_SHIPMENT_STATUS_SUCCESS,
    payload: response
  };
};

const getMemberShipmentStatusError = (response) => {
  return {
    type: types.GET_SHIPMENT_STATUS_ERROR,
    payload: response
  };
};

export const getMemberShipmentStatus = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberShipmentStatusRequest());

    return api
      .getMemberShipmentStatus(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberShipmentStatusSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberShipmentStatusError(error));
      });
  };
};

const getMemberStateRequest = () => {
  return {
    type: types.GET_MEMBER_STATE_REQUEST
  };
};

const getMemberStateSuccess = (stateName, stateValue) => {
  return {
    type: types.GET_MEMBER_STATE_SUCCESS,
    payload: { stateName, stateValue }
  };
};

const getMemberStateError = (response) => {
  return {
    type: types.GET_MEMBER_STATE_ERROR,
    payload: response
  };
};

const getMemberStateNotFound = (stateName) => {
  return {
    type: types.GET_MEMBER_STATE_NOT_FOUND,
    payload: stateName
  };
};

export const getMemberState = (jwtToken, memberGuid, stateName) => {
  return (dispatch) => {
    dispatch(getMemberStateRequest());

    return api
      .getMemberState(jwtToken, memberGuid, stateName)
      .then((response) => {
        dispatch(getMemberStateSuccess(stateName, response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberStateError(error));
      });
  };
};

const updateMemberStateRequest = () => {
  return {
    type: types.UPDATE_MEMBER_STATE_REQUEST
  };
};

const updateMemberStateSuccess = (stateName, stateValue) => {
  return {
    type: types.UPDATE_MEMBER_STATE_SUCCESS,
    payload: { stateName, stateValue }
  };
};

const updateMemberStateError = (response) => {
  return {
    type: types.UPDATE_MEMBER_STATE_ERROR,
    payload: response
  };
};

export const updateMemberState = (jwtToken, memberGuid, stateName, stateValue) => {
  return (dispatch) => {
    dispatch(updateMemberStateRequest());

    return api
      .updateMemberState(jwtToken, memberGuid, stateName, stateValue)
      .then((response) => {
        dispatch(updateMemberStateSuccess(stateName, response));
        return api.getMemberState(jwtToken, memberGuid, stateName);
      })
      .then((response) => {
        dispatch(getMemberStateSuccess(stateName, response));
        dispatch(
          showNotification(
            I18n.t('notification.update_member_state.success', {
              stateName: I18n.t(`member_state.${decamelize(stateName, '_')}`),
              stateAction: I18n.t(`member_state_actions.${stateValue ? decamelize(stateValue, '_') : 'resumed'}`)
            }),
            'success'
          )
        );
      })
      .catch((error) => {
        console.log(error); //TODO: How should we handle these chained API calls in terms of error handling
        dispatch(
          showNotification(
            I18n.t('notification.update_member_state.error', {
              stateName: I18n.t(`member_state.${decamelize(stateName, '_')}`)
            }),
            'error'
          )
        );
        dispatch(updateMemberStateError(error));
      });
  };
};

const sendMonitorInvoiceRequest = () => {
  return {
    type: types.SEND_MONITOR_INVOICE_REQUEST
  };
};

const sendMonitorInvoiceSuccess = () => {
  return {
    type: types.SEND_MONITOR_INVOICE_SUCCESS
  };
};

const sendMonitorInvoiceError = (error) => {
  return {
    type: types.SEND_MONITOR_INVOICE_ERROR,
    payload: error
  };
};

export const sendMonitorInvoice = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(sendMonitorInvoiceRequest());

    api
      .sendInvoiceToMember(jwtToken, memberGuid)
      .then(() => {
        dispatch(sendMonitorInvoiceSuccess());
        dispatch(showNotification(I18n.t('notification.send_monitor_invoice.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(sendMonitorInvoiceError(error));
        dispatch(
          showNotification(
            I18n.t(
              `notification.send_monitor_invoice.error${
                error?.status === HTTP_STATUS_CODES.CONFLICT ? '_conflict' : ''
              }`
            ),
            'error'
          )
        );
      });
  };
};

const getMemberMonitorsRequest = () => {
  return {
    type: types.GET_MEMBER_MONITORS_REQUEST
  };
};

const getMemberMonitorsSuccess = (response) => {
  return {
    type: types.GET_MEMBER_MONITORS_SUCCESS,
    payload: response
  };
};

const getMemberMonitorsError = (error) => {
  return {
    type: types.GET_MEMBER_MONITORS_ERROR,
    payload: error
  };
};

export const getMemberMonitors = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberMonitorsRequest());

    api
      .getMemberMonitors(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberMonitorsSuccess(response.response.body));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberMonitorsError(error));
      });
  };
};

/**
 * System user actions
 */

const getSystemUserRequest = () => {
  return {
    type: types.GET_SYSTEM_USER_REQUEST
  };
};

const getSystemUserSuccess = (response) => {
  return {
    type: types.GET_SYSTEM_USER_SUCCESS,
    payload: response
  };
};

const getSystemUserError = (error) => {
  return {
    type: types.GET_SYSTEM_USER_ERROR,
    payload: error
  };
};

export const getSystemUser = (jwtToken, systemUserGuid) => {
  return (dispatch) => {
    dispatch(getSystemUserRequest());
    dispatch(clearCurrentSystemUser());

    api
      .getSystemUser(jwtToken, systemUserGuid)
      .then((response) => {
        dispatch(getSystemUserSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getSystemUserError(error));
      });
  };
};

export const clearCurrentSystemUser = () => {
  return {
    type: types.CLEAR_CURRENT_SYSTEM_USER
  };
};

const getSystemUsersRequest = () => {
  return {
    type: types.GET_SYSTEM_USERS_REQUEST
  };
};

const getSystemUsersSuccess = (response, returnToIndexPage) => {
  return {
    type: types.GET_SYSTEM_USERS_SUCCESS,
    payload: response,
    returnToIndexPage
  };
};

const getSystemUsersError = (error) => {
  return {
    type: types.GET_SYSTEM_USERS_ERROR,
    payload: error
  };
};

export const getSystemUsers = (jwtToken, options) => {
  return (dispatch) => {
    dispatch(getSystemUsersRequest());
    dispatch(setCurrentSystemUserSearchQuery(options));

    api
      .getSystemUsers(jwtToken, options.query, options.offset, options.limit, options.sortBy, [
        'concatenatedNames',
        'title',
        'authorities',
        'numberOfPatients',
        'assignmentPriority'
      ])
      .then((response) => {
        dispatch(getSystemUsersSuccess(response, options.returnToIndexPage));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getSystemUsersError(error));
      });
  };
};

const getAssignableDoctorsRequest = () => {
  return {
    type: types.GET_ASSIGNABLE_DOCTORS_REQUEST
  };
};

const getAssignableDoctorsSuccess = (response) => {
  return {
    type: types.GET_ASSIGNABLE_DOCTORS_SUCCESS,
    payload: response
  };
};

const getAssignableDoctorsError = (error) => {
  return {
    type: types.GET_ASSIGNABLE_DOCTORS_ERROR,
    payload: error
  };
};

export const getAssignableDoctors = (jwtToken, regionId, source) => {
  return (dispatch) => {
    dispatch(getAssignableDoctorsRequest());

    let query = `authorities=='caregiver';hiddenRegions!=${regionId}`;

    if (source) {
      query += `;hiddenSources!="${source}"`;
    }

    api
      .getSystemUsers(jwtToken, query, 0, 100, undefined, ['authorities', 'hiddenRegions', 'hiddenSources'])
      .then((response) => {
        dispatch(getAssignableDoctorsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getAssignableDoctorsError(error));
      });
  };
};

export const updateSystemUserRequest = () => {
  return {
    type: types.UPDATE_SYSTEM_USER_REQUEST
  };
};

export const updateSystemUserSuccess = (response) => {
  return {
    type: types.UPDATE_SYSTEM_USER_SUCCESS,
    payload: response
  };
};

export const updateSystemUserError = (error) => {
  return {
    type: types.UPDATE_SYSTEM_USER_ERROR,
    payload: error
  };
};

export const updateSystemUser = (jwtToken, guid, updatedFields) => {
  return (dispatch) => {
    dispatch(updateSystemUserRequest());

    api
      .updateSystemUser(jwtToken, guid, updatedFields)
      .then((response) => {
        dispatch(updateSystemUserSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateSystemUserError(error));
      });
  };
};

export const toggleHiddenRegion = (regionId) => {
  return {
    type: types.TOGGLE_HIDDEN_REGION,
    payload: regionId
  };
};

export const clearHiddenRegions = () => {
  return {
    type: types.CLEAR_HIDDEN_REGIONS
  };
};

export const toggleAuthority = (authority) => {
  return {
    type: types.TOGGLE_AUTHORITY,
    payload: authority
  };
};

export const toggleHiddenSource = (source) => {
  return {
    type: types.TOGGLE_HIDDEN_SOURCE,
    payload: source
  };
};

export const clearHiddenSources = () => {
  return {
    type: types.CLEAR_HIDDEN_SOURCES
  };
};

export const clearAuthorities = () => {
  return {
    type: types.CLEAR_AUTHORITIES
  };
};

const updateAuthoritiesRequest = () => {
  return {
    type: types.UPDATE_AUTHORITIES_REQUEST
  };
};

const updateAuthoritiesSuccess = (response) => {
  return {
    type: types.UPDATE_AUTHORITIES_SUCCESS,
    payload: response
  };
};

const updateAuthoritiesError = (error) => {
  return {
    type: types.UPDATE_AUTHORITIES_ERROR,
    payload: error
  };
};

export const updateAuthorities = (jwtToken, guid, updatedFields) => {
  return (dispatch) => {
    dispatch(updateAuthoritiesRequest());

    api
      .updateSystemUser(jwtToken, guid, updatedFields)
      .then((response) => {
        dispatch(updateAuthoritiesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateAuthoritiesError(error));
        dispatch(showNotification(I18n.t('notification.update_authorities.error'), 'error'));
      });
  };
};

const updateHiddenSourcesRequest = () => {
  return {
    type: types.UPDATE_HIDDEN_SOURCES_REQUEST
  };
};

const updateHiddenSourcesSuccess = (response) => {
  return {
    type: types.UPDATE_HIDDEN_SOURCES_SUCCESS,
    payload: response
  };
};

const updateHiddenSourcesError = (error) => {
  return {
    type: types.UPDATE_HIDDEN_SOURCES_ERROR,
    payload: error
  };
};

export const updateHiddenSources = (jwtToken, guid, updatedFields) => {
  return (dispatch) => {
    dispatch(updateHiddenSourcesRequest());

    api
      .updateSystemUser(jwtToken, guid, updatedFields)
      .then((response) => {
        dispatch(updateHiddenSourcesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateHiddenSourcesError(error));
        dispatch(showNotification(I18n.t('notification.update_hidden_sources.error'), 'error'));
      });
  };
};

const updateHiddenRegionsRequest = () => {
  return {
    type: types.UPDATE_HIDDEN_REGIONS_REQUEST
  };
};

const updateHiddenRegionsSuccess = (response) => {
  return {
    type: types.UPDATE_HIDDEN_REGIONS_SUCCESS,
    payload: response
  };
};

const updateHiddenRegionsError = (error) => {
  return {
    type: types.UPDATE_HIDDEN_REGIONS_ERROR,
    payload: error
  };
};

export const updateHiddenRegions = (jwtToken, guid, updatedFields) => {
  return (dispatch) => {
    dispatch(updateHiddenRegionsRequest());

    api
      .updateSystemUser(jwtToken, guid, updatedFields)
      .then((response) => {
        dispatch(updateHiddenRegionsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateHiddenRegionsError(error));
        dispatch(showNotification(I18n.t('notification.update_hidden_regions.error'), 'error'));
      });
  };
};

const postHandoverRequest = () => {
  return {
    type: types.POST_HANDOVER_REQUEST
  };
};

const postHandoverSuccess = (response, remainingPatients) => {
  return {
    type: types.POST_HANDOVER_SUCCESS,
    payload: response,
    numberOfPatients: remainingPatients
  };
};

const postHandoverError = (error) => {
  return {
    type: types.POST_HANDOVER_ERROR,
    payload: error
  };
};

export const postHandover = (
  jwtToken,
  startDate,
  caregiver,
  patientDistribution,
  successCallback,
  remainingPatients
) => {
  return (dispatch) => {
    dispatch(postHandoverRequest());

    const id = uuidv4();

    api
      .postHandover(jwtToken, startDate, caregiver, patientDistribution)
      .then((res) => {
        if (!res.ok) {
          throw new Error();
        }

        dispatch(
          postHandoverSuccess(
            {
              id,
              startDate,
              caregiver,
              patientDistribution
            },
            remainingPatients
          )
        );
        successCallback && successCallback();
        dispatch(showNotification(I18n.t('notification.new_handover.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(postHandoverError(error));
        dispatch(showNotification(I18n.t('notification.new_handover.error'), 'error'));
      });
  };
};

export const setAssignmentEditUser = (user) => {
  return {
    type: types.SET_ASSIGNMENT_EDIT_USER,
    user
  };
};

export const addAssignmentPriority = (guid, assignmentPriority) => {
  return {
    type: types.ADD_ASSIGNMENT_PRIORITY,
    guid,
    assignmentPriority
  };
};

export const removeAssignmentPriority = (guid) => {
  return {
    type: types.REMOVE_ASSIGNMENT_PRIORITY,
    guid
  };
};

const getSystemUserProfileImageRequest = () => {
  return {
    type: types.GET_SYSTEM_USER_PROFILE_IMAGE_REQUEST
  };
};

const getSystemUserProfileImageSuccess = (response, userGuid, loggedInUserGuid) => {
  return {
    type: types.GET_SYSTEM_USER_PROFILE_IMAGE_SUCCESS,
    payload: response,
    userGuid,
    loggedInUserGuid
  };
};

const getSystemUserProfileImageError = (response) => {
  return {
    type: types.GET_SYSTEM_USER_PROFILE_IMAGE_ERROR,
    payload: response
  };
};

export const getSystemUserProfileImage = (jwtToken, guid, profileImageId, loggedInUserGuid) => {
  return (dispatch) => {
    dispatch(getSystemUserProfileImageRequest());
    api
      .getFile(jwtToken, guid, profileImageId, 100, 100)
      .then((response) => {
        dispatch(getSystemUserProfileImageSuccess(response.response.body, guid, loggedInUserGuid));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getSystemUserProfileImageError(error));
      });
  };
};

/**
 * Shared data actions
 */

const getRegionsRequest = () => {
  return {
    type: types.GET_REGIONS_REQUEST
  };
};

const getRegionsSuccess = (response) => {
  return {
    type: types.GET_REGIONS_SUCCESS,
    payload: response
  };
};

const getRegionsError = (error) => {
  return {
    type: types.GET_REGIONS_ERROR,
    payload: error
  };
};

export const getRegions = (jwtToken) => {
  return (dispatch) => {
    dispatch(getRegionsRequest());

    api
      .getRegions(jwtToken)
      .then((response) => {
        dispatch(getRegionsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getRegionsError(error));
      });
  };
};

const getAuthoritiesSuccess = (response) => {
  return {
    type: types.GET_AUTHORITIES,
    payload: response
  };
};

export const getAuthorities = () => {
  return (dispatch) => {
    api.getAuthorities().then((response) => {
      dispatch(getAuthoritiesSuccess(response));
    });
  };
};

const getMonitorSizesRequest = () => {
  return {
    type: types.GET_MONITOR_SIZES_REQUEST
  };
};

const getMonitorSizesSuccess = (response) => {
  return {
    type: types.GET_MONITOR_SIZES_SUCCESS,
    payload: response
  };
};

const getMonitorSizesError = (error) => {
  return {
    type: types.GET_MONITOR_SIZES_ERROR,
    payload: error
  };
};

export const getMonitorSizes = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMonitorSizesRequest());

    api
      .getMemberAnamnesisQuestion(jwtToken, memberGuid, 1, ARM_CIRCUMFERENCE_ID)
      .then((response) => {
        dispatch(getMonitorSizesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMonitorSizesError(error));
      });
  };
};

/**
 * Report actions
 */

export const getInvoiceReportRequest = () => {
  return {
    type: types.GENERATE_INVOICE_REPORT_REQUEST
  };
};

export const getInvoiceReportSuccess = (response) => {
  return {
    type: types.GENERATE_INVOICE_REPORT_SUCCESS,
    payload: response
  };
};

export const getInvoiceReportError = (error) => {
  return {
    type: types.GENERATE_INVOICE_REPORT_ERROR,
    payload: error
  };
};

export const setInvoiceReportMonth = (e) => {
  return {
    type: types.SET_INVOICE_REPORT_MONTH,
    payload: e.value
  };
};

export const setInvoiceReportYear = (e) => {
  return {
    type: types.SET_INVOICE_REPORT_YEAR,
    payload: e.value
  };
};

export const showOffsetSettings = () => {
  return {
    type: types.SHOW_OFFSET_SETTINGS_MODAL
  };
};

export const hideOffsetSettings = () => {
  return {
    type: types.HIDE_OFFSET_SETTINGS_MODAL
  };
};

export const setReferralPrintOffset = (type, dimension) => {
  return (dispatch) => {
    return (e) => {
      dispatch({
        type: types.SET_REFERRAL_PRINT_OFFSET,
        payload: {
          type,
          dimension,
          value: window.parseFloat(e.target.value)
        }
      });
    };
  };
};

export const getReferralDocumentsRequest = () => {
  return {
    type: types.GET_REFERRAL_PDFS_REQUEST
  };
};

export const getReferralDocumentsSuccess = (response, disabledReferrals) => {
  return {
    type: types.GET_REFERRAL_PDFS_SUCCESS,
    payload: {
      response,
      disabledReferrals
    }
  };
};

export const getReferralDocumentsError = (error) => {
  return {
    type: types.GET_REFERRAL_PDFS_ERROR,
    payload: error
  };
};

export const getReferralTestDocumentsRequest = () => {
  return {
    type: types.GET_REFERRAL_TEST_PDFS_REQUEST
  };
};

export const getReferralTestDocumentsSuccess = (response) => {
  return {
    type: types.GET_REFERRAL_TEST_PDFS_SUCCESS,
    payload: response
  };
};

export const getReferralTestDocumentsError = (error) => {
  return {
    type: types.GET_REFERRAL_TEST_PDFS_ERROR,
    payload: error
  };
};

const getReferralsRequest = () => {
  return {
    type: types.GET_REFERRALS_REQUEST
  };
};

const getReferralsSuccess = (response) => {
  return {
    type: types.GET_REFERRALS_SUCCESS,
    payload: response
  };
};

const getReferralsError = (error) => {
  return {
    type: types.GET_REFERRALS_ERROR,
    payload: error
  };
};

export const getReferrals = (jwtToken, regionId) => {
  return (dispatch) => {
    dispatch(getReferralsRequest());

    api
      .getReferrals(jwtToken, regionId)
      .then((response) => {
        dispatch(getReferralsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getReferralsError(error));
      });
  };
};

export const toggleReferral = (referralId) => {
  return {
    type: types.TOGGLE_REFERRAL_ITEM,
    payload: referralId
  };
};

export const toggleAllReferrals = () => {
  return {
    type: types.TOGGLE_ALL_REFERRAL_ITEMS
  };
};

const getCaregiversRequest = () => {
  return {
    type: types.GET_CAREGIVERS_REQUEST
  };
};

const getCaregiversSuccess = (response) => {
  return {
    type: types.GET_CAREGIVERS_SUCCESS,
    payload: response
  };
};

const getCaregiversError = (error) => {
  return {
    type: types.GET_CAREGIVERS_ERROR,
    payload: error
  };
};

export const getCaregivers = (jwtToken) => {
  return (dispatch) => {
    dispatch(getCaregiversRequest());

    let query = `authorities=='caregiver'`;

    api
      .getSystemUsers(jwtToken, query, 0, 100, undefined, ['authorities', 'hiddenRegions', 'numberOfPatients'])
      .then((response) => {
        dispatch(getCaregiversSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getCaregiversError(error));
      });
  };
};

const getNursesRequest = () => {
  return {
    type: types.GET_NURSES_REQUEST
  };
};

const getNursesSuccess = (response) => {
  return {
    type: types.GET_NURSES_SUCCESS,
    payload: response
  };
};

const getNursesError = (error) => {
  return {
    type: types.GET_NURSES_ERROR,
    payload: error
  };
};

export const getNurses = (jwtToken) => {
  return (dispatch) => {
    dispatch(getNursesRequest());

    let query = `authorities=='nurse'`;

    api
      .getSystemUsers(jwtToken, query, 0, 100, undefined, ['authorities', 'numberOfPatients'])
      .then((response) => {
        dispatch(getNursesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getNursesError(error));
      });
  };
};

const getShipmentItemsRequest = () => {
  return {
    type: types.GET_SHIPMENT_ITEMS_REQUEST
  };
};

const getShipmentItemsSuccess = (response) => {
  return {
    type: types.GET_SHIPMENT_ITEMS_SUCCESS,
    payload: response
  };
};

const getShipmentItemsError = (error) => {
  return {
    type: types.GET_SHIPMENT_ITEMS_ERROR,
    payload: error
  };
};

export const getShipmentItems = (jwtToken) => {
  return (dispatch) => {
    dispatch(getShipmentItemsRequest());

    api
      .getShipmentItems(jwtToken)
      .then((response) => {
        dispatch(getShipmentItemsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getShipmentItemsError(error));
      });
  };
};

export const toggleShipmentItem = (shipmentItemId) => {
  return {
    type: types.TOGGLE_SHIPMENT_ITEM,
    payload: shipmentItemId
  };
};

export const toggleAllShipmentsSelected = () => {
  return {
    type: types.TOGGLE_ALL_SHIPMENTS_SELECTED
  };
};

const scheduleShipmentsRequest = () => {
  return {
    type: types.SCHEDULE_SHIPMENTS_REQUEST
  };
};

const scheduleShipmentsSuccess = () => {
  return {
    type: types.SCHEDULE_SHIPMENTS_SUCCESS
  };
};

const scheduleShipmentsError = (error) => {
  return {
    type: types.SCHEDULE_SHIPMENTS_ERROR,
    payload: error
  };
};

export const scheduleShipments = (jwtToken, shipments) => {
  return (dispatch) => {
    dispatch(scheduleShipmentsRequest());

    api
      .scheduleShipments(jwtToken, shipments)
      .then(() => {
        dispatch(scheduleShipmentsSuccess());
      })
      .catch((error) => {
        console.log(error);
        dispatch(scheduleShipmentsError(error));
      });
  };
};

const addManualShipmentRequest = () => {
  return {
    type: types.ADD_MANUAL_SHIPMENT_REQUEST
  };
};

const addManualShipmentSuccess = () => {
  return {
    type: types.ADD_MANUAL_SHIPMENT_SUCCESS
  };
};

const addManualShipmentError = (error) => {
  return {
    type: types.ADD_MANUAL_SHIPMENT_ERROR,
    payload: error
  };
};

export const addManualShipment = (jwtToken, memberGuid, type, reason) => {
  return (dispatch) => {
    dispatch(addManualShipmentRequest());

    api
      .addManualShipment(jwtToken, memberGuid, type, reason)
      .then(() => {
        dispatch(addManualShipmentSuccess());
        dispatch(showNotification(I18n.t(`notification.add_manual_shipment_${type}.success`), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(addManualShipmentError(error));
        dispatch(showNotification(I18n.t(`notification.add_manual_shipment_${type}.error`), 'error'));
      });
  };
};

const deleteShipmentRequest = (shipmentId) => {
  return {
    type: types.DELETE_SHIPMENT_REQUEST,
    payload: shipmentId
  };
};

const deleteShipmentSuccess = (shipmentId) => {
  return {
    type: types.DELETE_SHIPMENT_SUCCESS,
    payload: shipmentId
  };
};

const deleteShipmentError = (error, shipmentId) => {
  return {
    type: types.DELETE_SHIPMENT_ERROR,
    payload: {
      error,
      shipmentId
    }
  };
};

export const deleteShipment = (jwtToken, shipmentId) => {
  return (dispatch) => {
    dispatch(deleteShipmentRequest(shipmentId));

    api
      .deleteShipment(jwtToken, shipmentId)
      .then(() => {
        dispatch(deleteShipmentSuccess(shipmentId));
      })
      .catch((error) => {
        console.log(error);
        dispatch(deleteShipmentError(error, shipmentId));
        dispatch(showNotification(I18n.t(`notification.delete_shipment.error`), 'error'));
      });
  };
};

const delayShipmentRequest = () => {
  return {
    type: types.DELAY_SHIPMENT_REQUEST
  };
};

const delayShipmentSuccess = (shipment) => {
  return {
    type: types.DELAY_SHIPMENT_SUCCESS,
    payload: shipment
  };
};

const delayShipmentError = (error) => {
  return {
    type: types.DELAY_SHIPMENT_ERROR,
    payload: error
  };
};

export const delayShipment = (jwtToken, shipment, delayDate) => {
  return (dispatch) => {
    dispatch(delayShipmentRequest(shipment));

    api
      .delayShipment(jwtToken, shipment, delayDate)
      .then((response) => {
        dispatch(delayShipmentSuccess(response));
        dispatch(showNotification(I18n.t(`notification.delay_shipment.success`), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(delayShipmentError(error));
        dispatch(showNotification(I18n.t(`notification.delay_shipment.error`), 'error'));
      });
  };
};

const undoDelayShipmentRequest = () => {
  return {
    type: types.UNDO_DELAY_REQUEST
  };
};

const undoDelayShipmentSuccess = (shipment) => {
  return {
    type: types.UNDO_DELAY_SUCCESS,
    payload: shipment
  };
};

const undoDelayShipmentError = (error) => {
  return {
    type: types.UNDO_DELAY_ERROR,
    payload: error
  };
};

export const undoDelayShipment = (jwtToken, shipment, delayDate) => {
  return (dispatch) => {
    dispatch(undoDelayShipmentRequest());

    api
      .delayShipment(jwtToken, shipment, delayDate)
      .then((response) => {
        dispatch(undoDelayShipmentSuccess(response));
        dispatch(showNotification(I18n.t(`notification.undo_delay_shipment.success`), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(undoDelayShipmentError(error));
        dispatch(showNotification(I18n.t(`notification.undo_delay_shipment.error`), 'error'));
      });
  };
};

const updateShipmentDelayRequest = () => {
  return {
    type: types.UPDATE_DELAY_DATE_REQUEST
  };
};

const updateShipmentDelaySuccess = (shipment) => {
  return {
    type: types.UPDATE_DELAY_DATE_SUCCESS,
    payload: shipment
  };
};

const updateShipmentDelayError = (error) => {
  return {
    type: types.UPDATE_DELAY_DATE_ERROR,
    payload: error
  };
};

export const updateShipmentDelay = (jwtToken, shipment, delayDate) => {
  return (dispatch) => {
    dispatch(updateShipmentDelayRequest(shipment));

    api
      .delayShipment(jwtToken, shipment, delayDate)
      .then((response) => {
        dispatch(updateShipmentDelaySuccess(response));
        dispatch(showNotification(I18n.t(`notification.update_shipment_delay.success`), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateShipmentDelayError(error));
        dispatch(showNotification(I18n.t(`notification.update_shipment_delay.error`), 'error'));
      });
  };
};

export const setSelectDelayDateModalVisibility = (visibility) => {
  return {
    type: types.SET_SELECT_DELAY_DATE_MODAL_VISIBILITY,
    visibility
  };
};

export const updateDelayDateModalVisibility = (visibility) => {
  return {
    type: types.UPDATE_DELAY_DATE_MODAL_VISIBILITY,
    visibility
  };
};

const getCaregiverStatisticsRequest = () => {
  return {
    type: types.GET_CAREGIVER_STATISTICS_REQUEST
  };
};

const getCaregiverStatisticsSuccess = (response) => {
  return {
    type: types.GET_CAREGIVER_STATISTICS_SUCCESS,
    payload: response
  };
};

const getCaregiverStatisticsError = (error) => {
  return {
    type: types.GET_CAREGIVER_STATISTICS_ERROR,
    payload: error
  };
};

export const getCaregiverStatistics = (jwtToken, year, caregiverRole) => {
  return (dispatch) => {
    dispatch(getCaregiverStatisticsRequest());

    api
      .getCaregiverStatistics(jwtToken, year, caregiverRole)
      .then((response) => {
        dispatch(getCaregiverStatisticsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getCaregiverStatisticsError(error));
      });
  };
};

const getPatientTotalsRequest = () => {
  return {
    type: types.GET_PATIENT_TOTALS_REQUEST
  };
};

const getPatientTotalsSuccess = (response) => {
  return {
    type: types.GET_PATIENT_TOTALS_SUCCESS,
    payload: response
  };
};

const getPatientTotalsError = (error) => {
  return {
    type: types.GET_PATIENT_TOTALS_ERROR,
    payload: error
  };
};

export const getPatientTotals = (jwtToken, role) => {
  return (dispatch) => {
    dispatch(getPatientTotalsRequest());

    api
      .getPatientTotals(jwtToken, role)
      .then((response) => {
        dispatch(getPatientTotalsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getPatientTotalsError(error));
      });
  };
};

const getAllDoctorsRequest = () => {
  return {
    type: types.GET_ALL_DOCTORS_REQUEST
  };
};

const getAllDoctorsSuccess = (response) => {
  return {
    type: types.GET_ALL_DOCTORS_SUCCESS,
    payload: response
  };
};

const getAllDoctorsError = (error) => {
  return {
    type: types.GET_ALL_DOCTORS_ERROR,
    payload: error
  };
};

export const getAllDoctors = (jwtToken) => {
  return (dispatch) => {
    dispatch(getAllDoctorsRequest());

    const query = `authorities=='caregiver'`;

    api
      .getSystemUsers(jwtToken, query, 0, 100, undefined, ['authorities'])
      .then((response) => {
        dispatch(getAllDoctorsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getAllDoctorsError(error));
      });
  };
};

const getDoctorFinalNoteBreakdownRequest = () => {
  return {
    type: types.GET_DOCTOR_FINAL_NOTE_BREAKDOWN_REQUEST
  };
};

const getDoctorFinalNoteBreakdownSuccess = (response) => {
  return {
    type: types.GET_DOCTOR_FINAL_NOTE_BREAKDOWN_SUCCESS,
    payload: response
  };
};

const getDoctorFinalNoteBreakdownError = (error) => {
  return {
    type: types.GET_DOCTOR_FINAL_NOTE_BREAKDOWN_ERROR,
    payload: error
  };
};

export const getDoctorFinalNoteBreakdown = (jwtToken, guid, startDate) => {
  return (dispatch) => {
    dispatch(getDoctorFinalNoteBreakdownRequest());

    api
      .getDoctorFinalNoteBreakdown(jwtToken, guid, startDate)
      .then((response) => {
        dispatch(getDoctorFinalNoteBreakdownSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getDoctorFinalNoteBreakdownError(error));
        dispatch(showNotification(I18n.t(`notification.get_final_note_breakdown.error`), 'error'));
      });
  };
};

export const clearDoctorFinalNoteBreakdown = () => {
  return {
    type: types.CLEAR_DOCTOR_FINAL_NOTE_BREAKDOWN
  };
};

const getRemunerationSchemeRequest = () => {
  return {
    type: types.GET_REMUNERATION_SCHEME_REQUEST
  };
};

const getRemunerationSchemeSuccess = (response) => {
  return {
    type: types.GET_REMUNERATION_SCHEME_SUCCESS,
    payload: response
  };
};

const getRemunerationSchemeError = (error) => {
  return {
    type: types.GET_REMUNERATION_SCHEME_ERROR,
    payload: error
  };
};

export const getRemunerationScheme = (jwtToken) => {
  return (dispatch) => {
    dispatch(getRemunerationSchemeRequest());

    api
      .getRemunerationScheme(jwtToken)
      .then((response) => {
        dispatch(getRemunerationSchemeSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getRemunerationSchemeError(error));
      });
  };
};

/**
 * Notification actions
 */

export const showNotification = (message, notificationType) => {
  return {
    type: types.SHOW_NOTIFICATION_MESSAGE,
    message,
    notificationType
  };
};

export const hideNotification = () => {
  return {
    type: types.HIDE_NOTIFICATION_MESSAGE
  };
};

/**
 * Jira actions
 */

export const showJiraModal = () => {
  return {
    type: types.SHOW_JIRA_MODAL
  };
};

export const hideJiraModal = () => {
  return {
    type: types.HIDE_JIRA_MODAL
  };
};

export const showJiraHistoryModal = () => {
  return {
    type: types.SHOW_JIRA_HISTORY_MODAL
  };
};

export const hideJiraHistoryModal = () => {
  return {
    type: types.HIDE_JIRA_HISTORY_MODAL
  };
};

const getMemberJiraIssuesRequest = () => {
  return {
    type: types.GET_MEMBER_JIRA_ISSUES_REQUEST
  };
};

const getMemberJiraIssuesSuccess = (response) => {
  return {
    type: types.GET_MEMBER_JIRA_ISSUES_SUCCESS,
    payload: response
  };
};

const getMemberJiraIssuesError = (error) => {
  return {
    type: types.GET_MEMBER_JIRA_ISSUES_ERROR,
    payload: error
  };
};

export const getMemberJiraIssues = (jwtToken, memberGuid) => {
  return (dispatch) => {
    dispatch(getMemberJiraIssuesRequest());
    api
      .getMemberJiraIssues(jwtToken, memberGuid)
      .then((response) => {
        dispatch(getMemberJiraIssuesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getMemberJiraIssuesError(error));
      });
  };
};

const postMemberJiraIssueRequest = () => {
  return {
    type: types.POST_MEMBER_JIRA_ISSUE_REQUEST
  };
};

const postMemberJiraIssueSuccess = (response) => {
  return {
    type: types.POST_MEMBER_JIRA_ISSUE_SUCCESS,
    payload: response
  };
};

const postMemberJiraIssueError = (error) => {
  return {
    type: types.POST_MEMBER_JIRA_ISSUE_ERROR,
    payload: error
  };
};

export const postMemberJiraIssue = (jwtToken, jiraIssue) => {
  return (dispatch) => {
    dispatch(postMemberJiraIssueRequest());
    api
      .postMemberJiraIssue(jwtToken, jiraIssue)
      .then((response) => {
        dispatch(postMemberJiraIssueSuccess(response));
        dispatch(showNotification(I18n.t(`notification.create_jira_issue.success`), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(postMemberJiraIssueError(error));
        dispatch(showNotification(I18n.t(`notification.create_jira_issue.error`), 'error'));
      });
  };
};

export const setJiraIssueType = (e) => {
  return {
    type: types.SET_JIRA_ISSUE_TYPE,
    payload: e.value
  };
};

export const setJiraIssueDescription = (e) => {
  return {
    type: types.SET_JIRA_ISSUE_DESCRIPTION,
    payload: e.target.value
  };
};

const getJiraIssueTypesRequest = () => {
  return {
    type: types.GET_JIRA_ISSUE_TYPES_REQUEST
  };
};

const getJiraIssueTypesSuccess = (response) => {
  return {
    type: types.GET_JIRA_ISSUE_TYPES_SUCCESS,
    payload: response
  };
};

const getJiraIssueTypesError = (error) => {
  return {
    type: types.GET_JIRA_ISSUE_TYPES_ERROR,
    payload: error
  };
};

export const getJiraIssueTypes = (jwtToken) => {
  return (dispatch) => {
    dispatch(getJiraIssueTypesRequest());
    api
      .getJiraIssueTypes(jwtToken, process.env.REACT_APP_JIRA_PROJECT_KEY || 'ST')
      .then((response) => {
        dispatch(getJiraIssueTypesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getJiraIssueTypesError(error));
      });
  };
};

/**
 * Services actions
 */

const sendBulkMessageRequest = () => {
  return {
    type: types.SEND_BULK_MESSAGE_REQUEST
  };
};

const sendBulkMessageSuccess = (response) => {
  return {
    type: types.SEND_BULK_MESSAGE_SUCCESS,
    payload: response
  };
};

const sendBulkMessageError = (error) => {
  return {
    type: types.SEND_BULK_MESSAGE_ERROR,
    payload: error
  };
};

export const sendBulkMessage = (jwtToken, caregivers, messageText) => {
  return (dispatch) => {
    dispatch(sendBulkMessageRequest());

    api
      .bulkSendChatMessage(jwtToken, caregivers, messageText)
      .then((response) => {
        dispatch(sendBulkMessageSuccess(response));
        dispatch(showNotification(I18n.t('notification.send_bulk_message.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(sendBulkMessageError(error));
        dispatch(showNotification(I18n.t('notification.send_bulk_message.error'), 'error'));
      });
  };
};

const sendBulkReferralsRequest = () => {
  return {
    type: types.SEND_BULK_REFERRALS_REQUEST
  };
};

const sendBulkReferralsSuccess = (response) => {
  return {
    type: types.SEND_BULK_REFERRALS_SUCCESS,
    payload: response
  };
};

const sendBulkReferralsError = (error) => {
  return {
    type: types.SEND_BULK_REFERRALS_ERROR,
    payload: error
  };
};

export const sendBulkReferrals = (jwtToken, personalNumbers, messageText) => {
  return (dispatch) => {
    dispatch(sendBulkReferralsRequest());

    api
      .postBulkReferrals(jwtToken, personalNumbers, messageText)
      .then((response) => {
        dispatch(sendBulkReferralsSuccess(response));
        dispatch(showNotification(I18n.t('notification.send_bulk_referrals.success'), 'success'));
      })
      .catch((error) => {
        console.log(error);
        dispatch(sendBulkReferralsError(error));
        dispatch(showNotification(I18n.t('notification.send_bulk_referrals.error'), 'error'));
      });
  };
};

const getCannedResponsesRequest = () => {
  return {
    type: types.GET_CANNED_RESPONSES_REQUEST
  };
};

const getCannedResponsesSuccess = (response) => {
  return {
    type: types.GET_CANNED_RESPONSES_SUCCESS,
    payload: response.body
  };
};

const getCannedResponsesError = (error) => {
  return {
    type: types.GET_CANNED_RESPONSES_ERROR,
    payload: error
  };
};

export const getCannedResponses = (jwtToken) => {
  return (dispatch) => {
    dispatch(getCannedResponsesRequest());

    api
      .getCannedResponses(jwtToken)
      .then((response) => {
        dispatch(getCannedResponsesSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getCannedResponsesError(error));
      });
  };
};

const getTemplateTagsRequest = () => {
  return {
    type: types.GET_TEMPLATE_TAGS_REQUEST
  };
};

const getTemplateTagsSuccess = (response) => {
  return {
    type: types.GET_TEMPLATE_TAGS_SUCCESS,
    payload: response
  };
};

const getTemplateTagsError = (error) => {
  return {
    type: types.GET_TEMPLATE_TAGS_ERROR,
    payload: error
  };
};

export const getTemplateTags = (jwtToken) => {
  return (dispatch) => {
    dispatch(getTemplateTagsRequest());

    api
      .getTemplateTags(jwtToken)
      .then((response) => {
        dispatch(getTemplateTagsSuccess(response));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getTemplateTagsError(error));
      });
  };
};

/**
 * User actions
 */

const getProfileImageRequest = () => {
  return {
    type: types.GET_PROFILE_IMAGE_REQUEST
  };
};

const getProfileImageSuccess = (response) => {
  return {
    type: types.GET_PROFILE_IMAGE_SUCCESS,
    payload: response
  };
};

const getProfileImageError = (response) => {
  return {
    type: types.GET_PROFILE_IMAGE_ERROR,
    payload: response
  };
};

export const getProfileImage = (jwtToken, guid, profileImageId) => {
  return (dispatch) => {
    dispatch(getProfileImageRequest());
    api
      .getFile(jwtToken, guid, profileImageId, 100, 100)
      .then((response) => {
        dispatch(getProfileImageSuccess(response.response.body));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getProfileImageError(error));
      });
  };
};
