import { useRef, useEffect } from 'react';
import { default as ReactSelect } from 'react-select';
import DropdownIndicator from '../DropdownIndicator';
import styles from './Select.module.scss';

function useReactSelectFocusFix() {
  const selectRef = useRef();
  useEffect(() => {
    if (selectRef.current && selectRef.current.select) {
      selectRef.current.select.getNextFocusedOption = () => null;
    }
  }, [selectRef]);
  return selectRef;
}

const Select = (props) => {
  const ref = useReactSelectFocusFix();

  return (
    <div className={`${styles.wrapper} ${props.disabled ? styles.disabled : ''}`}>
      {props.title && <h4>{props.title}</h4>}
      <ReactSelect {...props} ref={ref} components={{ DropdownIndicator }} />
    </div>
  );
};

export default Select;
