import * as types from '../constants/actionTypes';
import { NULL_GUID, HIDDEN_AUTHORITIES } from '../constants';

const initialState = {
  regions: [],
  sources: ['EuroAccident', 'MinDoktor'],
  armCircumferenceIntervals: [
    {
      id: '==S',
      name: 'Small (18-21 cm)'
    },
    {
      id: '==M',
      name: 'Medium (22-32 cm)'
    },
    {
      id: '==L',
      name: 'Large (33-42 cm)'
    },
    {
      id: '==XL',
      name: 'XLarge (43-56 cm)'
    },
    {
      id: '==NA',
      name: 'Inget alternativ passar'
    },
    {
      id: '=n=null',
      name: 'Ingen mätarstorlek angiven'
    }
  ],
  authorityTypes: {},
  caregivers: [],
  nurses: [],
  authorities: [],
  doctors: []
};

const sharedDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_REGIONS_REQUEST:
      return { ...state, fetchingRegions: true };
    case types.GET_REGIONS_SUCCESS:
      return {
        ...state,
        fetchingRegions: false,
        regions: [
          ...action.payload,
          { id: '=n=null', name: 'Ingen region vald' },
          { id: `=nn=null;region.id!=${NULL_GUID}`, name: 'Någon region vald' }
        ],
        regionsError: undefined
      };
    case types.GET_REGIONS_ERROR:
      return { ...state, fetchingRegions: false, regionsError: action.payload };
    case types.GET_AUTHORITIES: {
      const authorities = action.payload.filter((authority) => HIDDEN_AUTHORITIES.indexOf(authority) === -1).sort();
      const authorityTypes = {};
      authorities.forEach((authority) => (authorityTypes[authority] = authority));

      return { ...state, authorities, authorityTypes };
    }
    case types.GET_CAREGIVERS_REQUEST:
      return { ...state, loadingCaregivers: true };
    case types.GET_CAREGIVERS_SUCCESS:
      return {
        ...state,
        loadingCaregivers: false,
        caregivers: action.payload.members.map((member) => {
          return { ...member.user, ...member.systemUserExtras, caregiverRole: 'doctor' };
        }),
        caregiversError: undefined
      };
    case types.GET_CAREGIVERS_ERROR:
      return { ...state, loadingCaregivers: false, caregiversError: action.payload };
    case types.GET_NURSES_REQUEST:
      return { ...state, loadingNurses: true };
    case types.GET_NURSES_SUCCESS:
      return {
        ...state,
        loadingNurses: false,
        nurses: action.payload.members.map((member) => {
          return { ...member.user, ...member.systemUserExtras, caregiverRole: 'nurse' };
        }),
        nursesError: undefined
      };
    case types.GET_NURSES_ERROR:
      return { ...state, loadingNurses: false, nursesError: action.payload };
    case types.GET_ALL_DOCTORS_REQUEST:
      return { ...state, fetchingAllDoctors: true };
    case types.GET_ALL_DOCTORS_SUCCESS:
      return {
        ...state,
        fetchingAllDoctors: false,
        doctors: action.payload.members.map((m) => ({
          label: `${m.user.givenName} ${m.user.familyName}`,
          value: m.user.guid
        })),
        fetchAllDoctorsError: undefined
      };
    case types.GET_ALL_DOCTORS_ERROR:
      return { ...state, fetchingAllDoctors: false, fetchAllDoctorsError: action.payload };
    default:
      return state;
  }
};

export default sharedDataReducer;
