import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingIcon from '../LoadingIcon';
import { Translate, I18n } from 'react-redux-i18n';
import Select from '../Select';

const EditableSelect = ({
  link,
  text,
  options,
  isEditing,
  isEditable,
  onChange,
  error,
  errorI18nKey,
  isLoadingOptions,
  requiredAuthority,
  authorities,
  placeholder,
  defaultValue
}) => {
  if (isEditing && error) {
    return (
      <div className="error-message">
        <Translate value={errorI18nKey} />
      </div>
    );
  }

  if (requiredAuthority && authorities.indexOf(requiredAuthority) === -1) {
    return text || '-';
  }

  return isLoadingOptions ? (
    <LoadingIcon size="small" />
  ) : isEditing && isEditable ? (
    <Select
      isSearchable={false}
      classNamePrefix="single-select"
      onChange={onChange}
      options={options}
      defaultValue={options.find((option) => option.value === defaultValue)}
      placeholder={placeholder || I18n.t('global.choose')}
    />
  ) : (
    <div>{link ? <Link to={link}>{text}</Link> : text || '-'}</div>
  );
};

const mapStateToProps = (state) => {
  return {
    authorities: state.auth.authorities
  };
};

export default connect(mapStateToProps)(EditableSelect);
