import { useState, useEffect } from 'react';

type ApiMethod<T> = () => Promise<T>;

export function useDataFetcher<T>(apiMethod: ApiMethod<T>, intialValue) {
  const [data, setData] = useState<T>(intialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setIsLoading(true);
    apiMethod()
      .then((res) => setData(res))
      .catch((e) => setError(e))
      .finally(() => setIsLoading(false));
  }, []);

  return { data, setData, isLoading, error };
}
