import * as types from '../constants/actionTypes';
import { HTTP_STATUS_CODES } from '../constants';

const sortByDate = (a, b) => {
  if (a.createdDate === b.createdDate) {
    return 0;
  }

  if (a.createdDate > b.createdDate) {
    return -1;
  }

  return 1;
};

const initialState = {
  creatingIssue: false,
  jiraIssues: [],
  modalVisible: false,
  historyModalVisible: false,
  selectedSupportType: null,
  selectedSupportTypeId: '0',
  issueDescription: '',
  issueSummary: '',
  issueTypes: []
};

const jiraReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MEMBER_JIRA_ISSUES_REQUEST:
      return { ...state, loadingJiraIssues: true };
    case types.GET_MEMBER_JIRA_ISSUES_SUCCESS:
      return {
        ...state,
        loadingJiraIssues: false,
        jiraIssues: action.payload.sort(sortByDate),
        jiraIssuesError: undefined
      };
    case types.GET_MEMBER_JIRA_ISSUES_ERROR:
      return {
        ...state,
        loadingJiraIssues: false,
        jiraIssuesError:
          action.payload && action.payload.status !== HTTP_STATUS_CODES.NOT_FOUND ? action.payload : undefined
      };
    case types.POST_MEMBER_JIRA_ISSUE_REQUEST:
      return { ...state, creatingIssue: true };
    case types.POST_MEMBER_JIRA_ISSUE_SUCCESS:
      return {
        ...state,
        creatingIssue: false,
        modalVisible: false,
        jiraIssues: [action.payload, ...state.jiraIssues],
        createIssueError: undefined,
        selectedSupportType: null,
        selectedSupportTypeId: 0
      };
    case types.POST_MEMBER_JIRA_ISSUE_ERROR:
      return { ...state, creatingIssue: false, createIssueError: action.payload };
    case types.SHOW_JIRA_MODAL:
      return { ...state, modalVisible: true };
    case types.HIDE_JIRA_MODAL:
      return { ...state, modalVisible: false };
    case types.SET_JIRA_ISSUE_TYPE: {
      const selectedSupportType = state.issueTypes.find((type) => type.id === action.payload);
      return {
        ...state,
        selectedSupportType,
        selectedSupportTypeId: action.payload
      };
    }
    case types.SET_JIRA_ISSUE_DESCRIPTION:
      return { ...state, issueDescription: action.payload };
    case types.SHOW_JIRA_HISTORY_MODAL:
      return { ...state, historyModalVisible: true };
    case types.HIDE_JIRA_HISTORY_MODAL:
      return { ...state, historyModalVisible: false };
    case types.GET_JIRA_ISSUE_TYPES_REQUEST:
      return { ...state, loadingJiraIssueTypes: true };
    case types.GET_JIRA_ISSUE_TYPES_SUCCESS:
      return {
        ...state,
        loadingJiraIssueTypes: false,
        issueTypes: action.payload,
        jiraIssueTypesError: undefined
      };
    case types.GET_JIRA_ISSUE_TYPES_ERROR:
      return { ...state, loadingJiraIssueTypes: false, jiraIssueTypesError: action.payload };
    case types.CLEAR_CURRENT_MEMBER:
      return initialState;
    default:
      return state;
  }
};

export default jiraReducer;
