import React, { Component } from 'react';
import { connect } from 'react-redux';

class ThemeProvider extends Component {
  render() {
    return <div data-theme={this.props.theme.currentTheme}>{this.props.children}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme
  };
};
export default connect(mapStateToProps)(ThemeProvider);
