import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { DebounceInput } from 'react-debounce-input';
import Select from '../Select';

const JiraSupportModalContent = ({ content, onSelectIssueType, onChangeDescription }) => {
  const options = content.issueTypes.map((issueType) => {
    return { value: issueType.id, label: issueType.name };
  });
  const defaultValue = content.selectedSupportTypeId
    ? options.find((option) => option.value === content.selectedSupportTypeId)
    : null;

  return (
    <div>
      <h2>
        <Translate value="jira.select_issue_type" />
      </h2>
      <div>
        <Select
          isSearchable={false}
          classNamePrefix="single-select"
          onChange={onSelectIssueType}
          options={options}
          defaultValue={defaultValue}
          placeholder={I18n.t('jira.select_issue_type')}
        />
      </div>
      {content.selectedSupportType ? (
        <div className="jira-description">
          <DebounceInput
            placeholder={I18n.t('jira.description')}
            element="textarea"
            maxLength="2000"
            debounceTimeout={300}
            value={content.issueDescription}
            onChange={onChangeDescription}
          />
        </div>
      ) : null}
    </div>
  );
};

export default JiraSupportModalContent;
