export const APPLICATION_BASE_PATH = 'app';
export const APP_TITLE = 'Admin';

export const CURRENT_ENV = 'staging';

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sssZ';

export const MEMBER_SEARCH_LIMIT = 15;
export const MEMBER_SEARCH_PAGE_WINDOW_SIZE = 5;

export const REFERRAL_PRINT_OFFSET_SETTINGS_STORAGE_KEY = 'referralPrintOffsetSettings';

export const LOGIN_AUTHORITY = 'loginAdmin';
export const HIDDEN_AUTHORITIES = ['member', 'admin', 'sonat', 'kiosk'];

export const ARM_CIRCUMFERENCE_ID = 150;

export const NULL_GUID = '00000000-0000-0000-0000-000000000000';
export const REGION_SKANE_ID = '05d07812-7cc0-11e9-9348-342eb68fac74';

export const HTTP_STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  CONFLICT: 409
};

export const COMMENT_COLORS = ['#badc58', '#74b9ff', '#95afc0', '#ff7979', '#fdcb6e'];

export const COMMUNICATION_POSTPONE_DAYS = 7;

export const MEMBER_SERVICE_STATUS = {
  HIDDEN: 'hidden',
  ACTIVE: 'active',
  BLOCKED: 'blocked',
  NURSE: 'nurse',
  DOCTOR: 'doctor'
};

export const DOCTORS_ON_LEGACY_REMUNERATION_SCHEME = [
  'bbb7cfa0-bad9-44be-a86b-32449c530f63',
  '1d56e242-36ce-4590-9d6b-058fb2347a48',
  '72d828cc-da32-4d96-9805-96e614c0f163',
  'e24db723-c404-410c-a8f6-5a12a508a19e',
  '2f4ed253-5acc-11e9-9f54-06c074dbaa4c',
  '2d9873db-0264-4a0d-97af-94b99e5f0ad3',
  '8a546e44-7322-4562-ab00-67238bb2f81a',
  'c5e9681d-86a0-11e9-8508-0271a0fb1e5a',
  '96510c30-3160-11ea-8318-0271a0fb1e5a',
  'bc7a7f6e-3326-4295-9e4d-6e74b2eff3db'
];

export const FINAL_NOTE_LEGACY_COST = 200;
export const FINAL_NOTE_FALLBACK_REMUNERATION = 150;

export const DOCTOR_NOTE_TYPES = [
  'newMemberCheckup',
  'threeMonthCheckup',
  'yearlyCheckup',
  'extraCheckup',
  'sixWeekCheckup',
  'initialCheckup',
  'diagnosisScreening',
  'examinationFollowUp'
];

export const VAT_RATE = 1.25;

export const API_ERROR_CODES = {
  1: 'server_error.db_access_error',
  2: 'server_error.internal',
  3: 'server_error.unknown_user',
  4: 'server_error.insufficient_privileges',
  5: 'server_error.authentication_error',
  6: 'server_error.user_canceled_login',
  7: 'server_error.login_already_in_process',
  8: 'server_error.external_service_timeout',
  9: 'server_error.not_implemented',
  10: 'server_error.invalid_data_posted',
  11: 'server_error.missing_data',
  12: 'server_error.id_already_exists',
  401: 'server_error.unauthorized'
};

export const CAREGIVER_ROLE = {
  DOCTOR: 'doctor',
  NURSE: 'nurse'
};

export const PATIENT_STATE = {
  BOTTOM_FUNNEL_PAUSED: 'BOTTOM_FUNNEL_PAUSED',
  BOTTOM_FUNNEL_DONE: 'BOTTOM_FUNNEL_DONE',
  DEACTIVATION_FUNNEL_PAUSED: 'PAUSED'
};

export const DEACTIVATION_FUNNEL_STATE = {
  EMAIL_SENT: 'EMAIL_SENT',
  EMAIL_RETURN: 'EMAIL_RETURN',
  EMAIL_PURCHASE: 'EMAIL_PURCHASE',
  PHYSICAL_RETURN_INITIATED: 'PHYSICAL_RETURN_INITIATED',
  INVOICED: 'INVOICED',
  PAUSED: 'PAUSED',
  DONE: 'DONE',
  ABORTED: 'ABORTED'
};

export const BOTTOM_FUNNEL_STATE = {
  NO_MEASUREMENT_START: 'NO_MEASUREMENT_START',
  NO_MEASUREMENT_REMINDER_1: 'NO_MEASUREMENT_REMINDER_1',
  NO_MEASUREMENT_REMINDER_2: 'NO_MEASUREMENT_REMINDER_2',
  NO_MEASUREMENT_REMINDER_3: 'NO_MEASUREMENT_REMINDER_3',
  NO_MEASUREMENT_REMINDER_4: 'NO_MEASUREMENT_REMINDER_4',
  NO_MEASUREMENT_REMINDER_5: 'NO_MEASUREMENT_REMINDER_5',
  NO_MEASUREMENT_REMINDER_6: 'NO_MEASUREMENT_REMINDER_6',
  NO_MEASUREMENT_REMINDER_7: 'NO_MEASUREMENT_REMINDER_7',
  MEASUREMENT_REMINDER_1: 'MEASUREMENT_REMINDER_1',
  MEASUREMENT_REMINDER_2: 'MEASUREMENT_REMINDER_2',
  MEASUREMENT_REMINDER_3: 'MEASUREMENT_REMINDER_3',
  MEASUREMENT_REMINDER_4: 'MEASUREMENT_REMINDER_4',
  RETURN_NOTIFICATION: 'RETURN_NOTIFICATION',
  RETURN_INITIATED: 'RETURN_INITIATED',
  MONITOR_INVOICED: 'MONITOR_INVOICED',
  BOTTOM_FUNNEL_PAUSED: 'BOTTOM_FUNNEL_PAUSED',
  BOTTOM_FUNNEL_DONE: 'BOTTOM_FUNNEL_DONE'
};

export const JIRA_RESOLVED_STATUSES = ['Resolved', 'Closed', 'Canceled'];

export const CHAT_THUMBNAIL_WIDTH = 150;

export const WEB_SCREENING_DEFAULTS = {
  startPage: {
    heading: {
      sv: 'Testa om du är i riskzonen',
      en: "Check if you're at risk"
    },
    text: {
      sv: 'Visste du att ungefär 1 miljon personer i Sverige lever med högt blodtryck utan att veta om det, eftersom det sällan ger några symtom? Att ha koll på ditt blodtryck kan göra stor skillnad för din hälsa, särskilt eftersom högt blodtryck är den största riskfaktorn för hjärtinfarkt och stroke.\n\nGör testet för att se din risk för högt blodtryck.',
      en: 'Did you know that about 1 million people in Sweden live with high blood pressure without realising it, as it rarely shows any symptoms? Monitoring your blood pressure can make a significant difference to your health, especially since high blood pressure is the primary risk factor for heart attacks and strokes.\n\nTake the test to assess your risk for high blood pressure.'
    }
  },
  mainCta: {
    url: '',
    text: {
      sv: 'Kom igång',
      en: 'Get started'
    }
  },
  highRisk: {
    heading: {
      sv: 'Ta reda på om du har högt blodtryck',
      en: 'Find out if you have high blood pressure'
    },
    text: {
      sv: 'Genom att ladda ner Blodtrycksdoktorns app kan du på ett enkelt och bekvämt sätt få hjälp med att utreda om du har högt blodtryck. Du gör dina blodtrycksmätningar hemma i lugn och ro och får dina första resultat i mobilen inom en vecka.',
      en: 'By downloading the Blodtrycksdoktorn app, you can easily and comfortably get assistance to investigate if you have high blood pressure. Measure your blood pressure at home and get your initial results on your phone within a week.'
    }
  }
};
