import React from 'react';
import './LoadingIcon.scss';

type Props = {
  size?: string;
  type?: 'icon' | 'spinner' | 'spinner-secondary';
};

const LoadingIcon: React.FC<Props> = ({ size = '', type = 'spinner-secondary' }: Props) => {
  return <span className={`loading-icon ${size} ${type}`}></span>;
};

export default LoadingIcon;
