import React, { Fragment, useEffect } from 'react';
import moment from 'moment';
import { Translate, I18n } from 'react-redux-i18n';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import LoaderButton from '../LoaderButton';
import api from '../../api/apiClient';
import { REFERRAL_PRINT_OFFSET_SETTINGS_STORAGE_KEY, DATE_FORMAT, REGION_SKANE_ID } from '../../constants';
import CanPerform from '../CanPerform';
import Card from '../Card';
import Modal from '../Modal';
import { saveState } from '../../services/localStorage';
import {
  showOffsetSettings,
  hideOffsetSettings,
  setReferralPrintOffset,
  getReferralDocumentsRequest,
  getReferralDocumentsSuccess,
  getReferralDocumentsError,
  getReferralTestDocumentsRequest,
  getReferralTestDocumentsSuccess,
  getReferralTestDocumentsError,
  toggleReferral,
  getReferrals,
  showNotification,
  toggleAllReferrals
} from '../../actions';
import ContentLoader from '../ContentLoader';
import { useIndeterminateCheckbox } from '../../hooks/useIndeterminateCheckbox';

const ReferralsManagementView = ({
  authToken,
  reports,
  showOffsetSettings,
  hideOffsetSettings,
  setReferralPrintOffset,
  getReferralDocumentsRequest,
  getReferralDocumentsSuccess,
  getReferralDocumentsError,
  getReferralTestDocumentsRequest,
  getReferralTestDocumentsSuccess,
  getReferralTestDocumentsError,
  getReferrals,
  toggleReferral,
  showNotification,
  toggleAllReferrals
}) => {
  const selectAllCheckbox = useIndeterminateCheckbox(reports.referrals, reports.selectedReferrals);

  useEffect(() => {
    getReferrals(authToken, REGION_SKANE_ID);
  }, [authToken, getReferrals]);

  const saveOffsetSettings = () => {
    saveState(reports.offsetSettings, REFERRAL_PRINT_OFFSET_SETTINGS_STORAGE_KEY);
    hideOffsetSettings();
  };

  const downloadReferralDocuments = () => {
    getReferralDocumentsRequest();

    api
      .getReferralDocuments(authToken, reports.offsetSettings, reports.selectedReferrals)
      .then((data) => {
        getReferralDocumentsSuccess(data.response, reports.selectedReferrals);
        const blob = new Blob([data.response.body], {
          type: 'application/zip'
        });
        saveAs(blob, `Region_Skåne-remisser_${moment().format('YYYY-MM-DD')}.zip`);
      })
      .catch((error) => {
        console.log(error);
        getReferralDocumentsError(error);
        showNotification(I18n.t('reports_view.referrals.error'), 'error');
      });
  };

  const downloadTestReferralDocuments = () => {
    getReferralTestDocumentsRequest();

    api
      .getReferralDocuments(authToken, reports.offsetSettings, [], true)
      .then((data) => {
        console.log('resp', data.response);
        getReferralTestDocumentsSuccess(data.response);
        const blob = new Blob([data.response.body], {
          type: 'application/zip'
        });
        saveAs(blob, `testremiss.zip`);
      })
      .catch((error) => {
        console.log(error);
        getReferralTestDocumentsError(error);
      });
  };

  return (
    <Fragment>
      <CanPerform action="managePatients">
        <Card
          titleI18nKey="reports_view.referrals.header"
          additionalClass="large"
          corner={
            <span className="text-button fw-normal" onClick={showOffsetSettings}>
              <Translate value="reports_view.referrals.configure" />
            </span>
          }
        >
          <ContentLoader
            isLoading={reports.loadingReferrals}
            error={reports.referralsError}
            errorTitleI18n="reports_view.referrals.get_error"
            type="spinner-secondary"
          >
            <div className="checkbox-list">
              <div className="space-between pl-5 pb-10 fs-14 usn">
                <div>
                  <input
                    ref={selectAllCheckbox}
                    type="checkbox"
                    checked={reports.selectedReferrals.length === reports.referrals.length}
                    onChange={toggleAllReferrals}
                    readOnly
                    className="mr-10"
                    id="select-all-shipments"
                  />
                  <label htmlFor="select-all-shipments">Markera alla</label>
                </div>
              </div>
              {reports.referrals.length ? (
                reports.referrals.map((referral) => (
                  <ReferralRow
                    key={referral.id}
                    {...referral}
                    disabled={reports.disabledReferrals.includes(referral.id)}
                    onClick={() => toggleReferral(referral.id)}
                    isChecked={reports.selectedReferrals.includes(referral.id)}
                  />
                ))
              ) : (
                <Translate value="reports_view.referrals.no_referrals" />
              )}
            </div>
          </ContentLoader>
          <div className="mt-20">
            <LoaderButton
              disabled={!reports.selectedReferrals.length}
              onClick={downloadReferralDocuments}
              isLoading={reports.loadingReferralDocuments}
            >
              Generera utskrifter
            </LoaderButton>
          </div>
        </Card>
      </CanPerform>
      <Modal
        visible={reports.offsetSettingsVisible}
        actionCompletable={true}
        headerI18nKey="reports_view.referrals.modal_header"
        actionI18nKey="reports_view.referrals.save_settings"
        onClose={hideOffsetSettings}
        onActionCompleted={saveOffsetSettings}
      >
        <div className="columns">
          <div className="column no-padding mr-20">
            <h3>
              <Translate value="reports_view.referrals.rutin" />
            </h3>
            <h4>
              <Translate value="reports_view.referrals.x_offset" />
            </h4>
            <input
              type="number"
              step="0.1"
              value={reports.offsetSettings.rutin.x}
              onChange={setReferralPrintOffset('rutin', 'x')}
            />
            <h4>
              <Translate value="reports_view.referrals.y_offset" />
            </h4>
            <input
              type="number"
              step="0.1"
              value={reports.offsetSettings.rutin.y}
              onChange={setReferralPrintOffset('rutin', 'y')}
            />
          </div>
          <div className="column no-padding mr-20">
            <h3>
              <Translate value="reports_view.referrals.special" />
            </h3>
            <h4>
              <Translate value="reports_view.referrals.x_offset" />
            </h4>
            <input
              type="number"
              step="0.1"
              value={reports.offsetSettings.special.x}
              onChange={setReferralPrintOffset('special', 'x')}
            />
            <h4>
              <Translate value="reports_view.referrals.y_offset" />
            </h4>
            <input
              type="number"
              step="0.1"
              value={reports.offsetSettings.special.y}
              onChange={setReferralPrintOffset('special', 'y')}
            />
          </div>
        </div>
        <div className="mt-15">
          <span className="text-button" onClick={downloadTestReferralDocuments}>
            <Translate value="reports_view.referrals.test_referral" />
          </span>
        </div>
        {reports.referralTestDocumentsError ? (
          <div className="error-message">
            <Translate value="reports_view.referrals.test_error" />
          </div>
        ) : null}
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    reports: state.reports
  };
};

const mapActionsToProps = {
  showOffsetSettings,
  hideOffsetSettings,
  setReferralPrintOffset,
  getReferralDocumentsRequest,
  getReferralDocumentsSuccess,
  getReferralDocumentsError,
  getReferralTestDocumentsRequest,
  getReferralTestDocumentsSuccess,
  getReferralTestDocumentsError,
  toggleReferral,
  getReferrals,
  showNotification,
  toggleAllReferrals
};

export default connect(mapStateToProps, mapActionsToProps)(ReferralsManagementView);

const ReferralRow = ({ user, createdDate, isChecked, onClick, disabled }) => {
  let address;
  if (user.streetaddress && user.postalCode && user.city) {
    address = `${user.streetaddress} ${user.postalCode} ${user.city}`;
  }
  return (
    <div className={`checkbox-row ${disabled ? 'disabled' : ''}`} onClick={!disabled ? onClick : undefined}>
      <div className="column is-1 no-padding">
        <input type="checkbox" checked={isChecked} readOnly disabled={disabled} />
      </div>
      <div className="column is-2 no-padding" title={`${user.givenName} ${user.familyName}`}>
        {`${user.givenName} ${user.familyName}`}
      </div>
      <div className="column is-2 no-padding">{`${user.personalNumber}`}</div>
      <div className="column no-padding text-right" title={address || I18n.t('global.no_address')}>
        {address || I18n.t('global.no_address')}
      </div>
      <div className="column is-3 no-padding text-right">
        {moment(createdDate, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}
      </div>
    </div>
  );
};
