import * as types from '../constants/actionTypes';

const notificationReducer = (state = { message: '', notificationType: '', open: false }, action) => {
  switch (action.type) {
    case types.SHOW_NOTIFICATION_MESSAGE:
      return { ...state, message: action.message, notificationType: action.notificationType, open: true };
    case types.HIDE_NOTIFICATION_MESSAGE:
      return { ...state, open: false };
    case types.CLEAR_NOTIFICATION_MESSAGE:
      return { ...state, message: '', notificationType: '' };
    default:
      return state;
  }
};

export default notificationReducer;
