// @ts-nocheck
import { Switch, NavLink } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { APPLICATION_BASE_PATH } from '../../constants';
import AbsenceList from './AbsenceList';
import AbsenceProvider from './AbsenceProvider';
import CreateAbsence from './CreateAbsence';
import PrivateRoute from '../PrivateRoute';

const routes = [
  {
    path: `/${APPLICATION_BASE_PATH}/planning/manage-absence`,
    component: AbsenceList,
    breadcrumb: () => <Translate value="planning_view.page.absence_management" />
  },
  {
    path: `/${APPLICATION_BASE_PATH}/planning/manage-absence/new`,
    component: CreateAbsence,
    breadcrumb: () => <Translate value="manage_absence.create_heading" />
  },
  {
    path: `/${APPLICATION_BASE_PATH}/planning/manage-absence/:absenceId/edit`,
    component: () => <CreateAbsence editMode />,
    breadcrumb: () => <Translate value="manage_absence.edit_heading" />
  }
];

const Breadcrumbs = withBreadcrumbs(routes, { disableDefaults: true })(({ breadcrumbs }) => {
  return (
    <div className="breadcrumb-wrapper">
      {breadcrumbs.map(({ match, breadcrumb }, i) => (
        <span key={match.url}>
          {i === breadcrumbs.length - 1 ? <span>{breadcrumb}</span> : <NavLink to={match.url}>{breadcrumb}</NavLink>}
        </span>
      ))}
    </div>
  );
});

export default function ManageAbsenceView() {
  return (
    <AbsenceProvider>
      <Breadcrumbs />
      <Switch>
        {routes.map((route) => (
          <PrivateRoute key={route.path} path={route.path} component={route.component} exact />
        ))}
      </Switch>
    </AbsenceProvider>
  );
}
