import { I18n, Translate } from 'react-redux-i18n';
import SkeletonComponent from '../../../SkeletonComponent';

type Props = {
  caregivers: HistoricalCaregiver[];
  isLoading: boolean;
  type: 'doctor' | 'nurse';
};

export default function CaregiverHistoryList({ caregivers, isLoading, type }: Props) {
  if (isLoading) {
    return <SkeletonComponent width="100%" height="43px" count={2} />;
  }

  if (!caregivers.length) {
    return (
      <div>
        <Translate value={`member_details.caregiver_history.${type}.empty`} />
      </div>
    );
  }

  return (
    <div style={{ '--column-count': 3 } as React.CSSProperties}>
      <div className="row heading">
        <div>Vårdgivare</div>
        <div>Varaktighet</div>
        <div>Period</div>
      </div>
      {caregivers.map((item, i) => (
        <div className="row" key={i}>
          <div>
            {item.caregiver.givenName} {item.caregiver.familyName}
          </div>
          <div>
            <Translate value={`global.previous_caregiver.${item.type.toLowerCase()}`} />
          </div>
          <div>
            <Translate
              value="member_details.caregiver_history.period"
              from={item.from || I18n.t('member_details.caregiver_history.unknown_date')}
              to={item.to}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
