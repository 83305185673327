import React from 'react';
import { Translate } from 'react-redux-i18n';
import './Card.scss';
import LoadingIcon from '../LoadingIcon';
import ErrorMessage from '../ErrorMessage';
import SkeletonComponent from '../SkeletonComponent';

type CardProps = {
  title?: string | React.ReactNode;
  titleI18nKey?: string;
  descriptionI18nKey?: string;
  description?: string;
  children: React.ReactNode;
  additionalClass?: string;
  isLoading?: boolean;
  corner?: React.ReactNode;
  actionButtons?: React.ReactNode;
  error?: unknown;
  errorI18nKey?: string;
  loadingTitle?: boolean;
};

const Card = ({
  title,
  titleI18nKey,
  descriptionI18nKey,
  description,
  children,
  additionalClass,
  isLoading,
  corner,
  actionButtons,
  error,
  errorI18nKey,
  loadingTitle
}: CardProps) => {
  return (
    <div className={`card ${additionalClass || ''}`}>
      {titleI18nKey || title ? (
        <div className="card-header">
          <div className="columns is-mobile">
            <div className="column no-padding space-between">
              {loadingTitle ? (
                <SkeletonComponent width="50%" height="22px" />
              ) : titleI18nKey ? (
                <Translate value={titleI18nKey} />
              ) : (
                title
              )}
              {actionButtons ? <span className="card-actions">{actionButtons}</span> : null}
            </div>
            {isLoading !== undefined ? (
              <div className="column is-2 no-padding right-align">
                {isLoading ? <LoadingIcon type="spinner-secondary" size="small" /> : null}
              </div>
            ) : null}
            {corner && corner}
          </div>
        </div>
      ) : null}
      {descriptionI18nKey && (
        <div className="card-description">
          <Translate value={descriptionI18nKey} />
        </div>
      )}
      {description && <div className="card-description">{description}</div>}
      <div className="card-content">
        {error ? <ErrorMessage error={error} errorI18nKey={errorI18nKey} /> : children}
      </div>
    </div>
  );
};

export default Card;
