import { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import Modal from '../Modal';
import api from '../../api/apiClient';
import { loginRequest, loginSuccess, loginError } from '../../actions';
import { APPLICATION_BASE_PATH, API_ERROR_CODES } from '../../constants';
import metadata from '../../metadata.json';
import LoadingIcon from '../LoadingIcon';
import Button from '../Button';
import './LoginView.scss';

const allowCredentialsLogin =
  process.env.NODE_ENV === 'development' || process.env.REACT_APP_CREDENTIALS_LOGIN === 'enabled';

const POLL_RATE_IN_MS = 1000;

const getTimeUntilNextRequest = (requestTime) => {
  return POLL_RATE_IN_MS - requestTime > 0 ? POLL_RATE_IN_MS - requestTime : 0;
};

const LoginView = (props) => {
  const [loginState, _setLoginState] = useState('idle');
  const loginStateRef = useRef('idle');
  const [credentialLoginModalVisible, setCredentialLoginModalVisible] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [activeQrCode, setActiveQrCode] = useState('');
  const timeoutRef = useRef();

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    if (props.loginError) {
      setLoginState('error');
    }
  }, [props.loginError]);

  const setLoginState = (newState) => {
    _setLoginState(newState);
    loginStateRef.current = newState;
  };

  const beginLoginFlow = async () => {
    setLoginState('starting');
    try {
      const start = new Date();
      const response = await api.beginLoginFlow();
      const finish = new Date();
      setActiveQrCode(window.atob(response.body.QRCode));
      setLoginState('polling');
      setTimeout(() => {
        refreshQrCode(response.body.sessionId);
      }, getTimeUntilNextRequest(finish - start));
    } catch (err) {
      setLoginState('error');
      props.onLoginError(err);
    }
  };

  const refreshQrCode = async (sessionId) => {
    if (!sessionId) {
      return;
    }

    try {
      const start = new Date();
      const response = await api.refreshQrCode(sessionId);
      const finish = new Date();
      const status = response.body.sessionResponse?.grandidObject?.message?.status;
      const hintCode = response.body.sessionResponse?.grandidObject?.message?.hintCode;

      if (hintCode === 'userSign') {
        setLoginState('user_signing');
      }

      if (status === 'failed') {
        if (hintCode === 'userCancel') {
          setLoginState('idle');
        } else {
          props.onLoginError(I18n.t('login_view.generic_error'));
          setLoginState('error');
        }
        return;
      }

      if (status === 'succeeded' && response.body.loginResponse) {
        setLoginState('authenticated');
        props.onLoginSuccess(response.body.loginResponse);
        return;
      }

      setActiveQrCode(window.atob(response.body.sessionResponse.grandidObject.QRCode));

      if (['polling', 'user_signing'].includes(loginStateRef.current)) {
        timeoutRef.current = setTimeout(() => {
          refreshQrCode(sessionId);
        }, getTimeUntilNextRequest(finish - start));
      }
    } catch (err) {
      console.log(err);
      setLoginState('error');
      props.onLoginError(err);
      clearTimeout(timeoutRef.current);
    }
  };

  const cancelLogin = () => {
    setLoginState('idle');
    setActiveQrCode('');
    clearTimeout(timeoutRef.current);
  };

  const retry = () => {
    setLoginState('idle');
  };

  const logInTest = async () => {
    props.onLoginRequest();
    setLoginState('starting');
    setCredentialLoginModalVisible(false);
    const credentials = { identifier: username, password, method: 'credentials' };

    try {
      const response = await api.logInTest(credentials);
      props.onLoginSuccess(response);
    } catch (error) {
      props.onLoginError(error);
    }
  };

  const { from } = props.location.state || { from: { pathname: `/${APPLICATION_BASE_PATH}` } };

  if (props.redirectToReferrer && !props.loginError) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <div className="login-wrapper">
        <div className="login-container">
          <div className="login-form">
            {loginState === 'idle' ? (
              <Button onClick={beginLoginFlow} className="login-button">
                <Translate value="login_view.start_login" />
              </Button>
            ) : null}

            {loginState === 'starting' ? (
              <div className="flex justify-center mt-10">
                <LoadingIcon />
              </div>
            ) : null}

            {loginState === 'polling' && activeQrCode ? (
              <>
                <div className="flex justify-center" dangerouslySetInnerHTML={{ __html: activeQrCode }} />
                <div className="text-left">
                  <Translate value="login_view.instruction1" dangerousHTML />
                </div>
                <div className="mt-40">
                  <Button buttonType="text" onClick={cancelLogin}>
                    <Translate value="login_view.cancel_login" />
                  </Button>
                </div>
              </>
            ) : null}

            {loginState === 'user_signing' ? (
              <div>
                <p>
                  <Translate value="login_view.instruction2" dangerousHTML />
                </p>
                <div className="flex justify-center">
                  <LoadingIcon />
                </div>
              </div>
            ) : null}

            {loginState === 'error' ? (
              <>
                <div className="error-message">
                  {props.loginError && props.loginError.response ? (
                    <Translate
                      value={
                        API_ERROR_CODES[JSON.parse(props.loginError.response.text).code] || 'server_error.fallback'
                      }
                    />
                  ) : typeof props.loginError === 'string' ? (
                    <span>{props.loginError}</span>
                  ) : (
                    <Translate value="server_error.connectivity" />
                  )}
                </div>
                <Button onClick={retry} buttonType="secondary" className="mt-20">
                  <Translate value="global.buttons.retry" />
                </Button>
              </>
            ) : null}
          </div>
        </div>
        <footer className="login-footer">
          <div className="footer-logo"></div>
          <strong>Blodtrycksdoktorn AB</strong>
          <Translate value="login_view.footer_info" />
        </footer>
      </div>
      <div className="build-number">
        <div>
          Version:{' '}
          <span
            onClick={allowCredentialsLogin ? () => setCredentialLoginModalVisible(true) : undefined}
            className={allowCredentialsLogin ? 'pointer' : undefined}
          >
            {metadata.version}
          </span>{' '}
          (Build: #{process.env.REACT_APP_BITRISE_BUILD_NUMBER || 0})
        </div>
      </div>
      <Modal
        visible={credentialLoginModalVisible}
        actionCompletable={!!(username.length && password.length)}
        size="x-small"
        headerI18nKey="global.login"
        actionI18nKey="global.login"
        onClose={() => setCredentialLoginModalVisible(false)}
        onActionCompleted={logInTest}
        allowEnterCompletion={true}
      >
        <div className="columns">
          <div className="column no-padding mb-20">
            <input
              placeholder="E-postadress"
              autoFocus
              type="text"
              className="w-100"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column no-padding">
            <input
              placeholder="Lösenord"
              type="password"
              className="w-100"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    redirectToReferrer: state.auth.redirectToReferrer,
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.error
  };
};

const mapActionsToProps = {
  onLoginRequest: loginRequest,
  onLoginSuccess: loginSuccess,
  onLoginError: loginError
};

export default connect(mapStateToProps, mapActionsToProps)(LoginView);
