import { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import saveAs from 'file-saver';
import Card from '../../Card';
import SkeletonComponent from '../../SkeletonComponent';
import { PartnerUser, usePartner } from '../PartnerProvider';
import styles from './PartnerUserList.module.scss';
import { Translate, I18n } from 'react-redux-i18n';
import ErrorMessage from '../../ErrorMessage';
import { decamelize } from '../../../utils';
import Button from '../../Button';

type UserListProps = {
  partnerId: string;
  users?: PartnerUser[];
  isLoading: boolean;
  facilityId?: string;
  error?: unknown;
};

type UserRowProps = {
  partnerId: string;
  user: PartnerUser;
  facilityId?: string;
};

type UserUpdateState = {
  updatedUser?: boolean;
  newUser?: boolean;
  removedUser?: boolean;
};

const UserRow = ({ partnerId, facilityId, user }: UserRowProps) => {
  const properties = user.properties || [];

  /*
    The auscultationOnboarding property doesn't exist if the user hasn't yet submitted a test auscultation.
    Add a synthetic property for users with the auscultation service but with no onboarding status.
    */
  if (
    !properties.map((p) => p.key).includes('auscultationOnboarding') &&
    user.services
      ?.filter((s) => s.selected)
      ?.map((s) => s.service)
      ?.includes('auscultation')
  ) {
    properties.push({ key: 'auscultationOnboarding', value: 'unsubmitted' });
  }

  return (
    <div className="partner-row">
      <NavLink to={facilityId ? `${facilityId}/user/${user.id}` : `${partnerId}/user/${user.id}`}>
        <div className={styles.row}>
          <div className={styles.name}>{`${user.givenName} ${user.familyName}`}</div>
          <div className="vertical-align">
            {user.services
              ?.filter((s) => s.selected)
              ?.map((s) => s.service)
              ?.includes('admin') ? (
              <span className={styles.adminBadge}>
                <Translate value="manage_partners.service.admin" />
              </span>
            ) : null}
            {user.properties?.length
              ? user.properties.map((property, i) => (
                  <Tooltip
                    key={i}
                    title={
                      <div className="fs-12">
                        <Translate
                          value={`manage_partners.user_property.${decamelize(property.key, '_')}.${property.value}`}
                        />
                      </div>
                    }
                    arrow
                    placement="top"
                  >
                    <span
                      className={`${styles.userPropertyIcon} ${styles[`${property.key}`]} ${
                        styles[`${property.value || 'unsubmitted'}`]
                      }`}
                    ></span>
                  </Tooltip>
                ))
              : null}
          </div>
        </div>
      </NavLink>
    </div>
  );
};

const UserList = ({ partnerId, facilityId, users = [], isLoading }: UserListProps) => {
  if (isLoading) {
    return (
      <>
        {new Array(3).fill(0).map((_, i) => (
          <SkeletonComponent key={i} width="100%" height="38px" />
        ))}
      </>
    );
  }

  return (
    <>
      {users.map((user) => (
        <UserRow partnerId={partnerId} user={user} key={user.id} facilityId={facilityId} />
      ))}
    </>
  );
};

const PartnerUserList = ({ partnerId, users = [], isLoading, facilityId, error }: UserListProps) => {
  const { state } = useLocation<UserUpdateState>();
  const { toast, getPartnerUserExport } = usePartner();

  useEffect(() => {
    if (state?.updatedUser || state?.newUser || state?.removedUser) {
      toast(
        I18n.t(
          `notification.${state?.updatedUser ? 'update' : state?.newUser ? 'add' : 'remove'}_partner_user.success`
        ),
        'success'
      );
      window.history.replaceState({}, document.title);
    }
  }, []);

  const downloadUsers = async () => {
    try {
      const data = await getPartnerUserExport(partnerId);
      saveAs(data.response.body, `${new Date().toISOString()}-${partnerId.split('-')[0]}.csv`);
    } catch (err) {
      toast(I18n.t('notification.partner_user_export.error'), 'error');
    }
  };

  return (
    <Card
      title={
        <div className="vertical-align">
          <span className="pr-10">{I18n.t(`manage_partners.users.header${!facilityId ? '_global' : ''}`)}</span>
          <Button onClick={downloadUsers} size="small">
            <Translate value="manage_partners.users.export" />
          </Button>
        </div>
      }
      errorI18nKey="manage_partners.error"
      additionalClass="x-large"
      description={`${I18n.t('manage_partners.facilities.num_users', { userCount: users.length })}${
        !facilityId ? ` ${I18n.t('manage_partners.facilities.num_users_global')}` : ''
      }`}
      actionButtons={
        <>
          <NavLink to={facilityId ? `${facilityId}/users/new` : `${partnerId}/users/new`}>
            <div className="vertical-align">
              <span className="icon new-user mr-5"></span>
              <span>
                <Translate value="manage_partners.facilities.add_user" />
              </span>
            </div>
          </NavLink>
        </>
      }
    >
      {error ? (
        <ErrorMessage error={error} errorI18nKey="manage_partners.users.error" />
      ) : (
        <UserList partnerId={partnerId} users={users} isLoading={isLoading} facilityId={facilityId} />
      )}
    </Card>
  );
};

export default PartnerUserList;
