// @ts-nocheck
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import { APPLICATION_BASE_PATH } from '../../constants';
import { clearCurrentSystemUser } from '../../actions';
import SystemUserDetailsView from '../SystemUserDetailsView';
import EditSystemUserDetailsView from '../SystemUserDetailsView/EditSystemUserDetailsView';

const SystemUserDetails = ({ clearCurrentSystemUser }) => {
  useEffect(() => {
    return () => clearCurrentSystemUser();
  }, []);

  return (
    <Switch>
      <PrivateRoute
        path={`/${APPLICATION_BASE_PATH}/user/:memberId/edit`}
        component={EditSystemUserDetailsView}
        requiredAuthority="manageUsers"
      />
      <PrivateRoute
        path={`/${APPLICATION_BASE_PATH}/user/:memberId`}
        component={SystemUserDetailsView}
        requiredAuthority="viewPatients"
      />
    </Switch>
  );
};

const mapActionsToProps = {
  clearCurrentSystemUser
};

export default connect(null, mapActionsToProps)(SystemUserDetails);
