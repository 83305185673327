import React, { Component } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import moment from 'moment';
import { logout } from '../../actions';
import { removeItem } from '../../services/localStorage';
import { DATE_FORMAT, LOGIN_AUTHORITY } from '../../constants';
import CanPerform from '../CanPerform';
import Card from '../Card';
import ErrorFallback from '../ErrorFallback';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);

    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  isLoggedIn() {
    if (
      !this.props.auth ||
      !this.props.auth.token ||
      !this.props.auth.token.jwt ||
      !this.props.auth.authorities ||
      this.props.auth.authorities.indexOf(LOGIN_AUTHORITY) === -1
    ) {
      return false;
    }

    const tokenExpiryDate = moment(this.props.auth.token.expires, DATE_FORMAT);
    const now = moment();

    return now.isBefore(tokenExpiryDate);
  }

  logOut(props) {
    removeItem('auth');
    this.props.logout();

    if (this.props.auth.redirectToReferrer) {
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
    }

    window.location = '/login';

    return null;
  }

  render() {
    const { component: Component, requiredAuthority, ...rest } = this.props;

    return (
      <CanPerform action={requiredAuthority} fallbackComponent={DisallowedRoute}>
        <Route
          {...rest}
          render={(props) =>
            this.isLoggedIn() ? (
              <ErrorBoundary
                fallback={() => (
                  <ErrorFallback>
                    <Translate value="error_boundary.header" />
                    <br />
                    <Translate value="error_boundary.summary" />
                  </ErrorFallback>
                )}
              >
                <Component {...props} />
              </ErrorBoundary>
            ) : (
              this.logOut(props)
            )
          }
        />
      </CanPerform>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapActionsToProps = {
  logout
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(PrivateRoute));

const DisallowedRoute = () => {
  return (
    <Card>
      <Translate value="global.disallowed_route" />
    </Card>
  );
};
