import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import useSWR, { mutate } from 'swr';
import { APPLICATION_BASE_PATH, CAREGIVER_ROLE } from '../../constants';
import Card from '../Card';
import Select from '../Select';
import Button from '../Button';
import styles from './CaregiverAllocationView.module.scss';
import { basePath } from '../../api/apiClient';
import { showNotification } from '../../actions';
import SkeletonComponent from '../SkeletonComponent';
import { Link } from 'react-router-dom';
import useQueryParam from '../../hooks/useQueryParam';

function CaregiverAllocationView({ authToken, showNotification }) {
  const caregiverRoles = [
    {
      label: I18n.t('global.doctor'),
      value: CAREGIVER_ROLE.DOCTOR
    },
    {
      label: I18n.t('global.nurse'),
      value: CAREGIVER_ROLE.NURSE
    }
  ];
  const [role, setRole] = useQueryParam('role', CAREGIVER_ROLE.DOCTOR);
  const selectedRoleFilter = caregiverRoles.find((r) => r.value === role);
  const [pendingUpdates, setPendingUpdates] = useState<Record<string, string>>({});
  const [isEditing, setIsEditing] = useState(false);
  const { data: caregiverAllocations, isLoading } = useSWR<CaregiverAllocationWrapper>(
    `/admin/modules/caregiver-allocation?caregiverRole=${selectedRoleFilter?.value.toUpperCase()}`
  );
  const allocationSum = Object.values(pendingUpdates).reduce((acc, curr) => acc + Number(curr), 0);

  useEffect(() => {
    if (caregiverAllocations?.allocatedCaregivers?.length) {
      setPendingUpdates(
        caregiverAllocations.allocatedCaregivers.reduce(
          (prev, curr) => ({ ...prev, [curr.id]: curr.assignmentPercentage.toString() }),
          {}
        )
      );
    }
  }, [caregiverAllocations]);

  const handlePercentageUpdate = (caregiverId) => (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setPendingUpdates({ ...pendingUpdates, [caregiverId]: value });
  };

  const cancel = () => {
    if (!caregiverAllocations) {
      return;
    }

    setPendingUpdates(
      caregiverAllocations.allocatedCaregivers.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: curr.assignmentPercentage.toString() }),
        {}
      )
    );
    setIsEditing(false);
  };

  const save = async () => {
    if (allocationSum !== 100 || !caregiverAllocations) {
      return;
    }

    const body = {
      allocatedCaregivers: Object.keys(pendingUpdates).map((key) => ({
        caregiverId: key,
        percentage: Number(pendingUpdates[key])
      }))
    };

    const res = await fetch(`${basePath}/admin/modules/caregiver-allocation`, {
      headers: {
        authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(body)
    });

    if (!res.ok) {
      showNotification(I18n.t('notification.save_allocation.error'), 'error');
      return;
    }

    mutate(`/admin/modules/caregiver-allocation?caregiverRole=${selectedRoleFilter?.value.toUpperCase()}`);
    setIsEditing(false);
  };

  return (
    <Card
      titleI18nKey="manage_allocation.heading"
      additionalClass="x-large"
      actionButtons={
        <div className="fs-15">
          {isEditing ? (
            <>
              <Button buttonType="text" onClick={cancel} className="mr-10">
                <Translate value="global.buttons.cancel" />
              </Button>
              <Button buttonType="text" onClick={save} disabled={allocationSum !== 100}>
                <Translate value="global.buttons.save" />
              </Button>
            </>
          ) : (
            <Button buttonType="text" onClick={() => setIsEditing(true)}>
              <Translate value="global.buttons.edit" />
            </Button>
          )}
        </div>
      }
    >
      <div className="flex space-between mb-20">
        <Select
          title={I18n.t('global.role')}
          placeholder={I18n.t('global.choose_role')}
          isSearchable={false}
          value={selectedRoleFilter}
          onChange={(e) => setRole(e.value)}
          classNamePrefix="single-select"
          options={caregiverRoles}
        />
      </div>

      <div className={styles.allocationSum}>
        <Translate value="manage_allocation.current_allocation" />:{' '}
        <strong className={isLoading ? undefined : allocationSum === 100 ? 'positive' : 'negative'}>
          {isLoading ? '...' : allocationSum} %
        </strong>
      </div>

      <div style={{ '--column-count': 3 } as React.CSSProperties}>
        <div className="row heading">
          <div>
            <Translate value="manage_allocation.headings.caregiver" />
          </div>
          <div>
            <Translate value="manage_allocation.headings.allocation" />
          </div>
          <div>
            <Translate value="manage_allocation.headings.share" />
          </div>
        </div>

        {isLoading ? (
          <SkeletonComponent height="42px" width="100%" count={10} />
        ) : (
          <>
            {caregiverAllocations?.allocatedCaregivers.map((c) => (
              <div className="row" key={c.id}>
                <Link to={`/${APPLICATION_BASE_PATH}/user/${c.id}/allocation`}>
                  <strong>{c.name}</strong>
                </Link>
                <strong className={c.assignedPatients > c.capacity ? 'negative' : 'positive'}>
                  {Number(((c.assignedPatients || 1) / (c.capacity || 1)) * 100).toFixed()} %
                </strong>
                <span>
                  {isEditing ? (
                    <input
                      type="text"
                      value={pendingUpdates[c.id] || ''}
                      onChange={handlePercentageUpdate(c.id)}
                      className={styles.input}
                    />
                  ) : (
                    <span>{c.assignmentPercentage}</span>
                  )}{' '}
                  %
                </span>
              </div>
            ))}
          </>
        )}
      </div>
    </Card>
  );
}

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt
  };
};

const mapActionsToProps = {
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(CaregiverAllocationView);
