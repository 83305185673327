import { Fragment } from 'react';

type Props = {
  text?: string;
};

export default function RenderNewLines({ text }: Props) {
  return (
    <>
      {text?.split('\n').map((row, i, arr) => (
        <Fragment key={i}>
          <span>{row}</span>
          {i !== arr.length - 1 ? <br /> : null}
        </Fragment>
      ))}
    </>
  );
}
