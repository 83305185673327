import React from 'react';
import { components } from 'react-select';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="icon small chevron inverted-50"></span>
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
