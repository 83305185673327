import { useRef, useEffect } from 'react';

export const useIndeterminateCheckbox = (items: number[], selectedItems: number[]) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef && checkboxRef.current && selectedItems && selectedItems.length > 0 && selectedItems.length < items.length) {
      checkboxRef.current.indeterminate = true;
    } else if (checkboxRef && checkboxRef.current) {
      checkboxRef.current.indeterminate = false;
    }
  }, [selectedItems, items]);

  return checkboxRef;
};