import mixpanel from 'mixpanel-browser';

const trackingEnabled = process.env.REACT_APP_MIXPANEL_TRACKING === 'enabled';

if (trackingEnabled) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { api_host: 'https://api-eu.mixpanel.com' }, '');
}

const Mixpanel = {
  identify: (id) => {
    if (trackingEnabled) {
      mixpanel.identify(id);
    } else {
      console.log('Identify', id);
    }
  },
  set: (property, value) => {
    if (trackingEnabled) {
      mixpanel.people.set(property, value);
    } else {
      console.log('Setting property', property, value);
    }
  },
  track: (name, props) => {
    if (trackingEnabled) {
      mixpanel.track(name, props);
    } else {
      console.log('Tracking event', name);
    }
  },
  time_event: (name) => {
    if (trackingEnabled) {
      mixpanel.time_event(name);
    } else {
      console.log('Timing event', name);
    }
  },
  reset: () => {
    if (trackingEnabled) {
      mixpanel.reset();
    }
  }
};

const eventType = {
  loginFailure: 'Login failure',
  createJiraIssue: 'Create Jira issue',
  sendMessage: 'Send message',
  updatePaymentExemption: 'Update payment exemption',
  addPaymentExemption: 'Add payment exemption',
  removePaymentExemption: 'Remove payment exemption',
  toggleAdvancedSearch: 'Toggle advanced search',
  setSearchCriteria: 'Set search criteria'
};

export { Mixpanel, eventType };
