import { Link, Redirect } from 'react-router-dom';
import { APPLICATION_BASE_PATH } from '../../../constants';
import Button from '../../Button';
import { connect } from 'react-redux';
import { decamelize, isCaregiver } from '../../../utils';
import useSWR from 'swr';
import SkeletonComponent from '../../SkeletonComponent';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import { IndicatorIcon } from '../../ManageAbsenceView/AbsenceList';

function PlannedAbsenceView({ user, authorities, authority }) {
  const { data: absences = [], isLoading } = useSWR<CaregiverAbsence[]>(
    `/admin/modules/caregiver-absence?caregiver=${user.guid}`
  );

  if (user?.guid && !isCaregiver(user)) {
    return <Redirect to={`/${APPLICATION_BASE_PATH}/user/${user.guid}`} />;
  }

  return (
    <>
      <div className="card-header flex space-between">
        <Translate value="system_user_details.tab.planned_absence" />
        {authorities.includes(authority.manageAbsence) ? (
          <span className="card-actions">
            <Link to={`/${APPLICATION_BASE_PATH}/planning/manage-absence/new?caregiverId=${user.guid}`}>
              <Translate value="manage_absence.create_heading" />
            </Link>
          </span>
        ) : null}
      </div>

      {isLoading ? (
        <SkeletonComponent width="100%" height="42px" />
      ) : absences.length ? (
        <>
          <div style={{ '--column-count': 3 } as React.CSSProperties}>
            <div className="row heading">
              <div>
                <Translate value="manage_absence.absence_reason_heading" />
              </div>
              <div>
                <Translate value="manage_absence.period_heading" />
              </div>
              <div></div>
            </div>
            {absences.map((absence) => (
              <Link
                to={`/${APPLICATION_BASE_PATH}/planning/manage-absence/${absence.id}/edit`}
                className={`link-row ${!authorities.includes(authority.manageAbsence) ? 'pointer-events-none' : ''}`}
                key={absence.id}
              >
                <div className="row">
                  {absence.reason ? (
                    <Translate value={`manage_absence.reason.${decamelize(absence.reason)}`} />
                  ) : (
                    <em>
                      <Translate value="manage_absence.reason.missing" />
                    </em>
                  )}
                  <span className="flex vertical-align gap-1">
                    {moment(absence.from).format('D MMMM')} – {moment(absence.to).format('D MMMM')}
                    <IndicatorIcon absenceStart={absence.from} absolute={false} />
                  </span>
                  {authorities.includes(authority.manageAbsence) ? (
                    <span className="show-on-hover">
                      <Button size="medium">
                        <Translate value="global.buttons.edit" />
                      </Button>
                    </span>
                  ) : (
                    <span></span>
                  )}
                </div>
              </Link>
            ))}
          </div>
        </>
      ) : (
        <div>
          <Translate value="manage_absence.caregiver_no_absence" />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.systemUsers.currentSystemUser,
    authorities: state.auth.authorities,
    authority: state.sharedData.authorityTypes
  };
};

export default connect(mapStateToProps)(PlannedAbsenceView);
