import { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { Translate, I18n } from 'react-redux-i18n';
import { PartnerFacilityParams } from '..';
import Card from '../../Card';
import ToggleSwitch from '../../ToggleSwitch';
import { Facility, usePartner, PartnerUsersResponse, SetupCode } from '../PartnerProvider';
import SkeletonComponent from '../../SkeletonComponent';
import PartnerUserList from '../PartnerUserList';
import ErrorMessage from '../../ErrorMessage';
import { cleanUpFilename, updateObjectInArray } from '../../../utils';
import Button from '../../Button';
import { AnimatePresence, motion } from 'framer-motion';

const PartnerFacilityDetails = () => {
  const { partnerId, facilityId } = useParams<PartnerFacilityParams>();
  const [facility, setFacility] = useState<Facility>();
  const [loadingFacility, setLoadingFacility] = useState(false);
  const [fetchFacilityError, setFetchFacilityError] = useState<unknown>();
  const {
    getPartnerFacility,
    getUsers,
    setFacilityStatus,
    getInstructionsFile,
    facilityServices,
    toast,
    generateSetupCode,
    revokeSetupCode,
    sendSetupCode
  } = usePartner();
  const [users, setUsers] = useState<PartnerUsersResponse>();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [usersError, setUsersError] = useState<unknown>();

  useEffect(() => {
    setLoadingFacility(true);
    setLoadingUsers(true);

    getPartnerFacility(partnerId, facilityId)
      .then((response) => {
        if (response?.facilities && response?.facilities.length) {
          setFacility(response.facilities[0]);
        }
      })
      .catch(setFetchFacilityError)
      .finally(() => setLoadingFacility(false));

    getUsers(partnerId, facilityId)
      .then(setUsers)
      .catch(setUsersError)
      .finally(() => setLoadingUsers(false));
  }, [partnerId, facilityId]);

  const toggleStatus = async () => {
    try {
      const updatedFacility = await setFacilityStatus(partnerId, facilityId, {
        name: facility?.name,
        active: !facility?.active,
        services: facility?.services
      });
      setFacility(updatedFacility);
    } catch (err) {
      console.log(err);
    }
  };

  const downloadInstructionsFile = async () => {
    try {
      const data = await getInstructionsFile(partnerId, facilityId);
      saveAs(data.response.body, facility?.visitationInfo?.instructions?.filename);
    } catch (err) {
      toast(I18n.t('notification.instructions_file_download.error'), 'error');
    }
  };

  const generateNewSetupCode = async () => {
    try {
      const newSetupCode = await generateSetupCode(partnerId, facilityId);
      const existingCodes = facility?.setupCodes || [];
      setFacility({ ...facility, setupCodes: [newSetupCode, ...existingCodes] });
    } catch (err) {
      toast('Kunde inte generera ny inställningskod.', 'error');
    }
  };

  const revokeCode = async (code: SetupCode) => {
    if (!facility?.setupCodes) {
      return;
    }

    try {
      const updatedCode = await revokeSetupCode(partnerId, facilityId, code);
      setFacility({
        ...facility,
        setupCodes: updateObjectInArray(facility.setupCodes, {
          index: facility?.setupCodes?.findIndex((i) => i.id === code.id),
          item: updatedCode
        })
      });
    } catch (err) {
      toast('Kunde inte annullera inställningskoden.', 'error');
    }
  };

  const sendCodeViaEmail = async (code: SetupCode) => {
    if (!code?.id) {
      return;
    }

    try {
      await sendSetupCode(partnerId, facilityId, code.id);
      toast(I18n.t('notification.send_kiosk_activation_code.success', { email: facility?.email }), 'success');
    } catch (err) {
      toast(I18n.t('notification.send_kiosk_activation_code.error'), 'error');
    }
  };

  if (fetchFacilityError) {
    return (
      <Card additionalClass="x-large">
        <ErrorMessage error={fetchFacilityError} errorI18nKey="manage_partners.facilities.fetch_facility_error" />
      </Card>
    );
  }

  const serviceNames = facility?.services
    ?.filter((s) => s.selected && facilityServices.includes(s.service))
    ?.map((s) => s.service);

  const variants = {
    pre: { opacity: 0, x: -30 },
    visible: { opacity: 1, x: 0 }
  };

  return (
    <>
      <Card
        title={facility?.name}
        loadingTitle={loadingFacility}
        errorI18nKey="manage_partners.error"
        additionalClass="x-large"
        actionButtons={
          <NavLink to={`${facilityId}/edit`}>
            <div className="vertical-align">
              <span className="icon pen mr-5"></span>
              <span>
                <Translate value="manage_partners.facilities.edit" />
              </span>
            </div>
          </NavLink>
        }
      >
        {loadingFacility ? (
          <>
            {new Array(7).fill(0).map((_, i) => (
              <SkeletonComponent key={i} width="100%" height="38px" />
            ))}
          </>
        ) : (
          <>
            <div className="partner-row">
              <div>
                <div className="fw-bold">
                  <Translate value="manage_partners.facilities.services" />
                </div>
                <div>{serviceNames?.map((service) => I18n.t(`manage_partners.service.${service}`)).join(', ')}</div>
              </div>
            </div>
            <div className="partner-row">
              <div>
                <div className="fw-bold">
                  <Translate value="manage_partners.facilities.address" />
                </div>
                <div>
                  {facility?.streetaddress ?? (
                    <span className="error-message">
                      <Translate value="manage_partners.facilities.street_address_missing" />
                    </span>
                  )}{' '}
                  {facility?.postalCode ?? (
                    <span className="error-message">
                      <Translate value="manage_partners.facilities.postal_code_missing" />
                    </span>
                  )}{' '}
                  {facility?.city ?? (
                    <span className="error-message">
                      <Translate value="manage_partners.facilities.city_missing" />
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="partner-row">
              <div>
                <div className="fw-bold">
                  <Translate value="manage_partners.facilities.region" />
                </div>
                <div>{facility?.partnerRegion ?? '-'}</div>
              </div>
            </div>
            <div className="partner-row">
              <div>
                <div className="fw-bold">
                  <Translate value="manage_partners.facilities.cost_center" />
                </div>
                <div>{facility?.partnerCostCenter ?? '-'}</div>
              </div>
            </div>
            <div className="partner-row">
              <div>
                <div className="fw-bold">
                  <Translate value="manage_partners.facilities.email" />
                </div>
                <div>{facility?.email}</div>
              </div>
            </div>
            <div className="partner-row">
              <div>
                <div className="fw-bold">
                  <Translate value="manage_partners.facilities.phone" />
                </div>
                <div>{facility?.phoneNumber}</div>
              </div>
            </div>
            {serviceNames?.includes('auscultation') || serviceNames?.includes('examination') ? (
              <>
                <div className="partner-row">
                  <div>
                    <div className="fw-bold">
                      <Translate value="manage_partners.facilities.instructions_file" />
                    </div>
                    <div
                      className={`${facility?.visitationInfo?.instructions?.filename ? 'text-button fw-normal' : ''}`}
                      onClick={facility?.visitationInfo?.instructions?.filename ? downloadInstructionsFile : undefined}
                    >
                      {facility?.visitationInfo?.instructions?.filename
                        ? cleanUpFilename(facility.visitationInfo.instructions.filename)
                        : '-'}
                    </div>
                  </div>
                </div>
                <div className="partner-row">
                  <div>
                    <div className="fw-bold">
                      <Translate value="manage_partners.facilities.acceptable_distance_no_suffix" />
                    </div>
                    <div>{facility?.visitationInfo?.distance ? `${facility.visitationInfo.distance} km` : '-'}</div>
                  </div>
                </div>
                <div className="partner-row">
                  <div>
                    <div className="fw-bold">
                      <Translate value="manage_partners.facilities.cancellation_policy" />
                    </div>
                    <div>{facility?.visitationInfo?.cancellationPolicy || '-'}</div>
                  </div>
                </div>
              </>
            ) : null}
          </>
        )}
      </Card>
      <Card additionalClass="x-large">
        {loadingFacility ? (
          <SkeletonComponent width="100%" height="38px" />
        ) : (
          <div className="space-between">
            <span className="fw-bold">
              <Translate value="manage_partners.facilities.status" />:{' '}
              <span className={`${facility?.active ? 'status-active' : 'status-inactive'}`}>
                {facility?.active ? I18n.t('manage_partners.status.active') : I18n.t('manage_partners.status.inactive')}
              </span>
            </span>
            <ToggleSwitch checked={!!facility?.active} onChange={toggleStatus} type="partner" scope="facility" />
          </div>
        )}
      </Card>
      {serviceNames?.includes('kiosk') ? (
        <Card
          title={I18n.t(`manage_partners.facilities.activation_codes.title`)}
          description={I18n.t(`manage_partners.facilities.activation_codes.description`)}
          additionalClass="x-large"
          actionButtons={
            <>
              <div className="vertical-align">
                <span className="icon numbers mr-5"></span>
                <span className="fs-14">
                  <Button size="medium" buttonType="text" onClick={generateNewSetupCode}>
                    <Translate value="manage_partners.facilities.activation_codes.action.generate" />
                  </Button>
                </span>
              </div>
            </>
          }
        >
          <div className="table-container admin-table m-h-300 oy-auto">
            {facility?.setupCodes?.length ? (
              <table className="table w-100 overflow-hidden">
                <tbody>
                  <AnimatePresence initial={false}>
                    {facility.setupCodes.map((setupCode) => (
                      <motion.tr key={setupCode.id} variants={variants} initial="pre" animate="visible">
                        <th className={setupCode.status !== 'active' ? 'strike opacity-6 fw-500' : ''}>
                          {setupCode.code}{' '}
                          {setupCode.status === 'revoked'
                            ? I18n.t('manage_partners.facilities.activation_codes.status.revoked')
                            : setupCode.status === 'used'
                            ? I18n.t('manage_partners.facilities.activation_codes.status.used')
                            : ''}
                        </th>
                        <td className="text-right">
                          {setupCode.status === 'active' ? (
                            <>
                              <Button size="medium" buttonType="text" onClick={() => sendCodeViaEmail(setupCode)}>
                                <Translate value="manage_partners.facilities.activation_codes.action.send" />
                              </Button>
                              <Button size="medium" buttonType="text-destructive" onClick={() => revokeCode(setupCode)}>
                                <Translate value="manage_partners.facilities.activation_codes.action.revoke" />
                              </Button>
                            </>
                          ) : null}
                        </td>
                      </motion.tr>
                    ))}
                  </AnimatePresence>
                </tbody>
              </table>
            ) : (
              <div>
                <Translate value="manage_partners.facilities.activation_codes.no_codes" />
              </div>
            )}
          </div>
        </Card>
      ) : null}
      <PartnerUserList
        partnerId={partnerId}
        users={users?.users}
        isLoading={loadingUsers}
        facilityId={facilityId}
        error={usersError}
      />
    </>
  );
};

export default PartnerFacilityDetails;
