import ChatMessage from './ChatMessage';
import SystemMessage from './SystemMessage';

type Props = {
  message: ChatMessage;
  author?: Author;
};

export default function ChatItem({ message, author }: Props) {
  const fallbackAuthor: Author = { userId: '0', name: 'System', role: 'SYSTEM' };

  switch (message.type) {
    case 'MESSAGE':
      return <ChatMessage message={message} author={author || fallbackAuthor} />;
    case 'SYSTEM':
      return <SystemMessage message={message} />;
    default:
      return <ChatMessage message={message} author={author || fallbackAuthor} />;
  }
}
