import { useRef, useEffect } from 'react';

export default function useScrollToBottomOnUpdate() {
  const observedRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!observedRef.current) {
      return;
    }

    const scrollToBottom = () => {
      if (!scrollerRef.current) {
        return;
      }

      scrollerRef.current.scrollTop = scrollerRef.current.scrollHeight;
    };

    const observer = new MutationObserver(scrollToBottom);
    observer.observe(observedRef.current, { childList: true });

    return () => observer.disconnect();
  }, []);

  return [scrollerRef, observedRef] as const;
}
