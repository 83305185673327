// @ts-nocheck
import { Switch, NavLink } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import PrivateRoute from '../PrivateRoute';
import { Translate } from 'react-redux-i18n';
import { APPLICATION_BASE_PATH } from '../../constants';
import PartnerList from './PartnerList';
import PartnerDetails from './PartnerDetails';
import PartnerFacilityDetails from './PartnerFacilityDetails';
import PartnerProvider, { usePartner } from './PartnerProvider';
import CreatePartner from './CreatePartner';
import CreatePartnerFacility from './CreatePartnerFacility';
import CreateUser from './CreateUser';

const DynamicPartnerBreadcrumb = ({ match }) => {
  const { partners } = usePartner();
  return <span>{partners.find((p) => p.id === match.params.partnerId)?.name ?? '...'}</span>;
};

const routes = [
  {
    path: `/${APPLICATION_BASE_PATH}/services/partners`,
    component: PartnerList,
    breadcrumb: () => <Translate value="reports_view.page.partner_management" />
  },
  {
    path: `/${APPLICATION_BASE_PATH}/services/partners/new`,
    component: CreatePartner,
    breadcrumb: () => <Translate value="manage_partners.breadcrumb.create_partner" />
  },
  {
    path: `/${APPLICATION_BASE_PATH}/services/partners/:partnerId`,
    component: PartnerDetails,
    breadcrumb: DynamicPartnerBreadcrumb
  },
  {
    path: `/${APPLICATION_BASE_PATH}/services/partners/:partnerId/edit`,
    component: () => <CreatePartner editMode />,
    breadcrumb: () => <Translate value="manage_partners.breadcrumb.edit_partner" />
  },
  {
    path: `/${APPLICATION_BASE_PATH}/services/partners/:partnerId/user/:userId`,
    component: () => <CreateUser editMode />,
    breadcrumb: () => <Translate value="manage_partners.add_user.header_edit" />
  },
  {
    path: `/${APPLICATION_BASE_PATH}/services/partners/:partnerId/users/new`,
    component: CreateUser,
    breadcrumb: () => <Translate value="manage_partners.add_user.header" />
  },
  {
    path: `/${APPLICATION_BASE_PATH}/services/partners/:partnerId/new`,
    component: CreatePartnerFacility,
    breadcrumb: () => <Translate value="manage_partners.breadcrumb.create_facility" />
  },
  {
    path: `/${APPLICATION_BASE_PATH}/services/partners/:partnerId/facility/:facilityId`,
    component: PartnerFacilityDetails,
    breadcrumb: () => <Translate value="manage_partners.breadcrumb.facility" />
  },
  {
    path: `/${APPLICATION_BASE_PATH}/services/partners/:partnerId/facility/:facilityId/edit`,
    component: () => <CreatePartnerFacility editMode />,
    breadcrumb: () => <Translate value="manage_partners.facilities.edit_header" />
  },
  {
    path: `/${APPLICATION_BASE_PATH}/services/partners/:partnerId/facility/:facilityId/user/:userId`,
    component: () => <CreateUser editMode />,
    breadcrumb: () => <Translate value="manage_partners.add_user.header_edit" />
  },
  {
    path: `/${APPLICATION_BASE_PATH}/services/partners/:partnerId/facility/:facilityId/users/new`,
    component: CreateUser,
    breadcrumb: () => <Translate value="manage_partners.add_user.header" />
  }
];

const Breadcrumbs = withBreadcrumbs(routes, { disableDefaults: true })(({ breadcrumbs }) => {
  return (
    <div className="breadcrumb-wrapper">
      {breadcrumbs.map(({ match, breadcrumb }, i) => (
        <span key={match.url}>
          {i === breadcrumbs.length - 1 ? <span>{breadcrumb}</span> : <NavLink to={match.url}>{breadcrumb}</NavLink>}
        </span>
      ))}
    </div>
  );
});

export type PartnerFacilityParams = {
  partnerId: string;
  facilityId: string;
  userId: string;
};

const ManagePartnersView = () => {
  return (
    <>
      <PartnerProvider>
        <Breadcrumbs />
        <Switch>
          {routes.map((route) => (
            <PrivateRoute key={route.path} path={route.path} component={route.component} exact />
          ))}
        </Switch>
      </PartnerProvider>
    </>
  );
};

export default ManagePartnersView;
