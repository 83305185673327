import React from 'react';
import { Translate } from 'react-redux-i18n';
import moment from 'moment/moment';
import { DATE_FORMAT, PATIENT_STATE } from '../../../constants';
import ContentLoader from '../../ContentLoader';
import Button from '../../Button';

const FunnelStatus = ({ memberState, funnelStatus, isLoading, error, onUpdateState }) => {
  return (
    <div>
      <h2>
        <Translate value="member_details.funnel_state.header" />
      </h2>
      <ContentLoader isLoading={isLoading} error={error} errorTitleI18n={'member_details.funnel_state.error'}>
        {!funnelStatus.funnelName ? (
          <Translate value={'member_details.funnel_state.empty'} />
        ) : (
          <div>
            <div className="mb-10">
              <Translate value={'member_details.funnel_state.ongoing'} /> {funnelStatus.funnelName}
            </div>
            <div className="mb-15">
              <Translate value={'member_details.funnel_state.days_to_invoice'} /> {funnelStatus.daysToInvoice}
            </div>
            {memberState.DefaultBottomFunnel !== undefined &&
            memberState.DefaultBottomFunnel !== PATIENT_STATE.BOTTOM_FUNNEL_DONE ? (
              <Button
                buttonType={'primary'}
                fullWidth={false}
                onClick={() => {
                  onUpdateState(
                    'DefaultBottomFunnel',
                    memberState.DefaultBottomFunnel !== PATIENT_STATE.BOTTOM_FUNNEL_PAUSED
                      ? PATIENT_STATE.BOTTOM_FUNNEL_PAUSED
                      : '-'
                  );
                }}
              >
                <Translate
                  value={`member_details.funnel_state.${
                    memberState.DefaultBottomFunnel !== PATIENT_STATE.BOTTOM_FUNNEL_PAUSED ? 'pause' : 'resume'
                  }`}
                />
              </Button>
            ) : null}
            <div className="columns is-mobile">
              <div className="column is-8 pb-0">
                <h4>
                  <Translate value="member_details.funnel_state.table_headers.event" />
                </h4>
              </div>
              <div className="column is-4 pb-0">
                <h4>
                  <Translate value="member_details.funnel_state.table_headers.date" />
                </h4>
              </div>
            </div>
            {funnelStatus.events.map((event, index) => (
              <div className="columns is-mobile mb-10" key={index}>
                <div className="column is-8 pb-0">{event.description}</div>
                <div className="column is-4 pb-0">
                  {event.date ? moment(event.date, DATE_FORMAT).format('YYYY-MM-DD') : '-'}
                </div>
              </div>
            ))}
          </div>
        )}
      </ContentLoader>
    </div>
  );
};

export default FunnelStatus;
