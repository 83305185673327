import jwtDecode from 'jwt-decode';
import * as types from '../constants/actionTypes';
import { loadState } from '../services/localStorage';
import { LOGIN_AUTHORITY } from '../constants';

const initialState = loadState('auth', true) || {};
delete initialState.error;
delete initialState.isLoggingIn;

const authReducer = (state = { ...initialState, redirectToReferrer: true }, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return { ...state, isLoggingIn: true, refreshingToken: false };
    case types.LOGIN_SUCCESS: {
      const authorities = jwtDecode(action.payload.jwt).rol.split(',');
      let loginError;

      if (authorities.indexOf(LOGIN_AUTHORITY) === -1) {
        // Create a synthetic "insufficient privileges" error response object if the login authority was not present
        loginError = {
          response: {
            text: '{"code": "4"}'
          }
        };
      }

      return {
        ...state,
        isLoggingIn: false,
        redirectToReferrer: true,
        token: action.payload,
        error: loginError,
        refreshingToken: false,
        authorities
      };
    }
    case types.LOGIN_ERROR:
      return { ...state, isLoggingIn: false, redirectToReferrer: false, error: action.payload, refreshingToken: false };
    case types.LOGOUT:
      return { ...state, token: {}, authorities: undefined, redirectToReferrer: false };
    case types.REFRESH_TOKEN_REQUEST:
      return { ...state, refreshingToken: true };
    case types.REFRESH_TOKEN_SUCCESS:
      return { ...state, token: action.payload, refreshingToken: false };
    default:
      return state;
  }
};

export default authReducer;
