import { Translate } from 'react-redux-i18n';
import LoaderButton from '../../LoaderButton';
import ContentLoader from '../../ContentLoader';
import ShipmentRow from '../ShipmentRow';
import { useIndeterminateCheckbox } from '../../../hooks/useIndeterminateCheckbox';

const ShipmentItemList = ({
  isLoadingShipments,
  error,
  shipmentItems,
  scheduleShipmentItems,
  selectedShipmentItems,
  currentlyDeletingShipments,
  toggleShipmentItem,
  removeShipment,
  schedulingShipments,
  toggleAllShipmentsSelected,
  onSelectDelayDate,
  undoDelayShipment,
  updateDelayDate
}) => {
  const selectAllCheckbox = useIndeterminateCheckbox(shipmentItems, selectedShipmentItems);

  return (
    <>
      <ContentLoader
        isLoading={isLoadingShipments}
        error={error}
        errorTitleI18n="reports_view.shipments.error"
        type="spinner-secondary"
      >
        <div className="checkbox-list">
          {toggleAllShipmentsSelected ? (
            <div className="space-between pl-5 pb-10 fs-14 usn">
              <div>
                <input
                  ref={selectAllCheckbox}
                  type="checkbox"
                  checked={selectedShipmentItems.length === shipmentItems.length}
                  onChange={toggleAllShipmentsSelected}
                  readOnly
                  className="mr-10"
                  id="select-all-shipments"
                />
                <label htmlFor="select-all-shipments">Markera alla</label>
              </div>
            </div>
          ) : null}
          {shipmentItems.length ? (
            shipmentItems.map((shipment) => (
              <ShipmentRow
                self={shipment}
                key={shipment.id}
                {...shipment}
                undoDelayShipment={undoDelayShipment}
                onSelectDelayDate={onSelectDelayDate}
                updateDelayDate={updateDelayDate}
                onClick={toggleShipmentItem ? () => toggleShipmentItem(shipment.id) : undefined}
                isChecked={selectedShipmentItems ? selectedShipmentItems.includes(shipment.id) : false}
                isDeleting={currentlyDeletingShipments.includes(shipment.id)}
                onDelete={(e) => removeShipment(e, shipment.id)}
              />
            ))
          ) : (
            <Translate value={`reports_view.shipments.no_${updateDelayDate ? 'delayed_' : ''}shipments`} />
          )}
        </div>
      </ContentLoader>
      {scheduleShipmentItems ? (
        <div className="mt-20">
          <LoaderButton
            disabled={selectedShipmentItems.length === 0 || isLoadingShipments}
            onClick={!schedulingShipments ? scheduleShipmentItems : undefined}
            isLoading={schedulingShipments}
          >
            <Translate value="reports_view.shipments.done_button" />
          </LoaderButton>
        </div>
      ) : null}
    </>
  );
};

export default ShipmentItemList;
