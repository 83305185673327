import React, { useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import Cropper from 'react-easy-crop';
import Modal from '../Modal';
import api from '../../api/apiClient';
import { showNotification } from '../../actions';
import { getCroppedImage } from '../../utils';
import Button from '../Button';

const ProfileImageUploadModal = ({
  isVisible,
  onClose,
  authToken,
  memberGuid,
  memberName,
  getProfileImage,
  hasProfileImage,
  showNotification,
  user
}) => {
  const fileInput = useRef();
  const [imagePreview, setImagePreview] = useState();
  const [filename, setFilename] = useState();
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const validFileTypes = ['image/jpeg', 'image/png', 'image/gif'];

  const dismiss = () => {
    onClose();
    fileInput.current = null;
    setImagePreview(null);
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setFilename(null);
    setIsDragging(false);
  };

  const getImagePreview = (file) => {
    if (file && validFileTypes.includes(file.type)) {
      setFilename(file.name);
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.addEventListener('load', function () {
        setImagePreview(this.result);
      });
    }
  };

  const onFileInputChange = () => {
    const file = fileInput.current?.files[0];
    getImagePreview(file);
  };

  const uploadFile = () => {
    setIsUploadingImage(true);
    getCroppedImage(imagePreview, croppedArea)
      .then((croppedImage) => {
        const formData = new FormData();
        const newProfileImage = new File([croppedImage], 'profile-image', { type: croppedImage.type });
        formData.append('file', newProfileImage);
        return api.uploadFile(authToken, memberGuid, newProfileImage.name, formData.get('file'), 'profileImage');
      })
      .then((response) => {
        setIsUploadingImage(false);
        dismiss();
        getProfileImage(authToken, memberGuid, response.id, user.guid);
        showNotification(I18n.t('notification.upload_profile_picture.success', { name: memberName }), 'success');
      })
      .catch(() => {
        showNotification(I18n.t('notification.upload_profile_picture.error'), 'error');
        setIsUploadingImage(false);
      });
  };

  const onCropComplete = useCallback((_, croppedArea) => {
    setCroppedArea(croppedArea);
  }, []);

  const dragEnter = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragging(true);
  };

  const dragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const file = e.dataTransfer?.files[0];
    getImagePreview(file);
  };

  return (
    <Modal
      headerI18nKey={`profile_image_upload.header${!hasProfileImage ? '_new' : ''}`}
      actionI18nKey="profile_image_upload.action"
      visible={isVisible}
      onClose={dismiss}
      onActionCompleted={uploadFile}
      actionCompletable={!!imagePreview}
      actionCompleting={isUploadingImage}
      size="medium"
    >
      <div className="h-100" onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave} onDrop={onDrop}>
        {imagePreview ? (
          <div className="cropper-wrapper">
            <Button onClick={() => setZoom(zoom + 0.5)} disabled={zoom === 3}>
              +
            </Button>
            <Button onClick={() => setZoom(zoom - 0.5)} disabled={zoom === 1}>
              -
            </Button>
            <Cropper
              image={imagePreview}
              crop={crop}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        ) : null}
        <div
          className={`${!imagePreview ? 'profile-image-upload__drop-target' : ''} ${isDragging ? 'is-dragging' : ''}`}
        >
          <div className={`vertical-align ${isDragging ? 'pointer-events-none' : ''}`}>
            <input type="file" id="profile-image-input" onChange={onFileInputChange} accept="image/*" ref={fileInput} />
            <label htmlFor="profile-image-input">
              <Translate value="profile_image_upload.select_file" />
            </label>
            {!imagePreview && (
              <span className="ml-5 fw-bold">
                <Translate value="profile_image_upload.drag_here" />
              </span>
            )}
            {filename && <span className="ml-5 fw-bold">{filename}</span>}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    user: state.auth.token.user
  };
};

const mapActionsToProps = {
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(ProfileImageUploadModal);
