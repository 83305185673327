import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { DebounceInput } from 'react-debounce-input';
import Button from '../../Button';
import LoadingIcon from '../../LoadingIcon';
import './SearchBar.scss';

const SearchBar = ({ onSearchSubmit, onClearSearch, isLoading, value }) => {
  return (
    <div className="search-bar">
      <form method="POST" onSubmit={onSearchSubmit} autoComplete="off">
        <div className="columns">
          <div className="column is-10">
            <DebounceInput
              spellCheck={false}
              value={value}
              debounceTimeout={300}
              placeholder={I18n.t('member_search.input_placeholder')}
              onChange={onSearchSubmit}
            />
            {isLoading ? (
              <div className="loading">
                <LoadingIcon type="spinner-secondary" size="small" />
              </div>
            ) : null}
            {value && onClearSearch && !isLoading ? (
              <div className="clear">
                <span onClick={onClearSearch}></span>
              </div>
            ) : null}
          </div>
          <div className="column is-2">
            <Button type="submit">
              <Translate value="global.search" />
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
