import React from 'react';
import moment from 'moment';
import { Translate, I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { DATE_FORMAT, APPLICATION_BASE_PATH } from '../../../constants';
import { decamelize } from '../../../utils';
import Button from '../../Button';

const ShipmentRow = ({
  user,
  isChecked,
  onClick,
  isDeleting,
  onDelete,
  shipmentType,
  addedDate,
  reason,
  comment,
  cuffSize,
  delayDate,
  onSelectDelayDate,
  undoDelayShipment,
  updateDelayDate,
  self
}) => {
  const address = user.streetaddress
    ? `${user.streetaddress || '-'} ${user.postalCode || '-'} ${user.city || '-'}`
    : I18n.t('reports_view.shipments.address_missing');

  return (
    <div className="checkbox-row" onClick={!isDeleting ? onClick : undefined}>
      {onClick ? (
        <div className="column is-1 no-padding">
          <input type="checkbox" checked={isChecked} readOnly disabled={isDeleting} />
        </div>
      ) : null}
      <div className="column is-3 no-padding" title={`${user.givenName} ${user.familyName}`}>
        {cuffSize ? <span className="fw-bold">{`${cuffSize} `}</span> : null}
        <Link onClick={(e) => e.stopPropagation()} to={`/${APPLICATION_BASE_PATH}/member/${user.guid}`} target="_blank">
          {`${user.givenName} ${user.familyName}`}
        </Link>
      </div>
      {!updateDelayDate ? (
        <div className="column is-4 no-padding" title={address}>
          {address}
        </div>
      ) : (
        <div className="column is-4 no-padding">
          <Translate
            value="reports_view.shipments.current_delay"
            delayDate={moment(delayDate, DATE_FORMAT).format('DD MMM YYYY')}
          />
        </div>
      )}
      {!updateDelayDate ? (
        <div className="column no-padding text-center">
          <Tooltip
            title={
              <div className="fs-12">
                <div className="mb-5">
                  <Translate value="reports_view.shipments.created_at" />{' '}
                  {moment(addedDate, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}
                </div>
                <div>
                  <Translate value="reports_view.shipments.reason" />:{' '}
                  <Translate value={`reports_view.shipments.reasons.${decamelize(reason, '_')}`} />
                </div>
                {comment ? <div className="mt-5">{`"${comment}"`}</div> : null}
              </div>
            }
            arrow
            placement="top"
          >
            <div>
              <span>
                {shipmentType === 'return' ? <span className="icon left xx-small mr-5"></span> : null}
                <Translate value={`reports_view.shipments.type.${shipmentType}`} />
                {shipmentType === 'outgoing' ? <span className="icon right xx-small ml-5"></span> : null}
              </span>
            </div>
          </Tooltip>
        </div>
      ) : null}
      <div className="column no-padding text-right">
        {onSelectDelayDate ? (
          <Button
            size="medium"
            onClick={(e) => {
              e.stopPropagation();
              onSelectDelayDate(self);
            }}
          >
            <Translate value="reports_view.shipments.delay" />
          </Button>
        ) : null}
        {undoDelayShipment ? (
          <Button
            size="medium"
            onClick={(e) => {
              e.stopPropagation();
              undoDelayShipment(self);
            }}
          >
            <Translate value="reports_view.shipments.undo_delay" />
          </Button>
        ) : null}
        {updateDelayDate ? (
          <span className="ml-10">
            <Button
              buttonType="secondary"
              size="medium"
              onClick={(e) => {
                e.stopPropagation();
                updateDelayDate(self);
              }}
            >
              <Translate value="reports_view.shipments.change_delay_date" />
            </Button>
          </span>
        ) : null}
      </div>
      <div className="column is-1 no-padding text-right">
        {!isDeleting ? <span className="icon x-small cross" onClick={onDelete}></span> : null}
      </div>
    </div>
  );
};

export default ShipmentRow;
