import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';
import { API_ERROR_CODES } from '../../constants';
import './ErrorMessage.scss';

class ErrorMessage extends Component {
  constructor(props) {
    super(props);

    this.viewFullError = this.viewFullError.bind(this);

    this.state = {
      fullErrorVisible: false
    };
  }

  viewFullError() {
    this.setState({
      fullErrorVisible: true
    });
  }

  render() {
    const { error, errorI18nKey } = this.props;

    if (error === undefined) return null;

    let responseCode;

    if (error.response && error.response.text.length) {
      responseCode = JSON.parse(error.response.text).code;
    }

    if (!responseCode) {
      responseCode = error.status;
    }

    let detailedErrorMessage;

    if (error.response && error.response.text) {
      detailedErrorMessage = JSON.stringify(JSON.parse(error.response.text));
    } else {
      detailedErrorMessage = `{"statusCode": "${error.response ? error.response.statusCode : 500}"}`;
    }

    return (
      <div className="error-message-wrapper">
        <div className="error-message">
          <Translate value={errorI18nKey} />
          &nbsp;
          <Translate value="global.reason" />
          :&nbsp;
          <Translate
            className="mapped-error-message-content"
            value={API_ERROR_CODES[responseCode] || 'server_error.fallback'}
          />
          &nbsp;
          <div>
            <span
              className={`view-error ${responseCode ? 'hidden' : ''} ${!this.state.fullErrorVisible ? '' : 'hidden'}`}
              onClick={this.viewFullError}
            >
              <Translate value="global.show_error_info" />
            </span>
          </div>
        </div>
        <div className={`full-error-container ${!this.state.fullErrorVisible || !error.response ? 'hidden' : ''}`}>
          <pre>{JSON.stringify(JSON.parse(detailedErrorMessage), null, 2)}</pre>
        </div>
      </div>
    );
  }
}

export default ErrorMessage;
