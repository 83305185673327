// @ts-nocheck
import { useState } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { HIDDEN_AUTHORITIES, NULL_GUID } from '../../../constants';
import { decamelize, isCaregiver } from '../../../utils';
import SaveableMultiSelect from '../../SaveableMultiSelect';
import Button from '../../Button';
import Modal from '../../Modal';
import {
  toggleHiddenRegion,
  clearHiddenRegions,
  toggleAuthority,
  clearAuthorities,
  updateAuthorities,
  updateHiddenSources,
  updateHiddenRegions,
  clearHiddenSources,
  toggleHiddenSource
} from '../../../actions';

const SystemUserPermissions = (props) => {
  const [authoritiesModalVisible, setAuthoritiesModalVisible] = useState(false);
  const highlightedAuthorities = ['caregiver', 'nurse', 'healthscientist'];
  const { authorityTypes: authority, sources, regions, authorities } = props.sharedData;
  const { currentSystemUser, updatingHiddenSources, updatingHiddenRegions, currentSystemUserAuthorities } =
    props.systemUsers;

  const onDisallowedRegionChange = (_, event) => {
    switch (event.action) {
      case 'clear':
        props.clearHiddenRegions();
        break;
      case 'remove-value':
        props.toggleHiddenRegion(event.removedValue.value);
        break;
      case 'select-option':
        props.toggleHiddenRegion(event.option.value);
        break;
      default:
        return;
    }
  };

  const onDisallowedSourceChange = (_, event) => {
    switch (event.action) {
      case 'clear':
        props.clearHiddenSources();
        break;
      case 'remove-value':
        props.toggleHiddenSource(event.removedValue.value);
        break;
      case 'select-option':
        props.toggleHiddenSource(event.option.value);
        break;
      default:
        return;
    }
  };

  const onAuthoritiesChange = (event) => {
    props.toggleAuthority(event.target.value);
  };

  const saveAuthorites = () => {
    const { updateAuthorities, authToken, match, systemUsers } = props;

    const updatedFields = {
      user: {},
      systemUserExtras: {
        authorities: systemUsers.currentSystemUserAuthorities
      }
    };

    updateAuthorities(authToken, match.params.memberId, updatedFields);

    setAuthoritiesModalVisible(false);
  };

  const saveHiddenSources = () => {
    const { updateHiddenSources, authToken, match, systemUsers } = props;

    const updatedFields = {
      user: {},
      systemUserExtras: {
        hiddenSources: systemUsers.currentSystemUserHiddenSources
      }
    };

    updateHiddenSources(authToken, match.params.memberId, updatedFields);
  };

  const saveHiddenRegions = () => {
    const { updateHiddenRegions, authToken, match, systemUsers, sharedData } = props;

    const updatedFields = {
      user: {},
      systemUserExtras: {
        hiddenRegions: sharedData.regions.filter(
          (region) => systemUsers.currentSystemUserHiddenRegionIds.indexOf(region.id) > -1
        )
      }
    };

    updateHiddenRegions(authToken, match.params.memberId, updatedFields);
  };

  return (
    <>
      <div className="card-header">
        <div className="flex space-between">
          <Translate value="global.authorities" />
          <span className="card-actions fs-15">
            <Button buttonType="text" onClick={() => setAuthoritiesModalVisible(true)}>
              <Translate value="global.buttons.edit" />
            </Button>
          </span>
        </div>
      </div>
      <div className="mb-30">
        <div className="authority-columns">
          <div>
            {currentSystemUser && currentSystemUser.authorities && currentSystemUser.authorities.length ? (
              <ul className="check-list">
                {currentSystemUser.authorities
                  .filter((authority) => authority && !HIDDEN_AUTHORITIES.includes(authority))
                  .map((authority) => (
                    <li key={authority} className={highlightedAuthorities.includes(authority) ? 'fw-600' : undefined}>
                      {I18n.t(`authorities.${decamelize(authority, '_')}`)}
                    </li>
                  ))}
              </ul>
            ) : (
              <span>Inga rättigheter.</span>
            )}
          </div>
        </div>
      </div>
      {isCaregiver(currentSystemUser) ? (
        <>
          <h4>
            <Translate value="system_user_details.disallowed_sources" />
          </h4>
          <div className="mb-30">
            <SaveableMultiSelect
              requiredAuthority={authority.manageUsers}
              isSaving={updatingHiddenSources}
              onSave={saveHiddenSources}
              actionI18nKey="global.buttons.save"
              name="hidden-sources"
              placeholderI18nKey="global.choose"
              defaultValue={
                currentSystemUser.hiddenSources && currentSystemUser.hiddenSources.length
                  ? currentSystemUser.hiddenSources.map((source) => {
                      return { value: source, label: source };
                    })
                  : []
              }
              currentValue={props.systemUsers.currentSystemUserHiddenSources}
              noOptionsI18nKey="system_user_details.no_more_sources"
              onChange={onDisallowedSourceChange}
              options={sources.map((source) => {
                return { value: source, label: source };
              })}
            />
          </div>
          <h4>
            <Translate value="system_user_details.disallowed_regions" />
          </h4>
          <div className="mb-30">
            <SaveableMultiSelect
              requiredAuthority={authority.manageUsers}
              isSaving={updatingHiddenRegions}
              onSave={saveHiddenRegions}
              actionI18nKey="global.buttons.save"
              name="disallowed-regions"
              placeholderI18nKey="global.choose"
              defaultValue={
                currentSystemUser.hiddenRegions && currentSystemUser.hiddenRegions.length
                  ? currentSystemUser.hiddenRegions.map((region) => {
                      return { value: region.id, label: region.name };
                    })
                  : []
              }
              currentValue={props.systemUsers.currentSystemUserHiddenRegionIds}
              noOptionsI18nKey="system_user_details.no_more_regions"
              onChange={onDisallowedRegionChange}
              options={regions
                .filter((region) => region.id.indexOf('null') === -1 && region.id !== NULL_GUID)
                .map((region) => {
                  return { value: region.id, label: region.name };
                })}
              fallbackKey="label"
            />
          </div>
        </>
      ) : null}
      <Modal
        visible={authoritiesModalVisible}
        actionCompletable={true}
        headerI18nKey="global.authorities"
        actionI18nKey="global.buttons.save"
        onClose={() => setAuthoritiesModalVisible(false)}
        onActionCompleted={saveAuthorites}
      >
        <div className="stack">
          {authorities.map((authority, i) => {
            return (
              <div className="authority-container" key={i}>
                <input
                  type="checkbox"
                  value={authority}
                  id={`authority-${i}`}
                  checked={currentSystemUserAuthorities.includes(authority)}
                  onChange={onAuthoritiesChange}
                />
                <label htmlFor={`authority-${i}`}>
                  <Translate value={`authorities.${decamelize(authority, '_')}`} />
                  <div className="authority-description">
                    <Translate value={`authority_description.${decamelize(authority, '_')}`} />
                  </div>
                </label>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    systemUsers: state.systemUsers,
    sharedData: state.sharedData
  };
};

const mapActionsToProps = {
  toggleHiddenRegion,
  clearHiddenRegions,
  toggleAuthority,
  clearAuthorities,
  updateAuthorities,
  updateHiddenSources,
  updateHiddenRegions,
  clearHiddenSources,
  toggleHiddenSource
};

export default connect(mapStateToProps, mapActionsToProps)(SystemUserPermissions);
