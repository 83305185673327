import { useState, useEffect, useRef } from 'react';

function useIsOnscreen() {
  const [isOnscreen, setIsOnscreen] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;

      setIsOnscreen(entry.isIntersecting);
    });

    observer.observe(elementRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return [isOnscreen, elementRef] as const;
}

export default useIsOnscreen;
