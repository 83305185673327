import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import './styles/index.scss';
import Root from './Root';
import { Provider } from 'react-redux';
import { saveState } from './services/localStorage';
import configureStore from './store';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import metadata from './metadata.json';
import { CURRENT_ENV } from './constants';

const env = process.env.REACT_APP_API_ENV || CURRENT_ENV;

Sentry.init({
  dsn: 'https://58892b4c8cd74fbdbe069e1dd955335a@o557843.ingest.sentry.io/5690975',
  release: 'accumbo-admin@' + metadata.version,
  environment: env,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: env === 'production' ? 0.01 : 0.1,
  normalizeDepth: 2
});

const store = configureStore();

let currentLocale;
store.subscribe(() => {
  const locale = store.getState().i18n.locale;
  saveState(store.getState().auth, 'auth');

  if (currentLocale !== locale) {
    saveState(locale, 'locale');
    moment.locale(locale);
    currentLocale = locale;
  }
});

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root')
);
