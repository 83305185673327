import { useState, createContext, useContext } from 'react';
import { connect } from 'react-redux';
import { basePath } from '../../../api/apiClient';
import { showNotification } from '../../../actions';

type AbsenceContextValue = {
  doctors: Caregiver[];
  nurses: Caregiver[];
  selectedRoleFilter?: SelectOption;
  setSelectedRoleFilter(role: SelectOption): void;
  selectedCaregiverFilter?: SelectOption;
  setSelectedCaregiverFilter(caregiver: SelectOption): void;
  selectedPeriodFilter?: SelectOption;
  setSelectedPeriodFilter(period: SelectOption): void;
  toast(message: string, type: string): void;
  createAbsence(caregiverId: string, absence: Partial<CaregiverAbsence>): Promise<Response>;
  updateAbsence(absenceId: string, absence: Partial<CaregiverAbsence>): Promise<Response>;
  deleteAbsence(absenceId: string): Promise<Response>;
};

export const AbsenceContext = createContext<AbsenceContextValue>({
  doctors: [],
  nurses: [],
  setSelectedRoleFilter: () => undefined,
  setSelectedCaregiverFilter: () => undefined,
  setSelectedPeriodFilter: () => undefined,
  toast: () => undefined,
  createAbsence: () => new Promise((r) => r(new Response())),
  updateAbsence: () => new Promise((r) => r(new Response())),
  deleteAbsence: () => new Promise((r) => r(new Response()))
});

const AbsenceProvider = ({ authToken, showNotification, sharedData, children }) => {
  const [selectedRoleFilter, setSelectedRoleFilter] = useState<SelectOption>();
  const [selectedCaregiverFilter, setSelectedCaregiverFilter] = useState<SelectOption>();
  const [selectedPeriodFilter, setSelectedPeriodFilter] = useState<SelectOption>();

  const createAbsence = (caregiverId: string, absence: Partial<CaregiverAbsence>) => {
    return fetch(`${basePath}/admin/modules/caregiver-absence/${caregiverId}`, {
      headers: {
        authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(absence)
    });
  };

  const updateAbsence = (absenceId: string, absence: Partial<CaregiverAbsence>) => {
    return fetch(`${basePath}/admin/modules/caregiver-absence/${absenceId}`, {
      headers: {
        authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(absence)
    });
  };

  const deleteAbsence = (absenceId: string) => {
    return fetch(`${basePath}/admin/modules/caregiver-absence/${absenceId}`, {
      headers: {
        authorization: `Bearer ${authToken}`
      },
      method: 'DELETE'
    });
  };

  const value = {
    doctors: sharedData.caregivers,
    nurses: sharedData.nurses,
    selectedRoleFilter,
    setSelectedRoleFilter,
    selectedCaregiverFilter,
    setSelectedCaregiverFilter,
    selectedPeriodFilter,
    setSelectedPeriodFilter,
    toast: showNotification,
    createAbsence,
    updateAbsence,
    deleteAbsence
  };

  return <AbsenceContext.Provider value={value}>{children}</AbsenceContext.Provider>;
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    sharedData: state.sharedData
  };
};

const mapActionsToProps = {
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(AbsenceProvider);

export function useAbsence() {
  return useContext(AbsenceContext);
}
