import { removeItemFromArray, updateObjectInArray } from '../utils';

type HandoverValidationError = 'no_caregiver_selection' | 'region' | 'invalid_number';

type HandoverType = 'temporary' | 'permanent';

type HandoverReducerState = {
  validationError?: HandoverValidationError;
  startDate?: Date;
  endDate?: Date;
  numRemaining: number;
  numTotal: number;
  dateChangeModalVisible: boolean;
  selectedSecondaries: SecondaryCaregiver[];
  handoverType?: HandoverType;
};

type SecondaryCaregiver = {
  id: string;
  caregiverId?: string;
  numPatients: number;
  [key: string]: FixMe;
};

type HandoverAction = {
  type: string;
  propertyName?: string;
  propertyValue?: string;
  date?: Date;
  numRemaining?: number;
  numTotal: number;
  validationError?: HandoverValidationError;
  caregiverId?: string;
  id?: string;
  handoverType?: HandoverType;
};

export const initialState = {
  validationError: undefined,
  startDate: undefined,
  endDate: undefined,
  numRemaining: -1,
  numTotal: -1,
  dateChangeModalVisible: false,
  selectedSecondaries: [
    {
      id: Math.random().toString(36),
      caregiverId: undefined,
      numPatients: 0
    }
  ],
  handoverType: 'permanent' as HandoverType
};

const handoverReducer = (state: HandoverReducerState = initialState, action: HandoverAction) => {
  switch (action.type) {
    case 'setFromDate':
      return { ...state, startDate: action.date };
    case 'setToDate':
      return { ...state, endDate: action.date };
    case 'setNumRemaining':
      return {
        ...state,
        numRemaining: action.numRemaining,
        numTotal: action.numRemaining
      };
    case 'setNumTotal':
      return { ...state, numTotal: action.numTotal };
    case 'addSecondary':
      return {
        ...state,
        selectedSecondaries: [
          ...state.selectedSecondaries,
          {
            id: Math.random().toString(36),
            caregiverId: action.caregiverId,
            numPatients: ''
          }
        ]
      };
    case 'setPropertyForSecondary': {
      const updatedSecondaryIndex = state.selectedSecondaries.findIndex((secondary) => secondary.id === action.id);
      const updatedSecondary = state.selectedSecondaries.find((secondary) => secondary.id === action.id);
      if (updatedSecondary) {
        // Null coalesce to 'numPatients' as a fallback since the type definition states that `propertyName` can be undefined. Should never actually happen.
        updatedSecondary[action.propertyName ?? 'numPatients'] = action.propertyValue;
      }

      const selectedSecondaries = updateObjectInArray(state.selectedSecondaries, {
        item: updatedSecondary,
        index: updatedSecondaryIndex
      });

      return {
        ...state,
        numRemaining: state.selectedSecondaries.reduce((accumulator: number, currentValue: SecondaryCaregiver) => {
          return accumulator - (currentValue.numPatients || 0);
        }, state.numTotal),
        selectedSecondaries
      };
    }
    case 'setValidationError':
      return { ...state, validationError: action.validationError };
    case 'setNewToDate':
      return { ...state, newToDate: action.date };
    case 'removeSecondary': {
      const removedSecondaryIndex = state.selectedSecondaries.findIndex((secondary) => secondary.id === action.id);
      const removedSecondary = state.selectedSecondaries.find((secondary) => secondary.id === action.id);
      return {
        ...state,
        selectedSecondaries: removeItemFromArray(state.selectedSecondaries, { index: removedSecondaryIndex }),
        numRemaining: removedSecondary?.numPatients
          ? state.numRemaining + removedSecondary.numPatients
          : state.numRemaining
      };
    }
    case 'setHandoverType':
      return {
        ...state,
        handoverType: action.handoverType
      };
    case 'reset':
      return { ...initialState };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export default handoverReducer;
