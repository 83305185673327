import * as types from '../constants/actionTypes';

const initialState = {
  loadingProfileImage: false,
  profileImageUrl: null,
  profileImageError: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROFILE_IMAGE_REQUEST:
      return { ...state, loadingProfileImage: true };
    case types.GET_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        loadingProfileImage: false,
        profileImageUrl: window.URL.createObjectURL(action.payload),
        profileImageError: undefined
      };
    case types.GET_PROFILE_IMAGE_ERROR:
      return { ...state, loadingProfileImage: false, profileImageError: action.payload };
    case types.GET_SYSTEM_USER_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        profileImageUrl:
          action.userGuid === action.loggedInUserGuid
            ? window.URL.createObjectURL(action.payload)
            : state.profileImageUrl
      };
    case types.LOGOUT:
      return { ...state, profileImageUrl: null };
    default:
      return state;
  }
};

export default userReducer;
