import React from 'react';
import './SkeletonComponent.scss';

const SkeletonComponent = ({ width = '100px', height = '30px', margin = '5px', count = 1, as = 'div' }) => {
  const Tag = as;
  return (
    <>
      {new Array(count).fill(0).map((_, i) => (
        <Tag className="skeleton" key={i} style={{ width, height, marginBottom: margin }}></Tag>
      ))}
    </>
  );
};

export default SkeletonComponent;
