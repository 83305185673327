// @ts-nocheck
import { useContext } from 'react';
import { NavLink, Switch } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import PrivateRoute from '../PrivateRoute';
import AccountingReportView from './AccountingReportView';
import BatchView from './BatchView';
import { APPLICATION_BASE_PATH } from '../../constants';
import AccountingProvider, { AccountingContext } from './AccountingProvider';

const AccountingView = ({ location, match }) => {
  return (
    <AccountingProvider>
      <div className="column no-padding">
        <AccountingTabs />
        <div className={`card-container ${location.pathname === match.url ? 'left-tab-selected' : ''}`}>
          <Switch>
            <PrivateRoute
              exact
              path={`/${APPLICATION_BASE_PATH}/services/accounting`}
              component={AccountingReportView}
            />
            <PrivateRoute exact path={`/${APPLICATION_BASE_PATH}/services/accounting/batches`} component={BatchView} />
          </Switch>
        </div>
      </div>
    </AccountingProvider>
  );
};

export default AccountingView;

const AccountingTabs = () => {
  const { searchParams } = useContext(AccountingContext);

  return (
    <div className="tabs">
      <NavLink
        exact
        to={`/${APPLICATION_BASE_PATH}/services/accounting${
          searchParams.has('from') && searchParams.has('to')
            ? `?from=${searchParams.get('from')}&to=${searchParams.get('to')}`
            : ''
        }`}
      >
        <Translate value="accounting.tabs.summary" />
      </NavLink>
      <NavLink exact to={`/${APPLICATION_BASE_PATH}/services/accounting/batches`}>
        <Translate value="accounting.tabs.batches" />
      </NavLink>
    </div>
  );
};
