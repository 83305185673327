import { I18n, Translate } from 'react-redux-i18n';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Partner, usePartner } from '../../PartnerProvider';
import { ImageSelector } from '..';
import { env } from '../../../../api/apiClient';
import { ReactComponent as ExternalLinkIcon } from '../../../../assets/icons/external-link-icon.svg';
import { WEB_SCREENING_DEFAULTS } from '../../../../constants';
import styles from './WebScreeningForm.module.scss';

type Props = {
  partner?: Partner;
  required?: boolean;
  imageUrls: Record<string, string | undefined>;
  onImageSelection(name: string): void;
};

export default function WebScreeningForm({ partner, required, imageUrls, onImageSelection }: Props) {
  const { toast } = usePartner();
  const url = partner?.webScreeningConfiguration?.urlPath
    ? `https://${env !== 'production' ? 'test.' : ''}blodtryckstest.blodtrycksdoktorn.se/${
        partner?.webScreeningConfiguration.urlPath
      }`
    : '';

  return (
    <div className={`stack-2 ${styles.wrapper}`}>
      <div>
        <h4>URL</h4>
        <div className={styles.urlPathInput}>
          <div>
            <span>https://blodtryckstest.blodtrycksdoktorn.se/</span>
            <input
              type="text"
              name="urlPath"
              required={required}
              defaultValue={partner?.webScreeningConfiguration?.urlPath}
            />
          </div>
          {url ? (
            <>
              <CopyToClipboard onCopy={() => toast(I18n.t('notification.url_copied.success'), 'success')} text={url}>
                <span className={styles.copy}>
                  <Translate value="global.copy" />
                </span>
              </CopyToClipboard>
              <a className={styles.webScreeningLink} target="_blank" rel="noopener noreferrer" href={url}>
                <span>
                  <Translate value="global.open" /> {env !== 'production' ? '(testversion)' : ''}
                </span>
                <ExternalLinkIcon />
              </a>
            </>
          ) : null}
        </div>
      </div>
      <div>
        <h4>
          <Translate value="manage_partners.add_new_partner.web_screening.start_screen" />
        </h4>
        <div className="mb-20">
          <ImageSelector
            heading={I18n.t('manage_partners.add_new_partner.web_screening.partner_logo')}
            name="logoImage"
            imageSrc={imageUrls?.logoImage}
            required={required}
            onChange={onImageSelection('logoImage')}
          />
        </div>
        <div className="mb-20">
          <ImageSelector
            heading={I18n.t('manage_partners.add_new_partner.web_screening.start_screen_image')}
            name="startPageImage"
            imageSrc={imageUrls?.startPageImage}
            required={required}
            onChange={onImageSelection('startPageImage')}
          />
        </div>
        <div className="flex space-x-1 mb-10">
          <div className="w-50">
            <label htmlFor="web-screening-start-heading-sv">
              <Translate value="manage_partners.add_new_partner.web_screening.heading_sv" />
            </label>
            <input
              type="text"
              name="webScreeningStartPageHeadingSv"
              id="web-screening-start-heading-sv"
              defaultValue={
                partner?.webScreeningConfiguration?.startPage?.heading?.sv ||
                WEB_SCREENING_DEFAULTS.startPage.heading.sv
              }
            />
          </div>
          <div className="w-50">
            <label htmlFor="web-screening-start-heading-en">
              <Translate value="manage_partners.add_new_partner.web_screening.heading_en" />
            </label>
            <input
              type="text"
              name="webScreeningStartPageHeadingEn"
              id="web-screening-start-heading-en"
              defaultValue={
                partner?.webScreeningConfiguration?.startPage?.heading?.en ||
                WEB_SCREENING_DEFAULTS.startPage.heading.en
              }
            />
          </div>
        </div>
        <div className="flex space-x-1">
          <div className="w-50">
            <label htmlFor="web-screening-start-text-sv">
              <Translate value="manage_partners.add_new_partner.web_screening.text_sv" />
            </label>
            <textarea
              name="webScreeningStartPageTextSv"
              id="web-screening-start-text-sv"
              defaultValue={
                partner?.webScreeningConfiguration?.startPage?.text?.sv || WEB_SCREENING_DEFAULTS.startPage.text.sv
              }
            />
          </div>
          <div className="w-50">
            <label htmlFor="web-screening-start-text-en">
              <Translate value="manage_partners.add_new_partner.web_screening.text_en" />
            </label>
            <textarea
              name="webScreeningStartPageTextEn"
              id="web-screening-start-text-en"
              defaultValue={
                partner?.webScreeningConfiguration?.startPage?.text?.en || WEB_SCREENING_DEFAULTS.startPage.text.en
              }
            />
          </div>
        </div>
      </div>
      <div>
        <h4>
          <Translate value="manage_partners.add_new_partner.web_screening.main_cta" />
        </h4>
        <div className="mb-10">
          <label htmlFor="web-screening-main-cta">URL</label>
          <input
            type="text"
            name="webScreeningMainCtaUrl"
            id="web-screening-main-cta"
            required={required}
            defaultValue={partner?.webScreeningConfiguration ? partner?.webScreeningConfiguration.mainCta?.url : ''}
          />
        </div>
        <div className="flex space-x-1">
          <div className="w-50">
            <label htmlFor="web-screening-main-cta-text-sv">
              <Translate value="manage_partners.add_new_partner.web_screening.button_text_sv" />
            </label>
            <input
              type="text"
              name="webScreeningMainCtaTextSv"
              id="web-screening-main-cta-text-sv"
              defaultValue={
                partner?.webScreeningConfiguration?.mainCta?.text?.sv || WEB_SCREENING_DEFAULTS.mainCta.text.sv
              }
            />
          </div>
          <div className="w-50">
            <label htmlFor="web-screening-main-cta-text-en">
              <Translate value="manage_partners.add_new_partner.web_screening.button_text_en" />
            </label>
            <input
              type="text"
              name="webScreeningMainCtaTextEn"
              id="web-screening-main-cta-text-en"
              defaultValue={
                partner?.webScreeningConfiguration?.mainCta?.text?.en || WEB_SCREENING_DEFAULTS.mainCta.text.en
              }
            />
          </div>
        </div>
      </div>
      <div>
        <h4>
          <Translate value="manage_partners.add_new_partner.web_screening.secondary_cta" />
        </h4>
        <div className="mb-10">
          <label htmlFor="web-screening-secondary-cta">
            <Translate value="manage_partners.add_new_partner.web_screening.low_risk_cta_url_sv" />
          </label>
          <input
            type="text"
            name="webScreeningSecondaryCtaUrlSv"
            id="web-screening-secondary-cta-sv"
            defaultValue={partner?.webScreeningConfiguration?.secondaryCta?.url.sv || ''}
          />
        </div>
        <div className="mb-10">
          <label htmlFor="web-screening-secondary-cta">
            <Translate value="manage_partners.add_new_partner.web_screening.low_risk_cta_url_en" />
          </label>
          <input
            type="text"
            name="webScreeningSecondaryCtaUrlEn"
            id="web-screening-secondary-cta-en"
            defaultValue={partner?.webScreeningConfiguration?.secondaryCta?.url.en || ''}
          />
        </div>
        <div className="flex space-x-1">
          <div className="w-50">
            <label htmlFor="web-screening-secondary-cta-text-sv">
              <Translate value="manage_partners.add_new_partner.web_screening.button_text_sv" />
            </label>
            <input
              type="text"
              name="webScreeningSecondaryCtaTextSv"
              id="web-screening-secondary-cta-text-sv"
              defaultValue={partner?.webScreeningConfiguration?.secondaryCta?.text.sv || ''}
            />
          </div>
          <div className="w-50">
            <label htmlFor="web-screening-secondary-cta-text-en">
              <Translate value="manage_partners.add_new_partner.web_screening.button_text_en" />
            </label>
            <input
              type="text"
              name="webScreeningSecondaryCtaTextEn"
              id="web-screening-secondary-cta-text-en"
              defaultValue={partner?.webScreeningConfiguration?.secondaryCta?.text.en || ''}
            />
          </div>
        </div>
      </div>
      <div>
        <h4>
          <Translate value="manage_partners.add_new_partner.web_screening.results_high" />
        </h4>
        <div className="flex space-x-1 mb-10">
          <div className="w-50">
            <label htmlFor="web-screening-high-risk-heading-sv">
              <Translate value="manage_partners.add_new_partner.web_screening.heading_sv" />
            </label>
            <input
              type="text"
              name="webScreeningHighRiskHeadingSv"
              id="web-screening-high-risk-heading-sv"
              defaultValue={
                partner?.webScreeningConfiguration?.highRisk?.heading?.sv || WEB_SCREENING_DEFAULTS.highRisk.heading.sv
              }
            />
          </div>
          <div className="w-50">
            <label htmlFor="web-screening-high-risk-heading-en">
              <Translate value="manage_partners.add_new_partner.web_screening.heading_en" />
            </label>
            <input
              type="text"
              name="webScreeningHighRiskHeadingEn"
              id="web-screening-high-risk-heading-en"
              defaultValue={
                partner?.webScreeningConfiguration?.highRisk?.heading?.en || WEB_SCREENING_DEFAULTS.highRisk.heading.en
              }
            />
          </div>
        </div>
        <div className="flex space-x-1">
          <div className="w-50">
            <label htmlFor="web-screening-high-risk-text-sv">
              <Translate value="manage_partners.add_new_partner.web_screening.text_sv" />
            </label>
            <textarea
              name="webScreeningHighRiskTextSv"
              id="web-screening-high-risk-text-sv"
              defaultValue={
                partner?.webScreeningConfiguration?.highRisk?.text?.sv || WEB_SCREENING_DEFAULTS.highRisk.text.sv
              }
            />
          </div>
          <div className="w-50">
            <label htmlFor="web-screening-high-risk-text-en">
              <Translate value="manage_partners.add_new_partner.web_screening.text_en" />
            </label>
            <textarea
              name="webScreeningHighRiskTextEn"
              id="web-screening-high-risk-text-en"
              defaultValue={
                partner?.webScreeningConfiguration?.highRisk?.text?.en || WEB_SCREENING_DEFAULTS.highRisk.text.en
              }
            />
          </div>
        </div>
      </div>
      <div>
        <h4>
          <Translate value="manage_partners.add_new_partner.web_screening.results_low" />
        </h4>
        <div className="flex space-x-1 mb-10">
          <div className="w-50">
            <label htmlFor="web-screening-low-risk-heading-sv">
              <Translate value="manage_partners.add_new_partner.web_screening.heading_sv" />
            </label>
            <input
              type="text"
              name="webScreeningLowRiskHeadingSv"
              id="web-screening-low-risk-heading-sv"
              defaultValue={partner?.webScreeningConfiguration?.lowRisk?.heading?.sv}
            />
          </div>
          <div className="w-50">
            <label htmlFor="web-screening-low-risk-heading-en">
              <Translate value="manage_partners.add_new_partner.web_screening.heading_en" />
            </label>
            <input
              type="text"
              name="webScreeningLowRiskHeadingEn"
              id="web-screening-low-risk-heading-en"
              defaultValue={partner?.webScreeningConfiguration?.lowRisk?.heading?.en}
            />
          </div>
        </div>
        <div className="flex space-x-1 mb-10">
          <div className="w-50">
            <label htmlFor="web-screening-low-risk-text-sv">
              <Translate value="manage_partners.add_new_partner.web_screening.text_sv" />
            </label>
            <textarea
              name="webScreeningLowRiskTextSv"
              id="web-screening-low-risk-text-sv"
              defaultValue={partner?.webScreeningConfiguration?.lowRisk?.text?.sv}
            />
          </div>
          <div className="w-50">
            <label htmlFor="web-screening-low-risk-text-en">
              <Translate value="manage_partners.add_new_partner.web_screening.text_en" />
            </label>
            <textarea
              name="webScreeningLowRiskTextEn"
              id="web-screening-low-risk-text-en"
              defaultValue={partner?.webScreeningConfiguration?.lowRisk?.text?.en}
            />
          </div>
        </div>
        <div className={styles.imageSelectorWrapper}>
          <ImageSelector
            heading={I18n.t('manage_partners.add_new_partner.web_screening.low_risk_results_image_sv')}
            name="lowRiskWebScreeningImageSv"
            imageSrc={imageUrls?.lowRiskWebScreeningImageSv}
            onChange={onImageSelection('lowRiskWebScreeningImageSv')}
          />
          <ImageSelector
            heading={I18n.t('manage_partners.add_new_partner.web_screening.low_risk_results_image_en')}
            name="lowRiskWebScreeningImageEn"
            imageSrc={imageUrls?.lowRiskWebScreeningImageEn}
            onChange={onImageSelection('lowRiskWebScreeningImageEn')}
          />
        </div>
      </div>
    </div>
  );
}
