import { useState, useEffect } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { useHistory, useParams } from 'react-router-dom';
import { PartnerFacilityParams } from '..';
import { APPLICATION_BASE_PATH } from '../../../constants';
import { removeItemFromArray, toggleItem } from '../../../utils';
import Card from '../../Card';
import ErrorMessage from '../../ErrorMessage';
import LoaderButton from '../../LoaderButton';
import { PartnerUser, PartnerServiceState, usePartner } from '../PartnerProvider';
import Select from '../../Select';
import api from '../../../api/apiClient';

type TitleOption = {
  value: string;
  label: string;
};

export default function CreateUser({ editMode }) {
  const history = useHistory();
  const [titleOptions, setTitleOptions] = useState<TitleOption[]>([]);
  const [selectedTitle, setSelectedTitle] = useState<string>('');
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const { partnerId, facilityId, userId } = useParams<PartnerFacilityParams>();
  const [user, setUser] = useState<PartnerUser>();
  const { userServices, partners, getUser, createUser, updateUser, getPartnerFacility, removeUser, toast } =
    usePartner();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingUser, setFetchingUser] = useState(false);
  const [fetchUserError, setFetchUserError] = useState<unknown>();
  const [selectableServices, setSelectableServices] = useState<string[]>([]);
  const [removingUser, setRemovingUser] = useState(false);
  const [handlesCertificatePatients, setHandlesCertificatePatients] = useState(false);
  const redirectPath = facilityId
    ? `/${APPLICATION_BASE_PATH}/services/partners/${partnerId}/facility/${facilityId}`
    : `/${APPLICATION_BASE_PATH}/services/partners/${partnerId}`;

  useEffect(() => {
    if (editMode) {
      setFetchingUser(true);
      getUser(partnerId, userId)
        .then((res) => {
          if (res.users?.length) {
            const user = res.users[0];
            setUser(user);
            if (user?.title) {
              setSelectedTitle(user.title);
            }
            setSelectedServices(user?.services?.filter((s) => s.selected).map((s) => s.service) ?? []);
            if (user?.serviceOptions?.recommendationReasons?.includes('certificate')) {
              setHandlesCertificatePatients(true);
            }
          }
        })
        .catch(setFetchUserError)
        .finally(() => setFetchingUser(false));
    }

    if (facilityId) {
      // Todo: Handle differently? Unnecessary request?
      getPartnerFacility(partnerId, facilityId).then((res) => {
        if (res.facilities?.length) {
          const facility = res.facilities[0];
          setSelectableServices(facility?.services?.filter((s) => s.selected).map((s) => s.service) ?? []);
        }
      });
    } else {
      setSelectableServices(
        partners
          .find((p) => p.id === partnerId)
          ?.services?.filter((s) => s.selected)
          .map((s) => s.service) ?? []
      );
    }
  }, []);

  useEffect(() => {
    if (titleOptions.length) {
      return;
    }

    api.getPartnerUserTitles().then((titles) => {
      setTitleOptions(
        titles.map((title) => {
          return {
            value: title,
            label: I18n.t(`manage_partners.users.titles.${title}`)
          };
        })
      );
    });
  }, [api, titleOptions, setTitleOptions]);

  const getUserFromFormData = (e) => {
    const formData = new FormData(e.target);
    const partner = partners.find((p) => p.id === partnerId);
    let recommendationReasons = partner?.recommendationInfo?.recommendationReasons || [];
    if (!handlesCertificatePatients && recommendationReasons.includes('certificate')) {
      recommendationReasons = removeItemFromArray(recommendationReasons, {
        index: recommendationReasons.findIndex((r) => r === 'certificate')
      });
    }

    const user = {
      personalNumber: formData.get('personalNumber')?.toString()?.replace('-', ''),
      givenName: formData.get('givenName')?.toString(),
      familyName: formData.get('familyName')?.toString(),
      email: formData.get('email')?.toString(),
      title: selectedTitle,
      services: userServices.map(
        (s) => ({ service: s, selected: selectedServices.includes(s), disabled: false } as PartnerServiceState)
      ),
      active: true,
      serviceOptions: { recommendationReasons }
    };

    return user;
  };

  const createNew = async (e) => {
    e.preventDefault();

    const newUser = getUserFromFormData(e);

    try {
      setIsLoading(true);
      await createUser(partnerId, newUser, facilityId);
      history.replace(redirectPath, { newUser: true });
    } catch (err) {
      console.log(err);
      toast(I18n.t('notification.add_partner_user.error'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (e) => {
    e.preventDefault();

    const updatedUser = getUserFromFormData(e);

    try {
      setIsLoading(true);
      await updateUser(partnerId, userId, updatedUser);
      history.push(redirectPath, { updatedUser: true });
    } catch (err) {
      console.log(err);
      toast(I18n.t('notification.update_partner_user.error'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleService = (service) => {
    setSelectedServices(toggleItem(selectedServices, service));
  };

  const remove = async () => {
    if (!user?.id) {
      return;
    }

    if (window.confirm(I18n.t('manage_partners.users.confirm_delete'))) {
      setRemovingUser(true);
      try {
        await removeUser(partnerId, user.id);
        history.replace(redirectPath, { removedUser: true });
      } catch (err) {
        console.log(err);
        toast(I18n.t('notification.remove_partner_user.error'), 'error');
      } finally {
        setRemovingUser(false);
      }
    }
  };

  return (
    <Card
      titleI18nKey={`manage_partners.add_user.header${editMode ? '_edit' : ''}`}
      errorI18nKey="manage_partners.error"
      additionalClass="x-large"
      isLoading={fetchingUser}
    >
      {!fetchUserError ? (
        <form onSubmit={editMode ? update : createNew} className="partner-form">
          <div>
            <label>
              <h4>
                <Translate value="global.personal_number" />
              </h4>
              <input
                type="text"
                name="personalNumber"
                placeholder="ÅÅÅÅMMDD-XXXX"
                defaultValue={user?.personalNumber}
                disabled={fetchingUser}
                required
              />
            </label>
          </div>
          <div className=" flex">
            <label className="flex-1 mr-20">
              <h4>
                <Translate value="manage_partners.users.given_name" />
              </h4>
              <input type="text" name="givenName" defaultValue={user?.givenName} disabled={fetchingUser} required />
            </label>
            <label className="flex-1">
              <h4>
                <Translate value="manage_partners.users.family_name" />
              </h4>
              <input type="text" name="familyName" defaultValue={user?.familyName} disabled={fetchingUser} required />
            </label>
          </div>
          <div>
            <label>
              <h4>
                <Translate value="manage_partners.users.email" />
              </h4>
              <input type="text" name="email" defaultValue={user?.email} disabled={fetchingUser} required />
            </label>
          </div>

          <div>
            <label>
              <h4>
                <Translate value="manage_partners.users.title" />
              </h4>
            </label>
            <div>
              <Select
                placeholder={I18n.t('manage_partners.users.select_title')}
                isSearchable={false}
                value={titleOptions.find(({ value }) => value === selectedTitle)}
                onChange={({ value }) => {
                  setSelectedTitle(value);
                }}
                name="title"
                classNamePrefix="single-select"
                options={titleOptions}
              />
            </div>
          </div>
          <div className="mt-30">
            <h3>
              <Translate value="manage_partners.users.services" />
            </h3>
            <div>
              {userServices.map((service, i) => (
                <div key={i} className="mb-5">
                  <input
                    type="checkbox"
                    checked={selectedServices.includes(service)}
                    id={`${service}-checkbox`}
                    disabled={fetchingUser || !selectableServices.includes(service)}
                    onChange={() => toggleService(service)}
                  />
                  <label htmlFor={`${service}-checkbox`} className="ml-5 fw-500">
                    <Translate value={`manage_partners.service.${service}`} />
                  </label>
                </div>
              ))}
            </div>
          </div>
          {partners
            .find((p) => p.id === partnerId)
            ?.recommendationInfo?.recommendationReasons.includes('certificate') &&
          selectedServices.includes('recommendation') ? (
            <div className="mt-30">
              <h3>
                <Translate value="global.other" />
              </h3>
              <div>
                <input
                  type="checkbox"
                  checked={handlesCertificatePatients}
                  id="certificate-checkbox"
                  disabled={fetchingUser}
                  onChange={() => setHandlesCertificatePatients(!handlesCertificatePatients)}
                />
                <label htmlFor="certificate-checkbox" className="ml-5 fw-500">
                  <Translate value="manage_partners.users.recommends_certificate_patients" />
                </label>
              </div>
            </div>
          ) : null}
          <div className="card-buttons">
            {editMode ? (
              <LoaderButton isLoading={removingUser} onClick={remove} buttonType="destructive">
                <Translate value="manage_partners.users.button.remove" />
              </LoaderButton>
            ) : null}
            <LoaderButton type="submit" isLoading={isLoading}>
              {editMode ? (
                <Translate value="manage_partners.users.button.update" />
              ) : (
                <Translate value="manage_partners.users.button.add" />
              )}
            </LoaderButton>
          </div>
        </form>
      ) : (
        <ErrorMessage error={fetchUserError} errorI18nKey="manage_partners.users.fetch_user_error" />
      )}
    </Card>
  );
}
