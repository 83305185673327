import React from 'react';
import './Button.scss';
import { Link } from 'react-router-dom';
import { APPLICATION_BASE_PATH } from '../../constants';

type ButtonProps = {
  children: React.ReactNode;
  /** Native button type */
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'medium';
  /** The button's visual style */
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'destructive' | 'text' | 'input-attached' | 'text-destructive';
  disabled?: boolean;
  /** Indicates whether the button should occupy the full width of its parent container */
  fullWidth?: boolean;
  onClick?: () => void;
  as?: 'button' | 'a';
  to?: string;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  children,
  type = 'button',
  size,
  buttonType = 'primary',
  disabled = false,
  fullWidth = false,
  as = 'button',
  to,
  className,
  onClick
}) => {
  const Tag = as === 'button' ? 'button' : LinkWrapper;

  return (
    <Tag
      to={to}
      type={type}
      className={`button button-${buttonType} ${fullWidth ? 'full-width' : ''} ${size ? `button-size-${size}` : ''} ${
        className || ''
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Tag>
  );
};

export default Button;

const LinkWrapper = ({ to, children, ...rest }) => {
  return to ? (
    <Link to={`/${APPLICATION_BASE_PATH}${to}`} {...rest}>
      {children}
    </Link>
  ) : null;
};
