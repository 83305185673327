import { connect } from 'react-redux';

type Props = {
  currentMember: FixMe;
  next?: boolean;
  legacy?: boolean;
  children: React.ReactNode;
};

const Generation = ({ currentMember, next, legacy, children }: Props) => {
  if (currentMember?.enableNextGeneration && next) {
    return children;
  }

  if (!currentMember?.enableNextGeneration && legacy) {
    return children;
  }

  return null;
};

const mapStateToProps = (state) => {
  return {
    currentMember: state.members.currentMember
  };
};

// @ts-ignore
export default connect(mapStateToProps)(Generation);
